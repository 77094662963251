// @flow
import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useTheme } from '@material-ui/core/styles';
import { showAlert } from 'actions/app';
import { setBulkEmailToInitial, startRemovingAllAttachments } from 'actions/bulkEmail';
import { InlineFilters } from 'features/command-center';
import useDatatable, {
  buildButtonRendererDefinitionByObject,
  getColumnPreferences,
  getColumnsToRender
} from 'hooks/datatable';
import useMultipleSelection from 'hooks/multipleSelection';
import { useUrlParamsUpdater } from 'hooks/urlParamsUpdater';
import usePageTitle from 'hooks/usePageTitle';
import { UserProviderContext } from 'providers/UserProvider';
import { EntityRoutes } from 'routes/constants';
import { archiveMultipleSearchProjects } from 'services/searchProjects';
import {
  archiveSearchProjectFolder,
  getAllSearchProjectsFolders
} from 'services/searchProjects/folders';
import strings from 'strings';
import FPIconButton from 'UI/components/atoms/FPIconButton';
import { When } from 'UI/components/atoms/When';
import AutocompleteSelect from 'UI/components/molecules/AutocompleteSelect';
import FPButtonMenu from 'UI/components/molecules/FPButtonMenu';
import BulkEmailDrawer from 'UI/components/organisms/BulkEmailDrawer';
import DecisionDialog from 'UI/components/organisms/DecisionDialog';
import SearchProjectsFolderFormDrawer from 'UI/components/organisms/SearchProjects/FolderFormDrawer/SearchProjectsFolderFormDrawer';
import RestoreSearchProjectFolderDrawer from 'UI/components/organisms/SearchProjects/RestoreFolderDrawer';
import RestoreSearchProjectDialog from 'UI/components/organisms/SearchProjects/RestoreSearchProjectDialog.jsx';
import RestoreSearchProjectDrawer from 'UI/components/organisms/SearchProjects/RestoreSearchProjectDrawer';
import TreeViewList from 'UI/components/organisms/TreeViewList';
import DualPanelLayout from 'UI/components/templates/DualPanelLayout/DualPanelLayout';
import EmptyPlaceholder from 'UI/components/templates/EmptyPlaceholder';
import {
  ScrollableDataTable,
  ScrollableDataTableLayout
} from 'UI/components/templates/ScrollableDataTableLayout';
import {
  ARCHIVE_AVAILABILITY_DAYS,
  PageTitles,
  SEARCH_PROJECT_ITEM_TYPE_IDS,
  SEARCH_PROJECT_ROOT_FOLDERS
} from 'UI/constants/defaults';
import { Endpoints } from 'UI/constants/endpoints';
import { EntityType } from 'UI/constants/entityTypes';
import { FeatureFlags } from 'UI/constants/featureFlags';
import { SvgNewFolderIcon, SvgNoSearchProjectsFolders } from 'UI/res';
import { hasFeatureFlag, idOptionSelected, objectToArray } from 'UI/utils';
import { OptionRenderers } from 'UI/utils/renderers';

import {
  ArchiveSearchProjectColumnsDefinitions,
  ColumnsDefinitions,
  FOLDERS_FEATURE_FILTER_NAMES,
  VirtualProps
} from '../columns';

import CustomSearchProjectsIntoFoldersToolbar from './CustomSearchProjectsIntoFoldersToolbar';
import { useStyles } from './styles';

const { mainPage: pageCopies, dialogs: dialogsCopies } = strings.searchProjects;

export const ARCHIVE_FOLDER_RETENTION_DAYS =
  window.GPAC_ENV?.SEARCH_PROJECT_FOLDER_ARCHIVE_RETENTION_DAYS ||
  process.env.REACT_APP_REACT_APP_SEARCH_PROJECT_FOLDER_ARCHIVE_RETENTION_DAYS ||
  30;
const PAGE_KEY = 'searchProjects-list';
const orderByOptions = {
  column: 'created_at',
  direction: 'desc'
};

const PERSISTENCE_KEY = 'searchProjectsIntoFolders';

const SearchProjectsIntoFolders = ({ history, location }) => {
  usePageTitle({ title: PageTitles.SearchProject });
  const classes = useStyles();
  const { 400: grey400 } = useTheme().palette.grey;
  const [currentUser] = useContext(UserProviderContext);
  const finalColumnsDefinitions = [
    ...ColumnsDefinitions,
    ...ArchiveSearchProjectColumnsDefinitions
  ];
  const initialPreferences = getColumnPreferences(
    PAGE_KEY,
    0,
    orderByOptions,
    finalColumnsDefinitions,
    0,
    location.search
  );

  const [searchProject, setSearchProject] = useState(null);
  const [selectedFolder, setSelectedFolder] = useState(SEARCH_PROJECT_ROOT_FOLDERS.mine);
  const [uiState, setUiState] = useState({
    isArchiveFolderDialogOpen: false,
    isArchiveMultipleSearchProjectsDialogOpen: false,
    isArchivingFolder: false,
    isFolderDrawerOpen: false,
    isFolderBeingEdited: false,
    isBulkDrawerOpen: false,
    isRestoreFolderDrawerOpen: false,
    isRestoreSearchProjectDialogOpen: false,
    isRestoreSearchProjectDrawerOpen: false,
    fetchingDialog: false,
    shouldMoveSearchProjects: {
      toExistingFolder: false,
      toNewFolder: false
    },
    treeViewListState: {
      foldersIdToRefresh: [],
      foldersIdToShrink: [],
      isLoading: false,
      hasLoadedCorrectly: false
    }
  });

  const shouldShowArchiveMultipleSearchProjectsDialog =
    uiState.isArchiveMultipleSearchProjectsDialogOpen &&
    hasFeatureFlag(FeatureFlags.SearchProjectsArchiveMultiple);
  const shouldDisableCreateFolderButton = selectedFolder.created_by
    ? selectedFolder.created_by !== currentUser.id || selectedFolder.isArchived
    : selectedFolder.id !== SEARCH_PROJECT_ROOT_FOLDERS.mine.id;
  const shouldShowFolderMenuItems =
    selectedFolder?.created_by === currentUser.id && !selectedFolder?.isArchived;
  const shouldShowRestoreTableAction =
    selectedFolder.id === SEARCH_PROJECT_ROOT_FOLDERS.archived.id || selectedFolder?.isArchived;
  const isRestoreDrawerFeatureActive =
    shouldShowRestoreTableAction &&
    hasFeatureFlag(FeatureFlags.SearchProjectsRestoreSelectingFolder);

  const extendParams = useCallback(
    (_, extendFilters) => {
      const optionalFilters = {};
      Object.keys(extendFilters).forEach(key => {
        optionalFilters[key] = extendFilters[key].value[key];
      });

      return {
        folderId: selectedFolder.id,
        ...optionalFilters
      };
    },
    [selectedFolder.id]
  );

  const {
    columnOrder,
    columnPreferences,
    count,
    data,
    getData,
    handleColumnDisplayChange,
    handleColumnSortChange,
    handleFiltersChange,
    handleFiltersToggle,
    handleKeywordChange,
    handlePageChange,
    handlePerPageChange,
    listState
  } = useDatatable({
    columnsDefinitions: finalColumnsDefinitions,
    endpoint: Endpoints.SearchProjects,
    entityName: 'Search Projects',
    entityType: EntityType.SearchProjects,
    initialPreferences,
    key: PAGE_KEY,
    orderByOptions,
    paramsExtender: extendParams,
    shouldScrollOnNavigate: true,
    virtualProps: VirtualProps
  });

  const shouldEnableMultipleSelection =
    data.length > 0 &&
    (selectedFolder.id === SEARCH_PROJECT_ROOT_FOLDERS.mine.id ||
      (selectedFolder.created_by === currentUser.id && !selectedFolder.isArchived));

  const mappedSelectedSearchProjects = useMemo(
    () =>
      data.map(item => ({
        id: item.id,
        type: 'Search Project',
        type_id: SEARCH_PROJECT_ITEM_TYPE_IDS.searchProject
      })),
    [data]
  );

  const {
    filteredItems,
    handleRowSelection,
    multiSelectComponents,
    selectedIndexesInPage,
    setToInitial: setSelectionToInitial,
    totalSelected
  } = useMultipleSelection({
    count,
    data: mappedSelectedSearchProjects,
    disableSelectAllCheckbox: true
  });

  const { columns, orderBy, direction } = columnPreferences;
  useUrlParamsUpdater({ history, location, orderBy });

  const { isLoading, showWholeSkeleton, page, perPage, keyword, userFilter } = listState;

  const dispatch = useDispatch();

  const handleTreeViewLoad = useCallback(
    newState => setUiState(prev => ({ ...prev, treeViewListState: newState })),
    [setUiState]
  );

  useEffect(
    () => () => {
      dispatch(setBulkEmailToInitial());
      dispatch(startRemovingAllAttachments());
    },
    [dispatch]
  );

  const togglers = {
    folderDrawer:
      ({ open, edit, moveToNewFolder, moveToExistingFolder }) =>
      () =>
        setUiState(prev => ({
          ...prev,
          isFolderDrawerOpen: open,
          isFolderBeingEdited: edit,
          shouldMoveSearchProjects: {
            toExistingFolder: moveToExistingFolder,
            toNewFolder: moveToNewFolder
          }
        })),
    archiveFolderDialog:
      ({ open }) =>
      () =>
        setUiState(prev => ({ ...prev, isArchiveFolderDialogOpen: open })),
    restoreFolderDrawer:
      ({ open }) =>
      () =>
        setUiState(prev => ({ ...prev, isRestoreFolderDrawerOpen: open })),
    searchProjectRestoreDialog:
      ({ open }) =>
      () =>
        setUiState(prev => ({ ...prev, isRestoreSearchProjectDialogOpen: open })),
    searchProjectRestoreDrawer:
      ({ open }) =>
      () =>
        setUiState(prev => ({ ...prev, isRestoreSearchProjectDrawerOpen: open }))
  };

  const archiveMultipleSearchProjectsToggler =
    ({ open }) =>
    () =>
      setUiState(prev => ({ ...prev, isArchiveMultipleSearchProjectsDialogOpen: open }));

  const handleSendBulk = ({ rowIndex }) => {
    const {
      name: { id, name },
      total_items
    } = data[rowIndex];
    const searchProjectAux = { id, name, totalItems: total_items };
    setSearchProject(searchProjectAux);
    setUiState(prev => ({ ...prev, isBulkDrawerOpen: true }));
  };

  const handleOpenRestoreSearchProjectForm = ({ rowIndex }) => {
    const {
      id,
      name: { name },
      archived_at
    } = data[rowIndex];
    setSearchProject({ id, name, archivedAt: archived_at });
    togglers[
      isRestoreDrawerFeatureActive ? 'searchProjectRestoreDrawer' : 'searchProjectRestoreDialog'
    ]({ open: true })();
  };

  const handleRestoreSearchProject = () => {
    setSearchProject(null);
    getData();
  };

  const handleCloseBulkEmailDrawer = () => {
    setSearchProject(null);
    setUiState(prev => ({ ...prev, isBulkDrawerOpen: false }));
  };

  const buttonOptionsToRender = shouldShowRestoreTableAction
    ? {
        buttonName: pageCopies.dataTable.actions.restore,
        onClick: handleOpenRestoreSearchProjectForm
      }
    : {
        buttonName: pageCopies.dataTable.actions.sendBulk,
        onClick: handleSendBulk,
        additionalPropsBuilder: rowIndex => {
          const { total_items: spTotalItems, bulkable_items } = data[rowIndex];
          const hasntBulkableItems = spTotalItems === 0 || bulkable_items === 0;
          return { disabled: hasntBulkableItems };
        }
      };

  const finalColumns = getColumnsToRender([
    ...columns,
    buildButtonRendererDefinitionByObject(buttonOptionsToRender)
  ]);

  const handleSelectTreeViewItem = selectedItem => {
    if (selectedFolder.id !== selectedItem.id) {
      setSelectionToInitial();
      setSelectedFolder(selectedItem);
    }
  };

  const handleSubmitFolder = ({
    folderName,
    parentFoldersId,
    shouldRefreshDataTable,
    shouldRefreshSelectedFolder
  }) => {
    if (shouldRefreshSelectedFolder) setSelectedFolder(prev => ({ ...prev, name: folderName }));

    if (shouldRefreshDataTable) {
      setSelectionToInitial();
      getData();
    }

    setUiState(prev => ({
      ...prev,
      treeViewListState: {
        ...prev.treeViewListState,
        foldersIdToRefresh: objectToArray(parentFoldersId)
      }
    }));
  };

  const handleArchiveFolder = async () => {
    setUiState(prev => ({ ...prev, isArchivingFolder: true }));
    const { alert, success } = await archiveSearchProjectFolder(selectedFolder.id);
    dispatch(showAlert(alert));
    if (success) {
      const archiveFolderId = SEARCH_PROJECT_ROOT_FOLDERS.archived.id;
      setSelectedFolder(prev => ({ ...prev, isArchived: true, parent_id: archiveFolderId }));
      setUiState(prev => ({
        ...prev,
        treeViewListState: {
          ...prev.treeViewListState,
          foldersIdToRefresh: [selectedFolder.parent_id, archiveFolderId],
          foldersIdToShrink: [selectedFolder.id]
        }
      }));
    }
    setUiState(prev => ({ ...prev, isArchivingFolder: false }));
    togglers.archiveFolderDialog({ open: false })();
  };

  const handleRestoreFolder = ({ folderId, parentFoldersId }) => {
    setSelectedFolder(prev => ({
      ...prev,
      isArchived: false,
      parent_id: parentFoldersId.current,
      archived_at: null,
      archived_by: null
    }));

    setUiState(prev => ({
      ...prev,
      treeViewListState: {
        ...prev.treeViewListState,
        foldersIdToRefresh: objectToArray(parentFoldersId),
        foldersIdToShrink: [folderId]
      }
    }));
  };

  const handleFoldersShrink = () =>
    setUiState(prev => ({
      ...prev,
      treeViewListState: {
        ...prev.treeViewListState,
        foldersIdToShrink: []
      }
    }));

  const handleFoldersRefresh = () =>
    setUiState(prev => ({
      ...prev,
      treeViewListState: { ...prev.treeViewListState, foldersIdToRefresh: [] }
    }));

  const handleArchiveMultipleSearchProjects = async ok => {
    if (!ok) return;

    setUiState(prev => ({ ...prev, fetchingDialog: true }));
    const searchProjectsIds = filteredItems.data.map(({ id }) => id);
    const { alert, success } = await archiveMultipleSearchProjects(searchProjectsIds);
    dispatch(showAlert(alert));
    if (success) {
      archiveMultipleSearchProjectsToggler({ open: false })();
      setSelectionToInitial();
      getData();
    }
    setUiState(prev => ({ ...prev, fetchingDialog: false }));
  };

  const onSearchSearchProject = (_, selectedSearchProject) => {
    history.push(EntityRoutes.SearchProjectPreview.replace(':id', selectedSearchProject.id));
  };

  return (
    <>
      <DualPanelLayout
        classes={{ root: classes.layoutRoot }}
        leftPanel={{
          header: {
            title: pageCopies.title,
            Actions: (
              <FPIconButton
                tooltipProps={{ title: pageCopies.layoutContainer.foldersList.actions.newFolder }}
                onClick={togglers.folderDrawer({ open: true, edit: false })}
                disabled={shouldDisableCreateFolderButton}
                icon={SvgNewFolderIcon}
                iconProps={{ onSetColor: ({ primary }) => primary.main }}
              />
            ),
            Header: (
              <AutocompleteSelect
                name="searchProjectSearch"
                placeholder={pageCopies.searchBox.placeholder}
                getOptionLabel={option =>
                  !!option.name && !!option.totalItems
                    ? `${option.name} (${option.totalItems})`
                    : ''
                }
                onSelect={onSearchSearchProject}
                typeahead
                typeaheadLimit={null}
                url={Endpoints.SearchProjects}
                groupBy={option =>
                  option.created_by === currentUser.id
                    ? pageCopies.searchBox.groupsTitles.mine
                    : pageCopies.searchBox.groupsTitles.other
                }
                renderOption={OptionRenderers.searchProjects}
                getOptionSelected={idOptionSelected}
                noMargin
              />
            )
          },
          Content: (
            <TreeViewList
              TreeViewLoaderProps={{
                foldersIdToRefresh: uiState.treeViewListState.foldersIdToRefresh,
                foldersIdToShrink: uiState.treeViewListState.foldersIdToShrink,
                onClickTreeItem: handleSelectTreeViewItem,
                onFoldersRefresh: handleFoldersRefresh,
                onFoldersShrink: handleFoldersShrink,
                onLoadTreeView: handleTreeViewLoad,
                selectedTreeItemId: selectedFolder.id,
                service: {
                  method: getAllSearchProjectsFolders
                }
              }}
            />
          )
        }}
        rightPanel={{
          header: {
            HeaderTitle: selectedFolder?.name || userFilter.title,
            Actions: (
              <FPButtonMenu
                isIconButton
                menuItems={[
                  {
                    action: togglers.folderDrawer({ open: true, edit: true }),
                    title: pageCopies.layoutContainer.header.menuItemsTitles.edit,
                    visible: shouldShowFolderMenuItems
                  },
                  {
                    action: togglers.archiveFolderDialog({ open: true }),
                    title: pageCopies.layoutContainer.header.menuItemsTitles.archive,
                    visible: shouldShowFolderMenuItems
                  },
                  {
                    action: togglers.restoreFolderDrawer({ open: true }),
                    title: pageCopies.layoutContainer.header.menuItemsTitles.restore,
                    visible: selectedFolder.isArchived
                  }
                ]}
              />
            )
          },
          Content: (
            <ScrollableDataTableLayout
              topContent={
                <div className={classes.inlineFilters}>
                  <InlineFilters
                    filters={FOLDERS_FEATURE_FILTER_NAMES}
                    onFiltersChange={handleFiltersChange}
                    persistenceKey={PERSISTENCE_KEY}
                  />
                </div>
              }
              content={
                <>
                  <When
                    condition={
                      uiState.treeViewListState.hasLoadedCorrectly &&
                      !uiState.treeViewListState.isLoading
                    }
                  >
                    <ScrollableDataTable
                      components={multiSelectComponents}
                      columnOrder={columnOrder?.length ? columnOrder : undefined}
                      columns={finalColumns}
                      count={count || 0}
                      CustomToolbar={
                        <CustomSearchProjectsIntoFoldersToolbar
                          actions={{
                            onOpenFolderDrawer: togglers.folderDrawer,
                            onOpenArchiveDialog: archiveMultipleSearchProjectsToggler
                          }}
                          totalSelected={totalSelected}
                        />
                      }
                      data={data}
                      draggableColumns={{
                        enabled: true
                      }}
                      filter={false}
                      isExpandable
                      loading={showWholeSkeleton}
                      onColumnDisplayClick={handleColumnDisplayChange}
                      onColumnSortChange={handleColumnSortChange}
                      onPageClick={handlePageChange}
                      onPerPageClick={handlePerPageChange}
                      onRowSelectionChange={handleRowSelection}
                      onSearchTextChange={handleKeywordChange}
                      onToggleFilters={handleFiltersToggle}
                      page={page}
                      refreshing={isLoading}
                      rowsPerPage={perPage}
                      rowsSelected={selectedIndexesInPage}
                      searchText={keyword}
                      selectableRows={shouldEnableMultipleSelection ? 'multiple' : 'none'}
                      selectToolbarPlacement="none"
                      sortOrder={{ name: orderBy, direction }}
                    />
                  </When>
                  <When
                    condition={
                      !uiState.treeViewListState.hasLoadedCorrectly &&
                      !uiState.treeViewListState.isLoading
                    }
                  >
                    <EmptyPlaceholder
                      {...pageCopies.emptyStates.noFolders}
                      customEmptyState={<SvgNoSearchProjectsFolders size="300" fill={grey400} />}
                    />
                  </When>
                </>
              }
            />
          )
        }}
      />
      <When condition={uiState.isBulkDrawerOpen}>
        <BulkEmailDrawer searchProject={searchProject} onCloseDrawer={handleCloseBulkEmailDrawer} />
      </When>
      <When condition={uiState.isRestoreSearchProjectDrawerOpen && isRestoreDrawerFeatureActive}>
        <RestoreSearchProjectDrawer
          searchProject={searchProject}
          onRestore={handleRestoreSearchProject}
          onClose={togglers.searchProjectRestoreDrawer({ open: false })}
        />
      </When>
      <When condition={uiState.isRestoreSearchProjectDialogOpen}>
        <RestoreSearchProjectDialog
          fetchingDialog={uiState.fetchingDialog}
          onCloseDialog={togglers.searchProjectRestoreDialog({ open: false })}
          onFetchingDialog={fetchingDialog => setUiState(prev => ({ ...prev, fetchingDialog }))}
          onSuccess={handleRestoreSearchProject}
          searchProject={searchProject}
        />
      </When>
      <When condition={uiState.isFolderDrawerOpen}>
        <SearchProjectsFolderFormDrawer
          initialFolder={SEARCH_PROJECT_ROOT_FOLDERS.mine}
          isFolderBeingEdited={uiState.isFolderBeingEdited}
          onCloseDrawer={togglers.folderDrawer({
            open: false,
            edit: false,
            moveToExistingFolder: false,
            moveToNewFolder: false
          })}
          onSubmitFolder={handleSubmitFolder}
          searchProjectsToMove={filteredItems}
          shouldMoveSearchProjects={uiState.shouldMoveSearchProjects}
        />
      </When>
      <When condition={uiState.isArchiveFolderDialogOpen}>
        <DecisionDialog
          {...dialogsCopies.archiveFolder}
          message={strings.formatString(dialogsCopies.archiveFolder.message, {
            days: ARCHIVE_FOLDER_RETENTION_DAYS
          })}
          withButtons="YesNo"
          isHighLightActionOnLeft
          mustComplete
          fetching={uiState.isArchivingFolder}
          onConfirm={handleArchiveFolder}
          onClose={togglers.archiveFolderDialog({ open: false })}
        />
      </When>
      <When condition={uiState.isRestoreFolderDrawerOpen}>
        <RestoreSearchProjectFolderDrawer
          folderToRestore={selectedFolder}
          onRestoreFolder={handleRestoreFolder}
          onClose={togglers.restoreFolderDrawer({ open: false })}
        />
      </When>
      <When condition={shouldShowArchiveMultipleSearchProjectsDialog}>
        <DecisionDialog
          {...dialogsCopies.archiveMultipleSearchProjects.dialog}
          message={strings.formatString(
            dialogsCopies.archiveMultipleSearchProjects.dialog.message,
            {
              availabilityDays: ARCHIVE_AVAILABILITY_DAYS.searchProject
            }
          )}
          fetching={uiState.fetchingDialog}
          withButtons="YesNo"
          onConfirm={handleArchiveMultipleSearchProjects}
          onClose={archiveMultipleSearchProjectsToggler({ open: false })}
        />
      </When>
    </>
  );
};

export default SearchProjectsIntoFolders;
