// @flow
import React, { useEffect, useMemo, useState } from 'react';
import { connect, useDispatch } from 'react-redux';
import { showAlert } from 'actions/app';
/** API / EntityRoutes / Endpoints / EntityType */
import useDatatable, {
  addRingCentralContactToStore,
  buildRowActionsRendererDefinition,
  getColumnPreferences,
  getColumnsToRender
} from 'hooks/datatable';
import useMultipleSelection, { CustomTableToolbarSelect } from 'hooks/multipleSelection';
import { useSearchProject } from 'hooks/searchProject';
import { useQuickViewPreview } from 'hooks/useQuickViewPreview';
import { When } from 'UI/components/atoms/When';
/** Atoms, Components and Styles */
import AutocompleteSelect from 'UI/components/molecules/AutocompleteSelect';
/** Components */
import DataTable from 'UI/components/organisms/DataTable';
import FPQuickView from 'UI/components/organisms/inventoryProfiles/FPQuickView';
import FiltersLayout from 'UI/components/templates/FiltersLayout';
import { additionalPeriodRanges } from 'UI/components/templates/SideFiltersLayout/filters';
/** API / EntityRoutes / Endpoints / EntityType */
import { backNavigateListConfig } from 'UI/constants/config';
import { CommunicationActionPreset, PageTitles } from 'UI/constants/defaults';
import { Endpoints } from 'UI/constants/endpoints';
import { EntityType } from 'UI/constants/entityTypes';
import { FeatureFlags } from 'UI/constants/featureFlags';
import {
  createCustomStaticRanges,
  filterColumnsByFeatureFlag,
  getAlertMessageForRequiredFilters
} from 'UI/utils';

import {
  ColumnsDefinitions,
  DateFilters,
  FiltersGroups,
  PeriodColumnBySection,
  VirtualProps
} from './columns';

const pageKey = 'companies-list';

const CompaniesColumnsWithFeatureFlag = [
  {
    featureFlag: FeatureFlags.CompaniesHeadquarterColumn,
    columns: ['parentCompany']
  }
];

const MIN_REQUIRED_FILTERS =
  window.GPAC_ENV?.MIN_REQUIRED_FILTERS || process.env.REACT_APP_MIN_REQUIRED_FILTERS;

const CompaniesList = ({ location }) => {
  const dispatch = useDispatch();

  const orderByOptions = {
    column: 'name',
    direction: 'asc'
  };

  const filteredColumnsDefinition = filterColumnsByFeatureFlag(
    ColumnsDefinitions,
    CompaniesColumnsWithFeatureFlag
  );

  const initialPreferences = getColumnPreferences(
    pageKey,
    0,
    orderByOptions,
    filteredColumnsDefinition
  );

  const [dateTypeFilter, setDateTypeFilter] = useState(DateFilters[0]);

  const {
    columnPreferences,
    count,
    data,
    filters,
    getData,
    handleColumnDisplayChange,
    handleColumnOrderChange,
    handleColumnSortChange,
    handleFiltersApply,
    handleFiltersChange,
    handleFiltersToggle,
    handleKeywordChange,
    handleOneFilterChange,
    handlePageChange,
    handlePeriodChange,
    handlePerPageChange,
    handleSectionChange,
    listState,
    mainOptions,
    queryParams,
    isBelowMinFiltersThreshold
  } = useDatatable({
    key: pageKey,
    endpoint: Endpoints.Companies,
    entityName: 'Companies',
    entityType: EntityType.Company,
    initialPreferences,
    columnsDefinitions: filteredColumnsDefinition,
    orderByOptions,
    virtualProps: VirtualProps,
    periodDefaultValue: null,
    periodFilterColumn: PeriodColumnBySection,
    sendDateWithTimezone: true,
    shouldScrollOnNavigate: true,
    minRequiredFilters: MIN_REQUIRED_FILTERS
  });

  useEffect(() => {
    document.title = PageTitles.Company;
  }, []);

  const { columns, columnOrder, orderBy, direction } = columnPreferences;
  const { isLoading, showWholeSkeleton, isSideMenuOpen, page, perPage, keyword, userFilter } =
    listState;

  const dataForSelection = useMemo(
    () => data.map(({ id }) => ({ id, type_id: 6, type: 'Company' })),
    [data]
  );

  const {
    handleRowSelection,
    selectedIndexesInPage,
    multiSelectComponents,
    totalSelected,
    filteredItems
  } = useMultipleSelection({ data: dataForSelection, count, perPage });

  const { SearchProjectAction, SearchProjectForms } = useSearchProject({
    filteredItems,
    queryParams
  });

  const handleAction = ({ rowData }) =>
    addRingCentralContactToStore({
      rowData,
      columns: filteredColumnsDefinition,
      dispatch,
      role: EntityType.Company
    });

  const { entityId, quickViewState, listViewPreset, quickViewEntity, toggleQuickViewPreview } =
    useQuickViewPreview({ columns, data, entityType: EntityType.Company });

  const phoneActionButtons = buildRowActionsRendererDefinition({
    actions: [...CommunicationActionPreset, listViewPreset],
    namespace: EntityType.Company,
    handleAction,
    columns: filteredColumnsDefinition
  });

  const handleDateTypeFilterChange = (name, value) => {
    setDateTypeFilter(value);
    handleSectionChange && handleSectionChange(name, value.id);
  };

  const finalColumns = getColumnsToRender([...columns, phoneActionButtons]);

  const handleTabRefresh = async () => {
    await getData();
  };

  const shouldSelectRequiredFilters = isBelowMinFiltersThreshold && (!data || data.length === 0);

  return (
    <FiltersLayout
      title="Companies"
      section={EntityType.Company}
      listSelector={
        <AutocompleteSelect
          name="userFilter"
          placeholder="Companies to show"
          selectedValue={userFilter || mainOptions[0]}
          onSelect={handleOneFilterChange}
          defaultOptions={mainOptions}
          disableClearable
        />
      }
      defaultRange={null}
      extraSelector={
        <AutocompleteSelect
          name="dateTypeFilter"
          placeholder="Filter By"
          selectedValue={dateTypeFilter}
          onSelect={handleDateTypeFilterChange}
          defaultOptions={DateFilters}
          disableClearable
        />
      }
      dateRangerPickerProps={{
        staticRanges: createCustomStaticRanges(additionalPeriodRanges)
      }}
      filters={filters}
      isSideMenuOpen={isSideMenuOpen}
      isLoading={showWholeSkeleton}
      groups={FiltersGroups}
      enableStore={false}
      onSearch={handleFiltersApply}
      onFiltersChange={handleFiltersChange}
      onMenuToggle={handleFiltersToggle}
      titleLabelProps={backNavigateListConfig}
      onPeriodChange={handlePeriodChange}
      minRequiredFilters={shouldSelectRequiredFilters && MIN_REQUIRED_FILTERS}
      shouldSelectRequiredFilters={shouldSelectRequiredFilters}
    >
      <DataTable
        columnOrder={columnOrder?.length ? columnOrder : undefined}
        columns={finalColumns}
        components={multiSelectComponents}
        count={count}
        CustomToolbar={
          <CustomTableToolbarSelect total={totalSelected}>
            <SearchProjectAction />
          </CustomTableToolbarSelect>
        }
        data={data}
        draggableColumns={{
          enabled: true
        }}
        enableCustomFilters
        filter={false}
        isExpandable
        loading={showWholeSkeleton}
        onColumnDisplayClick={handleColumnDisplayChange}
        onColumnOrderChange={handleColumnOrderChange}
        onColumnSortChange={handleColumnSortChange}
        onPageClick={handlePageChange}
        onPerPageClick={handlePerPageChange}
        onRowSelectionChange={handleRowSelection}
        onSearchTextChange={handleKeywordChange}
        onToggleFilters={handleFiltersToggle}
        page={page}
        refreshing={isLoading}
        rowsPerPage={perPage}
        rowsSelected={selectedIndexesInPage}
        searchText={keyword}
        selectToolbarPlacement="none"
        sortOrder={{ name: orderBy, direction }}
        subtitle={shouldSelectRequiredFilters ? '' : null}
        title={
          shouldSelectRequiredFilters
            ? getAlertMessageForRequiredFilters(MIN_REQUIRED_FILTERS)
            : null
        }
      />
      <SearchProjectForms />
      <When condition={entityId && quickViewState.isQuickViewOpen}>
        <FPQuickView
          drawerProps={{
            open: quickViewState.isQuickViewOpen
          }}
          onClose={toggleQuickViewPreview}
          entityType={quickViewEntity}
          itemsToNavigate={data}
          id={entityId}
          location={location}
          onEditionCompleted={handleTabRefresh}
        />
      </When>
    </FiltersLayout>
  );
};

const mapDispatchToProps = dispatch => {
  return {
    onShowAlert: alert => dispatch(showAlert(alert))
  };
};

export default connect(null, mapDispatchToProps)(CompaniesList);
