import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import NoteAddIcon from '@material-ui/icons/NoteAdd';
import Refresh from '@material-ui/icons/Refresh';
import { showAlert } from 'actions/app';
import clsx from 'clsx';
import { RefreshButton } from 'features/command-center/components/layout/Layout/components/Header/Header.styled';
import { Toolbar } from 'features/command-center/components/shared';
import { FeedbackNoteDrawer } from 'features/command-center/components/shared/FeedbackNotes/FeedbackNotesDrawer';
import { StatsCard } from 'features/command-center/components/shared/StatsCard';
import { COMMAND_CENTER_BASE_URL, ENDPOINTS } from 'features/command-center/constants';
import useDatatable, {
  buildRowActionsRendererDefinition,
  getColumnPreferences,
  getColumnsToRender
} from 'hooks/datatable';
import { rowClickBuilder } from 'hooks/profileActions';
import usePageTitle from 'hooks/usePageTitle';
import moment from 'moment';
import { useFeatureFlags } from 'providers/FeatureFlagsProvider';
import { SECONDARY_API_CLIENT } from 'services/API';
import { Permissions, userHasPermission } from 'services/Authorization';
import strings from 'strings';
import FPIcon from 'UI/components/atoms/FPIcon';
import { When } from 'UI/components/atoms/When';
import DataTable from 'UI/components/organisms/DataTable';
import FiltersLayout from 'UI/components/templates/FiltersLayout';
import { backNavigateListConfig } from 'UI/constants/config';
import { DateFormats, PageTitles, RowActions } from 'UI/constants/defaults';
import { FeatureFlags } from 'UI/constants/featureFlags';
import { useFixedDatatableStyles, useTableCard } from 'UI/globalStyles/DataTableStyles';
import { SvgInformationCircle } from 'UI/res';
import { downloadFile as downloadCSV, getErrorMessage } from 'UI/utils';

import { DRAWER_CONTENT_TYPES } from '../OverallPerformance/OverallPerformance.constants';

import {
  COLUMNS,
  extraFilters,
  FiltersGroups,
  METRICS_DESCRIPTION,
  ORDER_BY_OPTIONS,
  PAGE_KEY
} from './DailyPerformance.constants';
import { ActionContent, useStyles } from './DailyPerformance.styles';
import { formatResponse } from './DailyPerformance.utils';

const COPIES = strings.commandCenter.dailyPerformance;
const DEFAULT_DATE = moment.utc().local().format(DateFormats.QueryShortFormat);
const EMPLOYEE_NUMBER_KEY = 'empNo';

export const DailyPerformance = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const fixedTableClasses = useFixedDatatableStyles();
  const tableClasses = useTableCard();
  const { checkIfFeatureFlagEnabled: isFeatureFlagEnabled } = useFeatureFlags();
  const canAccessFeedbackLogs = userHasPermission(
    Permissions.CommandCenter.FeedbackLogs.CanUseModule
  );
  const [employeeNumber, setEmployeeNumber] = useState();
  const [drawerContent, setDrawerContent] = useState(DRAWER_CONTENT_TYPES.NotesForm);

  const initialPreferences = getColumnPreferences(PAGE_KEY, 0, ORDER_BY_OPTIONS, COLUMNS);

  const {
    columnPreferences,
    count,
    data,
    filters,
    getData,
    handleColumnDisplayChange,
    handleColumnOrderChange,
    handleColumnSortChange,
    handleDateChange,
    handleFiltersApply,
    handleFiltersChange,
    handleFiltersToggle,
    handleKeywordChange,
    handlePageChange,
    handlePeriodChange,
    handlePerPageChange,
    listState,
    queryParams,
    selectedDate
  } = useDatatable({
    apiInstance: SECONDARY_API_CLIENT,
    columnsDefinitions: COLUMNS,
    endpoint: `${COMMAND_CENTER_BASE_URL}/${ENDPOINTS.productivityRecruiterPerformance}`,
    formatResponse,
    initialPreferences,
    key: PAGE_KEY,
    orderByOptions: ORDER_BY_OPTIONS,
    periodDefaultValue: null,
    sendDateWithTimezone: true,
    shouldScrollOnNavigate: true,
    dateParamName: 'cutoffDate',
    dateDefaultValue: DEFAULT_DATE
  });

  const { isLoading, showWholeSkeleton, isSideMenuOpen, page, perPage, keyword } = listState;
  const { columns, columnOrder, orderBy, direction } = columnPreferences;
  const selectedEmployee = data?.find(item => item[EMPLOYEE_NUMBER_KEY] === employeeNumber);

  const actions = [
    {
      name: RowActions.Add,
      Icon: NoteAddIcon,
      title: strings.commandCenter.feedbackNotes.tableActions.create,
      onClick: rowClickBuilder({
        columns,
        data,
        pickId: true,
        idKey: EMPLOYEE_NUMBER_KEY,
        callback: empNo => {
          setDrawerContent(DRAWER_CONTENT_TYPES.NotesForm);
          setEmployeeNumber(empNo);
        }
      })
    },
    {
      name: RowActions.View,
      title: strings.commandCenter.feedbackNotes.tableActions.view,
      onClick: rowClickBuilder({
        columns,
        data,
        pickId: true,
        idKey: EMPLOYEE_NUMBER_KEY,
        callback: empNo => {
          setDrawerContent(DRAWER_CONTENT_TYPES.FeedbackLog);
          setEmployeeNumber(empNo);
        }
      })
    }
  ];

  const handleCloseDrawer = () => {
    setEmployeeNumber(null);
    setDrawerContent(DRAWER_CONTENT_TYPES.NotesForm);
  };

  const actionsColumn = buildRowActionsRendererDefinition({
    actions,
    columns
  });

  const finalColumns = getColumnsToRender(
    isFeatureFlagEnabled(FeatureFlags.FeedbackNotes) && canAccessFeedbackLogs
      ? [...columns, actionsColumn]
      : columns
  );

  usePageTitle({ title: PageTitles.CommandCenterDailyPerformance, isLoading });

  const footer = (
    <div className={classes.footer}>
      <div className={classes.footerTitle}>
        <FPIcon
          role="img"
          aria-label="Info"
          component={SvgInformationCircle}
          onSetColor={palette => palette.info.main}
          size={18}
        />
        <Typography className={classes.label}>
          {COPIES.overallPerformanceReport.footer.title}
        </Typography>
      </div>
      <ul className={classes.list}>
        {METRICS_DESCRIPTION.map(item => (
          <li key={item.title}>
            <Typography className={classes.label}>
              <span className={classes.title}>{item.title} -</span> {item.content}
            </Typography>
          </li>
        ))}
      </ul>
    </div>
  );

  const handleDownloadFile = () => {
    (async () => {
      await downloadFile();
    })();
    return false;
  };

  const downloadFile = async () => {
    const fileDate = selectedDate ?? moment.utc().local().format(DateFormats.QueryShortFormat);
    const fileName = `daily-performance-report-${fileDate}-${Date.now()}`;
    try {
      const response = await SECONDARY_API_CLIENT.get(
        `${COMMAND_CENTER_BASE_URL}/${ENDPOINTS.downloadProductivityRecruiterPerformance}?${queryParams.params}`,
        {
          responseType: 'blob'
        }
      );
      downloadCSV(response.data, 'text/csv', fileName);
    } catch (err) {
      dispatch(
        showAlert({
          severity: 'error',
          title: "Couldn't download report",
          body: getErrorMessage(err)
        })
      );
    }
  };

  return (
    <FiltersLayout
      dateDefaultValue={DEFAULT_DATE}
      disableDateRange
      enableDatePicker
      enableStore={false}
      extraFiltersDefinition={extraFilters}
      extraSelector={
        <ActionContent>
          <RefreshButton
            type="button"
            startIcon={<Refresh width={20} height={20} />}
            onClick={getData}
          >
            Refresh Stats
          </RefreshButton>
        </ActionContent>
      }
      filters={filters}
      groups={FiltersGroups}
      isLoading={showWholeSkeleton}
      isSideMenuOpen={isSideMenuOpen}
      onDateChange={handleDateChange}
      onFiltersChange={handleFiltersChange}
      onMenuToggle={handleFiltersToggle}
      onPeriodChange={handlePeriodChange}
      onSearch={handleFiltersApply}
      title={COPIES.title}
      titleLabelProps={backNavigateListConfig}
    >
      <Grid container spacing={4}>
        <Grid item xs={12}>
          <StatsCard footer={footer} paddingContent={0}>
            <DataTable
              classes={{ responsiveBase: 'responsiveBase' }}
              className={clsx(tableClasses.tableRoot, fixedTableClasses.fixedList)}
              columnOrder={columnOrder?.length && columnOrder}
              columns={finalColumns}
              components={{ TableToolbar: Toolbar }}
              count={count ?? 0}
              data={data}
              download={isFeatureFlagEnabled(FeatureFlags.DailyPerformanceDownload)}
              draggableColumns={{
                enabled: true
              }}
              elevation={0}
              filter={false}
              isExpandable
              loading={showWholeSkeleton}
              onColumnDisplayClick={handleColumnDisplayChange}
              onColumnOrderChange={handleColumnOrderChange}
              onColumnSortChange={handleColumnSortChange}
              onDownload={handleDownloadFile}
              onPageClick={handlePageChange}
              onPerPageClick={handlePerPageChange}
              onSearchTextChange={handleKeywordChange}
              onToggleFilters={handleFiltersToggle}
              page={page}
              refreshing={isLoading}
              rowsPerPage={perPage}
              searchText={keyword}
              selectableRows="none"
              selectToolbarPlacement="none"
              sortOrder={{ name: orderBy, direction }}
            />
          </StatsCard>
        </Grid>
      </Grid>

      <When condition={!!selectedEmployee}>
        <FeedbackNoteDrawer
          initialDrawerContent={drawerContent}
          onClose={handleCloseDrawer}
          selectedEmployee={selectedEmployee}
        />
      </When>
    </FiltersLayout>
  );
};
