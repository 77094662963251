import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles(theme => ({
  actionButtons: {
    display: 'flex'
  },
  avatarList: {
    marginTop: theme.spacing(1)
  }
}));

export const useTitleDetailsStyles = makeStyles(theme => ({
  editSearchProjectButton: {
    marginLeft: theme.spacing(1)
  },
  infoText: {
    marginLeft: theme.spacing(0.5)
  },
  privacyIcon: {
    marginRight: theme.spacing(1),
    alignSelf: 'center'
  },
  redText: {
    color: theme.palette.brandColors.FPRed
  },
  detailsContainer: {
    marginLeft: theme.spacing(6.5)
  }
}));
