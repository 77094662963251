export const FORM_FIELDS_MAP = {
  TaskType: {
    key: 'taskType'
  },
  Interview: {
    key: 'interview'
  }
};

export const INTERVIEW_ID_MAP = {
  RecruitJobOrder: 1,
  MarketCandidate: 2,
  DebriefingCall: 3,
  PreparationCall: 4,
  ClosingCall: 5,
  OtherTask: 6
};
