import React from 'react';
import AutocompleteSelect from 'UI/components/molecules/AutocompleteSelect';
import {
  CoachFilterDef,
  RegionalFilterDef
} from 'UI/components/templates/SideFiltersLayout/filters';
import { FeatureFlags } from 'UI/constants/featureFlags';
import { hasFeatureFlag } from 'UI/utils';

const OPTION_ALL = 'All';

export const AutocompleteByRegional = ({ regional, onSelect, prependOptions }) => (
  <AutocompleteSelect
    value={regional}
    placeholder="Region"
    onSelect={onSelect}
    url={RegionalFilterDef.url}
    disableClearable
    displayKey="full_name"
    prependOptions={prependOptions}
  />
);

export const AutocompleteByCoach = ({ coach, onSelect, prependOptions, regional }) => {
  const areMultipleDivisionsEnable = hasFeatureFlag(FeatureFlags.HomeMultipleRegions);
  const regionalFullName =
    areMultipleDivisionsEnable && regional?.full_name ? regional?.full_name : null;
  return (
    <AutocompleteSelect
      disableClearable
      disabled={
        areMultipleDivisionsEnable && (regionalFullName === OPTION_ALL || !regionalFullName)
      }
      displayKey="full_name"
      onSelect={onSelect}
      placeholder="Team"
      prependOptions={prependOptions}
      url={
        areMultipleDivisionsEnable
          ? `${CoachFilterDef.url}&regionalIds=${regional?.id}`
          : CoachFilterDef.url
      }
      value={coach}
    />
  );
};
