// @flow
import { makeRequest } from 'services/utils';
import strings from 'strings';
import type { OperationResult } from 'types/app';
import { ARCHIVE_AVAILABILITY_DAYS } from 'UI/constants/defaults';
import { Endpoints } from 'UI/constants/endpoints';

const {
  BulkTemplateArchive,
  BulkEmailsFolders,
  BulkEmailsFolderById,
  BulkEmailsTemplates,
  BulkTemplateById,
  BulkTemplateRestore,
  BulkTemplateFolderArchive,
  BulkTemplateFolderRestore
} = Endpoints;

const { errors: errorCopies, success: successCopies } = strings.bulkEmails;

export const getTemplateById = (templateId): Promise<OperationResult> =>
  makeRequest({
    endpointOptions: {
      path: BulkTemplateById,
      replaceId: templateId
    },
    method: 'get',
    alertConfig: {
      error: errorCopies.bulkTemplateById
    }
  });

export const createFolder = ({ name, parentFolderId }): Promise<OperationResult> =>
  makeRequest({
    url: BulkEmailsFolders,
    method: 'post',
    data: { name, parent_folder_id: parentFolderId },
    alertConfig: {
      success: {
        title: successCopies.createBulkTemplateFolder.title,
        body: name
      }
    }
  });

export const editFolder = (folderId, { name, parentFolderId }): Promise<OperationResult> =>
  makeRequest({
    endpointOptions: {
      path: BulkEmailsFolderById,
      replaceId: folderId
    },
    data: { name, parent_folder_id: parentFolderId },
    method: 'put',
    apiVersion: 2,
    alertConfig: {
      success: {
        title: successCopies.updateBulkTemplateFolder.title,
        body: name
      }
    }
  });

export const getFolderData = (folderId: number): Promise<OperationResult> =>
  makeRequest({
    endpointOptions: {
      path: BulkEmailsFolderById,
      replaceId: folderId,
      queryParams: {
        includes: 'parentFolder'
      }
    },
    method: 'get'
  });

export const createTemplate = (data): Promise<OperationResult> =>
  makeRequest({
    url: BulkEmailsTemplates,
    data,
    method: 'post',
    alertConfig: {
      success: successCopies.createBulkTemplate
    }
  });

export const editTemplate = (id, formData): Promise<OperationResult> =>
  makeRequest({
    endpointOptions: {
      path: BulkTemplateById,
      replaceId: id
    },
    method: 'put',
    data: formData,
    alertConfig: {
      success: successCopies.updateBulkTemplate
    }
  });

export const archiveTemplate = (templateId): Promise<OperationResult> =>
  makeRequest({
    url: BulkTemplateArchive.replace(/:id/, templateId),
    method: 'delete',
    alertConfig: {
      success: {
        title: successCopies.archiveBulkTemplate.title,
        body: strings.formatString(successCopies.archiveBulkTemplate.body, {
          days: ARCHIVE_AVAILABILITY_DAYS.bulkTemplate
        })
      }
    }
  });

export const restoreTemplate = (templateId, destinationFolderId): Promise<OperationResult> =>
  makeRequest({
    endpointOptions: {
      path: BulkTemplateRestore,
      replaceId: templateId
    },
    method: 'post',
    data: { destinationFolderId },
    alertConfig: {
      success: {
        title: successCopies.restoreBulkTemplate.title,
        body: ({ template, destinationFolder }) =>
          strings.formatString(successCopies.restoreBulkTemplate.body, {
            templateName: template.name,
            destinationFolderName: destinationFolder.name
          })
      }
    }
  });

export const archiveTemplateFolder = (folderId): Promise<OperationResult> =>
  makeRequest({
    endpointOptions: {
      path: BulkTemplateFolderArchive,
      replaceId: folderId
    },
    method: 'delete',
    alertConfig: {
      success: {
        title: successCopies.archiveBulkTemplateFolder.title,
        body: strings.formatString(successCopies.archiveBulkTemplateFolder.body, {
          days: ARCHIVE_AVAILABILITY_DAYS.bulkTemplateFolder
        })
      }
    }
  });

export const restoreTemplateFolder = (folderId, destinationFolderId): Promise<OperationResult> =>
  makeRequest({
    endpointOptions: {
      path: BulkTemplateFolderRestore,
      replaceId: folderId
    },
    method: 'post',
    data: { destinationFolderId },
    alertConfig: {
      success: {
        title: successCopies.restoreBulkTemplateFolder.title,
        body: ({ folder, destinationFolder }) =>
          strings.formatString(successCopies.restoreBulkTemplateFolder.body, {
            folderName: folder.name,
            destinationFolderName: destinationFolder.name
          })
      }
    }
  });
