import { formatDateForChartLabel } from 'features/command-center/utils';
import moment from 'moment';

export const getChartDayLabels = () => {
  return [
    { v: 0, f: 'Mon' },
    { v: 1, f: 'Tue' },
    { v: 2, f: 'Wed' },
    { v: 3, f: 'Thu' },
    { v: 4, f: 'Fri' }
  ];
};

export const getChartMonthLabels = isCurrentYear => {
  const monthLabels = [
    { v: 0, f: 'Jan' },
    { v: 1, f: 'Feb' },
    { v: 2, f: 'Mar' },
    { v: 3, f: 'Apr' },
    { v: 4, f: 'May' },
    { v: 5, f: 'Jun' },
    { v: 6, f: 'Jul' },
    { v: 7, f: 'Aug' },
    { v: 8, f: 'Sep' },
    { v: 9, f: 'Oct' },
    { v: 10, f: 'Nov' },
    { v: 11, f: 'Dec' }
  ];

  const totalMonths = isCurrentYear ? moment().month() : 12;
  return monthLabels.slice(0, totalMonths + 1);
};

const convertSecondsToMinutes = value => {
  if (!value) return 0;
  return Math.floor(value / 60);
};

export const formatSeconds = seconds =>
  seconds < 60 ? `${seconds} seconds` : `${convertSecondsToMinutes(seconds)} min`;

export const formatTrendsResponse = ({ response, key, goalKey, label }) => {
  const xSeriesHeader = ['Days', label];
  if (!response?.length) {
    return [xSeriesHeader, ['', 0]];
  }

  const isKeyPhoneTime = key === 'phoneTime';
  const hasGoalKey = Boolean(goalKey);

  const formatItem = item => {
    const dateLabel = formatDateForChartLabel(item.date);
    const value = isKeyPhoneTime ? convertSecondsToMinutes(item[key]) : item[key];
    return hasGoalKey ? [dateLabel, value, item[goalKey]] : [dateLabel, value];
  };

  const formattedData = [
    hasGoalKey ? [...xSeriesHeader, 'Goal'] : xSeriesHeader,
    ...response.map(formatItem)
  ];

  return formattedData;
};

export const formatTrendsResponseWithUrl = ({
  dateKey = 'date',
  goalKey,
  isPhoneTime = false,
  key,
  label,
  response
}) => {
  const xSeriesHeader = [
    'Days',
    label,
    {
      role: 'annotationText',
      type: 'string'
    }
  ];
  if (!response?.length) {
    return [xSeriesHeader, ['', 0, '']];
  }

  const isPhoneTimeKey = isPhoneTime ?? key === 'phoneTime';
  const hasGoalKey = Boolean(goalKey);

  const formatItem = item => {
    const dateLabel = item?.[dateKey] ? formatDateForChartLabel(item[dateKey]) : null;
    const value = isPhoneTimeKey ? convertSecondsToMinutes(item[key]) : item[key];
    const url = item?.url ?? item?.[`${key}Url`];
    return hasGoalKey ? [dateLabel, value, url, item[goalKey]] : [dateLabel, value, url];
  };

  const formattedData = [
    hasGoalKey ? [...xSeriesHeader, 'Goal'] : xSeriesHeader,
    ...response.map(formatItem)
  ];

  return formattedData;
};

export const calculatePercentage = ({ total, goal }) => {
  const totalNumber = Number(total);
  const goalNumber = Number(goal);

  const isValidTotal = !Number.isNaN(totalNumber);
  const isValidGoal = !Number.isNaN(goalNumber) && goalNumber !== 0;

  return isValidTotal && isValidGoal ? totalNumber / goalNumber : 0;
};
