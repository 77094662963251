import React, { useState } from 'react';
import { GaugeStats, IconLabel, Widget } from 'features/command-center';
import { getProductivitySummary } from 'features/command-center/services';
import moment from 'moment';
import strings from 'strings';
import { DateFormats } from 'UI/constants/defaults';
import { getCurrentWeekRange } from 'UI/utils';

import { formatResponse } from './CallsGauge.utils';

const STRINGS = strings.commandCenter.activity.callsGauge;

export const CallsGauge = ({ requestParams = {}, footerLabel }) => {
  const [dateRange, setDateRange] = useState(() => getCurrentWeekRange());
  const queryDateRange = {
    startDate: moment(dateRange.startDate).format(DateFormats.QueryShortFormat),
    endDate: moment(dateRange.endDate).format(DateFormats.QueryShortFormat)
  };

  const handleDateRangeChange = selectedDateRange => setDateRange(selectedDateRange);

  return (
    <Widget
      header={STRINGS.title}
      promises={[
        () =>
          getProductivitySummary({
            metrics: 'calls',
            ...queryDateRange,
            ...requestParams
          })
      ]}
    >
      {([response]) => {
        const stats = formatResponse(response);

        return (
          <>
            <GaugeStats
              id="calls"
              stats={stats}
              dateRange={dateRange}
              onDateRangeChange={handleDateRangeChange}
            />
            {footerLabel && <IconLabel label={footerLabel} />}
          </>
        );
      }}
    </Widget>
  );
};
