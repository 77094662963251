import React, { useEffect } from 'react';
import { useFormContext } from 'react-hook-form';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormHelperText from '@material-ui/core/FormHelperText';
import Grid from '@material-ui/core/Grid';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import Typography from '@material-ui/core/Typography';
import strings from 'strings';
import TextBox from 'UI/components/atoms/TextBox';
import { When } from 'UI/components/atoms/When';
import AutocompleteSelect from 'UI/components/molecules/AutocompleteSelect';
import { REQUIRED_VALIDATION } from 'UI/utils';
import { buildBasePropsForField } from 'UI/utils/forms';

import { DateTimePickers } from '../shared';

import {
  ENTITY_OPTIONS_TO_REACH_OUT,
  FORM_FIELDS_MAP,
  REACH_OUT_AUTOCOMPLETE_PROPS_BY_ENTITY
} from './OtherTask.constants';
import { useStyles } from './OtherTask.styles';

const { form: COPIES } = strings.dailyPlan.drawer;

export const OtherTask = () => {
  const classes = useStyles();
  const { errors, register, unregister, watch, setValue } = useFormContext();
  const formValues = watch();
  const reachToEntity = formValues[FORM_FIELDS_MAP.ReachToEntity.key];

  useEffect(() => {
    register(FORM_FIELDS_MAP.ReachToEntity.key, { ...REQUIRED_VALIDATION });
    register(FORM_FIELDS_MAP.ReachToName.key, { ...REQUIRED_VALIDATION });

    return () => {
      unregister(FORM_FIELDS_MAP.ReachToEntity.key);
      unregister(FORM_FIELDS_MAP.ReachToName.key);
    };
  }, [register, unregister]);

  const handleAutocompleteChange = (name?, value) => {
    setValue(name, value, true);
  };

  const handleRadioChange = event => {
    const { value } = event.target;
    setValue(FORM_FIELDS_MAP.ReachToName.key, null);
    setValue(FORM_FIELDS_MAP.ReachToEntity.key, value, true);
  };

  return (
    <Grid container spacing={2}>
      <Grid container item spacing={2} alignItems="center">
        <Grid item sm={12}>
          <TextBox
            {...buildBasePropsForField(FORM_FIELDS_MAP.OtherTask.key, errors)}
            inputRef={register(REQUIRED_VALIDATION)}
            label={COPIES.placeholders.otherTask}
          />
        </Grid>

        <Grid container item spacing={2}>
          <DateTimePickers />
        </Grid>
      </Grid>

      <Grid item xs={12}>
        <Typography variant="body1" className={classes.fieldLabel}>
          {COPIES.labels.taskDetails}
        </Typography>
      </Grid>

      <Grid item>
        <Typography variant="body1" color="textSecondary">
          {COPIES.labels.reachOut}
        </Typography>
        <FormControl component="fieldset" error={!!errors[FORM_FIELDS_MAP.ReachToEntity.key]}>
          <RadioGroup
            aria-label="position"
            name={FORM_FIELDS_MAP.ReachToEntity.key}
            value={formValues[FORM_FIELDS_MAP.ReachToEntity.key] ?? null}
            onChange={handleRadioChange}
            row
          >
            {ENTITY_OPTIONS_TO_REACH_OUT.map(option => (
              <FormControlLabel
                key={option.value}
                control={<Radio color="primary" />}
                label={option.label}
                labelPlacement="end"
                value={option.value}
              />
            ))}
          </RadioGroup>
          <FormHelperText>{errors[FORM_FIELDS_MAP.ReachToEntity.key]?.message}</FormHelperText>
        </FormControl>
      </Grid>

      <When condition={!!reachToEntity}>
        <Grid item xs={12}>
          <AutocompleteSelect
            {...buildBasePropsForField(FORM_FIELDS_MAP.ReachToName.key, errors)}
            onSelect={handleAutocompleteChange}
            selectedValue={formValues[FORM_FIELDS_MAP.ReachToName.key] ?? null}
            typeahead
            typeaheadLimit={50}
            {...REACH_OUT_AUTOCOMPLETE_PROPS_BY_ENTITY[reachToEntity]}
          />
        </Grid>
      </When>

      <Grid item xs={12}>
        <Typography variant="body1">{COPIES.labels.comments}</Typography>
      </Grid>

      <Grid item xs={12}>
        <TextBox
          {...buildBasePropsForField(FORM_FIELDS_MAP.Comments.key, errors)}
          label={COPIES.placeholders.comments}
          inputRef={register()}
          multiline
        />
      </Grid>
    </Grid>
  );
};
