import React from 'react';
import { useFormContext } from 'react-hook-form';
import Grid from '@material-ui/core/Grid';
import Switch from '@material-ui/core/Switch';
import Typography from '@material-ui/core/Typography';
import strings from 'strings';
import TextBox from 'UI/components/atoms/TextBox';
import { EntityType } from 'UI/constants/entityTypes';

import { FORM_FIELDS_MAP } from '../../CallTask/CallTask.constants';
import { DateTimePickers } from '../DateTimePickers';

import { useStyles } from './ContactForm.styles';

const ENTITY_NAME = {
  [EntityType.Candidate]: 'Candidate',
  [EntityType.HiringAuthority]: 'Hiring Authority'
};

export const ContactForm = ({ entity, name, company }) => {
  const classes = useStyles();
  const { register, watch, setValue } = useFormContext();

  const formValues = watch();

  const checkboxKey = `isSelected-${entity}`;

  const isChecked = formValues[checkboxKey] !== undefined ? formValues[checkboxKey] : true;

  const handleCommentsChange = (fieldName, value) => {
    setValue(fieldName, value);
  };

  const handleChange = event => {
    setValue(checkboxKey, event.target.checked);
  };

  return (
    <Grid container item spacing={2} className={classes.formContainer}>
      <Grid item xs={5}>
        <Typography variant="body1" className={classes.subtitle}>
          {ENTITY_NAME[entity]} Name
        </Typography>
        <Typography variant="body1" color="textSecondary">
          {name}
        </Typography>
      </Grid>
      <Grid item xs={5}>
        <Typography variant="body1" className={classes.subtitle}>
          Company
        </Typography>
        <Typography variant="body1" color="textSecondary">
          {company}
        </Typography>
      </Grid>
      <Grid container item xs={2} justify="flex-end" alignContent="center">
        <Switch checked={isChecked} onChange={handleChange} color="primary" />
      </Grid>
      <DateTimePickers areEnabled={isChecked} fieldsKeySufix={entity} />
      <Grid item xs={12}>
        <Typography variant="body1">{strings.dailyPlan.drawer.form.labels.comments}</Typography>
        <TextBox
          label={strings.dailyPlan.drawer.form.labels.comments}
          name={`${FORM_FIELDS_MAP.Comments.key}-${entity}`}
          inputRef={register()}
          onChange={handleCommentsChange}
          value={formValues[`${FORM_FIELDS_MAP.Comments.key}-${entity}`]}
          multiline
          disabled={!isChecked}
        />
      </Grid>
    </Grid>
  );
};
