import React, { useEffect } from 'react';
import { useFormContext } from 'react-hook-form';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormHelperText from '@material-ui/core/FormHelperText';
import Grid from '@material-ui/core/Grid';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import Typography from '@material-ui/core/Typography';
import strings from 'strings';
import TextBox from 'UI/components/atoms/TextBox';
import { When } from 'UI/components/atoms/When';
import AutocompleteSelect from 'UI/components/molecules/AutocompleteSelect';
import { Endpoints } from 'UI/constants/endpoints';
import { EntityType } from 'UI/constants/entityTypes';
import { idOptionSelected, REQUIRED_VALIDATION } from 'UI/utils';
import { buildBasePropsForField } from 'UI/utils/forms';
import { OptionRenderers } from 'UI/utils/renderers';

import { DateTimePickers } from '../shared';

import {
  FORM_FIELDS_MAP,
  REACH_OUT_AUTOCOMPLETE_PROPS_BY_ENTITY,
  REACH_OUT_TO_RADIO_OPTIONS
} from './MarketCandidate.constants';
import { useStyles } from './MarketCandidate.styles';

export const MarketCandidate = () => {
  const classes = useStyles();
  const { errors, register, unregister, watch, setValue } = useFormContext();

  const formValues = watch();

  const reachToEntity = formValues[FORM_FIELDS_MAP.ReachToEntity.key];

  const handleAutocompleteChange = (name?, value) => {
    setValue(name, value, true);
  };

  const handleRadioChange = event => {
    const { value } = event.target;
    setValue(FORM_FIELDS_MAP.ReachToName.key, null);
    setValue(FORM_FIELDS_MAP.ReachToEntity.key, value, true);
  };

  const handleCommentsChange = (name, value) => {
    setValue(name, value);
  };

  useEffect(() => {
    register(FORM_FIELDS_MAP.CandidateToMarket.key, { ...REQUIRED_VALIDATION });
    register(FORM_FIELDS_MAP.ReachToEntity.key, { ...REQUIRED_VALIDATION });
    register(FORM_FIELDS_MAP.ReachToName.key, { ...REQUIRED_VALIDATION });

    return () => {
      unregister(FORM_FIELDS_MAP.CandidateToMarket.key);
      unregister(FORM_FIELDS_MAP.ReachToEntity.key);
      unregister(FORM_FIELDS_MAP.ReachToName.key);
    };
  }, [register, unregister]);

  return (
    <Grid container spacing={2}>
      <Grid container item spacing={2}>
        <DateTimePickers />
      </Grid>
      <Grid item xs={12}>
        <Typography variant="body1" className={classes.fieldLabel}>
          {strings.dailyPlan.drawer.form.labels.taskDetails}
        </Typography>
        <AutocompleteSelect
          {...buildBasePropsForField(FORM_FIELDS_MAP.CandidateToMarket.key, errors)}
          onSelect={handleAutocompleteChange}
          selectedValue={formValues[FORM_FIELDS_MAP.CandidateToMarket.key] || null}
          placeholder={strings.dailyPlan.drawer.form.placeholders.selectCandidate}
          renderOption={OptionRenderers.globalSearchDefault('title')}
          url={Endpoints.Search}
          typeahead
          typeaheadLimit={50}
          typeaheadParams={{ entityType: EntityType.Candidate }}
          getOptionSelected={idOptionSelected}
        />
      </Grid>
      <Grid item>
        <Typography variant="body1" component="div" color="textSecondary">
          {strings.dailyPlan.drawer.form.labels.selectReachOutTo}
        </Typography>
        <FormControl component="fieldset" error={!!errors[FORM_FIELDS_MAP.ReachToEntity.key]}>
          <RadioGroup
            aria-label="reachTo"
            name={FORM_FIELDS_MAP.ReachToEntity.key}
            value={formValues[FORM_FIELDS_MAP.ReachToEntity.key] || null}
            onChange={handleRadioChange}
            row
          >
            {REACH_OUT_TO_RADIO_OPTIONS.map(item => (
              <FormControlLabel
                key={item.id}
                value={item.value}
                control={<Radio color="primary" />}
                label={item.label}
                labelPlacement="end"
              />
            ))}
          </RadioGroup>
          <FormHelperText>{errors[FORM_FIELDS_MAP.ReachToEntity.key]?.message}</FormHelperText>
        </FormControl>
      </Grid>
      <When condition={!!reachToEntity}>
        <Grid item xs={12}>
          <AutocompleteSelect
            {...buildBasePropsForField(FORM_FIELDS_MAP.ReachToName.key, errors)}
            onSelect={handleAutocompleteChange}
            selectedValue={formValues[FORM_FIELDS_MAP.ReachToName.key] || null}
            typeahead
            typeaheadLimit={50}
            {...REACH_OUT_AUTOCOMPLETE_PROPS_BY_ENTITY[reachToEntity]}
          />
        </Grid>
      </When>
      <Grid item xs={12}>
        <Typography variant="body1">{strings.dailyPlan.drawer.form.labels.comments}</Typography>
        <TextBox
          label={strings.dailyPlan.drawer.form.labels.comments}
          name={FORM_FIELDS_MAP.Comments.key}
          onChange={handleCommentsChange}
          value={formValues[FORM_FIELDS_MAP.Comments.key]}
          inputRef={register()}
          multiline
        />
      </Grid>
    </Grid>
  );
};
