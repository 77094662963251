import { EntityType } from 'UI/constants/entityTypes';
import { formatKeywordTags } from 'UI/utils/inventory';

import { getPhonesSortedByDefaultPhone } from '../PhoneNumbersForm/utils';

/**
 * This map is used to transform data before submission to the API and also to preload data from the API into a form
 * Entries can have the following props:
 * If only key is present no transformatios is applied in neither direction (to/from the API)
 * If paramKey is present, then paramKey is sent to the API
 * outboundAccesor is a key to extract a value from an object. For instance, an outboundAccesor = 'id' would evaluate to object['id'] and this value would be sent to the API
 * inboundAccesor is a property path used to preload data coming from the API into the form. It's useful for nested objects, for instance: 'city.state' or 'address.zip'. If a more custom object is needed, then inboundAccessor could be a function.
 */
export const FormFieldsMap = {
  ApplicantId: {
    key: 'applicantId'
  },
  Id: {
    key: 'id'
  },
  Company: {
    key: 'company',
    paramKey: 'company_id',
    outboundAccesor: 'id',
    inboundAccesor: form =>
      form.company_id ? { id: form.company_id, name: form.current_company } : null
  },
  FirstName: {
    key: 'firstName',
    paramKey: 'first_name',
    inboundAccesor: 'personalInformation.first_name'
  },
  LastName: {
    key: 'lastName',
    paramKey: 'last_name',
    inboundAccesor: 'personalInformation.last_name'
  },
  Nickname: {
    key: 'nickname',
    paramKey: 'nickname',
    inboundAccesor: 'personalInformation.nickname'
  },
  Specialty: {
    key: 'specialty',
    paramKey: 'specialty_id',
    inboundAccesor: 'specialty',
    outboundAccesor: 'id'
  },
  Subspecialty: {
    key: 'subspecialty',
    paramKey: 'subspecialty_id',
    inboundAccesor: 'subspecialty',
    outboundAccesor: 'id'
  },
  FunctionalTitle: {
    key: 'functionalTitle',
    paramKey: 'position_id',
    inboundAccesor: 'position',
    outboundAccesor: 'id'
  },
  Position: {
    key: 'title'
  },
  Phones: {
    key: 'phones',
    inboundAccesor: rawObject => getPhonesSortedByDefaultPhone(rawObject, EntityType.Candidate)
  },
  Phone: {
    key: 'phone',
    inboundAccesor: 'personalInformation.contact.phone'
  },
  Ext: {
    key: 'ext',
    inboundAccesor: 'personalInformation.contact.ext'
  },
  Email: {
    key: 'email'
  },
  Mobile: {
    key: 'mobile',
    inboundAccesor: 'personalInformation.contact.mobile'
  },
  PersonalEmail: {
    key: 'personalEmail',
    paramKey: 'personal_email',
    inboundAccesor: 'personalInformation.contact.personal_email'
  },
  LinkedinUrl: {
    key: 'linkedinUrl',
    paramKey: 'link_profile'
  },
  Source: {
    key: 'source',
    paramKey: 'source_type_id',
    outboundAccesor: 'id',
    inboundAccesor: 'sourceType'
  },
  State: {
    key: 'state_id',
    paramKey: 'state_id',
    outboundAccesor: 'id',
    inboundAccesor: 'personalInformation.address.city.state'
  },
  City: {
    key: 'city_id',
    paramKey: 'city_id',
    outboundAccesor: 'id',
    inboundAccesor: 'personalInformation.address.city'
  },
  Zip: {
    key: 'zip',
    paramKey: 'zip',
    outboundAccesor: 'id',
    inboundAccesor: form => ({
      id: form.personalInformation?.address?.zip,
      title: form.personalInformation?.address?.zip
    })
  },
  Recruiter: {
    key: 'recruiter',
    paramKey: 'recruiter_id',
    outboundAccesor: 'id',
    inboundAccesor: 'recruiter'
  },
  FromContact: {
    key: 'fromContact',
    paramKey: 'hiringId',
    outboundAccesor: 'id'
  },
  Status: {
    key: 'status_id'
  },
  HasSubspecialties: {
    key: 'has_subspecialties'
  },
  KeywordTags: {
    key: 'keywordTags',
    outboundAccesor: keywordTags => formatKeywordTags(keywordTags)
  }
};

export const FormFieldsMapForHA = {
  Company: {
    key: 'company',
    inboundAccesor: 'company'
  },
  FirstName: {
    key: 'firstName',
    inboundAccesor: 'first_name'
  },
  LastName: {
    key: 'lastName',
    inboundAccesor: 'last_name'
  },
  Nickname: {
    key: 'nickname',
    inboundAccesor: 'nickname'
  },
  Specialty: {
    key: 'specialty',
    inboundAccesor: 'specialty'
  },
  Subspecialty: {
    key: 'subspecialty',
    inboundAccesor: 'subspecialty'
  },
  FunctionalTitle: {
    key: 'functionalTitle',
    inboundAccesor: 'position'
  },
  Position: {
    key: 'title',
    inboundAccesor: 'title'
  },
  Phones: {
    key: 'phones',
    inboundAccesor: rawObject =>
      getPhonesSortedByDefaultPhone(rawObject, EntityType.HiringAuthority)
  },
  Phone: {
    key: 'phone',
    inboundAccesor: 'work_phone'
  },
  Ext: {
    key: 'ext',
    inboundAccesor: 'ext'
  },
  Email: {
    key: 'email',
    inboundAccesor: 'work_email'
  },
  Mobile: {
    key: 'mobile',
    inboundAccesor: 'personal_phone'
  },
  PersonalEmail: {
    key: 'personalEmail',
    inboundAccesor: 'personal_email'
  },
  LinkedinUrl: {
    key: 'linkedinUrl',
    inboundAccesor: 'link_profile'
  },
  Source: {
    key: 'source',
    inboundAccesor: 'sourceType'
  },
  State: {
    key: 'state_id',
    paramKey: 'state_id',
    outboundAccesor: 'id',
    inboundAccesor: 'company.city.state'
  },
  City: {
    key: 'city_id',
    paramKey: 'city_id',
    outboundAccesor: 'id',
    inboundAccesor: 'company.city'
  },
  Zip: {
    key: 'zip',
    inboundAccesor: form => ({
      id: form.company?.zip,
      title: form.company?.zip
    })
  },
  FromContact: {
    key: 'fromContact',
    paramKey: 'hiringId',
    outboundAccesor: 'id'
  },
  Recruiter: {
    key: 'recruiter',
    paramKey: 'recruiter_id',
    outboundAccesor: 'id',
    inboundAccesor: 'recruiter'
  }
};
