// @flow
import { makeRequest } from 'services/utils';
import strings from 'strings';
import type { OperationResult } from 'types/app';
import { Endpoints } from 'UI/constants/endpoints';

import { getGeneralErrorAlert } from './utils';

const { alerts: alertsCopies } = strings.searchProjects;

export const splitSearchProject = ({
  partsName,
  partsQuantity,
  searchProjectId,
  folderId
}): Promise<OperationResult> =>
  makeRequest({
    url: Endpoints.SearchProjectSplit.replace(/:id/, searchProjectId),
    method: 'post',
    data: { partsQuantity, partsName, folderId },
    alertConfig: {
      success: {
        title: strings.formatString(alertsCopies.success.generalTitle, {
          action: alertsCopies.success.actions.split,
          entity: alertsCopies.entities.searchProject
        }),
        body: strings.formatString(alertsCopies.success.splitsBody, {
          entitiesLength: partsQuantity,
          entity: alertsCopies.entities.searchProjects
        })
      },
      error: getGeneralErrorAlert({
        action: alertsCopies.error.actions.split,
        entity: alertsCopies.entities.searchProject
      })
    }
  });
