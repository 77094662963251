// @flow
import React, { useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import Grow from '@material-ui/core/Grow';
import MenuItem from '@material-ui/core/MenuItem';
import MenuList from '@material-ui/core/MenuList';
import Paper from '@material-ui/core/Paper';
import Popper from '@material-ui/core/Popper';
import clsx from 'clsx';
import type { MenuButtonItemsProps } from 'types/app';
import FPIcon from 'UI/components/atoms/FPIcon';

import { useStyles } from './styles';

type CollapsableButtonMenuProps = {
  className: Object,
  icon: React.Node,
  iconSize: number,
  menuItems: Array<MenuButtonItemsProps>,
  text: string
};

const CollapsableButtonMenu = ({
  className,
  icon,
  iconSize,
  menuItems,
  text
}: CollapsableButtonMenuProps) => {
  const { isSidebarOpen } = useSelector(({ app }) => app.ui);

  const history = useHistory();
  const classes = useStyles({ isSidebarOpen });
  const [isOpen, setIsOpen] = useState(false);
  const anchorRef = useRef(null);

  const handleToggle = () => setIsOpen(prevOpen => !prevOpen);

  const handleClose = event => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) return;

    setIsOpen(false);
  };

  const handleClick = (e, menu) => {
    handleClose(e);
    menu.link && history.push(menu.link);
  };

  return (
    <>
      <button
        type="button"
        ref={anchorRef}
        aria-controls={isOpen && 'menu-list-grow'}
        aria-haspopup="true"
        onClick={handleToggle}
        className={clsx(classes.button, className)}
      >
        <FPIcon
          component={icon}
          size={iconSize}
          onSetColor={palette => palette.brandColors.FPWhite}
        />
        <span className={classes.label}>{text}</span>
      </button>
      <Popper
        anchorEl={anchorRef.current}
        className={classes.menuPopper}
        disablePortal
        open={isOpen}
        placement="right-start"
        role={undefined}
        transition
      >
        {({ TransitionProps }) => (
          <Grow {...TransitionProps} style={{ transformOrigin: 'left top' }}>
            <Paper>
              <ClickAwayListener onClickAway={handleClose}>
                <MenuList autoFocusItem={isOpen} id="menu-list-grow">
                  {menuItems &&
                    menuItems.map(menu => {
                      const Icon = menu?.icon;
                      return (
                        <MenuItem
                          key={menu.link}
                          className={classes.menuItem}
                          onClick={e => handleClick(e, menu)}
                        >
                          {Icon && <Icon className={classes.menuIcon} />}
                          <span className={classes.menuLabel}>{menu.title}</span>
                        </MenuItem>
                      );
                    })}
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </>
  );
};

export default CollapsableButtonMenu;
