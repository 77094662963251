import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(theme => ({
  emptyStateContainer: {
    margin: theme.spacing(9)
  },
  expandedTable: {
    position: 'fixed',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    zIndex: theme.zIndex.expandedTable,
    overflow: 'auto',
    display: 'flex',
    flexDirection: 'column'
  },
  linearProgress: {
    verticalAlign: 'baseline'
  }
}));
