import React from 'react';
import strings from 'strings';
import { When } from 'UI/components/atoms/When';
import FPQuickView from 'UI/components/organisms/inventoryProfiles/FPQuickView';
import EmptyPlaceholder from 'UI/components/templates/EmptyPlaceholder';
import { UIStatus } from 'UI/constants/status';
import { SvgDefaultEmptyState } from 'UI/res';

import { Chat, MessageComposer } from '../messageChat/components';

import { useStyles } from './styles';

export const ConversationContent = ({
  chat,
  chatStatus,
  handleClickRetry,
  handleClickSend,
  handleFetchMoreMessages,
  isQuickViewOpen,
  selectedConversationInList,
  shouldScrollToBottom,
  toggleQuickViewOpen
}) => {
  const classes = useStyles();

  const {
    entityId = '',
    entityType = '',
    externalNumber = '',
    fullName = ''
  } = selectedConversationInList || {};

  return !selectedConversationInList ? (
    <EmptyPlaceholder
      subtitle={strings.messageCenter.emptyState.unselected}
      customEmptyState={<SvgDefaultEmptyState height={400} />}
    />
  ) : (
    <>
      <div aria-label="conversation messages" className={classes.chatContainer}>
        <Chat
          chat={chat}
          chatStatus={chatStatus}
          onClickRetry={handleClickRetry}
          onFetchMoreMessages={handleFetchMoreMessages}
          shouldScrollToBottom={shouldScrollToBottom}
        />
      </div>

      <div className={classes.messageComposerContainer}>
        <MessageComposer
          disabled={chatStatus !== UIStatus.Success}
          entityId={entityId}
          entityType={entityType}
          externalNumber={externalNumber}
          onClickSend={handleClickSend}
          recipientFullName={fullName}
        />
      </div>

      <When condition={entityId && entityType && isQuickViewOpen}>
        <FPQuickView
          id={entityId}
          drawerProps={{
            open: isQuickViewOpen
          }}
          entityType={entityType}
          onClose={toggleQuickViewOpen}
        />
      </When>
    </>
  );
};
