import React from 'react';
import { useFormContext } from 'react-hook-form';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import strings from 'strings';
import { When } from 'UI/components/atoms/When';
import { EntityType } from 'UI/constants/entityTypes';
import { dateFormatter } from 'UI/utils';

import { FORM_FIELDS_MAP } from '../../CallTask/CallTask.constants';
import { ContactForm } from '../ContactForm';

import { useStyles } from './SendoutDetails.styles';

export const SendoutDetails = ({ sendoutDetails, onSendoutDetailsClick, isFetchingInterview }) => {
  const classes = useStyles();
  const { errors } = useFormContext();

  const {
    sendoutId,
    jobOrderTitle,
    companyName,
    hiringAuthorityName,
    candidateName,
    interviewDate
  } = sendoutDetails;

  return (
    <>
      <Grid container alignItems="center" justify="space-between" className={classes.mainContainer}>
        <Grid item>
          <Typography variant="body1">
            {jobOrderTitle} / {companyName}
          </Typography>
          <div className={classes.subInfo}>
            {isFetchingInterview ? (
              <div className={classes.circularProgressContainer}>
                <CircularProgress size={16} />
              </div>
            ) : (
              <Typography variant="body1" color="textSecondary">
                {hiringAuthorityName} / {candidateName} /{' '}
                {dateFormatter(interviewDate, ({ MonthDayYearTime }) => MonthDayYearTime)}
              </Typography>
            )}
          </div>
        </Grid>
        <Grid item>
          <Button
            variant="outlined"
            size="medium"
            className={classes.sendoutDetailsBtn}
            onClick={() => onSendoutDetailsClick && onSendoutDetailsClick(sendoutId)}
          >
            {strings.dailyPlan.drawer.form.labels.sendoutDetails}
          </Button>
        </Grid>
        <Grid item>
          <Typography variant="body1" color="textSecondary" className={classes.indication}>
            {strings.dailyPlan.drawer.form.labels.contactSelection}
          </Typography>
        </Grid>
        <When condition={!!errors[FORM_FIELDS_MAP.AtLeastOneContact.key]}>
          <Typography variant="body1" color="error" className={classes.indication}>
            {errors?.[FORM_FIELDS_MAP.AtLeastOneContact.key]?.message}
          </Typography>
        </When>
        <ContactForm entity={EntityType.Candidate} name={candidateName} company={companyName} />
        <ContactForm
          entity={EntityType.HiringAuthority}
          name={hiringAuthorityName}
          company={companyName}
        />
      </Grid>
    </>
  );
};
