import { dateFormatter } from 'UI/utils';

const GPAC_KEY = 'gpac';
const REGIONAL_KEY = 'regional';

export const addGpacAllRowToEndOfTable = sendouts => {
  if (!Array.isArray(sendouts) || sendouts.length === 0) return [];

  if (sendouts[0].coach === GPAC_KEY) {
    return [...sendouts, sendouts[0]];
  }
  return [...sendouts];
};

export const addLabelsAndFlattenData = data => {
  let sendouts = [];
  data.forEach(item => {
    if (item.team === GPAC_KEY) Object.assign(item, { coach: GPAC_KEY });
    if (item.team === REGIONAL_KEY) Object.assign(item, { coach: item.regional });
    if (item.team === GPAC_KEY || item.team === REGIONAL_KEY) {
      sendouts = [...sendouts, item, ...item.coaches];
    } else {
      sendouts = [...sendouts, ...item.coaches];
    }
  });
  return addGpacAllRowToEndOfTable(sendouts);
};

export const formatLastUpdateDate = lastUpdateDate => {
  return lastUpdateDate
    ? dateFormatter(lastUpdateDate, ({ MonthDayNumberYear }) => MonthDayNumberYear)
    : '-';
};

export const formatLastUpdateTime = lastUpdateDate => {
  return lastUpdateDate ? dateFormatter(lastUpdateDate, ({ SimpleTime }) => SimpleTime) : '-- : --';
};

export const getUpdatedParamsNamesByMinutes = (baseColumns, extraColumnsInfo, minutes) => {
  extraColumnsInfo.forEach(extraColumn => {
    if (extraColumn.updateFrecuencyInMinutes === minutes) {
      baseColumns.push(extraColumn.columnLabel);
    }
  });

  return String(baseColumns)
    .split(',')
    .join(', ')
    .replace(/,(?=[^,]+$)/, ' and');
};
