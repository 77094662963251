import React from 'react';
import GaugeChart from 'react-gauge-chart';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { additionalPeriodRanges } from 'UI/components/templates/SideFiltersLayout/filters';
import { createCustomStaticRanges } from 'UI/utils';

import { DateRange } from '../InlineFilters/components/DateRange';
import { StatsItemContainer } from '../StatsItemContainer';

import { CHART_COLORS } from './GaugeStats.constants';
import { useStyles } from './GaugeStats.styles';

export const GaugeStats = ({ id, stats, dateRange, onDateRangeChange, dateRangerPickerProps }) => {
  const classes = useStyles();
  const percent = stats[0]?.percentage ?? 0;

  return (
    <>
      <DateRange
        DateRangerPickerProps={{
          staticRanges: createCustomStaticRanges(additionalPeriodRanges),
          ...dateRangerPickerProps
        }}
        defaultRange={dateRange}
        disableTimeRange
        monthsToShow={1}
        onPeriodChange={onDateRangeChange}
      />

      <Grid container className={classes.flexAlignCenterSpaceBetween}>
        <Grid item lg={6} sm={12} className={classes.gaugeChartContainer}>
          <Typography className={classes.textCenter}>50%</Typography>
          <GaugeChart
            id={id}
            arcPadding={0.0}
            arcsLength={[0.5, 0.3, 0.2]}
            colors={[CHART_COLORS.red, CHART_COLORS.yellow, CHART_COLORS.green]}
            cornerRadius={0}
            hideText
            needleBaseColor={CHART_COLORS.needle}
            needleColor={CHART_COLORS.needle}
            needleScale={1}
            percent={percent > 1 ? 1 : percent}
          />

          <Typography variant="h6" className={classes.textCenter}>
            {Math.round(percent * 100)}%
          </Typography>
        </Grid>

        <Grid item lg={4} sm={12}>
          <StatsItemContainer items={stats} />
        </Grid>
      </Grid>
    </>
  );
};
