import React, { useState } from 'react';
import { FormContext, useForm } from 'react-hook-form';
import Drawer from '@material-ui/core/Drawer';
import Typography from '@material-ui/core/Typography';
import { HTTPStatusCodes } from 'constants/httpStatusCodes';
import { Loader } from 'features/command-center/components/shared/Widget/Loader';
import { CALL_TYPES, ENDPOINTS } from 'features/daily-plan/constants';
import { useShowAlert } from 'hooks';
import API from 'services/API';
import strings from 'strings';
import AutocompleteSelect from 'UI/components/molecules/AutocompleteSelect';
import SendoutManager from 'UI/components/organisms/sendouts';
import DrawerContentLayout from 'UI/components/templates/DrawerContentLayout';
import { Endpoints } from 'UI/constants/endpoints';

import { CallTask, MarketCandidate, OtherTask, RecruitJobOrder } from '../../forms';

import { FORM_FIELDS_MAP, INTERVIEW_ID_MAP } from './CreationTaskDrawer.constants';

const { form: COPIES } = strings.dailyPlan.drawer;

const getFormToRender = (taskTypeId, handleSendoutDetailsClick, isFetchingInterview) => {
  const taskFormMap = {
    [INTERVIEW_ID_MAP.RecruitJobOrder]: <RecruitJobOrder />,
    [INTERVIEW_ID_MAP.MarketCandidate]: <MarketCandidate />,
    [INTERVIEW_ID_MAP.DebriefingCall]: (
      <CallTask
        callType={CALL_TYPES.debrief}
        onSendoutDetailsClick={handleSendoutDetailsClick}
        isFetchingInterview={isFetchingInterview}
      />
    ),
    [INTERVIEW_ID_MAP.PreparationCall]: (
      <CallTask
        callType={CALL_TYPES.preparation}
        onSendoutDetailsClick={handleSendoutDetailsClick}
        isFetchingInterview={isFetchingInterview}
      />
    ),
    [INTERVIEW_ID_MAP.ClosingCall]: (
      <CallTask
        callType={CALL_TYPES.closing}
        onSendoutDetailsClick={handleSendoutDetailsClick}
        isFetchingInterview={isFetchingInterview}
      />
    ),
    [INTERVIEW_ID_MAP.OtherTask]: <OtherTask />,
    default: null
  };

  return taskFormMap[taskTypeId] ?? taskFormMap.default;
};

export const CreationTaskDrawer = ({ onClose }) => {
  const [taskType, setTaskType] = useState(null);
  const [selectedSendoutId, setSelectedSendoutId] = useState(null);
  const { showAlert } = useShowAlert();

  const [uiState, setUIState] = useState({
    isLoading: false,
    isSaving: false,
    isFetchingInterview: false
  });

  const form = useForm();
  const { handleSubmit, watch, setValue } = form;

  const formValues = watch();

  const handleSelectChange = (name?, value) => {
    setTaskType(value);
  };

  const handleSendoutDetailsClick = sendoutId => setSelectedSendoutId(sendoutId);

  const closeSendout = () => {
    setSelectedSendoutId(null);
  };

  const handleSendoutSubmit = async data => {
    if (!data) return;

    try {
      const interviewId = formValues?.[FORM_FIELDS_MAP.Interview.key]?.id;
      const endpoint = Endpoints.SendoutInterviewDetails.replace(':id', interviewId);
      setUIState(prev => ({ ...prev, isFetchingInterview: true }));
      const interviewResponse = await API.get(endpoint, { apiVersion: 2 });

      if (interviewResponse.status === HTTPStatusCodes.Ok) {
        const interviewDate = interviewResponse?.data?.interviewDate;
        const hiringAuthorityName = interviewResponse?.data?.hiringAuthorityName;
        setValue(FORM_FIELDS_MAP.Interview.key, {
          ...formValues[FORM_FIELDS_MAP.Interview.key],
          interviewDate,
          hiringAuthorityName
        });
      }
    } catch (error) {
      showAlert({
        title: COPIES.alert.error,
        autoHideDuration: 5000,
        body: COPIES.alert.error,
        error
      });
    }
    setUIState(prev => ({ ...prev, isFetchingInterview: false }));
  };

  const onSubmit = async formData => {
    try {
      console.log({ formData }); // TODO: Process formData depending on task type and make the request
    } catch (error) {
      showAlert({
        error,
        title: COPIES.alert.error,
        autoHideDuration: 5000,
        body: COPIES.alert.error
      });
    } finally {
      setUIState(prev => ({
        ...prev,
        isSaving: false
      }));
    }
  };

  return (
    <>
      <DrawerContentLayout
        title={COPIES.titles.createTask}
        drawerProps={{
          open: true
        }}
        uiState={{ isSaving: uiState.isSaving, isFormLoading: uiState.isLoading }}
        onClose={onClose}
        onSubmit={handleSubmit(onSubmit)}
      >
        <Typography variant="body1">{COPIES.labels.taskType}</Typography>
        <AutocompleteSelect
          name={FORM_FIELDS_MAP.TaskType.key}
          selectedValue={taskType}
          placeholder={COPIES.placeholders.taskType}
          url={ENDPOINTS.taskTypes}
          onSelect={handleSelectChange}
          renderOption={option => (
            <div>
              <Typography variant="body1">{option?.title}</Typography>
              {option?.description && (
                <Typography variant="body2" color="textSecondary">
                  {option.description}
                </Typography>
              )}
            </div>
          )}
        />

        {uiState.isLoading ? (
          <Loader />
        ) : (
          <FormContext {...form}>
            {getFormToRender(taskType?.id, handleSendoutDetailsClick, uiState.isFetchingInterview)}
          </FormContext>
        )}
      </DrawerContentLayout>
      {!!selectedSendoutId && (
        <Drawer open={!!selectedSendoutId} onClose={closeSendout}>
          <div role="presentation">
            <SendoutManager
              onEdit={handleSendoutSubmit}
              onClose={closeSendout}
              id={selectedSendoutId}
            />
          </div>
        </Drawer>
      )}
    </>
  );
};
