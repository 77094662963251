import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { showAlert } from 'actions/app';
import { getErrorMessage } from 'UI/utils';

export const useShowAlert = () => {
  const dispatch = useDispatch();

  const triggerAlert = useCallback(
    ({ autoHideDuration = 4000, body, error, severity = 'success', title = '' }) => {
      const finalBody = error ? getErrorMessage(error) : body;

      dispatch(
        showAlert({
          title,
          severity: error ? 'error' : severity,
          body: finalBody,
          autoHideDuration
        })
      );
    },
    [dispatch]
  );

  return { showAlert: triggerAlert };
};
