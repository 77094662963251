import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles(theme => ({
  mainContainer: {
    marginTop: theme.spacing(3)
  },
  subInfo: {
    marginTop: theme.spacing(1)
  },
  sendoutDetailsBtn: {
    textTransform: 'capitalize'
  },
  indication: {
    margin: theme.spacing(3, 0)
  },
  circularProgressContainer: {
    display: 'flex',
    justifyContent: 'center'
  }
}));
