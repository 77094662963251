import React, { useEffect, useRef } from 'react';
import { useFormContext } from 'react-hook-form';
import { useFeatureFlags } from 'providers/FeatureFlagsProvider';
import FPTextEditor from 'UI/components/molecules/FPTextEditor';
import { TINY_WITH_IMAGES_CONFIG, tinymceConfig } from 'UI/constants/config';
import { FeatureFlags } from 'UI/constants/featureFlags';

const NOTE_FIELD_KEY = 'notes';

export const SupportActivityNotesField = () => {
  const { checkIfFeatureFlagEnabled } = useFeatureFlags();

  const tinyConfig = checkIfFeatureFlagEnabled(FeatureFlags.FeedbackNotesImagesSuport)
    ? TINY_WITH_IMAGES_CONFIG
    : tinymceConfig;

  const editorRef = useRef(null);
  const { register, unregister, watch, setValue, errors } = useFormContext();

  const initEditor = (e, editor) => {
    editorRef.current = editor;
  };

  const handleEditorChange = editorValue => setValue(NOTE_FIELD_KEY, editorValue, true);

  useEffect(() => {
    register(NOTE_FIELD_KEY);

    return () => {
      unregister(NOTE_FIELD_KEY);
    };
  }, [register, unregister]);

  return (
    <FPTextEditor
      onInit={initEditor}
      config={tinyConfig}
      value={watch(NOTE_FIELD_KEY)}
      onChange={handleEditorChange}
      error={!!errors[NOTE_FIELD_KEY]}
      errorText={errors[NOTE_FIELD_KEY]?.message}
    />
  );
};
