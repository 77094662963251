import strings from 'strings';
import { Endpoints } from 'UI/constants/endpoints';
import { EntityType } from 'UI/constants/entityTypes';
import { idOptionSelected } from 'UI/utils';
import { OptionRenderers, Selectors } from 'UI/utils/renderers';

export const FORM_FIELDS_MAP = {
  Date: {
    key: 'date'
  },
  Time: {
    key: 'time'
  },
  CandidateToMarket: {
    key: 'candidateToMarket'
  },
  ReachToEntity: {
    key: 'reachTo'
  },
  ReachToName: {
    key: 'reachToName'
  },
  Comments: {
    key: 'comments'
  }
};

export const REACH_OUT_AUTOCOMPLETE_PROPS_BY_ENTITY = {
  searchProject: {
    placeholder: strings.dailyPlan.drawer.form.placeholders.selectSearchProject,
    url: '/search-projects',
    typeaheadParams: { limit: 20, onlyMine: false },
    getOptionLabel: option =>
      !!option.name && !!option.total_items ? `${option.name} (${option.total_items})` : '',
    renderOption: OptionRenderers.searchProjects,
    getOptionSelected: idOptionSelected
  },
  company: {
    displayKey: 'name',
    placeholder: strings.dailyPlan.drawer.form.placeholders.selectCompany,
    url: Endpoints.Search,
    typeaheadParams: { entityType: EntityType.Company },
    getOptionSelected: Selectors.byId,
    renderOption: OptionRenderers.globalSearchDefault('name'),
    typeaheadLimit: 50
  },
  hiringAuthority: {
    displayKey: 'full_name',
    placeholder: strings.dailyPlan.drawer.form.placeholders.selectContact,
    url: Endpoints.Search,
    typeaheadParams: { entityType: 'hiringAuthorityOrName' },
    getOptionSelected: Selectors.byId,
    groupBy: option => option.typeTitle,
    renderOption: OptionRenderers.globalSearchPerson('full_name'),
    typeaheadLimit: 20
  }
};

export const REACH_OUT_TO_RADIO_OPTIONS = [
  {
    id: 1,
    value: 'hiringAuthority',
    label: strings.dailyPlan.drawer.form.labels.hiringAuthority
  },
  {
    id: 2,
    value: 'company',
    label: strings.dailyPlan.drawer.form.labels.company
  },
  {
    id: 3,
    value: 'searchProject',
    label: strings.dailyPlan.drawer.form.labels.searchProject
  }
];
