import React from 'react';
import { EntityRoutes } from 'routes/constants';
import strings from 'strings';
import FPIcon from 'UI/components/atoms/FPIcon';
import { FeatureFlags } from 'UI/constants/featureFlags';
import { SvgCheck } from 'UI/res';
import { hasFeatureFlag } from 'UI/utils';

export const ColumnsDefinitions = [
  {
    name: 'id',
    options: {
      filter: false,
      display: 'excluded'
    }
  },
  {
    name: 'type',
    label: 'Type',
    options: {
      sort: true,
      display: true,
      renderer: {
        id: 'fastChipBuilder',
        config: {
          labelKey: 'title',
          baseClass: 'chip-company-type'
        }
      }
    }
  },
  {
    name: 'name',
    label: 'Name',
    options: {
      sort: true,
      display: true,
      renderer: {
        id: 'linkBuilder',
        config: {
          route: EntityRoutes.CompanyProfile,
          idKey: 'id',
          textKey: 'name',
          enableInTabRedirect: true
        }
      }
    }
  },
  {
    name: 'specialty_title',
    label: 'Specialty: Subspecialty',
    options: {
      sort: true,
      display: false
    }
  },
  {
    name: 'specialty',
    label: 'Specialty',
    options: {
      sort: true,
      display: true
    }
  },
  {
    name: 'industry',
    label: 'Industry',
    options: {
      sort: true,
      display: true
    }
  },
  {
    name: 'subspecialty',
    label: 'Subspecialty',
    options: {
      sort: true,
      display: false
    }
  },
  {
    name: 'location',
    label: 'Location',
    options: {
      sort: true,
      display: true
    }
  },
  {
    name: 'state',
    label: 'State',
    options: {
      sort: true,
      display: false
    }
  },
  {
    name: 'city',
    label: 'City',
    options: {
      sort: true,
      display: false
    }
  },
  {
    name: 'created_at',
    label: 'Added Date',
    options: {
      sort: true,
      display: true,
      renderer: 'date'
    }
  },
  {
    name: 'last_activity_date',
    label: 'Last Activity on',
    options: {
      sort: true,
      display: false,
      renderer: 'date'
    }
  },
  {
    name: 'coach_name',
    label: 'Team',
    options: {
      sort: true,
      display: true
    }
  },
  {
    name: 'recruiter_name',
    label: 'Recruiter',
    options: {
      sort: true,
      display: true
    }
  },
  {
    name: 'phone',
    label: 'Phone',
    options: {
      sort: false,
      display: false,
      renderer: 'phone'
    }
  },
  {
    name: 'website',
    label: 'Website',
    options: {
      sort: false,
      display: false,
      renderer: {
        id: 'website'
      }
    }
  },
  {
    name: 'number_of_contacts',
    label: 'Number of Contacts',
    options: {
      sort: true,
      display: true,
      renderer: 'formattedNumber'
    }
  },
  {
    name: 'employees',
    label: 'Employees',
    options: {
      display: hasFeatureFlag(FeatureFlags.FilterByNumberOfEmployeesRange) ? true : 'excluded',
      renderer: 'formattedNumber'
    }
  },
  {
    name: 'revenue',
    label: 'Revenue',
    options: {
      display: hasFeatureFlag(FeatureFlags.FilterByRevenueRange) ? true : 'excluded',
      renderer: 'formattedNumber'
    }
  },
  {
    name: 'is_priority_target',
    label: 'Priority Target',
    options: {
      sort: true,
      display: hasFeatureFlag(FeatureFlags.CompaniesTargetPriority) ? false : 'excluded',
      renderer: value =>
        value && <FPIcon component={SvgCheck} size={16} onSetColor={palette => palette.grey[600]} />
    }
  },
  {
    name: 'parentCompany',
    label: 'HQ / Main Company',
    options: {
      sort: true,
      display: hasFeatureFlag(FeatureFlags.CompaniesHeadquarterColumn) ? false : 'excluded',
      renderer: {
        id: 'linkBuilder',
        config: {
          route: EntityRoutes.CompanyProfile,
          idKey: 'ultimate_parent_id',
          textKey: 'ultimate_parent_company',
          enableInTabRedirect: true
        }
      }
    }
  },
  {
    name: 'address',
    label: 'Address',
    options: {
      sort: false,
      display: false,
      renderer: 'default'
    }
  },
  {
    name: 'last_activity_recruiter',
    label: 'Last Activity by',
    options: {
      sort: false,
      renderer: 'default'
    }
  },
  {
    name: 'last_activity_title',
    label: 'Last Activity',
    options: {
      sort: false,
      renderer: 'default'
    }
  },
  {
    name: 'keyword_tags',
    label: strings.inventoryProfiles.common.keywords.title,
    options: {
      sort: false,
      display: hasFeatureFlag(FeatureFlags.CompaniesKeywordTags) ? false : 'excluded',
      renderer: {
        id: 'multipleChipBuilder',
        config: {
          idKey: 'id',
          labelKey: 'keyword'
        }
      },
      setCellHeaderProps: () => ({
        style: { minWidth: 250 }
      })
    }
  }
];

const includeFilterIfHasFeatureFlag = ({ filter, featureFlagRequired }) => {
  return hasFeatureFlag(featureFlagRequired) ? [filter] : [];
};

export const FiltersGroups = [
  {
    id: 'general',
    name: 'Company',
    filters: [
      'types',
      'phone',
      'rangeByNumberOfContacts',
      'rangeByNumberOfEmployees',
      'rangeByRevenue',
      'targetCompanies',
      'website',
      'companyAssociations',
      ...includeFilterIfHasFeatureFlag({
        filter: 'keywordsTags',
        featureFlagRequired: FeatureFlags.CompaniesKeywordTags
      })
    ]
  },
  {
    id: 'industries',
    name: 'Industries',
    filters: ['industries', 'specialties', 'subspecialties']
  },
  { id: 'team', name: 'Team', filters: ['coaches', 'recruiters'] },
  {
    id: 'location',
    name: 'Location',
    filters: ['countries', 'states', 'cities', 'cityRadius', 'zips', 'radius']
  }
];

export const VirtualProps = [
  {
    propName: 'name',
    attributes: ['id', 'name']
  },
  {
    propName: 'parentCompany',
    attributes: ['ultimate_parent_id', 'ultimate_parent_company']
  }
];

export const PeriodColumnBySection = {
  0: 'created_at',
  1: 'last_activity_date'
};

export const CreatedAtFilter = { id: 0, title: 'Added Date', column: 'created_at' };
export const LastActivityDateFilter = {
  id: 1,
  title: 'Last Activity Date',
  column: 'last_activity_date'
};

export const DateFilters = [CreatedAtFilter, LastActivityDateFilter];
