import strings from 'strings';

const { options } = strings.searchProjects.forms.splits.sections.partsSelection;

/**
 * @param {'two' | 'three' | 'more'} quantity
 * @returns {String}
 */
export const formatOptionsTitle = quantity =>
  strings.formatString(options.title, options.quantity[quantity]);
