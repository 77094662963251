import React from 'react';

import { Container, TableContainer } from './ScrollableDataTableLayout.styled';

export const ScrollableDataTableLayout = ({ content, topContent }) => {
  return (
    <Container container direction="column">
      {topContent}
      <TableContainer item xs={12}>
        {content}
      </TableContainer>
    </Container>
  );
};
