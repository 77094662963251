import { formatKeywordTags } from 'UI/utils/inventory';

/**
 * This map is used to transform data before submission to the API and also to preload data from the API into a form
 * Entries can have the following props:
 * If only key is present no transformatios is applied in neither direction (to/from the API)
 * If paramKey is present, then paramKey is sent to the API
 * outboundAccesor is a key to extract a value from an object. For instance, an outboundAccesor = 'id' would evaluate to object['id'] and this value would be sent to the API
 * inboundAccesor is a property path used to preload data coming from the API into the form. It's useful for nested objects, for instance: 'city.state' or 'address.zip'. If a more custom object is needed, then inboundAccessor could be a function.
 */
export const FormFieldsMap = {
  Id: {
    key: 'id'
  },
  Company: {
    key: 'company',
    paramKey: 'company_id',
    outboundAccesor: 'id',
    inboundAccesor: form =>
      form.company_id ? { id: form.company_id, name: form.current_company } : null
  },
  Recruiter: {
    key: 'recruiter_id',
    paramKey: 'recruiter_id',
    inboundAccesor: 'recruiter',
    outboundAccesor: 'id'
  },
  Specialty: {
    key: 'specialty',
    paramKey: 'specialty_id',
    inboundAccesor: 'specialty',
    outboundAccesor: 'id'
  },
  Subspecialty: {
    key: 'subspecialty',
    paramKey: 'subspecialty_id',
    inboundAccesor: 'subspecialty',
    outboundAccesor: 'id'
  },
  FunctionalTitle: {
    key: 'functionalTitle',
    paramKey: 'position_id',
    inboundAccesor: 'position',
    outboundAccesor: 'id'
  },
  Position: {
    key: 'title'
  },
  SourceType: {
    key: 'source_type',
    paramKey: 'job_order_source_type_id',
    outboundAccesor: 'id',
    inboundAccesor: 'sourceType'
  },
  Source: {
    key: 'source'
  },
  State: {
    key: 'state_id',
    paramKey: 'state_id',
    outboundAccesor: 'id',
    inboundAccesor: 'personalInformation.address.city.state'
  },
  City: {
    key: 'city_id',
    paramKey: 'city_id',
    outboundAccesor: 'id',
    inboundAccesor: 'personalInformation.address.city'
  },
  Zip: {
    key: 'zip',
    paramKey: 'zip',
    outboundAccesor: 'id',
    inboundAccesor: form => ({
      id: form.personalInformation.address.zip,
      title: form.personalInformation.address.zip
    })
  },
  Location: {
    key: 'different_location',
    paramKey: 'different_location'
  },
  HiringAuthority: {
    key: 'hiring_authority_id',
    paramKey: 'hiring_authority_id',
    inboundAccesor: 'hiring_authority_id',
    outboundAccesor: 'id'
  },
  KeywordTags: {
    key: 'keywordTags',
    outboundAccesor: keywordTags => formatKeywordTags(keywordTags)
  }
};
