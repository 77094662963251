import clsx from 'clsx';

export const getItemStyles = ({
  level,
  hasSubItems,
  isExpanded,
  isItemActive,
  isSidebarOpen,
  isItemOrChildActive,
  item,
  classes
}) => {
  const shouldShowActiveIcon = () => {
    if (isSidebarOpen) {
      return isItemActive;
    }

    if (hasSubItems) {
      return isItemOrChildActive(item) && level >= 1;
    }

    return isItemOrChildActive(item);
  };

  const getBaseIconClass = () => {
    if (level === 0) {
      return classes.icon;
    }
    if (hasSubItems && isExpanded) {
      return classes.icon;
    }
    return classes.singleItemIcon;
  };

  const labelClasses = clsx(
    classes.label,
    hasSubItems
      ? isItemOrChildActive(item) && classes.labelBold
      : isItemActive && classes.itemSelected
  );

  const iconClasses = clsx(getBaseIconClass(), shouldShowActiveIcon() && classes.iconActive);

  return {
    iconClasses,
    labelClasses
  };
};
