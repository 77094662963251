import React, { useState } from 'react';
import Drawer from '@material-ui/core/Drawer';
import { SimplifiedTable } from 'features/command-center/components/shared';
import { TABLES_KEYS } from 'features/command-center/constants';
import { SECONDARY_API_CLIENT } from 'services/API';
import PlacementEditForm from 'UI/components/organisms/placements/PlacementEditForm';
import {
  ScrollableDataTable,
  ScrollableDataTableLayout
} from 'UI/components/templates/ScrollableDataTableLayout';
import { RowActions } from 'UI/constants/defaults';

import { COLUMNS, ORDER_BY_OPTIONS, VIRTUAL_PROPS } from './PlacementsDrawerContent.constants';

export const PlacementsDrawerContent = ({ endpoint = '' }) => {
  const [selectedPlacementId, setSelectedPlacementId] = useState(null);
  const [shouldRefresh, setShouldRefresh] = useState(true);
  const openDetailsDrawer = id => setSelectedPlacementId(id);

  const closeDetailsDrawer = () => {
    setSelectedPlacementId(null);
    setShouldRefresh(true);
  };
  const handleDrawerActionSubmit = data => {
    if (data) {
      setShouldRefresh(false);

      closeDetailsDrawer();
    }
  };

  return (
    <>
      <ScrollableDataTableLayout
        content={
          <SimplifiedTable
            apiInstance={SECONDARY_API_CLIENT}
            columns={COLUMNS}
            endpoint={endpoint}
            orderByOptions={ORDER_BY_OPTIONS}
            pageKey={TABLES_KEYS.drawerPlacements}
            rowActions={[
              {
                name: RowActions.View,
                customAction: 'rowClickBuilder',
                onClick: openDetailsDrawer
              }
            ]}
            shouldRefresh={shouldRefresh}
            shouldUseSavedPagination={false}
            TableComponent={ScrollableDataTable}
            showWrapper={false}
            virtualProps={VIRTUAL_PROPS}
          />
        }
      />

      {!!selectedPlacementId && (
        <Drawer open={!!selectedPlacementId} onClose={closeDetailsDrawer}>
          <div role="presentation">
            <PlacementEditForm
              onEdit={handleDrawerActionSubmit}
              onDelete={handleDrawerActionSubmit}
              onClose={closeDetailsDrawer}
              placementId={selectedPlacementId}
            />
          </div>
        </Drawer>
      )}
    </>
  );
};
