import React from 'react';
import Card from '@material-ui/core/Card';
import CircularProgress from '@material-ui/core/CircularProgress';
import EmptyPlaceholder from 'UI/components/templates/EmptyPlaceholder';

import { useStyles } from './styles';

export const UpdateMessage = ({ lastUpdate }) => {
  const classes = useStyles();
  return (
    <p className={classes.lastUpdateLabel}>
      Updated at: <span className={classes.lastUpdateTime}>{lastUpdate || 'Loading...'}</span>
    </p>
  );
};

export const LoadingRender = () => {
  const classes = useStyles();
  return (
    <Card className={classes.defaultWrapper}>
      <CircularProgress />
    </Card>
  );
};

export const EmptyRender = ({ title, subtitle }) => {
  const classes = useStyles();
  return (
    <Card className={classes.defaultWrapper}>
      <EmptyPlaceholder title={title} subtitle={subtitle} />
    </Card>
  );
};

export const getColumnsToHide = columnsToHideSetting =>
  columnsToHideSetting ? columnsToHideSetting.replace(/-/g, ' ').split('|') : [];
