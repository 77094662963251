// @flow
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { FormContext, useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router';
import { Grid } from '@material-ui/core';
import { confirm, showAlert } from 'actions/app';
import moment from 'moment';
import { useFeatureFlags } from 'providers/FeatureFlagsProvider';
import { EntityRoutes } from 'routes/constants';
import { splitSearchProject } from 'services/searchProjects';
import { getAllSearchProjectsFolders } from 'services/searchProjects/folders';
import strings from 'strings';
import Text from 'UI/components/atoms/Text';
import TextBox from 'UI/components/atoms/TextBox';
import { When } from 'UI/components/atoms/When';
import FPRadioGroup from 'UI/components/molecules/FPRadioGroup';
import InfoLabel from 'UI/components/molecules/InfoLabel';
import NumberedForm from 'UI/components/molecules/NumberedForm';
import TreeViewSelector from 'UI/components/molecules/TreeViewSelector/TreeViewSelector';
import DrawerContentLayout from 'UI/components/templates/DrawerContentLayout';
import {
  DEFAULT_SEARCH_PROJECT_FOLDERS_EXCLUDE_IDS,
  SEARCH_PROJECT_ROOT_FOLDERS,
  SearchProjectPrivacyScopes
} from 'UI/constants/defaults';
import { FeatureFlags } from 'UI/constants/featureFlags';
import { dateFormatter, objectToArray } from 'UI/utils';

import { FORM_FIELDS_MAP } from './fields';
import { useInformationRendererStyles, useStyles } from './styles';
import { formatOptionsTitle } from './utils';

const MAX_NUMBER_OF_SPLITS = process.env.REACT_APP_SEARCH_PROJECT_MAX_SPLIT;
const MIN_NUMBER_OF_SPLITS = 2;

const { title: drawerTitle, sections: sectionsCopies } = strings.searchProjects.forms.splits;

const { splitSearchProject: confirmDialogCopies } = strings.searchProjects.dialogs;

const InformationRenderer = ({ information }) => {
  const classes = useInformationRendererStyles();
  return (
    <Grid container>
      {information.map(item => (
        <Grid key={item.title} item xs={item.cols} className={classes.infoLabelSpacing}>
          <InfoLabel title={item.title} description={item.detail} />
        </Grid>
      ))}
    </Grid>
  );
};

const RADIO_OPTIONS = {
  two: { id: 2, title: formatOptionsTitle('two') },
  three: { id: 3, title: formatOptionsTitle('three') },
  more: { id: 'more', title: formatOptionsTitle('more') }
};

type SplitSearchProjectType = {
  isPrivate: boolean,
  originalTotalItems: number,
  searchProjectId: number,
  searchProjectName: string
};

type SplitsFormTypes = {
  onClose: () => void,
  SearchProject: SplitSearchProjectType
};

const SplitsForm = ({ onClose, SearchProject }: SplitsFormTypes) => {
  const { checkIfFeatureFlagEnabled } = useFeatureFlags();
  const isFoldersFeatureActive = checkIfFeatureFlagEnabled(FeatureFlags.SearchProjectsFolders);
  const classes = useStyles();
  const history = useHistory();

  const dispatch = useDispatch();
  const { searchProjectId, isPrivate, searchProjectName, originalTotalItems } = SearchProject;

  const [uiState, setUiState] = useState({ isSplitDialogFetching: false });

  const form = useForm({
    defaultValues: { searchProjectName }
  });
  const { errors, handleSubmit, register, setValue, unregister, watch } = form;
  const watchers = watch([
    FORM_FIELDS_MAP.splitOptions.key,
    FORM_FIELDS_MAP.splitIntoParts.key,
    FORM_FIELDS_MAP.searchProjectName.key
  ]);

  const splitName = strings.formatString(sectionsCopies.splitInformation.resume.name, {
    searchProjectName: watchers.searchProjectName,
    date: dateFormatter(moment(), ({ SearchProjectSplit }) => SearchProjectSplit)
  });
  const itemsPerSplit = useMemo(() => {
    const getTotalItemsString = totalItemsParam =>
      strings.formatString(sectionsCopies.splitInformation.resume.totalItems, {
        totalItems: totalItemsParam
      });
    if (errors.splitIntoParts || errors.splitOptions) {
      return getTotalItemsString(originalTotalItems);
    }

    const splitNumber = Number(
      Number.isNaN(Number(watchers.splitOptions)) ? watchers.splitIntoParts : watchers.splitOptions
    );
    const numberOfItemsPerSplit = Number.isNaN(splitNumber)
      ? originalTotalItems
      : Math.ceil(originalTotalItems / splitNumber);

    return getTotalItemsString(numberOfItemsPerSplit);
  }, [
    errors.splitIntoParts,
    errors.splitOptions,
    watchers.splitIntoParts,
    watchers.splitOptions,
    originalTotalItems
  ]);

  const getInformation = useCallback(
    shouldShowSplitInfo => [
      {
        title: sectionsCopies.resume.infoTitles.name,
        detail: shouldShowSplitInfo ? splitName : searchProjectName,
        cols: 12
      },
      {
        title: sectionsCopies.resume.infoTitles.totalItems,
        detail: shouldShowSplitInfo ? itemsPerSplit : originalTotalItems,
        cols: 6
      },
      {
        title: sectionsCopies.resume.infoTitles.visibility,
        detail: SearchProjectPrivacyScopes.getPrivacyState(isPrivate).title,
        cols: 6
      }
    ],
    [itemsPerSplit, splitName, originalTotalItems, isPrivate, searchProjectName]
  );

  useEffect(() => {
    register(
      { name: FORM_FIELDS_MAP.splitOptions.key },
      {
        required: sectionsCopies.partsSelection.validations.defaultOptions.required,
        validate: value =>
          value <= originalTotalItems ||
          Number.isNaN(Number(value)) ||
          sectionsCopies.partsSelection.validations.defaultOptions.splitsGreatherThanItems
      }
    );
    register(
      { name: FORM_FIELDS_MAP.searchProjectName.key, value: searchProjectName },
      { required: sectionsCopies.splitInformation.validations.required }
    );
  }, [register, searchProjectName, originalTotalItems]);

  const handleChangeSplitOptions = ({ target }) => {
    const hasNumberValue = Number.isInteger(Number(target.value));
    !hasNumberValue
      ? register(
          { name: FORM_FIELDS_MAP.splitIntoParts.key },
          {
            required: sectionsCopies.partsSelection.validations.defaultOptions.required,
            max: {
              value: MAX_NUMBER_OF_SPLITS,
              message: strings.formatString(
                sectionsCopies.partsSelection.validations.customSplits.max
              )
            },
            min: {
              value: MIN_NUMBER_OF_SPLITS,
              message: strings.formatString(
                sectionsCopies.partsSelection.validations.customSplits.min
              )
            },
            validate: value => {
              const valueToNumber = Number(value);
              if (!Number.isInteger(valueToNumber) || Number.isNaN(valueToNumber)) {
                return strings.formatString(
                  sectionsCopies.partsSelection.validations.customSplits.shouldBeInteger
                );
              }

              return (
                valueToNumber <= originalTotalItems ||
                sectionsCopies.partsSelection.validations.defaultOptions.splitsGreatherThanItems
              );
            }
          }
        )
      : unregister('splitIntoParts');
    setValue('splitOptions', target.value, true);
  };

  const handleTextBoxChange = (name, value) => setValue(name, value, true);

  const handleConfirmSplit = async formData => {
    setUiState({ isSplitDialogFetching: true });
    const featureParams = {};

    if (isFoldersFeatureActive)
      featureParams.folderId = formData[FORM_FIELDS_MAP.parentFolder.key].id;
    const { success, alert } = await splitSearchProject({
      partsName: formData?.searchProjectName.trim(),
      partsQuantity: formData?.splitIntoParts || formData.splitOptions,
      searchProjectId,
      ...featureParams
    });

    setUiState({ isSplitDialogFetching: false });
    dispatch(showAlert(alert));
    success && history.push(EntityRoutes.SearchProject);
  };

  const onSubmit = async formData => {
    const parts = formData?.splitIntoParts || formData.splitOptions;

    dispatch(
      confirm({
        ...confirmDialogCopies,
        message: strings.formatString(confirmDialogCopies.message, {
          searchProjectName: watchers.searchProjectName,
          parts
        }),
        severity: 'warning',
        withButtons: 'YesNo',
        onConfirm: ok => ok && handleConfirmSplit(formData)
      })
    );
  };

  return (
    <DrawerContentLayout
      title={drawerTitle}
      drawerProps={{
        open: true
      }}
      uiState={{
        isSaving: uiState.isSplitDialogFetching
      }}
      onClose={() => onClose && onClose()}
      onSubmit={handleSubmit(onSubmit)}
    >
      <FormContext {...form}>
        <NumberedForm>
          <NumberedForm.Item title={sectionsCopies.resume.title}>
            <InformationRenderer information={getInformation(false)} />
          </NumberedForm.Item>
          <NumberedForm.Item title={sectionsCopies.partsSelection.title}>
            <FPRadioGroup
              column
              classes={{ root: classes.radioGroup }}
              options={objectToArray(RADIO_OPTIONS)}
              onChange={handleChangeSplitOptions}
              value={watchers?.splitOptions || null}
              error={!!errors.splitOptions}
              errorMessage={errors?.splitOptions?.message}
            />
            {watchers.splitOptions === RADIO_OPTIONS.more.id && (
              <TextBox
                name="splitIntoParts"
                placeholder="Number of Search Projects"
                type="number"
                onChange={handleTextBoxChange}
                error={!!errors.splitIntoParts}
                errorText={errors?.splitIntoParts?.message}
              />
            )}
          </NumberedForm.Item>
          <NumberedForm.Item title={sectionsCopies.splitInformation.title}>
            <Text text={sectionsCopies.splitInformation.subtitle} variant="body1" fontSize={16} />
            <TextBox
              className={classes.nameInput}
              name={FORM_FIELDS_MAP.searchProjectName.key}
              label={sectionsCopies.splitInformation.label}
              onChange={handleTextBoxChange}
              value={watchers.searchProjectName}
              error={!!errors.searchProjectName}
              errorText={errors?.searchProjectName?.message}
            />
            <InformationRenderer information={getInformation(true)} />
          </NumberedForm.Item>
          <When condition={isFoldersFeatureActive}>
            <NumberedForm.Item title={sectionsCopies.parentFolder.title}>
              <TreeViewSelector
                defaultValue={SEARCH_PROJECT_ROOT_FOLDERS.mine}
                formFieldName={FORM_FIELDS_MAP.parentFolder.key}
                placeholder={sectionsCopies.parentFolder.placeholder}
                shouldRegisterWithin
                TreeViewLoaderProps={{
                  enableIconClick: true,
                  onlyShowFolders: true,
                  service: {
                    method: getAllSearchProjectsFolders,
                    queryParams: { excludeIds: DEFAULT_SEARCH_PROJECT_FOLDERS_EXCLUDE_IDS }
                  }
                }}
              />
            </NumberedForm.Item>
          </When>
        </NumberedForm>
      </FormContext>
    </DrawerContentLayout>
  );
};

export default SplitsForm;
