import React, { useCallback, useMemo, useState } from 'react';
import { DRAWER_KEYS, LineSteppedAreaChart, Widget } from 'features/command-center';
import { COMMAND_CENTER_BASE_URL } from 'features/command-center/constants';
import { useDrawerManager } from 'features/command-center/contexts';
import { getActivityHistory } from 'features/command-center/services';
import moment from 'moment';
import strings from 'strings';
import { additionalPeriodRanges } from 'UI/components/templates/SideFiltersLayout/filters';
import { DateFormats } from 'UI/constants/defaults';
import { createCustomStaticRanges, getCurrentWeekRange } from 'UI/utils';

import { DateRange } from '../../shared/InlineFilters/components/DateRange';
import { formatTrendsResponseWithUrl } from '../activity.utils';

const STRINGS = strings.commandCenter.activity.phoneTimeTrends;
const URL_INDEX_ACCORDING_VALUE = 1;

export const PhoneTimeTrends = ({ requestParams = {}, hasDrillDown }) => {
  const [dateRange, setDateRange] = useState(() => getCurrentWeekRange());
  const { openDrawer } = useDrawerManager();

  const queryDateRange = useMemo(
    () => ({
      startDate: moment(dateRange.startDate).format(DateFormats.QueryShortFormat),
      endDate: moment(dateRange.endDate).format(DateFormats.QueryShortFormat)
    }),
    [dateRange.endDate, dateRange.startDate]
  );

  const handleDateRangeChange = selectedDateRange => setDateRange(selectedDateRange);

  const promises = useCallback(() => {
    return Promise.all([
      getActivityHistory({
        ...requestParams,
        metrics: 'phoneTime',
        limit: 7,
        orderBy: 'date',
        direction: 'asc',
        ...queryDateRange
      })
    ]).then(([data]) => data);
  }, [queryDateRange, requestParams]);

  const cachedPromises = useMemo(() => [promises], [promises]);

  return (
    <Widget header={STRINGS.title} promises={cachedPromises}>
      {([response]) => {
        const data = formatTrendsResponseWithUrl({
          response,
          key: 'phoneTime',
          goalKey: 'phoneTimeGoal',
          label: STRINGS.title,
          isPhoneTime: true
        });

        const handlePointClick = (clickedData, row, column) => {
          const url = clickedData[column + URL_INDEX_ACCORDING_VALUE];
          url &&
            typeof url === 'string' &&
            openDrawer({
              activeDrawer: DRAWER_KEYS.Calls,
              contentProps: { endpoint: `${COMMAND_CENTER_BASE_URL}${url}` }
            });
        };

        const chartEvents = [
          {
            eventName: 'select',
            callback: ({ chartWrapper }) => {
              const selection = chartWrapper.getChart().getSelection();
              if (selection.length === 0) return;

              const [selectedItem] = selection;
              const { row, column } = selectedItem;

              if (row !== undefined && column !== undefined) {
                const clickedData = data[row + 1]; // +1 beacause the first row is headers
                handlePointClick(clickedData, row, column);
              }
            }
          }
        ];

        return (
          <>
            <DateRange
              DateRangerPickerProps={{
                staticRanges: createCustomStaticRanges(additionalPeriodRanges)
              }}
              defaultRange={dateRange}
              disableTimeRange
              onPeriodChange={handleDateRangeChange}
            />

            <LineSteppedAreaChart chartEvents={hasDrillDown && chartEvents} data={data} />
          </>
        );
      }}
    </Widget>
  );
};
