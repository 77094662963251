import React, { useState } from 'react';
import { GaugeStats, IconLabel, Widget } from 'features/command-center';
import { getActivitySummary } from 'features/command-center/services';
import moment from 'moment';
import strings from 'strings';
import { DateFormats } from 'UI/constants/defaults';
import { getCurrentWeekRange } from 'UI/utils';

import { formatResponse } from './InMailsGauge.utils';

const STRINGS = strings.commandCenter.activity.inMailsGauge;

export const InMailsGauge = ({ requestParams = {}, footerLabel }) => {
  const [dateRange, setDateRange] = useState(() => getCurrentWeekRange());
  const queryDateRange = {
    startDate: moment(dateRange.startDate).format(DateFormats.QueryShortFormat),
    endDate: moment(dateRange.endDate).format(DateFormats.QueryShortFormat)
  };

  const handleDateRangeChange = selectedDateRange => setDateRange(selectedDateRange);

  return (
    <Widget
      header={STRINGS.title}
      promises={[
        () =>
          getActivitySummary({
            metrics: 'inMailsGoalsReaching',
            ...queryDateRange,
            ...requestParams
          })
      ]}
    >
      {([response]) => {
        const stats = formatResponse(response);

        return (
          <>
            <GaugeStats
              id="inmails"
              stats={stats}
              dateRange={dateRange}
              onDateRangeChange={handleDateRangeChange}
              dateRangerPickerProps={{ direction: 'vertical' }}
            />

            {footerLabel && <IconLabel label={footerLabel} />}
          </>
        );
      }}
    </Widget>
  );
};
