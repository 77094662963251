import React, { useEffect } from 'react';
import { useFormContext } from 'react-hook-form';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { ENDPOINTS } from 'features/command-center/constants';
import { CALL_TYPES } from 'features/daily-plan/constants';
import strings from 'strings';
import { When } from 'UI/components/atoms/When';
import AutocompleteSelect from 'UI/components/molecules/AutocompleteSelect';
import { DateFormats } from 'UI/constants/defaults';
import { EntityType } from 'UI/constants/entityTypes';
import { REQUIRED_VALIDATION, toLocalTime } from 'UI/utils';
import { buildBasePropsForField } from 'UI/utils/forms';

import { SendoutDetails } from '../shared/SendoutDetails';

import { FORM_FIELDS_MAP } from './CallTask.constants';
import { useStyles } from './CallTask.styles';
import { buildSendoutDetails } from './CallTask.utils';

const { form: COPIES } = strings.dailyPlan.drawer;

const InterviewOptionsRender = option => (
  <div>
    <Typography variant="body1">
      {option?.jobOrderTitle} / {option?.companyName}
    </Typography>

    <Typography variant="body2" color="textSecondary">
      {option?.hiringAuthorityName} / {option?.candidateName} /{' '}
      {option?.interviewDate &&
        toLocalTime(option?.interviewDate).format(DateFormats.MonthDayYearTime)}
    </Typography>
  </div>
);

const validateAtLeastOneContact = getValues => {
  const formValues = getValues();
  const atLeastOneContact =
    formValues[FORM_FIELDS_MAP.IsCandidateSelected.key] ||
    formValues[FORM_FIELDS_MAP.IsHiringAuthSelected.key];
  return atLeastOneContact || strings.dailyPlan.drawer.form.labels.atLeastOneContact;
};

export const CallTask = ({ callType, onSendoutDetailsClick, isFetchingInterview }) => {
  const classes = useStyles();
  const { errors, register, unregister, watch, setValue, getValues } = useFormContext();
  const formValues = watch();

  useEffect(() => {
    register(FORM_FIELDS_MAP.Interview.key, { ...REQUIRED_VALIDATION });
    register(FORM_FIELDS_MAP.IsCandidateSelected.key);
    register(FORM_FIELDS_MAP.IsHiringAuthSelected.key);
    register(FORM_FIELDS_MAP.AtLeastOneContact.key, {
      validate: () => validateAtLeastOneContact(getValues)
    });
    return () => {
      unregister(FORM_FIELDS_MAP.Interview.key);
      unregister(FORM_FIELDS_MAP.IsCandidateSelected.key);
      unregister(FORM_FIELDS_MAP.IsHiringAuthSelected.key);
      unregister(FORM_FIELDS_MAP.AtLeastOneContact.key);
    };
  }, [register, unregister, getValues]);

  const handleAutocompleteChange = (name?, value) => {
    setValue(name, value, true);
    setValue(FORM_FIELDS_MAP.IsCandidateSelected.key, true);
    setValue(FORM_FIELDS_MAP.IsHiringAuthSelected.key, true);
    setValue(FORM_FIELDS_MAP.AtLeastOneContact.key, true);
  };

  const sendoutDetails = buildSendoutDetails(formValues[FORM_FIELDS_MAP.Interview.key]);

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Typography variant="body1" className={classes.fieldLabel}>
          {COPIES.labels.taskDetails}
        </Typography>

        <AutocompleteSelect
          {...buildBasePropsForField(FORM_FIELDS_MAP.Interview.key, errors)}
          apiVersion={2}
          getOptionLabel={option => `${option?.jobOrderTitle} / ${option?.companyName}`}
          onSelect={handleAutocompleteChange}
          placeholder={strings.dailyPlan.drawer.form.placeholders.selectInterview}
          renderOption={InterviewOptionsRender}
          selectedValue={formValues[FORM_FIELDS_MAP.Interview.key] || null}
          typeahead
          typeaheadLimit={50}
          typeaheadParams={{ entityType: EntityType.Joborder }}
          url={
            callType === CALL_TYPES.preparation
              ? ENDPOINTS.preparationCalls
              : ENDPOINTS.debriefingCalls
          }
        />
      </Grid>
      <When condition={!!formValues[FORM_FIELDS_MAP.Interview.key] && !!sendoutDetails}>
        <Grid item xs={12}>
          <SendoutDetails
            sendoutDetails={sendoutDetails}
            onSendoutDetailsClick={onSendoutDetailsClick}
            isFetchingInterview={isFetchingInterview}
          />
        </Grid>
      </When>
    </Grid>
  );
};
