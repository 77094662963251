import React, { useState } from 'react';
import Drawer from '@material-ui/core/Drawer';
import { SimplifiedTable } from 'features/command-center/components/shared';
import { TABLES_KEYS } from 'features/command-center/constants';
import { SECONDARY_API_CLIENT } from 'services/API';
import FeeAgreementDetails from 'UI/components/organisms/feeagreements/FeeAgreementDetails';
import {
  ScrollableDataTable,
  ScrollableDataTableLayout
} from 'UI/components/templates/ScrollableDataTableLayout';
import { RowActions } from 'UI/constants/defaults';
import { EntityType } from 'UI/constants/entityTypes';

import {
  ORDER_BY_OPTIONS,
  SENT_AGREEMENTS_COLUMNS,
  SIGNED_AGREEMENTS_COLUMNS,
  VIRTUAL_PROPS
} from './AgreementsDrawerContent.constants';

export const AgreementsDrawerContent = ({ endpoint = '', entity }) => {
  const [selectedAgreementId, setSelectedAgreementId] = useState(null);
  const [shouldRefresh, setShouldRefresh] = useState(true);
  const openDetailsDrawer = id => setSelectedAgreementId(id);
  const finalColumns =
    entity === `sent${EntityType.FeeAgreement}`
      ? SENT_AGREEMENTS_COLUMNS
      : SIGNED_AGREEMENTS_COLUMNS;
  const pageKey =
    entity === `sent${EntityType.FeeAgreement}`
      ? TABLES_KEYS.drawerSentAgreements
      : TABLES_KEYS.drawerSignedAgreements;

  const closeDetailsDrawer = () => {
    setSelectedAgreementId(null);
    setShouldRefresh(true);
  };
  const handleDrawerActionSubmit = data => {
    if (data) {
      setShouldRefresh(false);

      closeDetailsDrawer();
    }
  };

  return (
    <>
      <ScrollableDataTableLayout
        content={
          <SimplifiedTable
            apiInstance={SECONDARY_API_CLIENT}
            columns={finalColumns}
            endpoint={endpoint}
            pageKey={pageKey}
            orderByOptions={ORDER_BY_OPTIONS}
            rowActions={[
              {
                name: RowActions.View,
                customAction: 'rowClickBuilder',
                onClick: openDetailsDrawer
              }
            ]}
            shouldRefresh={shouldRefresh}
            shouldUseSavedPagination={false}
            showWrapper={false}
            TableComponent={ScrollableDataTable}
            virtualProps={VIRTUAL_PROPS}
          />
        }
      />

      {!!selectedAgreementId && (
        <Drawer open={!!selectedAgreementId} onClose={closeDetailsDrawer}>
          <div role="presentation">
            <FeeAgreementDetails
              onEdit={handleDrawerActionSubmit}
              onDelete={handleDrawerActionSubmit}
              onClose={closeDetailsDrawer}
              feeAgreementId={selectedAgreementId}
            />
          </div>
        </Drawer>
      )}
    </>
  );
};
