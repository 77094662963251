// @flow
import React, { useCallback, useEffect, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { useLocation } from 'react-router-dom';
import Box from '@material-ui/core/Box';
import Checkbox from '@material-ui/core/Checkbox';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { KeywordPicker } from 'common';
import { useShowAlert } from 'hooks';
import { useFeatureFlags } from 'providers/FeatureFlagsProvider';
import { EntityRoutes } from 'routes/constants';
import API from 'services/API';
import strings from 'strings';
import TextBox from 'UI/components/atoms/TextBox';
import { When } from 'UI/components/atoms/When';
import AutocompleteSelect from 'UI/components/molecules/AutocompleteSelect';
import IndustryFields from 'UI/components/molecules/IndustryFields';
import LocationForm from 'UI/components/organisms/LocationForm';
import { FORM_SPACING, getGridColumnSize, minWidthTextBox } from 'UI/constants/dimensions';
import { Endpoints } from 'UI/constants/endpoints';
import { EntityType } from 'UI/constants/entityTypes';
import { FeatureFlags } from 'UI/constants/featureFlags';
import { REQUIRED_VALIDATION, URL_VALIDATION } from 'UI/utils';
import { buildBasePropsForField } from 'UI/utils/forms';
import { DEFAULT_DISPLAY_TEXT_KEY } from 'UI/utils/inventory';
import { OptionRenderers } from 'UI/utils/renderers';

import { FormFieldsMap } from './fields';
import { gridStyles, StyledForm } from './styles';

const LOCATION_STATUS = {
  same: 0,
  different: 1
};

type JobOrderFormProps = {
  companyId: number | null,
  isEditing: boolean,
  onValuesChanged: () => void
};

const chainedSelects = {
  [FormFieldsMap.State.key]: [FormFieldsMap.City.key, FormFieldsMap.Zip.key],
  [FormFieldsMap.City.key]: [FormFieldsMap.Zip.key]
};

const JobOrderForm = ({ companyId, isEditing, onValuesChanged }: JobOrderFormProps) => {
  const classes = gridStyles();
  const location = useLocation();
  const { showAlert } = useShowAlert();
  const { checkIfFeatureFlagEnabled } = useFeatureFlags();
  const shouldShowKeywordTags = checkIfFeatureFlagEnabled(FeatureFlags.JobOrderKeywordTags);

  const { register, unregister, errors, setValue, reset, watch, getValues, formState } =
    useFormContext();
  const formValues = watch();
  const { touched: fieldsTouched, dirtyFields } = formState;
  const touchedLength = Object.keys(fieldsTouched).length;
  const dirtyFieldsLength = dirtyFields.size;
  const [differentLocation, setDifferentLocation] = useState(
    formValues[FormFieldsMap.Location.key] === LOCATION_STATUS.different || false
  );
  const [uiState, setUiState] = useState({
    isLoadingCompany: false
  });

  useEffect(() => {
    onValuesChanged && onValuesChanged(getValues());
  }, [touchedLength, dirtyFieldsLength, onValuesChanged, getValues]);

  const loadCompany = useCallback(
    async id => {
      if (!id) return;
      setUiState(prevState => ({ ...prevState, isLoadingCompany: true }));
      try {
        const response = await API.get(`${Endpoints.Companies}/${id}?excludeListings=true`);
        const company = response.data;
        if (company) {
          reset({
            [FormFieldsMap.Company.key]: company,
            [FormFieldsMap.Specialty.key]: company.specialty,
            [FormFieldsMap.Subspecialty.key]: company.subspecialty
          });
        }
      } catch (error) {
        showAlert({
          title: 'Company',
          error
        });
      } finally {
        setUiState(prevState => ({ ...prevState, isLoadingCompany: false }));
      }
    },
    [reset, showAlert]
  );

  useEffect(() => {
    companyId && loadCompany(companyId);
  }, [companyId, loadCompany]);

  useEffect(() => {
    register({ name: FormFieldsMap.Company.key }, REQUIRED_VALIDATION);
    register({ name: FormFieldsMap.SourceType.key }, REQUIRED_VALIDATION);

    register({ name: FormFieldsMap.Location.key }, REQUIRED_VALIDATION);
    register({ name: FormFieldsMap.KeywordTags.key });
  }, [register]);

  useEffect(() => {
    if (differentLocation) {
      setValue(FormFieldsMap.Location.key, 1);
      register({ name: FormFieldsMap.State.key }, REQUIRED_VALIDATION);
      register({ name: FormFieldsMap.City.key }, REQUIRED_VALIDATION);
      register({ name: FormFieldsMap.Zip.key }, REQUIRED_VALIDATION);
    } else {
      setValue(FormFieldsMap.Location.key, 0);
      unregister({ name: FormFieldsMap.State.key });
      unregister({ name: FormFieldsMap.City.key });
      unregister({ name: FormFieldsMap.Zip.key });
    }
  }, [differentLocation, register, setValue, unregister]);

  const handleComboChange = (name?: string, value: any) => {
    setValue(name, value, true);

    if (name && chainedSelects[name]) {
      chainedSelects[name].forEach(chainedSelect => {
        setValue(chainedSelect, null);
      });
    }
    onValuesChanged && onValuesChanged(getValues());
  };

  const handleCompanySelect = async (name: string, value: any) => {
    reset({});
    setValue(name, value, true);

    if (!value) return;

    await loadCompany(value.id);
  };

  const handleCheckChange = e => {
    const { name, checked } = e.target;

    setValue(name, checked ? LOCATION_STATUS.different : LOCATION_STATUS.same, checked);
    setDifferentLocation(checked);
  };

  const handleChangeKeywords = (name, value) => {
    setValue(name, value, true);
    onValuesChanged && onValuesChanged(getValues());
  };

  const defaultOptionSelectedFn = (option, value) => option.id === value.id;

  const { sm, xs, md, lg } = getGridColumnSize(isEditing);

  return (
    <Box width="100%" className="creation-form-layout">
      <Grid container spacing={FORM_SPACING}>
        <Grid item xs={xs} sm={sm} md={md} lg={lg}>
          <AutocompleteSelect
            {...buildBasePropsForField(FormFieldsMap.Company.key, errors)}
            selectedValue={formValues[FormFieldsMap.Company.key]}
            placeholder="Company *"
            url={Endpoints.Search}
            displayKey="name"
            typeahead
            typeaheadLimit={50}
            typeaheadParams={{ entityType: EntityType.Company }}
            onSelect={handleCompanySelect}
            disabled={!!companyId}
            getOptionSelected={defaultOptionSelectedFn}
            renderOption={OptionRenderers.globalSearchDefault('name')}
            noMargin
            createButton={{
              text: 'Create company',
              concatKeyword: true,
              url: EntityRoutes.CompanyCreate,
              showAlways: true,
              location
            }}
          />
        </Grid>
        <Grid item xs={xs} sm={sm} md={md} lg={lg}>
          <TextBox
            {...buildBasePropsForField(FormFieldsMap.Position.key, errors)}
            label="Title *"
            inputRef={register(REQUIRED_VALIDATION)}
            minWidth={minWidthTextBox}
          />
        </Grid>
        <IndustryFields
          isEditing={isEditing}
          onSelect={handleComboChange}
          onValuesChanged={onValuesChanged}
          loading={uiState.isLoadingCompany}
        />
        <Grid item xs={xs} sm={sm} md={md} lg={lg}>
          <AutocompleteSelect
            {...buildBasePropsForField(FormFieldsMap.SourceType.key, errors)}
            selectedValue={formValues[FormFieldsMap.SourceType.key]}
            placeholder="Source *"
            url={`${Endpoints.JobOrders}/${Endpoints.SourceTypes}`}
            onSelect={handleComboChange}
          />
        </Grid>
        <Grid item xs={xs} sm={sm} md={md} lg={lg}>
          <TextBox
            {...buildBasePropsForField(FormFieldsMap.Source.key, errors)}
            inputRef={register(URL_VALIDATION)}
            minWidth={minWidthTextBox}
            label="Job Board Source (URL)"
          />
        </Grid>
      </Grid>
      <Box>
        <hr className={classes.divider} />
      </Box>
      <div className={classes.locationCheckBox}>
        <Grid container>
          <Grid item xs={12}>
            <StyledForm
              control={
                <Checkbox
                  classes={{ root: classes.checkbox, checked: classes.checked }}
                  checked={differentLocation}
                  onChange={handleCheckChange}
                  name={FormFieldsMap.Location.key}
                />
              }
              label={strings.inventory.jobOrders.create.differentLocation}
            />
          </Grid>
        </Grid>
      </div>
      {formValues[FormFieldsMap.Location.key] === LOCATION_STATUS.different && (
        <Grid container spacing={FORM_SPACING}>
          <LocationForm
            state={formValues[FormFieldsMap.State.key]}
            city={formValues[FormFieldsMap.City.key]}
            zip={formValues[FormFieldsMap.Zip.key]}
            handleComboChange={handleComboChange}
            isEditing={isEditing}
          />
        </Grid>
      )}

      <When condition={shouldShowKeywordTags}>
        <Grid container spacing={2} className={classes.keywordsContainer}>
          <Grid item xs={12}>
            <hr className={classes.divider} />
            <Typography variant="subtitle1">
              {strings.inventoryProfiles.common.keywords.title}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <KeywordPicker
              allowNewItems
              displayTextKey={DEFAULT_DISPLAY_TEXT_KEY}
              endpoint={Endpoints.KeywordTags}
              name={FormFieldsMap.KeywordTags.key}
              onSelect={handleChangeKeywords}
              placeholder={strings.inventoryProfiles.common.keywords.placeholder}
              selectedValue={formValues?.[FormFieldsMap.KeywordTags.key] ?? []}
              typeaheadLimit={25}
            />
          </Grid>
        </Grid>
      </When>
    </Box>
  );
};

JobOrderForm.defaultProps = {
  companyId: null,
  isEditing: false
};

export default JobOrderForm;
