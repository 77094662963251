import React, { useState } from 'react';
import { LineSteppedAreaChart, Widget } from 'features/command-center/components/shared';
import { getActivityHistory } from 'features/command-center/services';
import moment from 'moment';
import strings from 'strings';
import { additionalPeriodRanges } from 'UI/components/templates/SideFiltersLayout/filters';
import { DateFormats } from 'UI/constants/defaults';
import { createCustomStaticRanges, getCurrentWeekRange } from 'UI/utils';

import { DateRange } from '../../shared/InlineFilters/components/DateRange';
import { formatTrendsResponse } from '../activity.utils';

const STRINGS = strings.commandCenter.activity.peopleReachedTrends;

export const PeopleReachedTrends = ({ requestParams = {} }) => {
  const [dateRange, setDateRange] = useState(() => getCurrentWeekRange());
  const queryDateRange = {
    startDate: moment(dateRange.startDate).format(DateFormats.QueryShortFormat),
    endDate: moment(dateRange.endDate).format(DateFormats.QueryShortFormat)
  };

  const handleDateRangeChange = selectedDateRange => setDateRange(selectedDateRange);

  return (
    <Widget
      header={STRINGS.title}
      promises={[
        () =>
          getActivityHistory({
            ...requestParams,
            metrics: 'peopleReached',
            limit: 7,
            orderBy: 'date',
            direction: 'asc',
            ...queryDateRange
          })
      ]}
    >
      {([response]) => {
        const data = formatTrendsResponse({
          response,
          key: 'peopleReached',
          goalKey: 'peopleReachedGoal',
          label: 'People Reached'
        });

        return (
          <>
            <DateRange
              DateRangerPickerProps={{
                staticRanges: createCustomStaticRanges(additionalPeriodRanges)
              }}
              defaultRange={dateRange}
              disableTimeRange
              onPeriodChange={handleDateRangeChange}
            />

            <LineSteppedAreaChart data={data} />
          </>
        );
      }}
    </Widget>
  );
};
