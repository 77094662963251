// @flow
import strings from 'strings';
import type { OperationResult } from 'types/app';
import { ARCHIVE_AVAILABILITY_DAYS } from 'UI/constants/defaults';
import { Endpoints } from 'UI/constants/endpoints';

import { makeRequest } from '../utils';

import { getFoldersGeneralAlert, getFoldersGeneralSuccessTitle } from './utils';

const { alerts: alertsCopies } = strings.searchProjects;

const basePayloadMapper = ({ name, parentFolderId }) => ({
  name,
  parent_id: parentFolderId
});

/**
 * @typedef {Object} GetAllFoldersQueryParams
 * @property {string} includes
 * @property {number} parentId
 * @property {Array<Number>} excludeIds
 */

/**
 * @param {GetAllFoldersQueryParams} [queryParams = {}]
 * @returns {Promise<OperationResult>}
 */
export const getAllSearchProjectsFolders = (queryParams = {}): Promise<OperationResult> =>
  makeRequest({
    method: 'get',
    endpointOptions: {
      path: Endpoints.SearchProjectFolders,
      queryParams
    }
  });

/**
 * @param {Number} id
 * @returns {Promise<OperationResult>}
 */
export const getSearchProjectFolderById = (id): Promise<OperationResult> =>
  makeRequest({
    method: 'get',
    endpointOptions: {
      path: Endpoints.SearchProjectFolderById,
      replaceId: id,
      queryParams: {
        includes: 'parent'
      }
    }
  });

export const createSearchProjectFolder = payload =>
  makeRequest({
    method: 'post',
    url: Endpoints.SearchProjectFolders,
    data: basePayloadMapper(payload),
    alertConfig: {
      success: {
        title: getFoldersGeneralSuccessTitle(alertsCopies.success.actions.create),
        body: payload.name
      },
      error: getFoldersGeneralAlert(alertsCopies.error.actions.create)
    }
  });

export const updateSearchProjectFolder = (folderId, payload) =>
  makeRequest({
    method: 'put',
    endpointOptions: {
      path: Endpoints.SearchProjectFolderById,
      replaceId: folderId
    },
    data: basePayloadMapper(payload),
    alertConfig: {
      success: {
        title: getFoldersGeneralSuccessTitle(alertsCopies.success.actions.edit),
        body: payload.name
      },
      error: getFoldersGeneralAlert(alertsCopies.error.actions.edit)
    }
  });

export const archiveSearchProjectFolder = folderId =>
  makeRequest({
    method: 'delete',
    endpointOptions: {
      path: Endpoints.SearchProjectFolderById,
      replaceId: folderId
    },
    alertConfig: {
      success: {
        title: getFoldersGeneralSuccessTitle(alertsCopies.success.actions.archive),
        body: strings.formatString(alertsCopies.success.archiveGeneralBody, {
          days: ARCHIVE_AVAILABILITY_DAYS.searchProjectFolder
        })
      },
      error: getFoldersGeneralAlert(alertsCopies.error.actions.archive)
    }
  });

export const restoreSearchProjectFolder = ({ folderId, folderName, parentId }) =>
  makeRequest({
    method: 'post',
    endpointOptions: {
      path: Endpoints.SearchProjectRestoreFolder,
      replaceId: folderId
    },
    data: { parentId },
    alertConfig: {
      success: {
        title: getFoldersGeneralSuccessTitle(alertsCopies.success.actions.restore),
        body: folderName
      },
      error: getFoldersGeneralAlert(alertsCopies.error.actions.restore)
    }
  });

const moveSearchProjectsToExistingFolder = payload =>
  makeRequest({
    url: Endpoints.SearchProjectMoveToFolder,
    method: 'post',
    data: {
      folderId: payload.parentFolderId,
      searchProjectIds: payload.searchProjectsIds
    },
    alertConfig: {
      success: {
        title: strings.formatString(alertsCopies.success.generalTitle, {
          action: alertsCopies.success.actions.move,
          entity:
            payload.searchProjectsIds.length > 1
              ? alertsCopies.entities.searchProjects
              : alertsCopies.entities.searchProject
        })
      },
      error: getFoldersGeneralAlert(alertsCopies.error.actions.move)
    }
  });

const moveSearchProjectsToNewFolder = payload =>
  makeRequest({
    url: Endpoints.SearchProjectFolders,
    method: 'post',
    data: {
      ...basePayloadMapper(payload),
      searchProjectIds: payload.searchProjectsIds
    },
    alertConfig: {
      success: {
        title: strings.formatString(alertsCopies.success.generalTitle, {
          action: alertsCopies.success.actions.move,
          entity:
            payload.searchProjectsIds.length > 1
              ? alertsCopies.entities.searchProjects
              : alertsCopies.entities.searchProject
        }),
        body: strings.formatString(alertsCopies.success.actionToSearchProjectName, {
          action: alertsCopies.success.actions.move,
          searchProjectName: payload.name
        })
      },
      error: getFoldersGeneralAlert(alertsCopies.error.actions.move)
    }
  });

export const executeFolderFormDrawerService = ({
  currentParentFolderId,
  folderName,
  isFolderBeingEdited,
  parentFolderId,
  searchProjectsToMove,
  shouldMoveSearchProjects
}) => {
  const searchProjectsIds = shouldMoveSearchProjects
    ? searchProjectsToMove.data.map(({ id }) => id)
    : null;

  if (shouldMoveSearchProjects?.toExistingFolder)
    return moveSearchProjectsToExistingFolder({
      parentFolderId,
      searchProjectsIds
    });

  const basePayload = { parentFolderId, name: folderName };

  if (shouldMoveSearchProjects?.toNewFolder)
    return moveSearchProjectsToNewFolder({ ...basePayload, searchProjectsIds });

  return isFolderBeingEdited
    ? updateSearchProjectFolder(currentParentFolderId, basePayload)
    : createSearchProjectFolder(basePayload);
};
