import React from 'react';
import { createQueryParams } from 'features/command-center/services/utils';
import useFetchWithStatus from 'hooks/fetchWithStatus';
import strings from 'strings';

import { EmptyRender, LoadingRender } from '../utils';

import DataTable from './DataTable';

const Loader = ({ endpoint, week, month, extraColumns, status }) => {
  const queryParams = createQueryParams({
    week: week?.date,
    extraColumns: extraColumns?.extraColumnsNames,
    status: status?.title
  });

  const finalEndpoint = endpoint ? `${endpoint}?${queryParams}` : null;
  const { Status } = useFetchWithStatus(finalEndpoint);

  return (
    <Status
      loading={<LoadingRender />}
      success={data => (
        <DataTable
          items={data?.report}
          month={month}
          week={week}
          lastUpdates={data?.last_updates}
          extraColumns={extraColumns}
          status={status}
        />
      )}
      empty={
        <EmptyRender
          title={strings.feeAgreements.dashboard.title}
          subtitle={strings.feeAgreements.dashboard.subtitle}
        />
      }
    />
  );
};

export default Loader;
