export const CALL_TYPES = {
  closing: 'closing',
  debrief: 'debrief',
  preparation: 'preparation'
};

export const TASK_STATUS = {
  completed: 1,
  pending: 2,
  overdue: 3
};
