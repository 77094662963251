import React from 'react';
import { SimplifiedTable } from 'features/command-center/components/shared';
import { TABLES_KEYS } from 'features/command-center/constants';
import { EntityRoutes } from 'routes/constants';
import { SECONDARY_API_CLIENT } from 'services/API';
import {
  ScrollableDataTable,
  ScrollableDataTableLayout
} from 'UI/components/templates/ScrollableDataTableLayout';
import { RowActions } from 'UI/constants/defaults';
import { ContactRole } from 'UI/constants/entityTypes';
import { DataTableLink } from 'UI/utils/renderers';

import {
  COLUMNS,
  ORDER_BY_OPTIONS,
  VIRTUAL_PROPS
} from './CallsRelatedContactsDrawerContent.constants';

const ContactRoutes = {
  [ContactRole.HiringAuthority]: EntityRoutes.HiringAuthorityProfile,
  [ContactRole.Name]: EntityRoutes.ContactProfile,
  [ContactRole.Candidate]: EntityRoutes.CandidateProfile
};

const extraRenderers = {
  contactLink: value => {
    if (!value) return null;

    const { id, roleId, fullName } = value;
    const route = ContactRoutes[roleId];

    return id && route ? (
      <DataTableLink id={id} text={fullName} route={route} enableInTabRedirect />
    ) : null;
  }
};

const formatResponse = response => {
  const formattedData = response.data.data.map(item => ({
    ...item,
    id: item?.originTableId ?? item?.id
  }));

  return { ...response, data: { ...response.data, data: formattedData } };
};

export const CallsRelatedContactsDrawerContent = ({ endpoint = '' }) => {
  return (
    <ScrollableDataTableLayout
      content={
        <SimplifiedTable
          apiInstance={SECONDARY_API_CLIENT}
          columns={COLUMNS}
          endpoint={endpoint}
          extraRenderers={extraRenderers}
          formatResponse={formatResponse}
          orderByOptions={ORDER_BY_OPTIONS}
          pageKey={TABLES_KEYS.drawerCallsRelatedContacts}
          quickViewEntityRoleProps={{ roleKey: 'roleId' }}
          rowActions={[RowActions.View]}
          shouldUseSavedPagination={false}
          showWrapper={false}
          TableComponent={ScrollableDataTable}
          virtualProps={VIRTUAL_PROPS}
        />
      }
    />
  );
};
