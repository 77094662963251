// @flow
import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { Link as LocalLink } from 'react-router-dom';
import Divider from '@material-ui/core/Divider';
import Link from '@material-ui/core/Link';
import Popover from '@material-ui/core/Popover';
import { showAlert } from 'actions/app';
import { addContact } from 'actions/ringCentral';
import clsx from 'clsx';
import copy from 'copy-to-clipboard';
import { useChatContext } from 'features/message-center/components/messageChat/ChatDrawerProvider';
import { Permissions, userHasPermission } from 'services/Authorization';
import type { LinkConfigProps } from 'types/app';
import FPIcon from 'UI/components/atoms/FPIcon';
import { CopyFilledCustomIcon, SvgExternalLink1 } from 'UI/res';
import SvgMessageFilled from 'UI/res/icons/filled/MessageFilled';
import SvgPhoneFilled from 'UI/res/icons/filled/PhoneFilled';
import { formatPhoneNumber, nestTernary, normalizeUrl } from 'UI/utils';

import FPIconButton from '../FPIconButton';

import {
  linkStyles,
  useDividerClasses,
  useFPLinkStyles,
  useRingCentralLinkStyles,
  useStyles
} from './styles';

type FPLinkProps = {
  linkProps: LinkConfigProps,
  itemId: string
};

const ICON_SIZE = 18;

const FPLink = ({
  linkProps: {
    url,
    target,
    type,
    role: entityType,
    isRoute = true,
    customLinkProps = {},
    ...restLinkProps
  },
  itemId,
  children,
  ...rest
}: FPLinkProps) => {
  const classes = useStyles();
  const classesLink = linkStyles();
  const dispatch = useDispatch();
  const dividerStyles = useDividerClasses();
  const fplinkClasses = useFPLinkStyles();
  const isEmail = rest.href?.includes('mailto:');
  const link = isEmail ? url : normalizeUrl(url);
  const ringCentralClasses = useRingCentralLinkStyles();
  const [anchorEl, setAnchorEl] = useState(null);
  const canUseTextel = userHasPermission(Permissions.Textel.CanUseModule);
  const { openDrawer } = useChatContext();

  const localLink = url;

  const handleClick = event => {
    event.preventDefault();
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => setAnchorEl(null);

  const addRingCentralContact = ({ id, phone, role }) => {
    handleClose();
    if (!id || !phone || !role) return;
    dispatch(
      addContact({
        id,
        role,
        phone,
        createdAt: new Date()
      })
    );
  };

  const handleCopyClick = value => {
    copy(value || '');
    dispatch(
      showAlert({
        severity: 'success',
        title: 'Awesome',
        body: 'Copied to Clipboard'
      })
    );
    handleClose();
  };

  const handleTextelMessageClick = () => {
    openChatDrawer();
    handleClose();
  };

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;
  const sharedProps = { family: 'mini', size: ICON_SIZE };

  const localLinkProps = isRoute
    ? {
        to: nestTernary(!itemId, url, localLink?.replace(':id', itemId)),
        component: LocalLink
      }
    : {
        href: url
      };

  const handleRingCentralAction = () => addRingCentralContact({ phone: url, ...restLinkProps });

  const openChatDrawer = () => {
    const phone = customLinkProps?.phoneNumber || (url?.replace(/\D/g, '') ?? '');

    if (!phone) return;

    openDrawer({
      recipient: {
        phone,
        countryCode: customLinkProps?.countryCode,
        name: customLinkProps?.profileName,
        entityId: restLinkProps?.id ?? itemId,
        entityType
      }
    });
  };

  const linkTypes = {
    local: (
      <Link
        {...localLinkProps}
        color="primary"
        target={target}
        TypographyClasses={{ root: fplinkClasses.local }}
        {...rest}
        {...restLinkProps}
      >
        {children}
      </Link>
    ),
    external: url && (
      <Link
        rel="noopener noreferrer"
        href={link}
        TypographyClasses={{ root: fplinkClasses.external }}
        target={target}
        {...rest}
      >
        {!isEmail && (
          <FPIcon
            className={ringCentralClasses.externalIcon}
            component={SvgExternalLink1}
            color="primary"
            size="sm"
          />
        )}
        {url}
      </Link>
    ),
    ringCentral: (
      <>
        <Link
          rel="noopener noreferrer"
          component="button"
          onClick={handleClick}
          variant="body1"
          classes={{ button: ringCentralClasses.ringCentralButton }}
          {...rest}
        >
          {customLinkProps?.formatPhone ? formatPhoneNumber(url) : url}
        </Link>
        <Popover
          id={id}
          open={open}
          anchorEl={anchorEl}
          onClose={handleClose}
          PaperProps={{ elevation: 0, classes }}
          anchorOrigin={{
            vertical: 'center',
            horizontal: 'right'
          }}
          transformOrigin={{
            vertical: 'center',
            horizontal: -11
          }}
        >
          <div
            className={clsx(
              ringCentralClasses.phoneActionContainer,
              ringCentralClasses.pointingArrow
            )}
          >
            <Link
              rel="noopener noreferrer"
              onClick={handleRingCentralAction}
              href={`tel:${url}`}
              classes={classesLink}
              {...rest}
            >
              <FPIcon component={SvgPhoneFilled} {...sharedProps} />
            </Link>
          </div>
          <div className={ringCentralClasses.phoneActionContainer}>
            <Divider classes={dividerStyles} absolute orientation="vertical" />
            {canUseTextel ? (
              <FPIconButton onClick={handleTextelMessageClick} classes={classesLink} {...rest}>
                <FPIcon component={SvgMessageFilled} {...sharedProps} />
              </FPIconButton>
            ) : (
              <Link
                rel="noopener noreferrer"
                onClick={() => handleRingCentralAction(url)}
                href={`sms:${url}`}
                classes={classesLink}
                {...rest}
              >
                <FPIcon component={SvgMessageFilled} {...sharedProps} />
              </Link>
            )}
          </div>
          <div className={ringCentralClasses.phoneActionContainer}>
            <Divider classes={dividerStyles} absolute orientation="vertical" />
            <Link
              rel="noopener noreferrer"
              onClick={() => handleCopyClick(url)}
              classes={classesLink}
              {...rest}
            >
              <CopyFilledCustomIcon size={22} />
            </Link>
          </div>
        </Popover>
      </>
    )
  };

  return linkTypes[type];
};

FPLink.defaultProps = {
  itemId: '',
  linkProps: undefined
};

export default FPLink;
