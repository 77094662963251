// @flow
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import IconButton from '@material-ui/core/IconButton';
import LinearProgress from '@material-ui/core/LinearProgress';
import Tooltip from '@material-ui/core/Tooltip';
import { toggleExpandTableAction } from 'actions/app';
import MUIDataTable, { debounceSearchRender, TableBody, TableToolbar } from 'mui-datatables';
import strings from 'strings';
import type { DataTableProps } from 'types/app';
import FPIcon from 'UI/components/atoms/FPIcon';
import FPIconButton from 'UI/components/atoms/FPIconButton';
import SkeletonList from 'UI/components/molecules/SkeletonList';
import EmptyPlaceholder from 'UI/components/templates/EmptyPlaceholder';
import { FeatureFlags } from 'UI/constants/featureFlags';
import { useTableCard } from 'UI/globalStyles/DataTableStyles';
import { SvgCollapse, SvgColumns, SvgExpand, SvgFilters, SvgTableSearch } from 'UI/res';
import { getId, hasFeatureFlag } from 'UI/utils';

import CustomFooter from './Footer';
import { useStyles } from './styles';

const DataTableEmptyState = ({ title, subtitle, ...props }) => {
  const classes = useStyles();
  return (
    <div className={classes.emptyStateContainer}>
      <EmptyPlaceholder
        title={title ?? strings.shared.emptyState.title}
        subtitle={subtitle ?? strings.shared.emptyState.subtitle}
        {...props}
      />
    </div>
  );
};

const TableBodyWithSpinner = props => {
  const { refreshing, columns } = props;

  const classes = useStyles();

  return refreshing ? (
    <tbody>
      <tr>
        <td colSpan={columns.length} height={600} align="center" className={classes.linearProgress}>
          <LinearProgress />
        </td>
      </tr>
    </tbody>
  ) : (
    <TableBody columns={columns} {...props} />
  );
};

const DataTable = ({
  children,
  classes = undefined,
  className = undefined,
  columns = [],
  components = undefined,
  count = 0,
  customEmptyState = undefined,
  CustomToolbar = undefined,
  data = [],
  enableCustomFilters = false,
  fixedSelectColumn = false,
  isExpandable = false,
  isServerSide = true,
  loading = false,
  onCellClick = undefined,
  onColumnDisplayClick = undefined,
  onColumnSortChange = undefined,
  onPageClick = undefined,
  onPerPageClick = undefined,
  onResetfiltersClick = undefined,
  onRowClick = undefined,
  onRowSelectionChange = undefined,
  onSearchTextChange = undefined,
  onToggleFilters = undefined,
  page = 0,
  refreshing = false,
  rowsPerPage = 0,
  searchText = '',
  selectableRows = 'multiple',
  selectableRowsHideCheckboxes = false,
  selectableRowsOnClick = false,
  skeletonRows,
  sortOrder = {},
  subtitle = undefined,
  title = undefined,
  ...rest
}: DataTableProps) => {
  const FiltersButton = () => (
    <Tooltip title="Filters">
      <IconButton onClick={onToggleFilters}>
        <FPIcon component={SvgFilters} />
      </IconButton>
    </Tooltip>
  );

  const tableClasses = useStyles();
  const dispatch = useDispatch();

  const { isTableExpanded } = useSelector(({ app }) => app.ui);

  const isExpandedTablesEnabled = hasFeatureFlag(FeatureFlags.ExpandableTables);

  const stylingProps = {
    className,
    classes: isExpandable
      ? {
          paper: isTableExpanded && tableClasses.expandedTable
        }
      : classes
  };

  const ExpandTableButton = () => (
    <FPIconButton
      onClick={handleExpandClick}
      tooltipProps={{
        title: isTableExpanded ? 'Collapse' : 'Expand',
        placement: 'bottom'
      }}
      icon={isTableExpanded ? SvgCollapse : SvgExpand}
    />
  );

  const renderCustomToolbar = () => {
    const toolbarItems = [];

    if (enableCustomFilters) toolbarItems.push(FiltersButton);
    if (CustomToolbar) {
      const CustomToolbarWrapper = () => CustomToolbar;
      toolbarItems.push(CustomToolbarWrapper);
    }
    if (isExpandedTablesEnabled && isExpandable) toolbarItems.push(ExpandTableButton);

    return toolbarItems.length > 0
      ? toolbarItems.map(Component => Component && <Component key={getId()} />)
      : undefined;
  };

  const CustomTableBody = bodyProps => (
    <TableBodyWithSpinner {...bodyProps} refreshing={refreshing} />
  );

  const SearchIcon = () => <FPIcon component={SvgTableSearch} />;
  const ViewColumnIcon = () => <FPIcon component={SvgColumns} />;

  const customComponents = components
    ? {
        ...components,
        TableBody: CustomTableBody,
        icons: { SearchIcon, ViewColumnIcon }
      }
    : { TableBody: CustomTableBody, icons: { SearchIcon, ViewColumnIcon } };

  const emptyTable = data.length === 0;

  const handleExpandClick = () =>
    !isTableExpanded
      ? dispatch(toggleExpandTableAction(true))
      : dispatch(toggleExpandTableAction(false));

  const options = isServerSide
    ? {
        sortOrder,
        filter: true,
        search: true,
        print: false,
        download: false,
        filterType: 'dropdown',
        fixedSelectColumn,
        responsive: 'standard',
        selectableRows,
        selectableRowsOnClick,
        selectableRowsHideCheckboxes,
        customToolbar: renderCustomToolbar,
        enableNestedDataAccess: '.',
        serverSide: true,
        count,
        page,
        rowsPerPage,
        searchText,
        searchPlaceholder: 'Search for...',
        customSearchRender: debounceSearchRender(400),
        onRowClick: (_, rowMeta) =>
          rowMeta.dataIndex !== undefined && onRowClick && onRowClick(rowMeta),
        onCellClick: (colData, cellMeta) =>
          cellMeta.dataIndex !== undefined && onCellClick && onCellClick(colData, cellMeta),
        onRowSelectionChange: (current, all, selected) => {
          onRowSelectionChange && onRowSelectionChange(current, all, selected);
        },
        onSearchChange: newSearchText => {
          onSearchTextChange && onSearchTextChange(newSearchText);
        },
        onColumnSortChange: (changedColumn, direction) => {
          onColumnSortChange && onColumnSortChange({ orderBy: changedColumn, direction });
        },
        onChangeRowsPerPage: newPerPage => {
          onPerPageClick && onPerPageClick(newPerPage);
        },
        onChangePage: newPage => {
          onPageClick && onPageClick(newPage);
        },
        onFilterChange: () => {
          onResetfiltersClick && onResetfiltersClick();
        },
        onViewColumnsChange: (changedColumn, action) => {
          onColumnDisplayClick &&
            onColumnDisplayClick({ column: changedColumn, display: action === 'add' });
        },
        customFooter: (
          newCount,
          newPage,
          newRowsPerPage,
          changeRowsPerPage,
          changePage,
          textLabels
        ) => {
          return (
            !Number.isNaN(count) &&
            count !== 0 && (
              <CustomFooter
                count={count}
                page={page}
                rowsPerPage={rowsPerPage}
                changeRowsPerPage={changeRowsPerPage}
                changePage={changePage}
                textLabels={textLabels}
              />
            )
          );
        },
        textLabels: {
          body: {
            toolbar: {
              search: 'search'
            },
            noMatch: (
              <DataTableEmptyState
                title={title}
                subtitle={subtitle}
                customEmptyState={customEmptyState}
              >
                {children}
              </DataTableEmptyState>
            ),
            toolTip: 'Sort',
            columnHeaderTooltip: column => `Sort by ${column.label}`
          }
        },
        rowHover: !emptyTable,
        ...rest
      }
    : {
        filter: false,
        search: false,
        print: false,
        download: false,
        viewColumns: true,
        serverSide: false,
        responsive: 'standard',
        selectableRows: 'none',
        onRowClick: (rowData, rowMeta) => {
          onRowClick && onRowClick(rowMeta);
        },
        customToolbar: renderCustomToolbar,
        textLabels: {
          body: {
            toolbar: {
              search: 'search'
            },
            toolTip: 'Sort',
            columnHeaderTooltip: column => `Sort by ${column.label}`
          }
        },
        ...rest
      };

  return loading ? (
    <SkeletonList rows={skeletonRows} />
  ) : (
    <MUIDataTable
      {...stylingProps}
      columns={columns}
      components={customComponents}
      data={data}
      options={options}
    />
  );
};

export const CustomToolbar = props => {
  const tableClasses = useTableCard();

  return (
    <TableToolbar
      classes={{ root: tableClasses.toolbar, actions: tableClasses.actions }}
      {...props}
    />
  );
};

export default DataTable;
