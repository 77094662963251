// @flow
import { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { HTTPStatusCodes } from 'constants/httpStatusCodes';
import { useFetchWithStatusV2 } from 'hooks/fetchWithStatus';
import { useFeatureFlags as useRootFeatureFlags } from 'providers/FeatureFlagsProvider';
import { EntityRoutes } from 'routes/constants';
import { SECONDARY_API_CLIENT } from 'services/API';
import {
  formatPhoneNumbers,
  PHONE_FORMAT_MODES,
  PHONE_ROW_PREFIX
} from 'UI/components/organisms/PhoneNumbersForm/utils';
import { Endpoints } from 'UI/constants/endpoints';
import { FeatureFlags } from 'UI/constants/featureFlags';
import { UIStatus } from 'UI/constants/status';
import { getId } from 'UI/utils';

type UseFeatureFlagsReturnType = {
  canSaveAsDraft: boolean,
  canExecuteCandidateAssignmentSheet: boolean
};
export const useFeatureFlags = (): UseFeatureFlagsReturnType => {
  const { checkIfFeatureFlagEnabled } = useRootFeatureFlags();

  return {
    canSaveAsDraft: checkIfFeatureFlagEnabled(FeatureFlags.Drafts),
    canExecuteCandidateAssignmentSheet: checkIfFeatureFlagEnabled(
      FeatureFlags.CandidateDataSheetFlow
    )
  };
};

const formatAttachments = attachments => {
  if (!attachments) return [];
  return attachments.map(item => ({ ...item, fileType: { id: item?.file_type_id } }));
};

const transformPhoneData = phonesArray => {
  const result = {
    phones: [],
    phoneDefaultId: null
  };

  if (!phonesArray) return result;

  phonesArray.forEach((phoneData, index) => {
    const phoneId = getId();

    result.phones.push({
      id: phoneId,
      countryCode: {
        id: phoneData?.countryCode?.id,
        country_iso_2: phoneData?.countryCode?.country_iso_2,
        country_iso_3: phoneData?.countryCode?.country_iso_3,
        country: phoneData?.countryCode?.country,
        code: phoneData?.countryCode?.code
      },
      phone: '',
      ext: '',
      use: '',
      type: ''
    });

    if (index === 0) {
      result.phoneDefaultId = phoneId;
    }

    result[`${PHONE_ROW_PREFIX.CountryCode}${phoneId}`] = {
      id: phoneData?.countryCode?.id,
      country_iso_2: phoneData?.countryCode?.country_iso_2,
      country_iso_3: phoneData?.countryCode?.country_iso_3,
      country: phoneData?.countryCode?.country,
      code: phoneData?.countryCode?.code
    };

    result[`${PHONE_ROW_PREFIX.Phone}${phoneId}`] = phoneData?.phone?.toString() || '';
    result[`${PHONE_ROW_PREFIX.Ext}${phoneId}`] = '';

    result[`${PHONE_ROW_PREFIX.Use}${phoneId}`] = {
      id: phoneData?.use?.id,
      title: phoneData?.use?.title
    };

    result[`${PHONE_ROW_PREFIX.Type}${phoneId}`] = {
      id: phoneData?.type?.id,
      title: phoneData?.type?.title
    };
  });

  return result;
};

const formatApplicantData = (data, checkIfFeatureFlagEnabled) => {
  const {
    firstName,
    lastName,
    email,
    otherEmail,
    title,
    company,
    source,
    sourceUrl,
    city,
    state,
    zipCode,
    phones,
    keywords
  } = data || {};
  return {
    firstName,
    lastName,
    email,
    personalEmail: otherEmail,
    title,
    company: { name: company },
    source,
    linkedinUrl: sourceUrl,
    city_id: city,
    state_id: state,
    zip: zipCode,
    keywordTags: checkIfFeatureFlagEnabled(FeatureFlags.CandidatesKeywordsFromApplicantUrl)
      ? formatKeywords(keywords)
      : [],
    ...transformPhoneData(phones)
  };
};

const formatKeywords = keywords => {
  if (!keywords || keywords?.length === 0) {
    return [];
  }
  return keywords.map(keyword => {
    return {
      keyword,
      display_text: keyword
    };
  });
};

export const useNewCandidateFromUrl = ({ applicantId }) => {
  const history = useHistory();
  const endpoint = applicantId
    ? Endpoints.CandidateCreateFromApplicant.replace(':applicantId', applicantId)
    : null;
  const { state } = useFetchWithStatusV2({
    endpoint,
    apiInstance: SECONDARY_API_CLIENT,
    initialStatus: UIStatus.Default
  });

  const { checkIfFeatureFlagEnabled } = useRootFeatureFlags();

  const formData = formatApplicantData(state.results, checkIfFeatureFlagEnabled);

  useEffect(() => {
    if (!applicantId) return;

    if (
      (applicantId && state.status === UIStatus.Success) ||
      (state.status === UIStatus.Error &&
        state.responseStatusCode !== HTTPStatusCodes.InternalServerError)
    ) {
      history.replace(EntityRoutes.CandidateCreate);
    }
  }, [applicantId, history, state.status, state.responseStatusCode]);

  const applicantFormData =
    state.status === UIStatus.Success
      ? {
          ...formData,
          phones: formatPhoneNumbers(formData, PHONE_FORMAT_MODES.preload),
          dataSheetId: state.results?.dataSheetId,
          attachments: formatAttachments(state.results?.attachments),
          applicantId
        }
      : null;

  return { state, applicantFormData };
};
