import React from 'react';
import {
  OVERALL_PERFORMANCE_COLUMNS,
  OVERALL_PERFORMANCE_ORDER_BY_OPTIONS,
  SimplifiedTable
} from 'features/command-center/components/shared';
import { TABLES_KEYS } from 'features/command-center/constants';
import { SECONDARY_API_CLIENT } from 'services/API';
import {
  ScrollableDataTable,
  ScrollableDataTableLayout
} from 'UI/components/templates/ScrollableDataTableLayout';

import { generateFinalEndpoint } from './HealthDistributionDrawerContent.utils';
import { formatResponse } from './utils';

export const HealthDistributionDrawerContent = ({ endpoint = '' }) => {
  const finalEndpoint = generateFinalEndpoint(endpoint);

  return (
    <ScrollableDataTableLayout
      content={
        <SimplifiedTable
          apiInstance={SECONDARY_API_CLIENT}
          columns={OVERALL_PERFORMANCE_COLUMNS}
          endpoint={finalEndpoint}
          formatResponse={formatResponse}
          orderByOptions={OVERALL_PERFORMANCE_ORDER_BY_OPTIONS}
          pageKey={TABLES_KEYS.healthDistributionStats}
          shouldUseSavedPagination={false}
          TableComponent={ScrollableDataTable}
          showWrapper={false}
        />
      }
    />
  );
};
