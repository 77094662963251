import { makeStyles } from '@material-ui/core/styles';
import { container } from 'UI/constants/dimensions';

export const useStyles = makeStyles({
  chatContainer: {
    flexGrow: 1,
    overflowY: 'auto',
    position: 'relative'
  },
  messageComposerContainer: {
    boxShadow: container.boxShadow,
    position: 'sticky',
    bottom: 0
  }
});
