// @flow
import React, { useEffect, useState } from 'react';
import { FormContext, useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { showAlert } from 'actions/app';
import {
  executeFolderFormDrawerService,
  getAllSearchProjectsFolders,
  getSearchProjectFolderById
} from 'services/searchProjects/folders';
import strings from 'strings';
import type { SearchProjectsFolderFormDrawerPropTypes } from 'types/app';
import TextBox from 'UI/components/atoms/TextBox';
import { When } from 'UI/components/atoms/When';
import NumberedForm from 'UI/components/molecules/NumberedForm';
import TreeViewSelector from 'UI/components/molecules/TreeViewSelector/TreeViewSelector';
import DrawerContentLayout from 'UI/components/templates/DrawerContentLayout';
import { DEFAULT_SEARCH_PROJECT_FOLDERS_EXCLUDE_IDS } from 'UI/constants/defaults';

const { title, sections, validations } = strings.searchProjects.folderForm;

const FORM_FIELDS_MAP = {
  folderName: {
    key: 'folderName'
  },
  parentFolder: {
    key: 'parentFolder'
  }
};

const getTitlePrefix = ({ isFolderBeingEdited, shouldMoveSearchProjects }) => {
  if (isFolderBeingEdited) return title.actions.edit;

  if (shouldMoveSearchProjects?.toExistingFolder) return title.actions.moveToExisting;
  if (shouldMoveSearchProjects?.toNewFolder) return title.actions.moveToNew;

  return title.actions.create;
};

const SearchProjectsFolderFormDrawer = ({
  initialFolder = null,
  isFolderBeingEdited = false,
  onCloseDrawer,
  onSubmitFolder = null,
  searchProjectsToMove = null,
  shouldMoveSearchProjects = null
}: SearchProjectsFolderFormDrawerPropTypes) => {
  const dispatch = useDispatch();
  const drawerTitle = strings.formatString(title.generalTitle, {
    action: getTitlePrefix({ isFolderBeingEdited, shouldMoveSearchProjects })
  });
  const excludedIds = {
    root: isFolderBeingEdited
      ? [DEFAULT_SEARCH_PROJECT_FOLDERS_EXCLUDE_IDS, initialFolder.id]
      : DEFAULT_SEARCH_PROJECT_FOLDERS_EXCLUDE_IDS,
    children: isFolderBeingEdited ? initialFolder?.id : null
  };
  const [previousParentId, setPreviousParentId] = useState(null);
  const [uiState, setUiState] = useState({
    isFormLoading: false,
    isSaving: false,
    isSuccess: false
  });

  const form = useForm({
    defaultValues: {
      [FORM_FIELDS_MAP.folderName.key]: isFolderBeingEdited ? initialFolder.name : '',
      [FORM_FIELDS_MAP.parentFolder.key]: initialFolder
    }
  });
  const { errors, handleSubmit, register, setValue, watch } = form;
  const watchers = watch();

  useEffect(() => {
    if (shouldMoveSearchProjects?.toExistingFolder) return;
    register(
      { name: FORM_FIELDS_MAP.folderName.key },
      { required: validations.required.folderName }
    );
  }, [register, shouldMoveSearchProjects]);

  useEffect(() => {
    register({ name: FORM_FIELDS_MAP.parentFolder.key });
  }, [register]);

  useEffect(() => {
    if (!initialFolder || !isFolderBeingEdited) return;

    setUiState(prev => ({ ...prev, isFormLoading: true }));
    (async () => {
      const { success, alert, data } = await getSearchProjectFolderById(initialFolder.id);
      if (success) {
        setPreviousParentId(data.parent.id);
        setValue(FORM_FIELDS_MAP.parentFolder.key, {
          id: data.parent.id,
          name: data.parent.name
        });
      } else dispatch(showAlert(alert));
      setUiState(prev => ({ ...prev, isFormLoading: false }));
    })();
  }, [dispatch, initialFolder, isFolderBeingEdited, setValue]);

  const onSubmit = handleSubmit(async formData => {
    setUiState({ isSaving: true });
    const parentFolderId = formData[FORM_FIELDS_MAP.parentFolder.key].id;
    const folderName = formData[FORM_FIELDS_MAP.folderName.key];

    const { success, data, alert } = await executeFolderFormDrawerService({
      currentParentFolderId: initialFolder.id,
      folderName,
      isFolderBeingEdited,
      parentFolderId,
      searchProjectsToMove,
      shouldMoveSearchProjects
    });
    dispatch(showAlert(alert));

    if (success) {
      const newData = {
        parentFoldersId: {
          current: parentFolderId
        }
      };

      if (isFolderBeingEdited) {
        if (parentFolderId !== previousParentId)
          newData.parentFoldersId.previous = previousParentId;
        newData.shouldRefreshSelectedFolder = true;
        newData.folderName = folderName;
      }

      if (shouldMoveSearchProjects) {
        newData.shouldRefreshDataTable = true;
      }

      onSubmitFolder && onSubmitFolder({ ...data, ...newData });
      setUiState({ isSaving: false, isSuccess: true });
      onCloseDrawer();
    } else setUiState({ isSaving: false });
  });

  return (
    <DrawerContentLayout
      drawerProps={{ open: true }}
      title={drawerTitle}
      onClose={onCloseDrawer}
      onSubmit={onSubmit}
      uiState={uiState}
    >
      <FormContext {...form}>
        <NumberedForm>
          <When condition={!shouldMoveSearchProjects?.toExistingFolder}>
            <NumberedForm.Item title={sections.folderName.title}>
              <TextBox
                name={FORM_FIELDS_MAP.folderName.key}
                placeholder={sections.folderName.placeholder}
                onChange={setValue}
                error={!!errors.folderName}
                errorText={errors.folderName?.message}
                value={watchers.folderName}
              />
            </NumberedForm.Item>
          </When>
          <NumberedForm.Item title={sections.parentFolder.title}>
            <TreeViewSelector
              formFieldName={FORM_FIELDS_MAP.parentFolder.key}
              placeholder={sections.parentFolder.placeholder}
              TreeViewLoaderProps={{
                enableIconClick: true,
                onlyShowFolders: true,
                service: {
                  method: getAllSearchProjectsFolders,
                  queryParams: { excludeIds: excludedIds.root },
                  childrenQueryParams: { excludedIds: excludedIds.children }
                }
              }}
            />
          </NumberedForm.Item>
        </NumberedForm>
      </FormContext>
    </DrawerContentLayout>
  );
};

export default SearchProjectsFolderFormDrawer;
