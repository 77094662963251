import omit from 'lodash/omit';
import uniqBy from 'lodash/uniqBy';
import moment from 'moment';
import queryString from 'query-string';
import { Permissions, userHasPermission, userHasRoles } from 'services/Authorization';
import strings from 'strings';
import { entityTypes } from 'UI/constants/entityTypes';
import { Roles } from 'UI/constants/roles';
import { SvgArrowLeft, SvgArrowRight } from 'UI/res';

import { getTimezoneByName } from '.';

const NAVIGATION_CONTROLS_ICON_SIZE = 14;

export const buildPilLabels = ({
  specialty,
  additionalSpecialties = [],
  subspecialty,
  additionalSubspecialties = []
}) => {
  if (!specialty) return null;

  const allSpecialties = [specialty, ...(additionalSpecialties || [])];
  const allSubspecialties = subspecialty ? [subspecialty, ...additionalSubspecialties] : [];
  const industriesTitles = uniqBy(allSpecialties.map(({ industry }) => industry.title)).sort();

  const specialtiesTitles = allSpecialties.map(({ title }) => title).sort();
  const subspecialtiesTitles = allSubspecialties.map(({ title }) => title).sort();

  return {
    industries: industriesTitles.join(', '),
    specialties: specialtiesTitles.join(', '),
    subspecialties: subspecialtiesTitles.join(', ')
  };
};

export const assignContactDataFromHiringAuthorityProfile = data => {
  const {
    company_id,
    created_at,
    ext,
    first_name,
    full_name,
    hiring_authority_status_id,
    id,
    last_name,
    other_ext,
    personal_email,
    personal_phone,
    position_id,
    specialty_id,
    specialty,
    position,
    subspecialty,
    title,
    updated_at,
    work_email,
    work_phone
  } = data;

  const initialValues = {
    specialty_id: specialty,
    subspecialty_id: subspecialty,
    position_id: position
  };

  const defaultValues = {
    ...data,
    company_id,
    created_at,
    ext,
    first_name,
    full_name,
    hiring_authority_status_id,
    id,
    last_name,
    other_ext,
    personal_email,
    personal_phone,
    position_id,
    specialty_id,
    subspecialty_id: subspecialty?.id,
    title,
    updated_at,
    work_email,
    work_phone
  };

  return { initialValues, defaultValues };
};

export const assignContactDataFromContactProfile = data => {
  const {
    id,
    personalInformation: {
      first_name,
      last_name,
      contact: { phone, mobile, ext, personal_email } = {},
      address: { zip = {}, city = {} } = {}
    } = {},
    specialty,
    specialty: { id: specialty_id } = {},
    subspecialty,
    position,
    title,
    status,
    company_id,
    current_company,
    email,
    sourceType = {},
    link_profile
  } = data;

  const name_status_id = status?.id;

  const initialValues = {
    company_id: { id: company_id, name: current_company },
    specialty_id: specialty,
    subspecialty_id: subspecialty,
    position_id: position,
    source_type_id: sourceType,
    name_status_id: status,
    state_id: city?.state,
    city_id: city,
    zip: { id: zip, title: zip }
  };

  const defaultValues = {
    id,
    company_id: initialValues.company_id,
    first_name,
    last_name,
    specialty_id,
    subspecialty_id: subspecialty?.id,
    position_id: position?.id,
    title,
    name_status_id,
    email,
    phone,
    ext,
    state_id: city?.state?.id,
    city_id: city?.id,
    zip,
    personal_email,
    mobile,
    source_type_id: sourceType?.id,
    link_profile
  };

  return { initialValues, defaultValues };
};

export const sendoutDataToForm = (data, joborder) => {
  const formatedSendout = {
    ...data,
    type_id: data.type_id || data.type,
    status_id: data.status,
    cc_emails: data.emailDetails.cc_emails.map(item => ({ work_email: item })),
    bcc_emails: data.emailDetails.bcc_emails.map(item => ({ work_email: item })),
    subject: data.emailDetails.subject,
    template: data.emailDetails.template,
    hiring_authority: data.hiringAuthorithies[0]?.hiringAuthority,
    candidate_id: {
      ...data.candidate.personalInformation,
      ...data.candidate,
      title: data.candidate.personalInformation.full_name
    },
    job_order_id: {
      id: joborder.id,
      title: joborder.title
    },
    interviews: data.interviews.map(item => ({
      ...item,
      interview_date: moment(item.interview_date).utc(false), // removing timezone format
      interview_type_id: item.interviewType.id,
      interview_time_zone: getTimezoneByName(item.interview_time_zone)
    }))
  };
  return formatedSendout;
};

export const buildLocationInfoLabel = ({ city, state, country }) => {
  const portionsWithValue = [city, state, country].filter(value => value);
  const formattedLocation = portionsWithValue.join(', ');

  return {
    title: 'Location',
    description: formattedLocation
  };
};

export const getEntityType = key => {
  const matchedEntity = entityTypes.find(item => item.id === key);
  return matchedEntity;
};

export const completeReferenceRelease = ({ referenceRelease, referenceReleaseHandler }) => {
  const handleReferenceReleaseComplete = releaseEmail => {
    referenceRelease?.onReferenceReleaseComplete &&
      referenceRelease.onReferenceReleaseComplete(releaseEmail);
    referenceReleaseHandler();
  };

  return {
    handleReferenceReleaseComplete
  };
};

export const removeQueryParams = (queryParams, paramsToRemove) => {
  if (!paramsToRemove || !paramsToRemove.length) return queryParams;

  const params = queryString.parse(queryParams);
  return queryString.stringify(omit(params, paramsToRemove));
};

export const getPropertyByPath = (obj, path, defaultValue = '') => {
  const keys = path.split('.');
  return keys.reduce((result, key) => {
    if (result && typeof result === 'object' && key in result) {
      return result[key];
    }
    return defaultValue;
  }, obj);
};

const reduceValuesToAttach = (valuesToAttach, data) => {
  return valuesToAttach.reduce((acc, { name, value, path }) => {
    const extractedValueFromPath = path ? getPropertyByPath(data, path) : undefined;

    if (!name && !value) {
      return extractedValueFromPath;
    }

    if (!name && value) {
      acc[value] = value;
    } else if (extractedValueFromPath !== undefined) {
      acc[name] = extractedValueFromPath;
    } else if (name && data[value] !== undefined) {
      acc[name] = data[value];
    } else if (typeof data[value] === 'object') {
      Object.assign(acc, data[value]);
    }

    return acc;
  }, {});
};

/**
 * Creates an new object by extracting properties from a source .
 * @param {Array} arrayDefinition - The array containing the 'entry' (name of new generated property ) and 'valuesToAttach' (array definition to find and extract properties from  a given source composed by 'name' and his 'value')
 * @param {string} data - The source to extract the properties (keys) to compose a new object.
 * @returns {Object} - An object with keys extracted from the source and corresponding values from the array.
 */

export const generateObjectFromSource = (arrayDefinition, data) => {
  const keyWordList = Object.fromEntries(
    arrayDefinition.map(item => {
      const { entry, valuesToAttach } = item;

      const attributes = reduceValuesToAttach(valuesToAttach, data);

      return typeof attributes === 'object' ? [[entry], { ...attributes }] : [[entry], attributes];
    })
  );

  return { keyWordList };
};

/**
 * This function formats a response by extracting data according to the provided 'keywordsDefinition'.
 * @param {Object} response - The response to format.
 * @param {Array} keywordsDefinition - The array of definitions for extracting properties.
 * @returns {Object} - The formatted response with extracted properties.
 */

export const formatResponseBuilder =
  response =>
  (keywordsDefinition = []) => {
    const formattedData = response?.data?.data.map(item => {
      if (!keywordsDefinition) return item;

      const { keyWordList } = generateObjectFromSource(keywordsDefinition, item);

      return {
        ...item,
        ...keyWordList
      };
    });

    return response
      ? {
          data: {
            ...response.data,
            data: formattedData
          }
        }
      : null;
  };

export const getTabPropsFromDefinition = ({ tabDefinition, tabKeyId }) => {
  const tab = tabDefinition.find(item => item.id === tabKeyId);
  const { extraRenderers, DataTableProps, initialColumns } = tab.props;

  return { extraRenderers, DataTableProps, initialColumns };
};

export const navigationPressetBuilder = ({
  onGoBack,
  onGoNext,
  isGoBackEnabled,
  isGoNextEnabled,
  GridItemProps
}) => [
  {
    props: {
      disabled: isGoBackEnabled,
      icon: SvgArrowLeft,
      onClick: onGoBack,
      iconProps: { size: NAVIGATION_CONTROLS_ICON_SIZE },
      text: strings.shared.buttons.previous,
      variant: 'outlined'
    },
    GridItemProps
  },
  {
    props: {
      disabled: isGoNextEnabled,
      icon: SvgArrowRight,
      iconPosition: 'right',
      onClick: onGoNext,
      iconProps: { size: NAVIGATION_CONTROLS_ICON_SIZE },
      text: strings.shared.buttons.next,
      variant: 'outlined'
    },
    GridItemProps
  }
];

export const accountablePermissionsManagement = ({
  assistantForAccountableRecruiter,
  assistantRecruiter,
  isAccountableCoach,
  isAccountableRecruiter,
  isMainCoach,
  isMainRecruiter,
  isTeamworkEntity
}) => {
  /** ::::::::::::::::::::::::::::::::::::::::::::::::::::
   *
   *   S H A R E D   S E T T I N G S
   *
   * :::::::::::::::::::::::::::::::::::::::::::::::::::::
   */

  const isHighLevelUser = userHasRoles([Roles.Operations, Roles.DataCoordinator]);

  // AccountableAssistant

  const isAuthorizedUser = isMainCoach || isHighLevelUser || isMainRecruiter;
  const isUserAllowedToAddAccountableAssistant =
    isAuthorizedUser || userHasPermission(Permissions.Inventory.OverrideAssignment);

  const isAssistantButtonEnabled =
    isTeamworkEntity && !assistantRecruiter?.recruiter && isUserAllowedToAddAccountableAssistant;

  // AccountableAssistant

  const hasAssistantManagementPermission =
    isAccountableRecruiter || isHighLevelUser || isAccountableCoach;

  const isAcountableUserAuthorized =
    hasAssistantManagementPermission || userHasPermission(Permissions.Inventory.OverrideAssignment);

  const isAssistantForAccountableButtonEnabled =
    isTeamworkEntity && !assistantForAccountableRecruiter?.recruiter && isAcountableUserAuthorized;

  return { isAssistantButtonEnabled, isAssistantForAccountableButtonEnabled };
};

export const getKeywordTags = (value, displayTextKey) => {
  if (!Array.isArray(value) || value.length === 0) {
    return { existentKeywordTagsIds: [], newKeywordTags: [] };
  }

  return value.reduce(
    (acc, curr) => {
      if (!curr || typeof curr !== 'object') return acc;

      if (curr.id) {
        acc.existentKeywordTagsIds.push(curr.id);
      } else if (typeof curr[displayTextKey] === 'string' && curr[displayTextKey].trim()) {
        acc.newKeywordTags.push(curr[displayTextKey]);
      }

      return acc;
    },
    { existentKeywordTagsIds: [], newKeywordTags: [] }
  );
};

export const DEFAULT_DISPLAY_TEXT_KEY = 'display_text';
export const formatKeywordTags = (formKeywords, displayTextKey = DEFAULT_DISPLAY_TEXT_KEY) => {
  if (formKeywords?.existentKeywordTagsIds && formKeywords?.newKeywordTags) {
    return formKeywords;
  }

  return getKeywordTags(formKeywords, displayTextKey);
};
