import blueGrey from '@material-ui/core/colors/blueGrey';
import cyan from '@material-ui/core/colors/cyan';
import green from '@material-ui/core/colors/green';
import orange from '@material-ui/core/colors/orange';
import red from '@material-ui/core/colors/red';
import teal from '@material-ui/core/colors/teal';
import { createMuiTheme, makeStyles } from '@material-ui/core/styles';
import {
  avatarMeasures,
  componentDimensions,
  container,
  gridDimensions,
  input,
  inputContainerSize,
  layout,
  mapAutocompletePadding,
  middlePadding,
  navBarHeight,
  sidebarItem,
  skeletonRadius
} from 'UI/constants/dimensions';
import {
  flexAlignCenter,
  flexAlignCenterFlexEnd,
  flexAlignCenterJustifyCenter,
  flexAlignCenterSpaceAround,
  flexAlignCenterSpaceBetween,
  flexJustifyEnd
} from 'UI/utils/styles';

import { colors } from './UI/res/colors';

export const globalStyles = {
  contentLayout: {
    maxWidth: layout.mainPageContentWidth.maxWidth,
    width: '100%',
    margin: '40px 36px'
  },
  floatingButtonContainer: {
    position: 'absolute',
    right: 20,
    bottom: 20,
    zIndex: 10
  },
  resetButton: {
    margin: 0,
    padding: 0,
    color: colors.middlegrey,
    textTransform: 'capitalize',
    textDecoration: 'underline'
  },
  inputSpacing: {
    padding: middlePadding
  },
  inputMinSpacing: {
    padding: mapAutocompletePadding
  },
  containerBoxShadow: {
    boxShadow: container.boxShadow
  },
  mapActionButton: {
    letterSpacing: '1.43px',
    width: '100%'
  },
  profileSkeletonItem: {
    width: '70%',
    height: 8,
    borderRadius: skeletonRadius,
    display: 'flex',
    flex: 1,
    margin: '0px 39px'
  },
  skeletonSeparator: {
    borderRadius: skeletonRadius,
    width: '100%',
    height: 3,
    margin: '24px 0px'
  },
  skeletonCardItem: {
    height: 16,
    width: '100%',
    margin: '8px 0',
    borderRadius: skeletonRadius
  },
  skeletonRow: {
    height: 20,
    margin: '8px 0px',
    width: '100%',
    borderRadius: skeletonRadius
  },
  skeletonItem: {
    borderRadius: skeletonRadius,
    width: '100%',
    height: 48
  },
  skeletonCol: {
    width: '45%',
    height: 18,
    borderRadius: skeletonRadius
  },
  skeletonContainer: {
    margin: '24px 0',
    width: '100%',
    ...flexAlignCenterSpaceAround
  },
  skeletonTitleBar: {
    height: 72,
    width: '100%',
    backgroundColor: colors.inactiveSideBarTab,
    ...flexAlignCenterSpaceAround
  },
  skeletonCardContainer: {
    width: '100%',
    flexDirection: 'column',
    borderRadius: 4,
    backgroundColor: colors.inactiveSideBarTab,
    ...flexAlignCenterSpaceAround
  },
  skeletonItemOnTitleBar: {
    display: 'flex',
    flex: '1 1 auto',
    height: 18,
    borderRadius: skeletonRadius
  },
  feeDrawerslabel: {
    marginBottom: 8
  },
  cardContainer: {
    width: '100%',
    boxShadow: container.boxShadow,
    borderRadius: 4
  },
  modalHeader: {
    ...flexAlignCenterSpaceBetween,
    padding: '0 20px',
    height: 72,
    backgroundColor: colors.sideBar
  },
  mediumDivider: {
    height: 1,
    margin: '16px 0'
  },
  divider: {
    height: 1
  },
  newItemSubHeader: {
    fontSize: 16,
    marginLeft: 5
  },
  transition: '0.5s cubic-bezier(.79,.14,.15,.86) 0s'
};

export const notifierStyles = {
  snackContainer: {
    top: navBarHeight + 16
  }
};

const theme = createMuiTheme({
  props: {
    MuiDrawer: {
      anchor: 'right',
      ModalProps: {
        disableEnforceFocus: true,
        disableBackdropClick: true
      }
    },
    MuiSkeleton: {
      variant: 'rect'
    },
    MuiToggleButtonGroup: {
      size: 'small',
      color: 'primary'
    }
  },
  sidebarMenu: {
    backgroundColor: '#FFFFFF'
  },
  sidebarItem: {
    width: '100%',
    height: sidebarItem.height,
    padding: sidebarItem.padding
  },
  breakpoints: {
    values: {
      xs: 820,
      sm: 1023,
      md: 1366,
      lg: 1600
    }
  },
  selectBox: {
    select: {
      height: input.height,
      padding: 0,
      justifyContent: 'flex-start',
      alignItems: 'center',
      flex: 1,
      display: 'flex',
      paddingLeft: 15
    }
  },
  button: {
    borderRadius: input.borderRadius,
    minWidth: 120,
    maxWidth: 400,
    alignItems: 'center',
    textTransform: 'capitalize',
    fontSize: 16,
    fontWeight: 500
  },
  input: {
    minHeight: input.height,
    borderRadius: input.borderRadius,
    margin: '10px 0',
    width: '100%',
    '& fieldset': {
      borderRadius: input.borderRadius
    },
    '& input': {
      padding: '0px 8px !important'
    },
    '& label': {
      transform: 'translate(14px, 12px) scale(1)'
    }
  },
  mainLayout: {
    width: '100%',
    margin: '40px 0',
    padding: '0 70px'
  },
  typography: {
    fontSize: 14,
    fontFamilyHeader: '"Kanit", sans-serif !important',
    fontFamilyContent: '"Roboto", sans-serif !important',
    fontSizeLarge: 16,
    fontSizeSmall: 11,
    headerWeights: {
      light: '200',
      regular: '400',
      bold: '600',
      black: '800'
    },
    fontWeightLight: '300 !important',
    fontWeightRegular: '400 !important',
    fontWeightMedium: '500 !important',
    fontWeightBold: '700 !important',
    fontWeightBlack: '900 !important',
    overline: {
      color: '#1F2329',
      display: 'block',
      opacity: 0.7
    }
  },
  textShadow: {
    textShadow: '0px 3px 6px #7c7a7ac7',
    opacity: 1
  },
  palette: {
    brandColors: {
      FPRed: '#D52028',
      FPWhite: '#ffffff'
    },
    primary: {
      FP50: '#E7E9FA',
      FP100: '#D9E0FC',
      FP200: '#B3C0F8',
      FP300: '#8C9FF4',
      light: '#6F85EF',
      main: '#4056F4',
      dark: '#3343C8',
      FP700: '#2331A7',
      contrastText: '#E1E4EB'
    },
    secondary: {
      FP100: '#FAFBFF',
      FP200: '#F4F7FF',
      FP300: '#EFF3FF',
      light: colors.lightPurple,
      main: '#E5EAFE',
      dark: '#A7B0DB',
      FP700: '#737DB8',
      contrastText: '#030D28'
    },
    success: {
      FP100: '#D2FAD8',
      FP200: '#A5F6BA',
      FP300: '#72E69C',
      light: '#49CE86',
      main: '#27AE60',
      dark: '#009562',
      FP700: '#137D55',
      contrastText: '#E1E4EB'
    },
    info: {
      FP100: '#CBEFFD',
      FP200: '#99DBFB',
      FP300: '#63BEF5',
      light: '#3CA0EC',
      main: '#0275E0',
      dark: '#015AC0',
      FP700: '#0143A1',
      contrastText: '#FAFBFD'
    },
    warning: {
      FP100: '#FFF3D3',
      FP200: '#FEE3A8',
      FP300: '#FDCF7D',
      light: '#FABB5F',
      main: '#F39C12',
      dark: '#D37C2D',
      FP700: '#B16022',
      contrastText: '#121417'
    },
    error: {
      FP100: '#FEE4D7',
      FP200: '#FCC3AF',
      FP300: '#F79B84',
      light: '#EF7465',
      main: '#E53935',
      dark: '#C3262F',
      FP700: '#A41B2F',
      contrastText: '#FAFBFD'
    },
    grey: {
      50: '#FAFBFD',
      100: '#F8F9FB',
      200: '#F0F3F7',
      300: '#E4E8EE',
      400: '#CDD3DD',
      500: '#A8B1BD',
      600: '#6A7381',
      700: '#49505A',
      800: '#1F2329',
      900: '#030D28',
      A100: '#E6E9EE',
      A200: '#D3D7DD',
      A400: '#BAC0C9',
      A700: '#49505A',
      black: '#000'
    },
    status: {
      active: '#27AE60',
      sendout: '#4056F4',
      sendover: '#4735C9',
      placed: '#66CBCF',
      inactive: '#9B9EA7',
      deceased: '#525764'
    },
    red,
    green,
    teal,
    cyan,
    orange,
    blueGrey,
    customColors: colors
  },
  shape: {
    borderRadius: 8
  },
  zIndex: {
    bulkStatusCard: 1551,
    bulkTemplatesAction: 2,
    dataTable: 3,
    dataTableHeader: '0 !important',
    dialog: 1400,
    mapMarkerActive: 2,
    mapPopup: 3,
    profileRecruiterView: 5,
    ringCentralWidget: 1350,
    searchOnThisAreaBtn: 3,
    snackbar: 1500,
    tableDateRangeFilter: 110,
    stickyHeader: 4,
    stickyColumn: 3,
    expandedTable: 1002,
    expandedTableFilters: 1004,
    collapsedTableFilters: 4
  },
  overrides: {
    MuiAlert: {
      message: {
        fontWeight: 300
      }
    },
    MuiLink: {
      button: {
        fontSize: 16
      }
    },
    MuiAvatarGroup: {
      avatar: {
        border: 'unset'
      }
    },
    MuiDrawer: {
      paper: {
        width: componentDimensions.drawer.width,
        overflowX: 'hidden'
      }
    },
    MuiCheckbox: {
      colorPrimary: {
        '&$checked': {
          color: colors.success
        }
      }
    },
    MuiSvgIcon: {
      root: {
        width: 24,
        height: 24
      }
    },
    MuiFormHelperText: {
      root: {
        '&$error': {
          fontSize: 12
        }
      }
    },
    MuiPickersToolbar: {
      toolbar: {
        overflow: 'hidden',
        height: 'auto',
        margin: '-1px',
        paddingTop: 16,
        paddingBottom: 16
      }
    },
    MuiDialog: {
      root: {
        zIndex: `${1400} !important`
      },
      paper: {
        position: 'absolute',
        backgroundColor: colors.sideBar,
        top: 111,
        padding: 24,
        maxWidth: '650px !important'
      }
    },
    MuiTableRow: {
      root: {
        height: 56,
        '&:hover[id]': {
          backgroundColor: `${colors.lightPurple} !important`
        },
        '&:hover .dataTableStickyLeftColumn': {
          backgroundColor: `${colors.lightPurple} !important`
        },
        '&$selected:hover[id]': {
          backgroundColor: colors.lightPurple
        }
      },
      footer: {
        cursor: 'default',
        '&:hover': {
          backgroundColor: `${colors.white} !important`
        },
        '&$selected:hover': {
          backgroundColor: colors.white
        }
      }
    },
    MUIDataTableFilter: {
      root: {
        maxWidth: 600,
        minWidth: 450
      },
      reset: {
        width: '100%',
        display: 'flex',
        justifyContent: 'space-between'
      },
      gridListTile: {
        marginTop: 0
      }
    },
    MuiTableCell: {
      root: {
        padding: 12,
        borderBottom: '1px solid #e0e0e0'
      }
    },
    MuiToolbar: {
      root: {
        backgroundColor: colors.white
      }
    },
    MUIDataTableHeadRow: {
      root: {
        whiteSpace: 'nowrap'
      }
    },
    MUIDataTableToolbar: {
      root: {
        backgroundColor: colors.inactiveSideBarTab,
        marginBottom: -1
      },
      actions: {
        ...flexAlignCenter,
        backgroundColor: colors.inactiveSideBarTab,
        flex: 'initial',
        // move all icons to the right
        '& > span, & > button': {
          order: 99
        },
        // target the custom toolbar icon
        '& > div': {
          order: 1
        }
      }
    },
    MUIDataTableToolbarSelect: {
      root: {
        backgroundColor: colors.linkWater,
        paddingRight: 24
      }
    },
    MUIDataTableHeadCell: {
      sortAction: {
        alignItems: 'center'
      },
      fixedHeader: {
        zIndex: 3
      },
      root: {
        fontSize: '14px !important'
      }
    },
    MUIDataTable: {
      paper: {
        height: '100%'
      },
      responsiveBase: {
        flex: 1
      }
    },
    MUIDataTableSelectCell: {
      headerCell: {
        backgroundColor: colors.white,
        zIndex: 3
      },
      fixedLeft: {
        zIndex: 3
      }
    },
    MuiGridList: {
      root: {
        margin: '-4px !important'
      }
    },
    MuiGridListTile: {
      root: {
        padding: '4px 12px !important'
      }
    },
    MuiToggleButton: {
      root: {
        color: colors.success,
        border: `1px solid ${colors.success}`,
        '&.Mui-disabled': {
          color: colors.white,
          backgroundColor: colors.success
        }
      }
    }
  }
});

theme.overrides.MuiAutocomplete = {
  endAdornment: {
    top: 'unset'
  },
  popper: {
    zIndex: theme.zIndex.dialog
  }
};

theme.overrides.MuiDivider = {
  root: {
    height: 1,
    borderRadius: skeletonRadius,
    width: '100%',
    margin: theme.spacing(3, 0),
    backgroundColor: `${theme.palette.grey[300]} !important`
  }
};

theme.overrides.MuiTooltip = {
  tooltip: {
    fontSize: '12px !important',
    lineHeight: '14px',
    fontWeight: 400,
    padding: theme.spacing(1),
    backgroundColor: `${theme.palette.grey[800]} !important`,
    boxShadow: theme.shadows[1]
  },
  arrow: {
    color: `${theme.palette.grey[800]} !important`
  }
};

theme.overrides.MuiSkeleton = {
  root: {
    borderRadius: theme.spacing(3),
    color: theme.palette.grey.A200
  }
};

// Global Font Responsive Rules and formats

// To Do: override typography mess

theme.typography.h1 = {
  fontSize: 32,
  fontWeight: theme.typography.headerWeights.bold,
  fontFamily: theme.typography.fontFamilyHeader,
  letterSpacing: 1.2
};
theme.typography.h2 = {
  fontSize: 20,
  fontWeight: theme.typography.fontWeightMedium
};
theme.typography.h5 = {
  fontSize: 24,
  fontWeight: theme.typography.fontWeightMedium
};
theme.typography.h6 = {
  fontSize: 18,
  fontWeight: theme.typography.fontWeightMedium
};
theme.typography.subtitle1 = {
  fontSize: 16,
  fontWeight: theme.typography.fontWeightRegular,
  [theme.breakpoints.up('lg')]: {
    fontSize: theme.typography.fontSizeLarge
  }
};
theme.typography.body1 = {
  fontSize: theme.typography.fontSize,
  fontWeight: theme.typography.fontWeightRegular,
  [theme.breakpoints.up('lg')]: {
    fontSize: theme.typography.fontSizeLarge
  }
};
theme.typography.body2 = {
  fontSize: theme.typography.fontSize,
  fontWeight: theme.typography.fontWeightLight,
  [theme.breakpoints.up('lg')]: {
    fontSize: theme.typography.fontSizeLarge
  }
};
theme.typography.subtitle2 = {
  fontWeight: theme.typography.fontWeightLight,
  color: colors.secondaryText
};

export const THEME = theme;

/**
 * @param {Object} props
 * @param {boolean} props.fixedHeight
 * @returns {Object}
 */

/** ------------------------------------------------------------------------
 * Init to use material's styles for global styling instead of inline styles
 * --------------------------------------------------------------------------
 */

const heightToggle = 44;

export const useGlobalStyles = makeStyles(
  globalTheme => ({
    snackContainer: {
      top: navBarHeight + 16,
      zIndex: theme.zIndex.snackbar
    },
    maxContentLayout: {
      ...theme.mainLayout,
      height: 'max-content'
    },
    newItemsActions: {
      margin: theme.spacing(4, 0),
      ...flexAlignCenterFlexEnd
    },
    newItemsSection: {
      marginBottom: 32
    },
    customTableToolbarSelect: {
      backgroundColor: colors.linkWater,
      padding: globalTheme.spacing(1, 3),
      borderRadius: 5
    },
    hint: {
      height: 20,
      width: 20,
      backgroundColor: colors.success,
      borderRadius: 25,
      cursor: 'pointer',
      margin: theme.spacing(0, 1),
      ...flexAlignCenterJustifyCenter
    },
    navText: {
      fontSize: globalTheme.typography.h6.fontSize,
      color: globalTheme.palette.primary.main,
      border: 'none',
      backgroundColor: 'transparent',
      padding: 0,
      fontWeight: 'bold',
      '&:hover': {
        color: globalTheme.palette.primary.main,
        textDecoration: 'underline'
      }
    },
    primaryLink: {
      color: globalTheme.palette.primary.main,
      fontWeight: 'bold',
      '&:hover': {
        color: globalTheme.palette.primary.main
      }
    },
    tightDivider: {
      margin: '0 !important'
    },
    sheetQuestion: {
      margin: `${theme.spacing(0, 0, 3)} !important`
    },
    sheetInputContainer: {
      marginTop: theme.spacing(0.5)
    },
    tightGrid: {
      paddingBottom: '0 !important'
    },
    otherLabel: {
      marginTop: `${theme.spacing(1)}px !important`
    },
    datepickerContainer: {
      margin: theme.spacing(1, 0)
    },
    tableToolbarCard: {
      borderTop: `1px solid ${theme.palette.grey[400]}`
    },
    toggleButtonLayoutV1: {
      marginRight: theme.spacing(2),
      height: heightToggle
    },
    toggleButtonLayoutV2: {
      margin: theme.spacing(0, 2)
    },
    itemCreationDataWrapper: {
      paddingTop: theme.spacing(2)
    },
    alertActionButton: {
      minWidth: 'unset',
      whiteSpace: 'nowrap'
    },
    circularProgress: {
      marginTop: theme.spacing(5)
    }
  }),
  { classNamePrefix: 'globalClasses' }
);

const useGlobalStylesV2 = makeStyles(globalTheme => ({
  '@global': {
    '.align-items-center': {
      alignItems: 'center',
      display: 'flex'
    },
    '.margin-right-bottom-1': {
      margin: globalTheme.spacing(0, 1, 1, 0)
    },
    '.margin-x1': {
      margin: globalTheme.spacing(0, 1)
    },
    '.margin-y1': {
      margin: globalTheme.spacing(1, 0)
    },
    '.padding-x1': {
      padding: globalTheme.spacing(0, 1)
    },
    '.padding-y1': {
      padding: globalTheme.spacing(1, 0)
    },
    'w-100': {
      width: '100%'
    },
    '.preset-warning-chip-style': {
      borderColor: `${globalTheme.palette.warning.dark} !important`,
      '& > span': {
        color: globalTheme.palette.customColors.completeBlack
      },
      '& > svg': {
        color: `${globalTheme.palette.warning.dark} !important`
      }
    },
    '.preset-error-chip': {
      borderColor: `${globalTheme.palette.error.dark} !important`,
      '& > span': {
        color: globalTheme.palette.error.dark,
        fontWeight: '400 !important'
      },
      '& > svg': {
        fill: `${globalTheme.palette.error.dark} !important`
      }
    },
    '.chip-valid-email': {
      border: `1px solid ${globalTheme.palette.success.main} !important`,
      color: `${globalTheme.palette.success.main} !important`,
      textTransform: 'capitalize',
      fontWeight: '400',
      '& > svg': {
        fill: `${globalTheme.palette.success.main} !important`
      }
    },
    '.chip-invalid-email': {
      border: `1px solid ${globalTheme.palette.error.dark} !important`,
      color: `${globalTheme.palette.error.dark} !important`,
      textTransform: 'capitalize',
      fontWeight: '400',
      '& > svg': {
        fill: `${globalTheme.palette.error.main} !important`
      }
    },
    '.chip-risky-email': {
      padding: `${globalTheme.spacing(0.5)}px !important`,
      border: `1px solid ${globalTheme.palette.warning.main} !important`,
      color: `${globalTheme.palette.warning.main} !important`,
      textTransform: 'capitalize',
      fontWeight: '400',
      '& > svg': {
        fill: `${globalTheme.palette.warning.main} !important`
      }
    },
    '.chip-sendout-type, .chip-candidate-type, .chip-joborder-type, .chip-company-type, .chip-company-link-type':
      {
        color: 'white !important'
      },
    '.chip-candidate-status, .chip-joborder-status': {
      backgroundColor: 'transparent !important'
    },
    '.chip-sendout-type-1': {
      backgroundColor: `${globalTheme.palette.info.dark} !important`
    },
    '.chip-sendout-type-2, .chip-candidate-type-1, .chip-joborder-type-1, .chip-company-type-0': {
      backgroundColor: `${globalTheme.palette.warning.main} !important`
    },
    '.chip-candidate-type-2, .chip-joborder-type-2': {
      backgroundColor: `#E9250C !important`
    },
    '.chip-sendout-status-1, .chip-sendout-status-5': {
      color: `${globalTheme.palette.success.main} !important`,
      borderColor: `${globalTheme.palette.success.main} !important`
    },
    '.chip-sendout-status-2': {
      color: `${globalTheme.palette.info.light} !important`,
      borderColor: `${globalTheme.palette.info.light} !important`
    },
    '.chip-sendout-status-3, .chip-sendout-status-6': {
      color: `${theme.palette.grey[600]} !important`,
      borderColor: `${theme.palette.grey[600]} !important`
    },
    '.chip-sendout-status-4, .chip-sendout-status-7': {
      color: `#FA632E !important`,
      borderColor: `#FA632E !important`
    },
    '.chip-candidate-type-0, .chip-joborder-type-0, .chip-company-type-1, .placement-status-4, .fa-status-3':
      {
        backgroundColor: `${globalTheme.palette.success.main} !important`
      },
    '.chip-candidate-status-1, .chip-joborder-status-1': {
      color: `${globalTheme.palette.status.active} !important`,
      border: `1px solid ${globalTheme.palette.status.active} !important`
    },
    '.chip-candidate-status-2, .chip-joborder-status-2': {
      color: `${globalTheme.palette.status.sendout} !important`,
      border: `1px solid ${globalTheme.palette.status.sendout} !important`
    },
    '.chip-candidate-status-3, .chip-joborder-status-3': {
      color: `${globalTheme.palette.status.sendover} !important`,
      border: `1px solid ${globalTheme.palette.status.sendover} !important`
    },
    '.chip-candidate-status-4, .chip-joborder-status-4': {
      color: `${globalTheme.palette.status.placed} !important`,
      border: `1px solid ${globalTheme.palette.status.placed} !important`
    },
    '.chip-candidate-status-5, .chip-joborder-status-5': {
      color: `${globalTheme.palette.status.inactive} !important`,
      border: `1px solid ${globalTheme.palette.status.inactive} !important`
    },
    '.chip-company-type-2': {
      backgroundColor: `#9B9EA7 !important`
    },
    '.chip-company-type-4': {
      backgroundColor: `${theme.palette.error.main} !important`
    },
    '.chip-candidate-type-outlined-0': {
      color: `${globalTheme.palette.success.main} !important`,
      border: `1px solid ${globalTheme.palette.success.main} !important`
    },
    '.chip-candidate-type-outlined-1': {
      color: `${globalTheme.palette.warning.main} !important`,
      border: `1px solid ${globalTheme.palette.warning.main} !important`
    },
    '.chip-candidate-type-outlined-2': {
      color: `#E9250C !important`,
      border: `1px solid #E9250C !important`
    },
    '.chip-company-link-type-parent': {
      backgroundColor: '#2331A7 !important',
      textTransform: 'capitalize'
    },
    '.chip-company-link-type-sibling': {
      backgroundColor: `#8872F0 !important`,
      textTransform: 'capitalize'
    },
    '.chip-company-link-type-child': {
      backgroundColor: '#3CA0EC !important',
      textTransform: 'capitalize'
    },
    '.status-indicator': {
      display: 'inline-block',
      borderRadius: '50%',
      border: 'none',
      padding: 0,
      width: 12,
      height: 12
    },
    '.task-status-icon': {
      width: '18px !important'
    },
    '.task-status-1': {
      color: `${globalTheme.palette.success.main} !important`
    },
    '.task-status-3': {
      color: `${globalTheme.palette.warning.main} !important`
    },
    '.placement-status-1': {
      backgroundColor: `#B00020 !important`
    },
    '.placement-status-2': {
      backgroundColor: `#F39C12 !important`
    },
    '.placement-status-3': {
      backgroundColor: `#A9CE10 !important`
    },
    '.placement-status-5': {
      backgroundColor: `${globalTheme.palette.status.deceased} !important`
    },
    ' .chip-candidate-status-6, .chip-candidate-status-7, .chip-company-type-3': {
      border: `1px solid ${globalTheme.palette.status.deceased} !important`,
      color: `${globalTheme.palette.status.deceased} !important`
    },
    '.placement-status-6': {
      backgroundColor: `#EB0000AF !important`
    },
    '.placement-status-7': {
      backgroundColor: `#8FA9CB !important`
    },
    '.placement-status-8': {
      backgroundColor: `${globalTheme.palette.primary.main} !important`
    },
    '.placement-status-9': {
      backgroundColor: `#10C4CF !important`
    },
    '.placement-status-10 ': {
      backgroundColor: `#018786 !important`
    },
    '.placement-status-11 ': {
      backgroundColor: `#7284E8 !important`
    },
    '.placement-status-12 ': {
      backgroundColor: `#3CA0EC !important`
    },
    '.fa-status-1, .fa-status-2': {
      backgroundColor: `#F39C12 !important`
    },
    '.fa-status-4, .fa-status-5': {
      backgroundColor: `#E9250C !important`
    },
    '.fa-status-6, .fa-status-7': {
      backgroundColor: `#545764 !important`
    },
    '.fa-status-8': {
      backgroundColor: `#E1E4EB !important`
    },
    '.fa-status-9': {
      backgroundColor: `#1A1C21 !important`
    },
    '.fa-status-10': {
      backgroundColor: `#DDDDDD !important`
    },
    '.MuiAvatar-root.avatar-small': {
      display: 'inline-flex',
      width: avatarMeasures.small,
      height: avatarMeasures.small,
      fontSize: avatarMeasures.small / avatarMeasures.fontRatio,
      objectFit: 'contain'
    },
    '.MuiAvatar-root.avatar-medium': {
      display: 'inline-flex',
      width: avatarMeasures.medium,
      height: avatarMeasures.medium,
      fontSize: avatarMeasures.medium / avatarMeasures.fontRatio,
      objectFit: 'contain'
    },
    '.MuiAvatar-root.avatar-large': {
      display: 'inline-flex',
      width: avatarMeasures.large,
      height: avatarMeasures.large,
      fontSize: avatarMeasures.large / avatarMeasures.fontRatio,
      objectFit: 'contain'
    },
    '.MuiAvatar-root.avatar-primary': {
      backgroundColor: globalTheme.palette.primary.FP100,
      color: globalTheme.palette.primary.main
    },
    '.MuiAvatar-root.avatar-red': {
      backgroundColor: globalTheme.palette.red[100],
      color: globalTheme.palette.customColors.user.red.text
    },
    '.MuiAvatar-root.avatar-green': {
      backgroundColor: globalTheme.palette.green.light,
      color: globalTheme.palette.green.main
    },
    '.MuiAvatar-root.avatar-purple': {
      backgroundColor: globalTheme.palette.customColors.user.purple.background,
      color: globalTheme.palette.customColors.user.purple.text
    },
    '.MuiAvatar-root.avatar-cyan': {
      backgroundColor: globalTheme.palette.cyan[100],
      color: globalTheme.palette.cyan[500]
    },
    '.MuiAvatar-root.avatar-teal': {
      backgroundColor: globalTheme.palette.teal[100],
      color: globalTheme.palette.teal[500]
    },
    '.MuiAvatar-root.avatar-orange': {
      backgroundColor: globalTheme.palette.orange[100],
      color: globalTheme.palette.orange[500]
    },
    '.MuiAvatar-root.avatar-blueGrey': {
      backgroundColor: globalTheme.palette.blueGrey[100],
      color: globalTheme.palette.blueGrey[500]
    },
    '.map-marker': {
      borderRadius: '50%',
      border: 'none',
      padding: 0,
      width: '18px',
      height: '18px'
    },
    '.map-marker-sm': {
      borderRadius: '50%',
      border: 'none',
      padding: 0,
      width: '8px',
      height: '8px'
    },
    '.marker-type-alpha': {
      backgroundColor: globalTheme.palette.customColors.active
    },
    '.marker-type-poejo': {
      backgroundColor: globalTheme.palette.customColors.orange
    },
    '.marker-type-cant-help': {
      backgroundColor: globalTheme.palette.customColors.red
    },
    '.marker-company-client': {
      backgroundColor: globalTheme.palette.customColors.active
    },
    '.marker-company-not-signed': {
      backgroundColor: globalTheme.palette.customColors.inactive
    },
    '.marker-company-signed': {
      backgroundColor: globalTheme.palette.customColors.orange
    },
    '.inputContainer': {
      width: inputContainerSize
    },
    '.mapboxgl-marker-inventory': {
      width: '18px',
      height: '18px',
      '&:hover': {
        zIndex: theme.zIndex.mapMarkerActive
      }
    },
    '.mapboxgl-popup': {
      zIndex: theme.zIndex.mapPopup
    },
    '@keyframes hvr-pulse': {
      '0%': {
        transform: 'scale(0.95)',
        boxShadow: '0 0 0 0 rgba(145, 142, 142, 0.7)'
      },
      '70%': {
        transform: 'scale(1)',
        boxShadow: '0 0 0 10px rgba(0, 0, 0, 0)'
      },
      '100%': {
        transform: 'scale(0.95)',
        boxShadow: '0 0 0 0 rgba(0, 0, 0, 0)'
      }
    },
    '.employeesTitle': {
      margin: globalTheme.spacing(1, 0, 0, 1)
    },
    '.dataSheetButton': {
      margin: globalTheme.spacing(0, 4),
      ...flexJustifyEnd
    },
    '.tabModeOverview': {
      padding: theme.spacing(4)
    },
    '.dataTableStickyColumn': {
      position: 'sticky',
      right: 0,
      backgroundColor: 'white',
      textAlign: 'center',
      zIndex: theme.zIndex.stickyColumn
    },
    '.dataTableStickyColumnHeader': {
      position: 'sticky',
      right: 0,
      backgroundColor: 'white',
      textAlign: 'center !important',
      zIndex: `${theme.zIndex.stickyHeader} !important`
    },
    '.dataTableStickyLeftColumn': {
      backgroundColor: 'white',
      left: 0,
      position: 'sticky',
      width: 150,
      zIndex: theme.zIndex.stickyColumn
    },
    '.dataTableStickyLeftColumnHeader': {
      left: 0,
      position: 'sticky',
      width: 150,
      zIndex: `${theme.zIndex.stickyHeader} !important`
    },
    '.phoneNumbersColumn': {
      width: 'max-content'
    },
    '.under-goal': {
      backgroundColor: colors.linkWater,
      padding: theme.spacing(0.75, 1)
    },
    '.creation-form-layout': {
      maxWidth: gridDimensions.maxContainer
    }
  }
}));

export const GlobalStyles = () => {
  useGlobalStylesV2();

  return null;
};
