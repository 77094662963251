import React from 'react';
import Grid from '@material-ui/core/Grid';
import strings from 'strings';
import FPIconButton from 'UI/components/atoms/FPIconButton';
import TitleLabel from 'UI/components/atoms/TitleLabel';
import { DRAWER_CONTENT_TYPES } from 'UI/pages/CommandCenter/OverallPerformance/OverallPerformance.constants';
import { SvgArrowLeft } from 'UI/res';

import { FeedbackLog } from '../FeedbackLog';
import { FeedbackNoteForm } from '../FeedbackNoteForm';

import { useStyles } from './DrawerContent.styles';

const { form: STRINGS } = strings.commandCenter.feedbackNotes.drawer;

export const DrawerContent = ({
  drawerContent,
  employeeNumber,
  selectedNoteId = null,
  onNewItemClick = () => {},
  onEditComplete = () => {},
  onEditNoteClick = () => {},
  onBackClick = () => {},
  initialFeedbackNote
}) => {
  const classes = useStyles();
  return drawerContent === DRAWER_CONTENT_TYPES.NotesForm ? (
    <>
      <Grid container className={classes.backTitle}>
        <Grid item>
          <FPIconButton
            onClick={onBackClick}
            iconMode="custom"
            className={classes.iconButton}
            icon={SvgArrowLeft}
            family="mini"
            tooltipProps={{ title: 'Back' }}
          />
        </Grid>
        <Grid item>
          <TitleLabel
            fontSize={28}
            text={selectedNoteId ? STRINGS.titles.editNote : STRINGS.titles.addNewNote}
          />
        </Grid>
      </Grid>
      <FeedbackNoteForm
        employeeNumber={employeeNumber}
        onEditComplete={onEditComplete}
        onBackClick={onBackClick}
        initialFeedbackNote={initialFeedbackNote}
      />
    </>
  ) : (
    <FeedbackLog
      employeeNumber={employeeNumber}
      onNewItemClick={onNewItemClick}
      onEditNoteClick={onEditNoteClick}
    />
  );
};
