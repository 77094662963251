export const FORM_FIELDS_MAP = {
  parentFolder: {
    key: 'parentFolder'
  },
  searchProjectName: {
    key: 'searchProjectName'
  },
  splitOptions: {
    key: 'splitOptions'
  },
  splitIntoParts: {
    key: 'splitIntoParts'
  }
};
