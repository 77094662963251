// @flow
import { makeRequest } from 'services/utils';
import strings from 'strings';
import { Endpoints } from 'UI/constants/endpoints';
import { dateFormatter } from 'UI/utils';

const {
  BulkEmailDeschedule,
  BulkEmailScheduleById,
  BulkEmailSchedules,
  BulkEmailScheduleUpdateSendDate,
  BulkEmailSendScheduledNow
} = Endpoints;

const {
  success: {
    scheduleBulkEmail: scheduleBulkSuccess,
    sendScheduledBulkNow: sendScheduledBulkNowSuccess,
    updateScheduledBulkEmail: updateScheduledBulkEmailSuccess,
    updateScheduledBulkDateTime: updateScheduledBulkDateTimeSuccess,
    deleteScheduledBulkEmail: deleteScheduledBulkEmailSuccess
  },
  errors: {
    updateScheduleDate: updateScheduleDateCopy,
    deleteScheduledBulkEmail: deleteScheduledBulkEmailError
  }
} = strings.bulkEmails;

const getScheduleFinalSuccessCopies = (baseCopies, sendDate) => ({
  title: baseCopies.title,
  body: strings.formatString(baseCopies.body, {
    formattedDate: dateFormatter(sendDate, ({ MonthDayAtTime }) => MonthDayAtTime)
  })
});

const getScheduleReplacedUrlById = (id, endpoint) =>
  (endpoint || BulkEmailScheduleById).replace(/:id/, id);

export const scheduleBulkEmail = bulk =>
  makeRequest({
    url: BulkEmailSchedules,
    method: 'post',
    data: bulk,
    alertConfig: {
      success: getScheduleFinalSuccessCopies(scheduleBulkSuccess, bulk.send_date)
    }
  });

export const updateScheduledBulkEmail = (scheduleId: number, bulk) =>
  makeRequest({
    url: getScheduleReplacedUrlById(scheduleId),
    method: 'put',
    data: bulk,
    alertConfig: {
      success: getScheduleFinalSuccessCopies(updateScheduledBulkEmailSuccess, bulk.send_date)
    }
  });

export const updateScheduleDate = (scheduleId, sendDate) =>
  makeRequest({
    url: BulkEmailScheduleUpdateSendDate.replace(/:id/, scheduleId),
    method: 'put',
    data: { send_date: sendDate },
    alertConfig: {
      success: getScheduleFinalSuccessCopies(updateScheduledBulkDateTimeSuccess, sendDate),
      error: updateScheduleDateCopy
    }
  });

export const deleteScheduledBulkEmail = scheduleId =>
  makeRequest({
    url: getScheduleReplacedUrlById(scheduleId),
    method: 'delete',
    alertConfig: {
      success: deleteScheduledBulkEmailSuccess,
      error: deleteScheduledBulkEmailError
    }
  });

export const descheduleBulkEmail = scheduleId =>
  makeRequest({
    url: getScheduleReplacedUrlById(scheduleId, BulkEmailDeschedule),
    method: 'delete'
  });

export const sendScheduledBulkNow = (scheduleId, bulk) =>
  makeRequest({
    url: getScheduleReplacedUrlById(scheduleId, BulkEmailSendScheduledNow),
    method: 'post',
    data: bulk,
    alertConfig: {
      success: sendScheduledBulkNowSuccess
    }
  });
