import React from 'react';
import Grid from '@material-ui/core/Grid';
import isNil from 'lodash/isNil';
import { useFeatureFlags } from 'providers/FeatureFlagsProvider';
import strings from 'strings';
import FPHtmlViewer from 'UI/components/atoms/FPHtmlViewer';
import { When } from 'UI/components/atoms/When';
import InfoLabel from 'UI/components/molecules/InfoLabel';
import { DateFormats } from 'UI/constants/defaults';
import { FeatureFlags } from 'UI/constants/featureFlags';
import { capitalizeFirstLetter, formatDuration, toLocalTime } from 'UI/utils';

import { getEmployeeFileCopyForNote } from '../FeedbackNotesList/FeedbackNotesList.utils';
import { ACTIVITY_MODE } from '../SupportActivityList/SupportActivityList.constants';

import { Container, NotesLabel, useStyles } from './SupportActivityDetails.styled';
import { SupportActivityNotesField } from './SupportActivityNotesField';

const { fields: FIELDS_COPIES } = strings.commandCenter.feedbackNotes.activityDetailsDrawer;

export const SupportActivityDetails = ({ activity, mode }) => {
  const classes = useStyles();
  const { checkIfFeatureFlagEnabled } = useFeatureFlags();
  const canSaveInEmployeeFile = checkIfFeatureFlagEnabled(
    FeatureFlags.FeedbackNotesSaveInEmployeeFile
  );

  return (
    <Container>
      <Grid container spacing={4}>
        <Grid container item>
          <Grid item xs={6}>
            <InfoLabel
              cropped={false}
              title={FIELDS_COPIES.createdAt}
              description={
                activity?.createdAt
                  ? toLocalTime(activity?.createdAt).format(DateFormats.SimpleDateTime)
                  : ''
              }
            />
          </Grid>

          <Grid item xs={6}>
            <InfoLabel
              cropped={false}
              title={FIELDS_COPIES.contact}
              description={activity?.giverName}
            />
          </Grid>
        </Grid>

        <Grid container item>
          <Grid item xs={6}>
            <InfoLabel
              cropped={false}
              title={FIELDS_COPIES.updatedBy}
              description={activity?.updatedBy?.fullName}
            />
          </Grid>

          <Grid item xs={6}>
            <InfoLabel
              cropped={false}
              title={FIELDS_COPIES.updatedAt}
              description={
                activity?.updatedAt
                  ? toLocalTime(activity?.updatedAt).format(DateFormats.SimpleDateTime)
                  : ''
              }
            />
          </Grid>
        </Grid>

        <Grid container item>
          {!isNil(activity?.secDuration) && (
            <Grid item xs={6}>
              <InfoLabel
                cropped={false}
                title={FIELDS_COPIES.duration}
                description={formatDuration(activity?.secDuration)}
              />
            </Grid>
          )}

          <Grid item xs={6}>
            <InfoLabel
              cropped={false}
              title={FIELDS_COPIES.direction}
              description={activity?.direction}
            />
          </Grid>
        </Grid>

        {(!!activity?.body || !!activity.subject) && (
          <Grid item xs={12}>
            <InfoLabel
              cropped={false}
              title={FIELDS_COPIES.content}
              customValueRender={
                <>
                  <p>Subject: {activity?.subject}</p>
                  {activity?.body && (
                    <iframe
                      data-testid="email-frame"
                      title="htmlFrame"
                      className={classes.emailBodyRenderer}
                      srcDoc={activity?.body}
                    />
                  )}
                </>
              }
            />
          </Grid>
        )}

        <Grid item xs={12}>
          {mode === ACTIVITY_MODE.View ? (
            <InfoLabel
              cropped={false}
              title={`${activity?.type} ${FIELDS_COPIES.notes}`}
              customValueRender={<FPHtmlViewer content={activity?.notes} />}
            />
          ) : (
            <>
              <NotesLabel>{capitalizeFirstLetter(activity?.type)} Notes</NotesLabel>
              <SupportActivityNotesField />
            </>
          )}
        </Grid>

        <When condition={canSaveInEmployeeFile}>
          <Grid item xs={12}>
            {(activity?.lastSavedToEmployeeFileResult || activity?.saveInEmployeeFile) && (
              <InfoLabel
                cropped={false}
                title={strings.commandCenter.feedbackNotes.savedInEmployeeFileAt.title}
                description={getEmployeeFileCopyForNote(activity)}
              />
            )}
          </Grid>
        </When>
      </Grid>
    </Container>
  );
};
