import React from 'react';
import { SimplifiedTable } from 'features/command-center/components/shared';
import { TABLES_KEYS } from 'features/command-center/constants';
import { SECONDARY_API_CLIENT } from 'services/API';
import {
  ScrollableDataTable,
  ScrollableDataTableLayout
} from 'UI/components/templates/ScrollableDataTableLayout';

import {
  COLUMNS,
  ORDER_BY_OPTIONS,
  VIRTUAL_PROPS
} from './HiringAuthoritiesDrawerContent.constants';
import { formatResponse } from './HiringAuthoritiesDrawerContent.utils';

export const HiringAuthoritiesDrawerContent = ({ endpoint = '' }) => {
  return (
    <ScrollableDataTableLayout
      content={
        <SimplifiedTable
          apiInstance={SECONDARY_API_CLIENT}
          columns={COLUMNS}
          endpoint={endpoint}
          formatResponse={formatResponse}
          orderByOptions={ORDER_BY_OPTIONS}
          pageKey={TABLES_KEYS.drawerHiringAuthorities}
          shouldUseSavedPagination={false}
          showWrapper={false}
          virtualProps={VIRTUAL_PROPS}
          TableComponent={ScrollableDataTable}
        />
      }
    />
  );
};
