import React, { useMemo } from 'react';
import clsx from 'clsx';
import strings from 'strings';
import FPHint from 'UI/components/atoms/FPHint';
import FPHtmlViewer from 'UI/components/atoms/FPHtmlViewer';
import EmptyPlaceholder from 'UI/components/templates/EmptyPlaceholder';
import { SvgNoSendouts } from 'UI/res';
import { getId } from 'UI/utils';

import { useStyles } from '../styles';

import { Columns, SplitCell } from './ExpandableRow';
import Row from './Row';
import { isColumnToHide, statusAll } from './UserToolReport.constants';
import {
  addLabelsAndFlattenData,
  formatLastUpdateDate,
  formatLastUpdateTime,
  getUpdatedParamsNamesByMinutes
} from './UserToolReports.utils';

const DataTable = ({ items, month, week, lastUpdates, extraColumns, status }) => {
  const areValidItems = Array.isArray(items) && items.length > 0;

  const classes = useStyles();
  const sendouts = useMemo(
    () => (areValidItems ? addLabelsAndFlattenData(items) : []),
    [areValidItems, items]
  );

  const hasGpacOrRegionalCoach = sendouts.some(
    item => item.coach === 'gpac' || item.coach === 'regional'
  );

  const toolsUsageTeamClassName = clsx({
    [classes.team]: true,
    [classes.toolsUsageHeight]: true
  });

  const finalExtraColumns = extraColumns?.extraColumnsInfo
    ? extraColumns.extraColumnsInfo.map(extraColumn => ({
        label: extraColumn.columnLabel,
        ...(extraColumn.labelDown && { labelDown: extraColumn.labelDown }),
        name: extraColumn.columnLabel
      }))
    : [];

  const tableColumns = [
    {
      label: 'Team',
      className: toolsUsageTeamClassName,
      labelDown: status?.title === statusAll ? 'Status' : null,
      name: Columns.Team
    },
    { label: 'Calls', name: Columns.Calls },
    { label: 'InMails', name: Columns.InMails },
    { label: 'Indeed', name: Columns.Indeed },
    { label: 'Live Posts', name: Columns.LivePost },
    { label: 'Gpac Careers', name: Columns.GpacCareer },
    { label: 'Bulks Mo', labelDown: 'Reached', name: Columns.BulksMo },
    { label: 'Bulks Tu', labelDown: 'Reached', name: Columns.BulksTu },
    { label: 'Bulks We', labelDown: 'Reached', name: Columns.BulksWe },
    { label: 'Bulks Th', labelDown: 'Reached', name: Columns.BulksTh },
    { label: 'Bulks Fr', labelDown: 'Reached', name: Columns.BulksFr },
    { label: 'Bulks Sa', labelDown: 'Reached', name: Columns.BulksSa },
    { label: 'Bulks Su', labelDown: 'Reached', name: Columns.BulksSu },
    { label: 'Bulks Total', labelDown: 'Reached', name: Columns.BulksTotal },
    ...finalExtraColumns
  ];

  return areValidItems ? (
    <>
      <FPHint
        description={
          <>
            {strings.dashboard.toolsUsage.updateInfo.title}
            <ul>
              <li>
                <FPHtmlViewer
                  content={strings.formatString(
                    strings.dashboard.toolsUsage.updateInfo.thirtyMinutesUpdate,
                    {
                      updatedFields: getUpdatedParamsNamesByMinutes(
                        ['Bulks', 'Calls'],
                        extraColumns.extraColumnsInfo,
                        30
                      ),
                      date: formatLastUpdateDate(lastUpdates.bulks),
                      time: formatLastUpdateTime(lastUpdates.bulks)
                    }
                  )}
                />
              </li>
              <li>
                <FPHtmlViewer
                  content={strings.formatString(strings.dashboard.toolsUsage.updateInfo.inMails, {
                    date: formatLastUpdateDate(lastUpdates.inmails),
                    time: formatLastUpdateTime(lastUpdates.inmails)
                  })}
                />
              </li>
              <li>
                <FPHtmlViewer
                  content={strings.formatString(strings.dashboard.toolsUsage.updateInfo.livePosts, {
                    date: formatLastUpdateDate(lastUpdates.live_posts),
                    time: formatLastUpdateTime(lastUpdates.live_posts)
                  })}
                />
              </li>
            </ul>
          </>
        }
        size="md"
        containerClassName={classes.boxCopyWrapper}
        typographyProps={{ component: 'div' }}
      />

      <table className={classes.board}>
        <thead className={classes.boardHead}>
          <tr className={classes.title}>
            <th aria-label="Empty action button" />
            {tableColumns.map(
              column =>
                !isColumnToHide(column.name) && (
                  <th key={getId()} className={column.className || classes.toolsUsageHeight}>
                    <SplitCell column={column} header />
                  </th>
                )
            )}
          </tr>
        </thead>
        <tbody>
          {sendouts.map(item => (
            <Row
              key={getId()}
              item={item}
              month={month}
              week={week}
              extraColumns={extraColumns}
              personStatus={status}
              hasGpacOrRegionalCoach={hasGpacOrRegionalCoach}
            />
          ))}
        </tbody>
      </table>
    </>
  ) : (
    <EmptyPlaceholder
      title={strings.shared.emptyState.title}
      subtitle={strings.shared.emptyState.subtitle}
      customEmptyState={<SvgNoSendouts height={400} />}
    />
  );
};
export default DataTable;
