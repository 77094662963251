// @flow
import React, { useState } from 'react';
import Grid from '@material-ui/core/Grid';
import MenuIcon from '@material-ui/icons/Menu';
import usePageTitle from 'hooks/usePageTitle';
import FPIconButton from 'UI/components/atoms/FPIconButton';
import TitleLabel from 'UI/components/atoms/TitleLabel';
import ContentPageLayout from 'UI/components/templates/ContentPageLayout';
import { AppName } from 'UI/constants/defaults';

import { useStyles } from './styles';

type DualPanelLayoutProps = {
  leftPanel: {
    header: {
      title: string,
      Actions?: React.ReactNode,
      Header?: React.ReactNode
    },
    Content: React.ReactNode
  },
  rightPanel: {
    header: {
      HeaderTitle: React.ReactNode,
      Actions?: React.ReactNode
    },
    Content: React.ReactNode
  }
};

const DualPanelLayout = ({ leftPanel, rightPanel }: DualPanelLayoutProps) => {
  const classes = useStyles();
  const [isExpandedLayout, setIsExpandedLayout] = useState(true);

  const { title, Header: LeftHeader, Actions: LeftActions } = leftPanel.header;
  const { HeaderTitle: RightHeaderTitle, Actions: RightActions } = rightPanel.header;

  usePageTitle({ title: `${AppName} | ${title}` });

  const toggleExpandedLayout = () => setIsExpandedLayout(prev => !prev);

  return (
    <ContentPageLayout mode="customLayout">
      <Grid container>
        {isExpandedLayout && (
          <Grid item xs={4}>
            <div className={classes.panelContainer}>
              <div className={classes.leftHeaderContainer}>
                <div className={classes.leftHeader}>
                  <TitleLabel text={title} />
                  {LeftActions && <div>{LeftActions}</div>}
                </div>
                {LeftHeader && LeftHeader}
              </div>
              <div className={classes.leftContent}>{leftPanel?.Content}</div>
            </div>
          </Grid>
        )}
        <Grid item xs={isExpandedLayout ? 8 : 12}>
          <div className={classes.panelContainer}>
            <div className={classes.rightHeaderContainer}>
              <div className={classes.rightHeader}>
                <FPIconButton aria-label="show/hide message list" onClick={toggleExpandedLayout}>
                  <MenuIcon color="primary" />
                </FPIconButton>
                {RightHeaderTitle && (
                  <div className={classes.rightHeaderTitle}>{RightHeaderTitle}</div>
                )}
              </div>
              {RightActions && <div>{RightActions}</div>}
            </div>
            <div className={classes.rigthContentContainer}>{rightPanel.Content}</div>
          </div>
        </Grid>
      </Grid>
    </ContentPageLayout>
  );
};

export default DualPanelLayout;
