import React, { useCallback, useState } from 'react';
import Grid from '@material-ui/core/Grid';
import {
  CallsGauge,
  Drawers,
  HealthDistribution,
  InMailsGauge,
  PDAByTenure,
  PeopleReachedGauge,
  PhoneTimeGauge,
  PlacementsStatsSummary,
  ProductionCashInByMonth,
  TeamLayout,
  WeeklyActivityStatsSummary,
  WeeklyInventoryGrowthStatsSummary,
  WeeklyProductivityStatsSummary
} from 'features/command-center';
import { DrawerManagerProvider } from 'features/command-center/contexts';
import { useRefreshStats } from 'features/command-center/hooks';
import { useFeatureFlags } from 'providers/FeatureFlagsProvider';
import { FeatureFlags } from 'UI/constants/featureFlags';

export const TeamOverview = ({ id, match }) => {
  const [teamName, setTeamName] = useState('');
  const { checkIfFeatureFlagEnabled } = useFeatureFlags();

  const { refreshData, refreshKey } = useRefreshStats();

  const teamId = id ?? match?.params?.id;
  const canDoDrillDown = checkIfFeatureFlagEnabled(FeatureFlags.CommandCenterDrillDown);
  const requestParams = { team: teamId, ...(canDoDrillDown && { version: 1 }) };

  const handleTeamLoaded = useCallback(newTeamData => {
    const nameParts = newTeamData?.managerName ? newTeamData.managerName.split(' ') : [''];
    setTeamName(nameParts[0]);
  }, []);

  return (
    <DrawerManagerProvider>
      <TeamLayout teamId={teamId} onRefresh={refreshData} onTeamLoaded={handleTeamLoaded}>
        <Grid key={refreshKey} container spacing={3}>
          <Grid item xs={12}>
            <HealthDistribution requestParams={requestParams} hasDrillDown={canDoDrillDown} />
          </Grid>

          <Grid item xs={12}>
            <PlacementsStatsSummary requestParams={requestParams} hasDrillDown={canDoDrillDown} />
          </Grid>

          <Grid item xs={12}>
            <WeeklyActivityStatsSummary
              requestParams={requestParams}
              hasDrillDown={canDoDrillDown}
            />
          </Grid>

          <Grid item xs={12}>
            <WeeklyProductivityStatsSummary
              requestParams={requestParams}
              hasDrillDown={canDoDrillDown}
            />
          </Grid>

          <Grid item xs={12}>
            <WeeklyInventoryGrowthStatsSummary
              requestParams={requestParams}
              hasDrillDown={canDoDrillDown}
            />
          </Grid>

          {checkIfFeatureFlagEnabled(FeatureFlags.CommandCenterPDA) && (
            <Grid item xs={12}>
              <PDAByTenure requestParams={requestParams} />
            </Grid>
          )}

          <Grid item xs={12}>
            <ProductionCashInByMonth enableDateFilter requestParams={requestParams} />
          </Grid>

          <Grid item xs={12} sm={6}>
            <PeopleReachedGauge requestParams={{ ...requestParams, groupBy: 'team' }} />
          </Grid>

          <Grid item xs={12} sm={6}>
            <InMailsGauge requestParams={{ ...requestParams, groupBy: 'team' }} />
          </Grid>

          <Grid item xs={12} sm={6}>
            <CallsGauge requestParams={{ ...requestParams, groupBy: 'team' }} />
          </Grid>

          <Grid item xs={12} sm={6}>
            <PhoneTimeGauge requestParams={{ ...requestParams, groupBy: 'team' }} />
          </Grid>
        </Grid>
      </TeamLayout>
      <Drawers prefixTitle={teamName ? `Team ${teamName}'s` : ''} />
    </DrawerManagerProvider>
  );
};
