import { capitalizeName } from 'features/command-center/utils';
import startCase from 'lodash/startCase';
import moment from 'moment';
import { DateFormats } from 'UI/constants/defaults';
import { roundDecimals } from 'UI/utils';

import { TENURE_RANGES } from './OverallPerformance.constants';

export const formatResponse = response => {
  const formattedData = response.data.data.map(item => ({
    ...item,
    recruiterName: {
      empNo: item.empNo,
      name: capitalizeName(item?.name)
    },
    team: {
      managerEmpNo: item.managerEmpNo,
      team: capitalizeName(item?.team)
    },
    inMails: {
      inMails: item.inMails,
      inMailsGoals: item.inMailsGoals
    },
    avgInMails: {
      avgInMails: item.avgInMails,
      avgInMailsGoals: item.avgInMailsGoals
    },
    bulks: {
      bulks: item.bulks,
      bulksGoal: item.bulksGoal
    },
    callsTime: {
      callsTime: item.callsTime,
      callsTimeGoal: item.callsTimeGoal
    },
    calls: {
      calls: item.calls,
      callsGoal: item.callsGoal
    },
    peopleReached: {
      peopleReached: item.peopleReached,
      peopleReachedGoal: item.peopleReachedGoal
    },
    posts: {
      todayLivePosts: item.todayLivePosts,
      livePostingsDailyGoal: item.livePostingsDailyGoal
    },
    sendouts: {
      sendouts: item.sendouts,
      sendoutsGoal: item.sendoutsGoal
    },
    productionYTD: item.productionYtd,
    cashInYTD: item.cashInYtd,
    outStandingBillings: item.outstandingBillings,
    hireDate: item?.hireDate && moment(item?.hireDate).format(DateFormats.SimpleDate),
    tenure: item?.tenure ? TENURE_RANGES[item.tenure] : null,
    lastFeedbackActivityType:
      item?.lastFeedbackActivityType && startCase(item?.lastFeedbackActivityType),
    avgFeePercentage: item?.avgFeePercentage && roundDecimals(item?.avgFeePercentage, 2)
  }));

  return { ...response, data: { ...response.data, data: formattedData } };
};
