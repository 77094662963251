// @flow
import React, { useCallback, useState } from 'react';
import CircularProgress from '@material-ui/core/CircularProgress';
import Grid from '@material-ui/core/Grid';
import { useShowAlert } from 'hooks';
import usePageTitle from 'hooks/usePageTitle';
import API from 'services/API';
import { userHasRoles } from 'services/Authorization';
import strings from 'strings';
import FPIconButton from 'UI/components/atoms/FPIconButton';
import AutocompleteSelect from 'UI/components/molecules/AutocompleteSelect';
import ContentPageLayout from 'UI/components/templates/ContentPageLayout';
import { backNavigateListConfig } from 'UI/constants/config';
import { PageTitles } from 'UI/constants/defaults';
import { Endpoints } from 'UI/constants/endpoints';
import { Roles } from 'UI/constants/roles';
import { SvgDownloadFilled } from 'UI/res/icons/filled';
import { downloadFile } from 'UI/utils';

import { useStyles } from '../styles';

import Loader from './Loader';
import { columnsToHideInUserToolReport, statusAll } from './UserToolReport.constants';

export const AutocompleteByYear = ({ year, onSelect, onOptionsLoaded }) => (
  <AutocompleteSelect
    value={year}
    name="weekFilter"
    placeholder="Year"
    onSelect={onSelect}
    onOptionsLoaded={onOptionsLoaded}
    url={Endpoints.SendoutDashboardYears}
    disableClearable
  />
);

export const AutocompleteByWeek = ({ week, onSelect, onOptionsLoaded }) => (
  <AutocompleteSelect
    value={week}
    name="weekFilter"
    placeholder="Week"
    onSelect={onSelect}
    onOptionsLoaded={onOptionsLoaded}
    groupBy={option => option.month}
    url={Endpoints.ToolsUsageReportWeeks}
    disableClearable
  />
);

export const AutocompleteByStatus = ({ status, onSelect, onOptionsLoaded }) => (
  <AutocompleteSelect
    value={status}
    name="statusFilter"
    placeholder="Employee Status"
    onSelect={onSelect}
    onOptionsLoaded={onOptionsLoaded}
    url={Endpoints.UserStatuses}
    disableClearable
  />
);

const CURRENT_ELEMENT_INDEX = 0;

const UserToolReports = () => {
  const classes = useStyles();
  const { showAlert } = useShowAlert();
  const [week, setWeek] = useState(null);
  const [status, setStatus] = useState(null);
  const [loading, setLoading] = useState(false);
  const hasLoadedOptions = !!week && !!status;

  usePageTitle({
    title: PageTitles.DashboardToolsUsageReport
  });

  const onSelectForWeek = (event, newValue) => {
    setWeek(newValue);
    setStatus(status);
  };

  const onSelectForStatus = (event, newValue) => {
    setStatus(newValue);
  };

  const onOptionsLoadedForWeek = useCallback((items?: any[]) => {
    setWeek(items[CURRENT_ELEMENT_INDEX]);
  }, []);

  const onOptionsLoadedForStatus = useCallback((items?: any[]) => {
    items.push({ id: 0, title: statusAll });
    setStatus(items[CURRENT_ELEMENT_INDEX]);
  }, []);

  const toolsUsageExtraColumns = [
    {
      rolesNeeded: [
        Roles.ProductionDirector,
        Roles.Operations,
        Roles.Leadership,
        Roles.Admin,
        Roles.DataCoordinator,
        Roles.Finance,
        Roles.Staff,
        Roles.Coach,
        Roles.RegionalDirector
      ],
      columnName: 'draw_balance',
      columnLabel: 'Draw Balance',
      updateFrecuencyInMinutes: 30
    }
  ];

  const getToolsUsageExtraColumns = () => {
    const extraColumnsInfo = [];
    const extraColumnsNames = [];

    toolsUsageExtraColumns.forEach(item => {
      if (
        !columnsToHideInUserToolReport.includes(item.columnLabel) &&
        userHasRoles(item.rolesNeeded)
      ) {
        extraColumnsInfo.push({
          columnName: item.columnName,
          columnLabel: item.columnLabel,
          updateFrecuencyInMinutes: item.updateFrecuencyInMinutes
        });
        extraColumnsNames.push(item.columnName);
      }
    });

    return {
      extraColumnsInfo,
      extraColumnsNames: String(extraColumnsNames)
    };
  };

  const extraColumns = getToolsUsageExtraColumns();

  const handleDownload = async () => {
    if (loading) return;

    setLoading(true);
    const fileName = `tools-usage-report-${week?.date}`;

    try {
      const params = {
        week: week?.date,
        extraColumns: extraColumns?.extraColumnsNames,
        status: status?.title
      };
      const response = await API.get(Endpoints.ToolsUsageReportDownload, {
        responseType: 'blob',
        apiVersion: 1,
        params
      });
      downloadFile(response.data, 'text/csv', fileName);
      showAlert({
        title: strings.dashboard.toolsUsage.successDownload,
        autoHideDuration: 1500
      });
    } catch (error) {
      showAlert({
        title: strings.dashboard.toolsUsage.errorDownload,
        error
      });
    } finally {
      setLoading(false);
    }
  };

  const AutocompleteViewFilters = (
    <>
      {userHasRoles([Roles.Operations, Roles.Leadership]) && (
        <Grid
          item
          xs={2}
          className={classes.downloadFile}
          container
          direction="row"
          justify="flex-end"
          alignItems="center"
        >
          {loading && <CircularProgress size={24} />}
          <FPIconButton
            icon={SvgDownloadFilled}
            onClick={handleDownload}
            tootlipProps={{ title: strings.dashboard.toolsUsage.downloadReport }}
          />
        </Grid>
      )}
      <Grid item xs={3}>
        <AutocompleteByStatus
          status={status}
          onSelect={onSelectForStatus}
          onOptionsLoaded={onOptionsLoadedForStatus}
        />
      </Grid>
      <Grid item xs={6}>
        <AutocompleteByWeek
          week={week}
          onSelect={onSelectForWeek}
          onOptionsLoaded={onOptionsLoadedForWeek}
        />
      </Grid>
    </>
  );

  return (
    <ContentPageLayout
      titleLabelProps={backNavigateListConfig}
      customTitleGridConfig={{
        xs: 12,
        sm: 4,
        lg: 5
      }}
      text={strings.dashboard.toolsUsage.title}
      actionsBarConfig={{
        xs: 12,
        sm: 8,
        lg: 7,
        spacing: 2
      }}
      actionsBar={AutocompleteViewFilters}
    >
      <Loader
        endpoint={hasLoadedOptions ? Endpoints.ToolsUsageReportData : null}
        week={week}
        extraColumns={extraColumns}
        status={status}
      />
    </ContentPageLayout>
  );
};

export default UserToolReports;
