// @flow
import React, { useCallback, useMemo, useState } from 'react';
import Grid from '@material-ui/core/Grid';
import { Drawers, RecruiterLayout } from 'features/command-center';
import { DrawerManagerProvider, RecruiterInfoProvider } from 'features/command-center/contexts';
import { useRefreshStats } from 'features/command-center/hooks';
import { useFeatureFlags } from 'providers/FeatureFlagsProvider';
import strings from 'strings';
import TabsView from 'UI/components/templates/TabsView';
import { FeatureFlags } from 'UI/constants/featureFlags';

import { Activity } from './Activity';
import { PilGrowth } from './PilGrowth';
import { useStyles } from './Recruiter.styles';
import { RecruiterOverview } from './RecruiterOverview';

const STRINGS = strings.commandCenter.activity.recruiter;

export const Recruiter = ({ id, match }) => {
  const classes = useStyles();
  const [recruiterName, setRecruiterName] = useState('');
  const [teamId, setTeamId] = useState('');
  const { checkIfFeatureFlagEnabled } = useFeatureFlags();
  const { refreshData, refreshKey } = useRefreshStats();

  const areTabsEnabled = checkIfFeatureFlagEnabled(FeatureFlags.CommandCenterRecruiterTabs);
  const isPilGrowthEnabled = checkIfFeatureFlagEnabled(
    FeatureFlags.CommandCenterRecruiterPilGrowth
  );
  const isActivityEnabled = checkIfFeatureFlagEnabled(FeatureFlags.CommandCenterRecruiterActivity);

  const recruiterId = id ?? match?.params?.id;

  const handleRecruiterLoaded = useCallback(recruiterData => {
    setRecruiterName(recruiterData?.recruiterName ?? '');
    setTeamId(recruiterData?.managerEmpNo);
  }, []);

  const TABS = useMemo(
    () => [
      {
        id: 'recruiterOverview',
        label: STRINGS.tabs.overview,
        view: <RecruiterOverview recruiterId={recruiterId} key={refreshKey} />,
        isEnabled: true
      },
      {
        id: 'pilGrowth',
        label: STRINGS.tabs.pilGrowth,
        view: <PilGrowth recruiterId={recruiterId} teamId={teamId} key={refreshKey} />,
        isEnabled: isPilGrowthEnabled
      },
      {
        id: 'activity',
        label: STRINGS.tabs.activity,
        view: <Activity recruiterId={recruiterId} teamId={teamId} key={refreshKey} />,
        isEnabled: isActivityEnabled
      }
    ],
    [recruiterId, refreshKey, teamId, isPilGrowthEnabled, isActivityEnabled]
  );

  const FINAL_TABS = TABS.filter(tab => tab.isEnabled);

  return (
    <DrawerManagerProvider>
      <RecruiterInfoProvider recruiterId={recruiterId} onRecruiterLoaded={handleRecruiterLoaded}>
        <RecruiterLayout onRefresh={refreshData}>
          <Grid item xs={12}>
            {areTabsEnabled && (isPilGrowthEnabled || isActivityEnabled) ? (
              <TabsView
                enableUrlNavigation
                initialTab="recruiterOverview"
                panelHeight="unset"
                tabs={FINAL_TABS ?? []}
                tabPanelProps={{
                  className: classes.tab
                }}
                useIndexes={false}
              />
            ) : (
              <RecruiterOverview recruiterId={recruiterId} />
            )}
          </Grid>
        </RecruiterLayout>
        <Drawers prefixTitle={recruiterName ?? ''} />
      </RecruiterInfoProvider>
    </DrawerManagerProvider>
  );
};
