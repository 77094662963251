export const buildSendoutDetails = sendoutData => {
  if (!sendoutData) return null;
  const sendoutDetails = {
    candidateName: sendoutData?.candidateName ?? '',
    companyName: sendoutData?.companyName ?? '',
    hiringAuthorityName: sendoutData?.hiringAuthorityName ?? '',
    interviewDate: sendoutData?.interviewDate ?? '',
    interviewId: sendoutData?.id ?? null,
    jobOrderTitle: sendoutData?.jobOrderTitle ?? '',
    location: sendoutData?.location ?? '',
    sendoutId: sendoutData?.sendoutId ?? null
  };

  return sendoutDetails;
};
