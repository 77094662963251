// @flow
import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import { showAlert } from 'actions/app';
import { setBulkEmailToInitial, startRemovingAllAttachments } from 'actions/bulkEmail';
import { HTTPStatusCodes } from 'constants/httpStatusCodes';
import useDatatable, {
  addRingCentralContactToStore,
  buildMultiplePhonesActionsRenderer,
  buildRowActionsRendererDefinition,
  extractObjectFromDataTable,
  getColumnPreferences,
  getColumnsToRender
} from 'hooks/datatable';
import useMultipleSelection, { CustomTableToolbarSelect } from 'hooks/multipleSelection';
import { useSearchProject } from 'hooks/searchProject';
import usePageTitle from 'hooks/usePageTitle';
import { useQuickViewPreview } from 'hooks/useQuickViewPreview';
import { UserProviderContext } from 'providers/UserProvider';
import { EntityRoutes } from 'routes/constants';
import { Permissions, userHasPermission } from 'services/Authorization';
import { archiveSearchProject, getSearchProjectQuickInfo } from 'services/searchProjects';
/** Atoms, Components and Styles */
import strings from 'strings';
import FPIconButton from 'UI/components/atoms/FPIconButton';
import { When } from 'UI/components/atoms/When';
import BulkEmailDrawer from 'UI/components/organisms/BulkEmailDrawer';
import DataTable from 'UI/components/organisms/DataTable';
import DecisionDialog from 'UI/components/organisms/DecisionDialog';
import DetailedConfirmationDialog from 'UI/components/organisms/DetailedConfirmationDialog';
import FPQuickView from 'UI/components/organisms/inventoryProfiles/FPQuickView';
import ActivityLogDrawer from 'UI/components/organisms/SearchProjects/ActivityLogDrawer';
import CollaborationsForm from 'UI/components/organisms/SearchProjects/CollaborationsForm';
import RestoreSearchProjectDialog from 'UI/components/organisms/SearchProjects/RestoreSearchProjectDialog.jsx';
import RestoreSearchProjectDrawer from 'UI/components/organisms/SearchProjects/RestoreSearchProjectDrawer';
import SplitsForm from 'UI/components/organisms/SearchProjects/SplitsForm';
import FiltersLayout from 'UI/components/templates/FiltersLayout';
import {
  ARCHIVE_AVAILABILITY_DAYS,
  CommunicationActionPreset,
  SearchProjectPrivacyScopes,
  SelectionType
} from 'UI/constants/defaults';
import { Endpoints } from 'UI/constants/endpoints';
import { EntityType, SearchProjectItemTypeIds } from 'UI/constants/entityTypes';
import { FeatureFlags } from 'UI/constants/featureFlags';
import { SvgBulks, SvgDeleteFilled, SvgEdit } from 'UI/res';
import { getFinalColumns, hasFeatureFlag } from 'UI/utils';
import { DataTableLink } from 'UI/utils/renderers';

import { ColumnsDefinitions, FiltersGroups, VirtualProps } from './columns';
import { SearchProjectsHeaderAction, SearchProjectsTitleDetails } from './components';
import { useStyles } from './styles';
import {
  getFilteredCollabs,
  getManagementPermissions,
  getMappedSearchProjectData,
  getQueryShortFormatDate
} from './utils';

const ContactRoutes = {
  [SearchProjectItemTypeIds.Candidate]: EntityRoutes.CandidateProfile,
  [SearchProjectItemTypeIds.HiringAuthority]: EntityRoutes.HiringAuthorityProfile,
  [SearchProjectItemTypeIds.Name]: EntityRoutes.ContactProfile,
  [SearchProjectItemTypeIds.NameCandidate]: EntityRoutes.ContactProfile,
  [SearchProjectItemTypeIds.NameHA]: EntityRoutes.ContactProfile,
  [SearchProjectItemTypeIds.Company]: EntityRoutes.CompanyProfile
};
const CONTACT_ROLES = {
  [SearchProjectItemTypeIds.Candidate]: EntityType.Candidate,
  [SearchProjectItemTypeIds.HiringAuthority]: EntityType.HiringAuthority,
  [SearchProjectItemTypeIds.Name]: EntityType.Contact,
  [SearchProjectItemTypeIds.NameCandidate]: EntityType.Contact,
  [SearchProjectItemTypeIds.NameHA]: EntityType.Contact,
  [SearchProjectItemTypeIds.Company]: EntityType.Company
};

const orderByOptions = {
  column: 'full_name',
  direction: 'asc'
};
const preferencesKey = 'searchProjectPreview-list';

const {
  dialogs: { archiveSearchProject: archiveSearchProjectDialogCopies },
  preview: { actions: actionsCopies, dialogs: previewDialogs, buttons }
} = strings.searchProjects;

const extraRenderer = {
  spItemLink: value => {
    if (!value) return null;

    const { id, item_search_project_type: typeId, full_name: fullName } = value;
    const route = ContactRoutes[typeId];

    return id && route ? (
      <DataTableLink id={id} text={fullName} route={route} enableInTabRedirect />
    ) : null;
  }
};

const SearchProjectPreview = ({ match, history }) => {
  const searchProjectId = match.params.id;
  const pageKey = `${preferencesKey}-${searchProjectId}`;

  const multiplePhonesActionButtons = buildMultiplePhonesActionsRenderer({
    customName: 'phoneNumbers',
    handleAction,
    featureFlagToCheck: FeatureFlags.SearchProjectPreviewMultiplePhones,
    columns: getFinalColumns(ColumnsDefinitions)
  });

  const SEARCH_PROJECTS_COLUMNS = [
    ...getFinalColumns(ColumnsDefinitions),
    multiplePhonesActionButtons
  ].filter(column => !!column);

  const initialPreferences = getColumnPreferences(
    preferencesKey,
    0,
    orderByOptions,
    SEARCH_PROJECTS_COLUMNS
  );

  const classes = useStyles();
  const [currentUser] = useContext(UserProviderContext);
  const dispatch = useDispatch();

  const [collaborators, setCollaborators] = useState([]);
  const [searchProject, setSearchProject] = useState({
    archivedAt: null,
    createdAt: null,
    daysBeforeDeletion: null,
    id: null,
    isArchived: false,
    isPrivate: false,
    name: '',
    privacyScope: null,
    totalItems: 0
  });
  const [uiState, setUiState] = useState({
    managementPermissions: {},
    fetchingDialog: false,
    hasBulkableItems: false,
    isActivityDrawerOpen: false,
    isArchiveDialogOpen: false,
    isBulkDrawerOpen: false,
    isCollaborationFormOpen: false,
    isLoadingSearchProjectInfo: false,
    isRestoreDialogOpen: false,
    isRestoreDrawerOpen: false,
    isSplitsFormOpen: false,
    lastBulkSentDate: '',
    originalTotalItems: null,
    totalCompanies: null
  });

  const showCollabsDrawer = uiState.isCollaborationFormOpen && collaborators.length > 0;
  const showActivityLogDrawer = uiState.isActivityDrawerOpen;
  const shouldAllowItemsSelection =
    uiState.managementPermissions?.canManageItems && !searchProject.isArchived;
  const isArchiveFeatureActive = hasFeatureFlag(FeatureFlags.SearchProjectsArchive);
  const isSearchProjctFoldersFeatureActive = hasFeatureFlag(FeatureFlags.SearchProjectsFolders);
  const isRestoreDrawerFeatureActive =
    isArchiveFeatureActive &&
    isSearchProjctFoldersFeatureActive &&
    hasFeatureFlag(FeatureFlags.SearchProjectsRestoreSelectingFolder);

  const avatarsToShow = useMemo(() => (collaborators.length === 2 ? 2 : 1), [collaborators]);

  const {
    columnPreferences,
    count,
    data,
    filters,
    getData,
    handleColumnDisplayChange,
    handleColumnOrderChange,
    handleColumnSortChange,
    handleFiltersApply,
    handleFiltersChange,
    handleFiltersToggle,
    handleKeywordChange,
    handlePageChange,
    handlePerPageChange,
    listState,
    queryParams
  } = useDatatable({
    key: pageKey,
    columnKey: preferencesKey,
    endpoint: Endpoints.SearchProjectsInventory.replace(/:id/, searchProjectId),
    entityName: 'Search Project Preview',
    entityType: EntityType.SearchProjectPreview,
    initialPreferences,
    columnsDefinitions: SEARCH_PROJECTS_COLUMNS,
    orderByOptions,
    virtualProps: VirtualProps,
    shouldScrollOnNavigate: true,
    numberOfActionColumns: 1
  });

  const { columns, columnOrder, orderBy, direction } = columnPreferences;
  const { isLoading, showWholeSkeleton, isSideMenuOpen, page, perPage, keyword } = listState;

  const { entityId, quickViewEntity, quickViewState, listViewPreset, toggleQuickViewPreview } =
    useQuickViewPreview({
      columns,
      quickViewEntityRoleProps: { roleKey: 'item_search_project_type' },
      data
    });

  const dataForSelection = useMemo(
    () =>
      data.map(item => ({
        id: item.id,
        type: item.item_search_project_title,
        type_id: item.item_search_project_type
      })),
    [data]
  );

  const {
    handleRowSelection,
    selectedIndexesInPage,
    multiSelectComponents,
    totalSelected,
    setToInitial: setSelectionToInitial,
    filteredItems
  } = useMultipleSelection({ data: dataForSelection, count, perPage });

  const enableBulkButton = uiState.hasBulkableItems && count > 0 && !isLoading;
  const correctQueryParams = { ...queryParams, type: 'name' };
  const createdFrom = 'searchProjectPreview';
  const shouldEnableMasterListFeature =
    userHasPermission(Permissions.SearchProjects.CanMarkAsMasterList) &&
    hasFeatureFlag(FeatureFlags.SearchProjectsMasterListFields);

  const handleSearchProjectUpdate = newSearchProjectData => {
    setSearchProject(prev => {
      const newData = {
        ...prev,
        name: newSearchProjectData.name,
        isPrivate: newSearchProjectData.isPrivate,
        privacyScope: SearchProjectPrivacyScopes.getPrivacyState(newSearchProjectData.isPrivate)
      };

      if (hasFeatureFlag(FeatureFlags.SearchProjectsFolders)) {
        newData.folder = newSearchProjectData.folder;
      }

      if (shouldEnableMasterListFeature) {
        newData.isMasterList = newSearchProjectData.isMasterList;
        newData.geoPolygon = newSearchProjectData.geoPolygon;
        newData.seatCount = newSearchProjectData.seatCount;
      }

      return newData;
    });
  };

  const {
    handleRemoveFromSearchProject,
    SearchProjectAction,
    SearchProjectForms,
    searchProjectTogglers
  } = useSearchProject({
    createdFrom,
    filteredItems,
    queryParams: correctQueryParams,
    setSelectionToInitial,
    enableMoveActions: true,
    onUpdateSearchProject: handleSearchProjectUpdate
  });

  const getSPSummarizedInfo = useCallback(
    async searchProjectIdParam => {
      try {
        if (!currentUser?.id) return;
        setUiState(prev => ({ ...prev, isLoadingSearchProjectInfo: true }));
        const {
          alert,
          data: quickInfoData,
          success
        } = await getSearchProjectQuickInfo(searchProjectIdParam);

        if (success) {
          if (quickInfoData.isArchived && !isArchiveFeatureActive) history.push('/404');

          const filteredCollaborators = getFilteredCollabs(currentUser.id, quickInfoData.collabs);
          const managementPermissions = getManagementPermissions(
            currentUser.roles,
            quickInfoData.roleTitle
          );

          const mappedSearchProjectData = getMappedSearchProjectData(quickInfoData);

          setSearchProject(mappedSearchProjectData);
          setCollaborators(filteredCollaborators);
          setUiState(prev => ({
            ...prev,
            managementPermissions,
            lastBulkSentDate: quickInfoData.sentDate,
            hasBulkableItems: quickInfoData.bulkableItems > 0,
            originalTotalItems: quickInfoData.totalItems,
            totalCompanies: quickInfoData.totalCompanies
          }));
        } else {
          dispatch(showAlert(alert));
        }
      } catch (err) {
        const redirectStatuses = [HTTPStatusCodes.BadRequest, HTTPStatusCodes.NotFound];
        redirectStatuses.includes(err.response.status) && history.push('/404');
      } finally {
        setUiState(prev => ({ ...prev, isLoadingSearchProjectInfo: false }));
      }
    },
    [dispatch, history, currentUser, isArchiveFeatureActive]
  );

  useEffect(() => {
    searchProjectId && getSPSummarizedInfo(searchProjectId);
  }, [searchProjectId, getSPSummarizedInfo]);

  useEffect(() => {
    if (!history.location.state) return;
    const { createdFrom: historyCreatedFrom } = history.location.state;
    if (historyCreatedFrom === createdFrom) {
      handleFiltersChange({});
      history.replace({ location: { ...history.location, state: null } });
    }
  }, [searchProjectId, history, handleFiltersChange]);

  usePageTitle({ title: searchProject.name, isLoading });

  useEffect(
    () => () => {
      dispatch(setBulkEmailToInitial());
      dispatch(startRemovingAllAttachments());
    },
    [dispatch]
  );

  useEffect(() => {
    searchProject.name &&
      setSearchProject(prev => ({ ...prev, totalItems: totalSelected || count }));
  }, [searchProject.name, totalSelected, count, dispatch]);

  const setTablePageAfterItemsDeletion = items => {
    const currentPageCount = page * perPage;
    let itemsLeft = 0;
    if (items.type === SelectionType.Include) {
      /** Happens when single or page selection is executed */
      const deletedItemsCount = items.data.length;
      itemsLeft = count - deletedItemsCount;
    } else if (items.type === SelectionType.Exclude) {
      /** Happens when all items are selected */
      itemsLeft = items.data.length;
    }
    setSelectionToInitial();
    if (itemsLeft === 0) {
      getData();
    } else {
      const moveToLastExistingPage = itemsLeft <= currentPageCount;
      if (moveToLastExistingPage) {
        const newPage = Math.floor(itemsLeft / perPage);
        handlePageChange(newPage);
      } else getData();
    }
    setUiState(prev => ({ ...prev, fetchingDialog: false }));
  };

  function handleAction({ rowData, origin }) {
    const item = extractObjectFromDataTable(columns, ['item_search_project_type'], rowData);
    const roleId = item?.item_search_project_type;
    const itemRole = CONTACT_ROLES[roleId];
    itemRole &&
      addRingCentralContactToStore({
        rowData,
        columns: SEARCH_PROJECTS_COLUMNS,
        dispatch,
        role: itemRole,
        origin
      });
  }

  const togglers = {
    activityLogDrawer:
      ({ open }) =>
      () =>
        setUiState(prev => ({ ...prev, isActivityDrawerOpen: open })),
    archiveDialog:
      ({ open }) =>
      () =>
        setUiState(prev => ({ ...prev, isArchiveDialogOpen: open })),
    restoreDialog:
      ({ open }) =>
      () =>
        setUiState(prev => ({ ...prev, isRestoreDialogOpen: open })),
    restoreDrawer:
      ({ open }) =>
      () =>
        setUiState(prev => ({ ...prev, isRestoreDrawerOpen: open })),
    collaborationsDrawer:
      ({ open }) =>
      () =>
        setUiState(prev => ({ ...prev, isCollaborationFormOpen: open }))
  };

  const handleSetCollaborators = collabs => setCollaborators(collabs);

  const handleArchiveSearchProject = async ok => {
    if (!ok) return;
    setUiState(prev => ({ ...prev, fetchingDialog: true }));
    const response = await archiveSearchProject(searchProjectId, isArchiveFeatureActive);
    dispatch(showAlert(response?.alert));
    setUiState(prev => ({ ...prev, fetchingDialog: false }));
    response.success && history.push(EntityRoutes.SearchProject);
  };

  const handleOpenUpdateSPForm = searchProjectTogglers.searchProjectForm({
    edit: true,
    moveItems: false,
    open: true
  });

  const phoneActionButtons = buildRowActionsRendererDefinition({
    actions: [...CommunicationActionPreset, listViewPreset],
    namespace: EntityType.Contact,
    handleAction,
    columns: SEARCH_PROJECTS_COLUMNS
  });

  const finalColumns = getColumnsToRender([...columns, phoneActionButtons], extraRenderer);

  const handleOpenBulkModal = useCallback(() => {
    setUiState(prev => ({ ...prev, isBulkDrawerOpen: true }));
  }, []);

  const handleOnCloseBulkDrawer = () => {
    setUiState(prev => ({ ...prev, isBulkDrawerOpen: false }));
  };

  const BulkEmailButton = useCallback(
    ({ disabled }) => (
      <FPIconButton
        onClick={handleOpenBulkModal}
        showTooltipWhenDisabled
        disabled={disabled}
        icon={SvgBulks}
        tooltipProps={{
          arrow: false,
          placement: 'bottom',
          title: disabled ? buttons.sendBulkDisabled : buttons.sendBulk
        }}
        iconProps={{
          onSetColor: ({ primary, grey }) => (disabled ? grey[600] : primary.main)
        }}
      />
    ),
    [handleOpenBulkModal]
  );

  const ActionButtons = () => (
    <div className={classes.actionButtons}>
      <BulkEmailButton disabled={!enableBulkButton} />
      <SearchProjectAction />
      {uiState.managementPermissions?.canManageItems && (
        <FPIconButton
          onClick={handleRemoveFromSearchProject({
            id: searchProjectId,
            dialogCopies: {
              ...previewDialogs.removeItems,
              message: strings.formatString(previewDialogs.removeItems.message, {
                spName: searchProject.name
              })
            },
            onRemove: () => {
              setTablePageAfterItemsDeletion(filteredItems);
              getSPSummarizedInfo(searchProjectId);
            }
          })}
          tooltipProps={{
            arrow: false,
            title: buttons.removeItems,
            placement: 'bottom'
          }}
          icon={SvgDeleteFilled}
          iconProps={{
            onSetColor: palette => palette.primary.main
          }}
        />
      )}
    </div>
  );

  const deleteDialogSections = [
    { title: 'Search Project Name', detail: searchProject.name },
    { title: 'Added Date', detail: getQueryShortFormatDate(searchProject.createdAt) },
    { title: 'Visibility', detail: searchProject.privacyScope?.title },
    { title: 'Last Bulk Sent On', detail: getQueryShortFormatDate(uiState.lastBulkSentDate) }
  ];

  const conditionalActions = !searchProject.isArchived
    ? {
        menuItems: [
          {
            title: actionsCopies.moreOptions.menuItems.edit,
            visible: uiState.managementPermissions?.searchProject?.canEdit,
            action: handleOpenUpdateSPForm
          },
          {
            title: actionsCopies.moreOptions.menuItems.split,
            visible: uiState.managementPermissions?.searchProject?.canSplit,
            action: () => setUiState(prev => ({ ...prev, isSplitsFormOpen: true }))
          },
          {
            title: actionsCopies.moreOptions.menuItems.manageCollabs,
            visible: uiState.managementPermissions?.canManageCollaborations,
            action: togglers.collaborationsDrawer({ open: true })
          },
          {
            title: actionsCopies.moreOptions.menuItems.historyLog,
            visible: uiState.managementPermissions?.searchProject?.canViewHistoryLog,
            action: togglers.activityLogDrawer({ open: true })
          },
          {
            title: isArchiveFeatureActive ? actionsCopies.moreOptions.menuItems.archive : 'Delete',
            visible: uiState.managementPermissions?.searchProject?.canArchive,
            action: togglers.archiveDialog({ open: true })
          },
          {
            title: actionsCopies.moreOptions.menuItems.extractCompaniesContacts,
            visible:
              hasFeatureFlag(FeatureFlags.SearchProjectsExtractCompaniesContacts) &&
              uiState.totalCompanies > 0,
            action: searchProjectTogglers.searchProjectForm({
              open: true,
              shouldExtractCompanyContacts: true
            })
          }
        ],
        titleEditButton: uiState.managementPermissions?.searchProject?.canEdit
          ? {
              icon: SvgEdit,
              iconProps: {
                onSetColor: palette => palette.primary.main
              },
              tooltipProps: {
                title: actionsCopies.editIconButton.title
              },
              size: 'sm',
              onClick: handleOpenUpdateSPForm,
              variant: 'bold'
            }
          : null
      }
    : null;

  return (
    <>
      <FiltersLayout
        title={searchProject.name}
        section={EntityType.SearchProjectPreview}
        filters={filters}
        disableDateRange
        sectionName="Search Projects"
        selectorGridProps={{ md: 9, lg: 9 }}
        titleLabelProps={{
          actionProps: conditionalActions?.titleEditButton,
          backNavigation: true,
          loading: isLoading
        }}
        listSelector={
          <SearchProjectsTitleDetails
            avatarsToShow={avatarsToShow}
            canManageCollaborations={uiState.managementPermissions?.canManageCollaborations}
            canViewHistoryLog={uiState.managementPermissions?.searchProject?.canViewHistoryLog}
            collaborators={collaborators}
            searchProject={searchProject}
            onOpenActivityDrawer={togglers.activityLogDrawer({ open: true })}
            onOpenCollaborationsDrawer={togglers.collaborationsDrawer({ open: true })}
          />
        }
        HeaderActionComponent={
          <SearchProjectsHeaderAction
            isSearchProjectArchived={searchProject.isArchived}
            menuItems={conditionalActions?.menuItems}
            onOpenRestoreForm={togglers[
              isRestoreDrawerFeatureActive ? 'restoreDrawer' : 'restoreDialog'
            ]({ open: true })}
          />
        }
        isSideMenuOpen={isSideMenuOpen}
        isLoading={isLoading || uiState.isLoadingSearchProjectInfo}
        groups={FiltersGroups}
        enableStore={false}
        onSearch={handleFiltersApply}
        onFiltersChange={handleFiltersChange}
        onMenuToggle={handleFiltersToggle}
      >
        <DataTable
          isExpandable
          loading={showWholeSkeleton || uiState.isLoadingSearchProjectInfo}
          refreshing={isLoading}
          data={data}
          columns={finalColumns}
          count={count}
          sortOrder={{ name: orderBy, direction }}
          page={page}
          filter={false}
          enableCustomFilters
          rowsPerPage={perPage}
          searchText={keyword}
          draggableColumns={{
            enabled: true
          }}
          columnOrder={columnOrder?.length ? columnOrder : undefined}
          onColumnDisplayClick={handleColumnDisplayChange}
          onColumnOrderChange={handleColumnOrderChange}
          onSearchTextChange={handleKeywordChange}
          onColumnSortChange={handleColumnSortChange}
          onPerPageClick={handlePerPageChange}
          onPageClick={handlePageChange}
          onToggleFilters={handleFiltersToggle}
          onRowSelectionChange={handleRowSelection}
          selectableRows={shouldAllowItemsSelection ? 'multiple' : 'none'}
          rowsSelected={selectedIndexesInPage}
          selectToolbarPlacement="none"
          CustomToolbar={
            totalSelected > 0 ? (
              <CustomTableToolbarSelect total={totalSelected}>
                <ActionButtons />
              </CustomTableToolbarSelect>
            ) : (
              <When condition={!searchProject.isArchived}>
                <div>
                  <BulkEmailButton disabled={!enableBulkButton} />
                </div>
              </When>
            )
          }
          components={multiSelectComponents}
        />
      </FiltersLayout>
      <SearchProjectForms searchProject={searchProject} />
      <When condition={entityId && quickViewState.isQuickViewOpen}>
        <FPQuickView
          drawerProps={{
            open: quickViewState.isQuickViewOpen
          }}
          onClose={toggleQuickViewPreview}
          entityType={quickViewEntity}
          itemsToNavigate={data}
          quickViewEntityRoleProps={{ roleKey: 'item_search_project_type' }}
          id={entityId}
        />
      </When>
      <When condition={uiState.isBulkDrawerOpen}>
        <BulkEmailDrawer
          selectedItems={filteredItems}
          queryParams={queryParams}
          onCloseDrawer={handleOnCloseBulkDrawer}
          onSetSelectionToInitial={setSelectionToInitial}
          onDiscardChanges={() => {
            dispatch(setBulkEmailToInitial());
            dispatch(startRemovingAllAttachments());
            setUiState(prev => ({ ...prev, isBulkDrawerOpen: false }));
          }}
          searchProject={searchProject}
        />
      </When>
      <When condition={showActivityLogDrawer}>
        <ActivityLogDrawer
          open={uiState.isActivityDrawerOpen}
          onClose={togglers.activityLogDrawer({ open: false })}
          searchProjectId={searchProjectId}
        />
      </When>
      <When condition={showCollabsDrawer}>
        <CollaborationsForm
          searchProject={searchProject}
          initCollabs={collaborators}
          onClose={togglers.collaborationsDrawer({ open: false })}
          onSetCollaborators={handleSetCollaborators}
          open={uiState.isCollaborationFormOpen}
        />
      </When>
      <When condition={uiState.isSplitsFormOpen}>
        <SplitsForm
          SearchProject={{
            isPrivate: searchProject.isPrivate,
            originalTotalItems: uiState.originalTotalItems,
            searchProjectId,
            searchProjectName: searchProject.name
          }}
          onClose={() => setUiState(prev => ({ ...prev, isSplitsFormOpen: false }))}
        />
      </When>
      <When condition={uiState.isArchiveDialogOpen && isArchiveFeatureActive}>
        <DecisionDialog
          {...archiveSearchProjectDialogCopies.dialog}
          message={strings.formatString(archiveSearchProjectDialogCopies.dialog.message, {
            searchProjectName: searchProject.name,
            availabilityDays: ARCHIVE_AVAILABILITY_DAYS.searchProject
          })}
          fetching={uiState.fetchingDialog}
          withButtons="YesNo"
          onConfirm={handleArchiveSearchProject}
          onClose={togglers.archiveDialog({ open: false })}
        />
      </When>
      <When condition={isRestoreDrawerFeatureActive && uiState.isRestoreDrawerOpen}>
        <RestoreSearchProjectDrawer
          onClose={togglers.restoreDrawer({ open: false })}
          onRestore={() => getSPSummarizedInfo(searchProjectId)}
          searchProject={searchProject}
        />
      </When>
      <When condition={uiState.isRestoreDialogOpen && isArchiveFeatureActive}>
        <RestoreSearchProjectDialog
          fetchingDialog={uiState.fetchingDialog}
          onCloseDialog={togglers.restoreDialog({ open: false })}
          onFetchingDialog={fetchingDialog => setUiState(prev => ({ ...prev, fetchingDialog }))}
          onSuccess={() => getSPSummarizedInfo(searchProjectId)}
          searchProject={searchProject}
        />
      </When>
      <When condition={uiState.isArchiveDialogOpen && !isArchiveFeatureActive}>
        <DetailedConfirmationDialog
          decisionDialogProps={{
            ...archiveSearchProjectDialogCopies.deleteDialog,
            fetching: uiState.fetchingDialog,
            changeButtonsOrder: true,
            isHighLightActionOnLeft: true,
            onConfirm: handleArchiveSearchProject,
            onClose: togglers.archiveDialog({ open: false })
          }}
          details={deleteDialogSections}
        />
      </When>
    </>
  );
};

export default SearchProjectPreview;
