export const DAILY_PLAN_BASE_URL = '/daily-plans';

export const ENDPOINTS = {
  // Task types
  taskTypes: `${DAILY_PLAN_BASE_URL}/tasks/types`,

  callTypes: `${DAILY_PLAN_BASE_URL}/call-types`,
  outcomes: `${DAILY_PLAN_BASE_URL}/outcomes`,
  taskStatuses: `${DAILY_PLAN_BASE_URL}/statuses`,

  tasks: `${DAILY_PLAN_BASE_URL}/tasks`
};
