import React, { useState } from 'react';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import { Alert } from '@material-ui/lab';
import { HTTPStatusCodes } from 'constants/httpStatusCodes';
import { Loader } from 'features/command-center/components/shared/Widget/Loader';
import strings from 'strings';
import { FPCard, FPCardContent } from 'UI/components/atoms/FPCard';

import { useStyles } from './Applicantloader.styles';

const ERROR_MESSAGES = {
  [HTTPStatusCodes.Forbidden]: strings.candidates.creation.applicant.fetch.error.forbidden,
  [HTTPStatusCodes.NotFound]: strings.candidates.creation.applicant.fetch.error.notFound,
  [HTTPStatusCodes.InternalServerError]:
    strings.candidates.creation.applicant.fetch.error.serverError,
  default: strings.candidates.creation.applicant.fetch.error.serverError
};

const getErrorMessage = statusCode => ERROR_MESSAGES[statusCode] || ERROR_MESSAGES.default;

export const ApplicantLoader = ({ state }) => {
  const classes = useStyles();
  const [showAlert, setShowAlert] = useState(true);

  const onRetry = () => window.location.reload();
  const onClose = () => setShowAlert(false);

  const stateContent = {
    loading: (
      <FPCard>
        <FPCardContent variant="relaxed">
          <Typography variant="body2">
            {strings.candidates.creation.applicant.fetch.loading}
          </Typography>
          <Loader />
        </FPCardContent>
      </FPCard>
    ),
    success: null,
    error: (
      <Alert
        onClose={state.responseStatusCode === HTTPStatusCodes.InternalServerError ? null : onClose}
        action={
          state.responseStatusCode === HTTPStatusCodes.InternalServerError ? (
            <Button color="inherit" size="small" variant="outlined" onClick={onRetry}>
              {strings.candidates.creation.applicant.fetch.tryAgain}
            </Button>
          ) : null
        }
        severity="error"
      >
        {getErrorMessage(state.responseStatusCode)}
      </Alert>
    )
  };

  if (!showAlert) {
    return null;
  }

  return <div className={classes.alertContainer}>{stateContent[state.status]}</div> || null;
};
