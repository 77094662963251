import React from 'react';
import { Chart } from 'react-google-charts';
import { calculateChartLabelInterval } from 'features/command-center/utils';

import { DEFAULT_OPTIONS } from './LineSteppedAreaChart.constants';

export const LineSteppedAreaChart = ({ chartEvents, data, options }) => {
  return (
    <Chart
      chartEvents={chartEvents}
      chartType="LineChart"
      data={data}
      height="320px"
      legendToggle
      options={{
        hAxis: {
          showTextEvery: calculateChartLabelInterval(data?.length)
        },
        ...DEFAULT_OPTIONS,
        ...options
      }}
      width="100%"
    />
  );
};
