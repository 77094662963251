import React, { useCallback, useMemo, useState } from 'react';
import { Chart } from 'react-google-charts';
import { DRAWER_KEYS, Widget } from 'features/command-center/components/shared';
import { DateRange } from 'features/command-center/components/shared/InlineFilters/components/DateRange';
import { COMMAND_CENTER_BASE_URL } from 'features/command-center/constants';
import { useDrawerManager } from 'features/command-center/contexts';
import { getCallTrendsHistory } from 'features/command-center/services';
import moment from 'moment';
import strings from 'strings';
import { additionalPeriodRanges } from 'UI/components/templates/SideFiltersLayout/filters';
import { DateFormats } from 'UI/constants/defaults';
import { createCustomStaticRanges, getCurrentWeekRange } from 'UI/utils';

import { DEFAULT_OPTIONS } from './ActivityTrends.constants';
import { formatCallsData, generateChartData, insertShadedData } from './ActivityTrends.utils';

const URL_INDEX_ACCORDING_VALUE = 1;

export const ActivityTrends = ({ requestParams = {}, hasDrillDown }) => {
  const [dateRange, setDateRange] = useState(() => getCurrentWeekRange());
  const { openDrawer } = useDrawerManager();
  const queryParams = useMemo(
    () => ({
      startDate: moment(dateRange.startDate).format(DateFormats.QueryFormat),
      endDate: moment(dateRange.endDate).format(DateFormats.QueryFormat),
      ...requestParams
    }),
    [dateRange.startDate, dateRange.endDate, requestParams]
  );

  const handleDateRangeChange = selectedDateRange => setDateRange(selectedDateRange);

  const promises = useCallback(() => {
    return Promise.all([getCallTrendsHistory(queryParams)]).then(([data]) => data);
  }, [queryParams]);

  const cachedPromises = useMemo(() => [promises], [promises]);

  return (
    <Widget header={strings.commandCenter.activity.activityTrends.title} promises={cachedPromises}>
      {([data]) => {
        const finalData = data?.data ?? data;
        const isEmpty = finalData.length === 0;
        const transformedData = formatCallsData(finalData);
        const chartData = generateChartData(transformedData);
        const shadedData = insertShadedData(chartData);

        const chartOptions = {
          ...DEFAULT_OPTIONS,
          vAxis: {
            ...DEFAULT_OPTIONS.vAxis,
            viewWindow: isEmpty ? { min: 1, max: 5 } : undefined,
            ticks: isEmpty ? [1, 2, 3, 4, 5] : undefined
          }
        };

        const handlePointClick = (clickedData, row, column) => {
          const url = clickedData[column + URL_INDEX_ACCORDING_VALUE];
          url &&
            typeof url === 'string' &&
            openDrawer({
              activeDrawer: DRAWER_KEYS.Calls,
              contentProps: { endpoint: `${COMMAND_CENTER_BASE_URL}${url}` }
            });
        };

        const chartEvents = [
          {
            eventName: 'select',
            callback: ({ chartWrapper }) => {
              const selection = chartWrapper.getChart().getSelection();
              if (selection.length === 0) return;

              const [selectedItem] = selection;
              const { row, column } = selectedItem;

              if (row !== undefined && column !== undefined) {
                const clickedData = shadedData[row + 1]; // +1 beacause the first row is headers
                handlePointClick(clickedData, row, column);
              }
            }
          }
        ];

        return (
          <>
            <DateRange
              DateRangerPickerProps={{
                staticRanges: createCustomStaticRanges(additionalPeriodRanges)
              }}
              defaultRange={dateRange}
              disableTimeRange
              onPeriodChange={handleDateRangeChange}
            />
            <Chart
              chartEvents={hasDrillDown && chartEvents}
              chartType="ComboChart"
              data={shadedData}
              options={chartOptions}
              width="100%"
              height="400px"
              legendToggle
            />
          </>
        );
      }}
    </Widget>
  );
};
