import { makeStyles } from '@material-ui/core';

const LEFT_COLUMN_MIN_WIDTH = 350;
const BOTTOM_SPACING = 100;

export const useStyles = makeStyles(theme => ({
  contentLayout: {
    height: '100%',
    overflow: 'hidden',
    padding: theme.spacing(3, 0, 0, 0)
  },
  rootContainer: {
    overflow: 'hidden',
    height: `calc(100% - ${BOTTOM_SPACING}px)`
  },
  headerContainer: {
    borderTopRightRadius: 8,
    borderTopLeftRadius: 8,
    padding: theme.spacing(3, 0),
    backgroundColor: theme.palette.brandColors.FPWhite,
    borderBottom: `2px solid ${theme.palette.grey[400]}`
  },
  bodyContainer: {
    overflow: 'hidden',
    backgroundColor: theme.palette.brandColors.FPWhite
  },
  headerSearchBoxContainer: {
    minWidth: LEFT_COLUMN_MIN_WIDTH,
    padding: theme.spacing(0, 2.5),
    borderRight: `1px solid ${theme.palette.grey[400]}`
  },
  headerOptionsContainer: {
    padding: theme.spacing(0, 2.5)
  },
  tableContainer: {
    overflow: 'scroll',
    width: `calc(100% - ${LEFT_COLUMN_MIN_WIDTH}px)`
  },
  titleLabel: {
    marginLeft: theme.spacing(6)
  },
  inlineFilters: {
    padding: theme.spacing(3, 3, 0)
  }
}));
