import React, { useState } from 'react';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { Endpoints } from 'UI/constants/endpoints';
import { getId } from 'UI/utils';

import { useStyles } from '../styles';

import ExpandableRow, { Columns, SplitCell } from './ExpandableRow';
import { isColumnToHide } from './UserToolReport.constants';

const ExpandableRowButton = ({ item, status, onMouseDown }) => {
  const classes = useStyles();
  return (
    <span
      role="button"
      tabIndex={0}
      className={classes.expandableRowButton}
      key={item.team}
      onMouseDown={onMouseDown}
    >
      {status.expanded ? <ExpandMoreIcon /> : <ChevronRightIcon />}
    </span>
  );
};

const Row = ({ item, year, month, week, extraColumns, personStatus, hasGpacOrRegionalCoach }) => {
  const initialState = hasGpacOrRegionalCoach
    ? { coach: '', expanded: false }
    : { coach: item.coach, expanded: true };

  const [status, setStatus] = useState(initialState);

  const classes = useStyles();
  const isYearView = !!year?.date;
  const isGpacOrRegionalRow = ['gpac', 'regional'].includes(item.team);
  const finalExtraColumns = extraColumns?.extraColumnsInfo
    ? extraColumns.extraColumnsInfo.map(extraColumn => ({
        label: item[extraColumn.columnName],
        ...(extraColumn.labelDown && { labelDown: item[extraColumn.labelDown] }),
        name: item[extraColumn.columnLabel]
      }))
    : [];
  const tableColumns = isYearView
    ? [
        {
          label: item.team === 'regional' ? item.regional : item.team,
          className: classes.team
        },
        { label: item.january },
        { label: item.february },
        { label: item.march },
        { label: item.april },
        { label: item.may },
        { label: item.june },
        { label: item.july },
        { label: item.august },
        { label: item.september },
        { label: item.october },
        { label: item.november },
        { label: item.december },
        { label: item.total }
      ]
    : [
        {
          label: item.team === 'regional' ? item.regional : item.team,
          className: classes.team,
          name: Columns.Team
        },
        { label: item.calls, name: Columns.Calls },
        { label: item.inmails, name: Columns.InMails },
        { label: item.indeed, name: Columns.Indeed },
        { label: item.live_post, name: Columns.LivePost },
        { label: item.gpac_career, name: Columns.GpacCareer },
        { label: item.bulks_mo, labelDown: item.reached_mo, name: Columns.BulksMo },
        { label: item.bulks_tu, labelDown: item.reached_tu, name: Columns.BulksTu },
        { label: item.bulks_we, labelDown: item.reached_we, name: Columns.BulksWe },
        { label: item.bulks_th, labelDown: item.reached_th, name: Columns.BulksTh },
        { label: item.bulks_fr, labelDown: item.reached_fr, name: Columns.BulksFr },
        { label: item.bulks_sa, labelDown: item.reached_sa, name: Columns.BulksSa },
        { label: item.bulks_su, labelDown: item.reached_su, name: Columns.BulksSu },
        { label: item.bulks_total, labelDown: item.reached_total, name: Columns.BulksTotal },
        ...finalExtraColumns
      ];

  return (
    <>
      <tr className={item.team !== '' ? classes[item.team] : ''}>
        <td className={classes.expandableButton}>
          {!isGpacOrRegionalRow && (
            <ExpandableRowButton
              item={item}
              status={status}
              onMouseDown={() =>
                setStatus({
                  coach: item.team,
                  expanded: !status.expanded
                })
              }
            />
          )}
        </td>

        {tableColumns.map(
          column =>
            !isColumnToHide(column.name) && (
              <td key={getId()} className={column.className || classes.rowFontWeight}>
                <SplitCell column={column} header={false} />
              </td>
            )
        )}
      </tr>
      {status.expanded && (
        <ExpandableRow
          endpoint={Endpoints.ToolsUsageReportRecruiters}
          coach={status.coach}
          year={year}
          month={month}
          week={week}
          extraColumns={extraColumns}
          personStatus={personStatus}
        />
      )}
    </>
  );
};

export default Row;
