import strings from 'strings';
import { DateFormats } from 'UI/constants/defaults';
import { localTimeFormatter } from 'UI/utils';

export const formatResponse = response => {
  const formattedData = response.data.data.map(item => ({
    ...item,
    reachOutReasonTitle: item?.reachOutReason?.title ?? '',
    formattedAddressableIssues: !item?.addressableIssues
      ? []
      : item?.addressableIssues.map(addressableIssue => ({
          ...addressableIssue
        })),
    userId: item?.createdBy?.fortpacId,
    employeeFile: {
      saveInEmployeeFile: item?.saveInEmployeeFile,
      successfullySavedToEmployeeFile: item?.successfullySavedToEmployeeFile,
      lastSavedToEmployeeFileResult: item?.lastSavedToEmployeeFileResult
    }
  }));

  return { ...response, data: { ...response.data, data: formattedData } };
};

export const getEmployeeFileCopyForNote = note => {
  if (!note) return '';
  const { successfullySavedToEmployeeFile, lastSavedToEmployeeFileResult } = note;

  if (!lastSavedToEmployeeFileResult)
    return strings.commandCenter.feedbackNotes.savedInEmployeeFileAt.inProcess;

  return successfullySavedToEmployeeFile
    ? strings.formatString(strings.commandCenter.feedbackNotes.savedInEmployeeFileAt.success, {
        date: localTimeFormatter(lastSavedToEmployeeFileResult, DateFormats.SimpleDateTime)
      })
    : strings.commandCenter.feedbackNotes.savedInEmployeeFileAt.error;
};
