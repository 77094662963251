import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import Grid from '@material-ui/core/Grid';
import { Toolbar } from 'features/command-center/components/shared';
import { StatsCard } from 'features/command-center/components/shared/StatsCard';
import { CreationTaskDrawer } from 'features/daily-plan';
import { ENDPOINTS } from 'features/daily-plan/constants';
import useDatatable, {
  addMultipleColumns,
  addRingCentralContactToStore,
  buildMultiplePhonesActionsRenderer,
  extractObjectFromDataTable,
  getColumnPreferences,
  getColumnsToRender
} from 'hooks/datatable';
import usePageTitle from 'hooks/usePageTitle';
import { EntityRoutes } from 'routes/constants';
import strings from 'strings';
import FPActionButton from 'UI/components/atoms/FPActionButton';
import { When } from 'UI/components/atoms/When';
import DataTable from 'UI/components/organisms/DataTable';
import FiltersLayout from 'UI/components/templates/FiltersLayout';
import { additionalPeriodRanges } from 'UI/components/templates/SideFiltersLayout/filters';
import { backNavigateListConfig } from 'UI/constants/config';
import { PageTitles } from 'UI/constants/defaults';
import { SvgAdd } from 'UI/res';
import { createCustomStaticRanges, getCurrentWeekRange, getFinalColumns } from 'UI/utils';
import { DataTableLink } from 'UI/utils/renderers';

import {
  COLUMNS,
  EXTRA_FILTERS,
  FILTERS_DEFINITION,
  ORDER_BY_OPTIONS,
  PAGE_KEY
} from './DailyPlan.constants';
import { ActionContent } from './DailyPlan.styles';
import { formatResponse } from './DailyPlan.utils';

const COPIES = strings.dailyPlan;
const ICON_BUTTON_SIZE = 16;

const ContactRoutes = {
  hiringAuthority: EntityRoutes.HiringAuthorityProfile,
  contact: EntityRoutes.ContactProfile,
  candidate: EntityRoutes.CandidateProfile,
  company: EntityRoutes.CompanyProfile
};

const EXTRA_RENDERERS = {
  contactLink: value => {
    if (!value) return null;

    const { id, entityType, fullName } = value;
    const route = ContactRoutes[entityType];

    return id && route ? (
      <DataTableLink id={id} text={fullName} route={route} enableInTabRedirect />
    ) : null;
  }
};

export const DailyPlan = () => {
  const dispatch = useDispatch();
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);

  const handleAction = ({ rowData, origin }) => {
    const item = extractObjectFromDataTable(columns, ['contact'], rowData);
    const { entityType } = item?.contact;
    entityType &&
      addRingCentralContactToStore({
        rowData,
        columns: dailyPlanColumns,
        dispatch,
        role: entityType,
        origin
      });
  };

  const getContactInfo = ({ columns, data }) => {
    const { rowData } = data;
    const item = extractObjectFromDataTable(columns, ['id', 'contact'], rowData);
    return {
      name: item?.contact?.fullName,
      entityId: item?.contact?.id,
      entityType: item?.contact?.entityType
    };
  };

  const multiplePhonesActionButtons = buildMultiplePhonesActionsRenderer({
    customName: 'phoneNumbers',
    handleAction,
    columns: getFinalColumns(COLUMNS),
    getContactInfo
  });

  const dailyPlanColumns = addMultipleColumns(getFinalColumns(COLUMNS), [
    { column: multiplePhonesActionButtons, afterColumn: 'contactStatus' }
  ]).filter(column => !!column);

  const initialPreferences = getColumnPreferences(PAGE_KEY, 0, ORDER_BY_OPTIONS, dailyPlanColumns);

  const {
    columnPreferences,
    count,
    data,
    filters,
    handleColumnDisplayChange,
    handleColumnOrderChange,
    handleColumnSortChange,
    handleFiltersApply,
    handleFiltersChange,
    handleFiltersToggle,
    handleKeywordChange,
    handlePageChange,
    handlePeriodChange,
    handlePerPageChange,
    listState
  } = useDatatable({
    columnsDefinitions: dailyPlanColumns,
    endpoint: ENDPOINTS.tasks,
    formatResponse,
    initialPreferences,
    key: PAGE_KEY,
    orderByOptions: ORDER_BY_OPTIONS,
    periodDefaultValue: getCurrentWeekRange(),
    periodFilterColumn: 'date',
    shouldScrollOnNavigate: true
  });

  const { isLoading, showWholeSkeleton, isSideMenuOpen, page, perPage, keyword } = listState;
  const { columns, columnOrder, orderBy, direction } = columnPreferences;

  const finalColumns = getColumnsToRender(columns, EXTRA_RENDERERS);

  usePageTitle({ title: PageTitles.DailyPlan, isLoading });

  const handleCloseDrawer = () => {
    setIsDrawerOpen(false);
  };

  const handleOpenDrawer = () => {
    setIsDrawerOpen(true);
  };

  return (
    <FiltersLayout
      dateRangerPickerProps={{
        staticRanges: createCustomStaticRanges(additionalPeriodRanges)
      }}
      defaultRange={getCurrentWeekRange()}
      enableStore={false}
      extraFiltersDefinition={EXTRA_FILTERS}
      filters={filters}
      includeFilters={FILTERS_DEFINITION}
      isLoading={showWholeSkeleton}
      isSideMenuOpen={isSideMenuOpen}
      onFiltersChange={handleFiltersChange}
      onMenuToggle={handleFiltersToggle}
      onPeriodChange={handlePeriodChange}
      onSearch={handleFiltersApply}
      title={COPIES.title}
      titleLabelProps={backNavigateListConfig}
    >
      <Grid container spacing={4}>
        <ActionContent item xs={12}>
          <FPActionButton
            icon={SvgAdd}
            text={COPIES.actionButton}
            onClick={handleOpenDrawer}
            iconProps={{ family: 'mini', size: ICON_BUTTON_SIZE }}
          />
        </ActionContent>

        <Grid item xs={12}>
          <StatsCard header="Tasks" paddingContent={0} footer={null}>
            <DataTable
              columnOrder={columnOrder?.length && columnOrder}
              columns={finalColumns}
              components={{ TableToolbar: Toolbar }}
              count={count ?? 0}
              data={data}
              draggableColumns={{
                enabled: true
              }}
              elevation={0}
              filter={false}
              isExpandable
              loading={showWholeSkeleton}
              onColumnDisplayClick={handleColumnDisplayChange}
              onColumnOrderChange={handleColumnOrderChange}
              onColumnSortChange={handleColumnSortChange}
              onPageClick={handlePageChange}
              onPerPageClick={handlePerPageChange}
              onSearchTextChange={handleKeywordChange}
              onToggleFilters={handleFiltersToggle}
              page={page}
              refreshing={isLoading}
              rowsPerPage={perPage}
              searchText={keyword}
              selectableRows="none"
              selectToolbarPlacement="none"
              sortOrder={{ name: orderBy, direction }}
            />
          </StatsCard>
        </Grid>
      </Grid>

      <When condition={isDrawerOpen}>
        <CreationTaskDrawer onClose={handleCloseDrawer} />
      </When>
    </FiltersLayout>
  );
};
