import { makeStyles } from '@material-ui/core';
import { toolbarAndContentArea } from 'UI/constants/dimensions';
import { flexAlignCenterJustifyCenter } from 'UI/utils/styles';

export const useStyles = makeStyles(theme => ({
  board: {
    marginTop: theme.spacing(2),
    width: '100%',
    fontSize: '0.875rem',
    textAlign: 'left',
    fontFamily: theme.typography.fontFamilyContent,
    fontWeight: theme.typography?.fontWeightLight,
    lineHeight: 1.43,
    boxShadow: '0 2px 6px 0 rgba(47, 47, 47, 0.4)',
    borderCollapse: 'collapse',
    '& th': {
      height: '40.5px',
      fontWeight: theme.typography?.fontWeightMedium,
      textAlign: 'end',
      padding: '0 8px 0 8px'
    },
    '& tr': {
      height: '33px',
      fontWeight: theme.typography?.fontWeightLight,
      backgroundColor: theme.palette.customColors?.white
    },
    '& tr:nth-child(even)': {
      backgroundColor: `${theme.palette.grey[200]}`
    },
    '& td': {
      textAlign: 'end',
      padding: theme.spacing(0, 1)
    }
  },
  boardHead: {
    position: 'sticky',
    top: 0
  },
  boxCopyWrapper: {
    display: 'flex',
    alignItems: 'flex-start',
    marginBottom: theme.spacing(2),
    '& ul': {
      margin: theme.spacing(0, -3, 0)
    }
  },
  category: {
    background: `${theme.palette.grey[200]} !important`,
    textAlign: 'left !important',
    justifyContent: 'flex-start',
    fontWeight: theme.typography?.fontWeightBold
  },
  defaultWrapper: {
    height: '50vh',
    padding: theme.spacing(3),
    ...flexAlignCenterJustifyCenter
  },
  downloadFile: {
    marginTop: theme.spacing(1),
    gap: theme.spacing(1)
  },
  emptyCategory: {
    background: `${theme.palette.grey[200]} !important`
  },
  expandableButton: {
    padding: 0,
    width: 0
  },
  expandableLoadingRender: {
    padding: '0 !important',
    '& div': {
      background: theme.palette.customColors?.white
    }
  },
  expandableRowButton: {
    cursor: 'pointer',
    color: theme.palette.customColors?.darkGrey
  },
  expandableTableFontWeight: {
    fontWeight: `${theme.typography?.headerWeights?.light} !important`
  },
  expandibleTeam: {
    textAlign: 'left !important',
    width: '20%',
    fontWeight: theme.typography?.fontWeightLight
  },
  headerAction: {
    '& .MuiCardHeader-action': {
      display: 'flex',
      alignItems: 'center',
      margin: 'auto'
    }
  },
  headerLabelDown: {
    fontSize: theme.typography.fontSizeSmall
  },
  labelDown: {
    color: theme.palette.customColors?.darkGrey,
    fontSize: theme.typography.fontSizeSmall
  },
  lastUpdateLabel: {
    color: theme.palette.customColors?.darkGrey,
    fontStyle: 'italic',
    fontWeight: theme.typography?.fontWeightBold,
    margin: 'auto'
  },
  lastUpdateTime: {
    fontStyle: 'italic',
    fontWeight: 'normal'
  },

  loadingContainer: {
    ...flexAlignCenterJustifyCenter,
    height: `calc(100vh - ${toolbarAndContentArea}px)`
  },
  rate: {
    width: '140px'
  },
  rowFontWeight: {
    fontWeight: `${theme.typography?.headerWeights?.regular} !important`
  },
  team: {
    textAlign: 'left !important',
    width: '20%',
    fontWeight: theme.typography?.fontWeightMedium
  },
  title: {
    background: `${theme.palette.grey[400]} !important`
  },
  toolsUsageHeight: {
    height: '60px !important'
  },
  total: {
    width: '50px',
    background: `${theme.palette.grey[500]} !important`
  },
  totalTitle: {
    background: `${theme.palette.grey[500]} !important`
  },

  gpac: {
    background: `${theme.palette.brandColors?.FPRed} !important`,
    fontWeight: theme.typography?.fontWeightBold,
    color: `${theme.palette.brandColors?.FPWhite} !important`,
    '& div': {
      color: theme.palette.customColors?.white
    }
  },
  regional: {
    background: `${theme.palette.grey.A700} !important`,
    fontWeight: theme.typography?.fontWeightBold,
    color: theme.palette.brandColors?.FPWhite,
    '& div': {
      color: theme.palette.customColors?.white
    }
  }
}));
