// @flow
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { showAlert } from 'actions/app';
import { useGlobalStyles } from 'GlobalStyles';
import DraftsProvider from 'providers/DraftsProvider';
import queryString from 'query-string';
import strings from 'strings';
import { When } from 'UI/components/atoms/When';
import { FormFieldsMap } from 'UI/components/organisms/CandidateForm/fields';
import DraftsManager from 'UI/components/organisms/DraftsManager';
import ContentPageLayout from 'UI/components/templates/ContentPageLayout';
import { PageTitles } from 'UI/constants/defaults';
import { EntityType } from 'UI/constants/entityTypes';
import { UIStatus } from 'UI/constants/status';

import { ApplicantLoader } from './ApplicantLoader';
import NewCandidateContent from './NewCandidateContent';
import { useNewCandidateFromUrl } from './NewCandidateContent.hooks';

const draftRequiredFields = [
  FormFieldsMap.FirstName.key,
  FormFieldsMap.LastName.key,
  FormFieldsMap.Email.key
];

export type NewCandidateProps = {
  history: any,
  location: any
};

const NewCandidate = (props: NewCandidateProps) => {
  const { location } = props;
  const dispatch = useDispatch();
  const { hiringId, contactId, applicantId } = queryString.parse(location.search);
  const isFromProfile = !!hiringId || !!contactId;

  const [selectedDraft, setSelectedDraft] = useState(null);

  const globalClasses = useGlobalStyles();

  const { state: applicantState, applicantFormData } = useNewCandidateFromUrl({ applicantId });

  const isApplicantDataLoading = applicantState.status === UIStatus.Loading;

  useEffect(() => {
    document.title = PageTitles.CandidateCreate;
  }, []);

  useEffect(() => {
    if (!selectedDraft?.formData) return;

    if (!selectedDraft?.formData?.email)
      dispatch(
        showAlert({
          severity: 'error',
          ...strings.candidates.creation.candidateWithoutEmail
        })
      );
  }, [dispatch, selectedDraft]);

  return (
    <ContentPageLayout
      text={
        isFromProfile
          ? strings.candidates.creation.titleFromDirectory
          : strings.candidates.creation.title
      }
      titleLabelProps={{ backNavigation: true }}
    >
      <div className={globalClasses.itemCreationDataWrapper}>
        <DraftsProvider type={EntityType.Candidate} draftValidationFields={draftRequiredFields}>
          <>
            <When condition={applicantState.status !== UIStatus.Default}>
              <ApplicantLoader state={applicantState} />
            </When>
            <When condition={!isFromProfile && !isApplicantDataLoading}>
              <DraftsManager type={EntityType.Candidate} onDraftSelected={setSelectedDraft} />
            </When>
            <When condition={!isApplicantDataLoading}>
              <NewCandidateContent
                key={selectedDraft?.id || 0}
                isFromProfile={isFromProfile}
                hiringId={hiringId}
                contactId={contactId}
                initialApplicantData={applicantFormData}
                setSelectedDraft={setSelectedDraft}
                {...props}
              />
            </When>
          </>
        </DraftsProvider>
      </div>
    </ContentPageLayout>
  );
};

export default NewCandidate;
