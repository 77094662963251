// @flow
import React, { useEffect, useMemo } from 'react';
import { useFormContext } from 'react-hook-form';
import Box from '@material-ui/core/Box';
import Divider from '@material-ui/core/Divider';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormGroup from '@material-ui/core/FormGroup';
import Switch from '@material-ui/core/Switch';
import Typography from '@material-ui/core/Typography';
import isNil from 'lodash/isNil';
import { getCurrentUser } from 'services/Authentication';
import { FeeAgreementRoleHierarchy, getHighestUserRoleInHierarchy } from 'services/Authorization';
import { useFetchGuaranteeOptions } from 'services/FeeAgreement';
import strings from 'strings';
import FPHint from 'UI/components/atoms/FPHint';
import Text from 'UI/components/atoms/Text';
import TextBox from 'UI/components/atoms/TextBox';
import AutocompleteSelect from 'UI/components/molecules/AutocompleteSelect';
import EmailPicker from 'UI/components/molecules/EmailPicker';
import FPRadioGroup from 'UI/components/molecules/FPRadioGroup';
import FeeAgreementPaymentTerms from 'UI/components/organisms/feeagreements/FeeAgreementPaymentTerms';
import { FeeAgreementDefaultValues } from 'UI/constants/defaults';
import { Endpoints } from 'UI/constants/endpoints';
import { EntityType, PaymentMode, PaymentModes } from 'UI/constants/entityTypes';
import { FeatureFlags } from 'UI/constants/featureFlags';
import { Roles } from 'UI/constants/roles';
import { FeeAgreementMaxCopies } from 'UI/constants/status';
import {
  hasFeatureFlag,
  idOptionSelected,
  REGULAR_PERCENT_VALIDATION,
  REQUIRED_VALIDATION
} from 'UI/utils/index';

import { useStyles } from './style';

type ManagedFeeAgreementProps = {
  hiringAuthorities: Array<any>,
  jobOrders: Array<any>,
  company: any,
  defaultValues: any
};

const onlyTwoValidDecimals = '33';
const areNumberOfDecimalsValid = (percent: number) => {
  const isInteger = Math.floor(percent) === percent;
  if (isInteger) return true;

  const decimalPortion = percent.toString().split('.')[1];
  if (!decimalPortion || decimalPortion === onlyTwoValidDecimals) return true;

  return decimalPortion.length <= 1;
};

const areSplitPaymentsEnabled = hasFeatureFlag(FeatureFlags.FeeAgreementsSplitPayments);

const rolesThatCanCreateOnBehalf = [
  Roles.Operations,
  Roles.Coach,
  Roles.AssistantRegionalDirector,
  Roles.RegionalDirector,
  Roles.Leadership
];

const ManagedFeeAgreement = (props: ManagedFeeAgreementProps) => {
  const { hiringAuthorities, jobOrders, company, defaultValues } = props;
  const classes = useStyles(props);
  const guaranteeOptions = useFetchGuaranteeOptions();

  const currentUser = getCurrentUser();
  const highestRole = getHighestUserRoleInHierarchy(currentUser, FeeAgreementRoleHierarchy);

  const form = useFormContext();
  const { register, unregister, errors, setValue, watch, getValues, triggerValidation } = form;

  const fieldsConfig = useMemo(
    () => [
      {
        name: 'fee_agreement_payment_scheme_id',
        validation: REQUIRED_VALIDATION
      },
      {
        name: 'hiringAuthority',
        validation: REQUIRED_VALIDATION
      },
      {
        name: 'fee_percentage_change_requested'
      },
      {
        name: 'verbiage_changes_requested'
      },
      {
        name: 'guarantee_days_change_requested'
      },
      {
        name: 'split_payment_requested'
      },
      {
        name: 'cc_emails'
      },
      {
        name: 'job_orders'
      },
      {
        name: 'fee_percentage',
        validation: {
          validate(val) {
            const payMode = getValues('fee_agreement_payment_scheme_id');
            const percentage = Number.parseFloat(val);
            const areDecimalsValid = areNumberOfDecimalsValid(percentage);

            if (payMode === PaymentMode.Flat) return true;

            const isOutOfBounds =
              percentage < REGULAR_PERCENT_VALIDATION.min.value ||
              percentage > REGULAR_PERCENT_VALIDATION.max.value;
            if (isOutOfBounds) {
              return REGULAR_PERCENT_VALIDATION.min.message;
            }

            if (!areDecimalsValid) {
              return strings.feeAgreements.creation.invalidDecimals;
            }

            return true;
          }
        }
      },
      {
        name: 'flat_fee_amount',
        type: 'custom',
        validation: {
          validate(val) {
            const payMode = getValues('fee_agreement_payment_scheme_id');
            const flatFee = Number.parseFloat(val);
            return (
              payMode !== PaymentMode.Flat ||
              (payMode === PaymentMode.Flat && flatFee) ||
              REQUIRED_VALIDATION.required
            );
          }
        }
      },
      {
        name: 'overridden_user_id'
      }
    ],
    [getValues]
  );

  useEffect(() => {
    fieldsConfig.forEach(({ name, type, validation }) => {
      register({ name, type }, validation);
    });

    return () => unregister(fieldsConfig.map(({ name }) => name));
  }, [register, unregister, fieldsConfig]);

  useEffect(() => {
    register(
      { name: 'guarantee_days' },
      {
        validate(val) {
          const payMode = getValues('fee_agreement_payment_scheme_id');
          const guaranteeDays = Number.parseFloat(val);
          return (
            payMode === PaymentMode.Conversion ||
            (guaranteeOptions[payMode] && guaranteeOptions[payMode].indexOf(guaranteeDays) > -1) ||
            REQUIRED_VALIDATION.required
          );
        }
      }
    );
    return () => unregister('guarantee_days');
  }, [register, getValues, unregister, guaranteeOptions]);

  const formValues = watch(undefined, defaultValues);
  const {
    fee_agreement_payment_scheme_id: paymentMode,
    split_payment_requested: splitPaymentRequest = null
  } = formValues;
  const feePercentage = Number.parseFloat(formValues.fee_percentage);

  const handleFieldChange = (field: string, value: any) => setValue(field, value, true);
  const handleToggleCheck = (event: any, value?: any) => {
    const {
      target: { name, id }
    } = event;

    setValue(id, defaultValues[id], true);
    setValue(name, value, true);
  };

  const handleRadioChange = ({ target }) => {
    setValue(target.name, target.value);
    triggerValidation();
  };

  const areNotesValid = notes => {
    const thereAreNotes = !!notes;
    const percentChangeRequested = formValues.fee_percentage_change_requested;
    const otherChangesRequested =
      formValues.verbiage_changes_requested || formValues.guarantee_days_change_requested;
    const changesRequested = percentChangeRequested || otherChangesRequested;

    const onlyPercentChangeIsRequested = !otherChangesRequested && percentChangeRequested;

    const validWhenStandard =
      (onlyPercentChangeIsRequested &&
        ((feePercentage < FeeAgreementDefaultValues.FeePercent && thereAreNotes) ||
          feePercentage >= FeeAgreementDefaultValues.FeePercent)) ||
      (otherChangesRequested && thereAreNotes);
    const validWhenFlat = (otherChangesRequested && thereAreNotes) || !otherChangesRequested;
    const validWhenConversion =
      (paymentMode === PaymentMode.Conversion &&
        formValues.verbiage_changes_requested &&
        thereAreNotes) ||
      !formValues.verbiage_changes_requested;

    return (
      !changesRequested ||
      ((paymentMode === PaymentMode.Standard || paymentMode === PaymentMode.BaseSalary) &&
        validWhenStandard) ||
      (paymentMode === PaymentMode.Flat && validWhenFlat) ||
      (paymentMode === PaymentMode.Conversion && validWhenConversion)
    );
  };

  const enableOnBehalf = rolesThatCanCreateOnBehalf.includes(highestRole?.id);
  const onBehalfEndpoint = Endpoints.FeeAgreementOverridableUsers;

  const requiresLaterTemplateCreation =
    highestRole?.id === Roles.Operations && formValues.verbiage_changes_requested;
  const shouldShowPaymentTerms =
    paymentMode === PaymentMode.Standard || paymentMode === PaymentMode.BaseSalary;
  const showGuarantee = paymentMode !== PaymentMode.Conversion && !splitPaymentRequest;
  return (
    <>
      {enableOnBehalf && (
        <>
          <Typography>{strings.feeAgreements.creation.onBehalf}</Typography>
          <AutocompleteSelect
            width="100%"
            name="overridden_user_id"
            displayKey="full_name"
            placeholder={strings.feeAgreements.creation.selectRecruiter}
            url={onBehalfEndpoint}
            selectedValue={formValues.overridden_user_id || null}
            error={!!errors.overridden_user_id}
            errorText={errors.overridden_user_id && errors.overridden_user_id.message}
            onSelect={handleFieldChange}
          />
        </>
      )}
      <FPRadioGroup
        name="fee_agreement_payment_scheme_id"
        label={strings.feeAgreements.fields.type}
        labelVariant="subtitle1"
        options={PaymentModes}
        value={paymentMode}
        onChange={handleRadioChange}
      />
      {(paymentMode === PaymentMode.Standard ||
        paymentMode === PaymentMode.Conversion ||
        paymentMode === PaymentMode.BaseSalary) && (
        <Box className={classes.fieldGroup}>
          <FormGroup aria-label="position" row>
            <FormControlLabel
              value="end"
              control={
                <Switch
                  id="fee_percentage"
                  name="fee_percentage_change_requested"
                  onChange={handleToggleCheck}
                  checked={formValues.fee_percentage_change_requested}
                  color="primary"
                />
              }
              label={strings.feeAgreements.creation.requestFeeChange}
              labelPlacement="end"
              className={classes.tightBottom}
            />
          </FormGroup>
          <TextBox
            name="fee_percentage"
            label={`${strings.feeAgreements.creation.feePercent} *`}
            value={formValues.fee_percentage}
            error={!!errors.fee_percentage}
            errorText={errors.fee_percentage && errors.fee_percentage.message}
            defaultValue={defaultValues.fee_percentage}
            onChange={handleFieldChange}
            inputType="percentage"
            disabled={!formValues.fee_percentage_change_requested}
          />
        </Box>
      )}
      {paymentMode === PaymentMode.Flat && (
        <Box className={classes.fieldGroup}>
          <TextBox
            name="flat_fee_amount"
            label={`${strings.feeAgreements.creation.flatAmount} *`}
            value={formValues.flat_fee_amount}
            error={!!errors.flat_fee_amount}
            errorText={errors.flat_fee_amount && errors.flat_fee_amount.message}
            defaultValue={defaultValues.flat_fee_amount}
            onChange={handleFieldChange}
            inputType="currency"
          />
        </Box>
      )}
      {areSplitPaymentsEnabled && shouldShowPaymentTerms && (
        <Box className={classes.fieldGroup}>
          <FormGroup aria-label="position" row>
            <FormControlLabel
              value="end"
              control={
                <Switch
                  id="split_payment"
                  name="split_payment_requested"
                  onChange={handleToggleCheck}
                  checked={splitPaymentRequest}
                  color="primary"
                />
              }
              label={strings.feeAgreements.creation.requestPaymentTerm}
              labelPlacement="end"
              className={classes.tightBottom}
            />
          </FormGroup>
          {splitPaymentRequest && <FeeAgreementPaymentTerms />}
        </Box>
      )}
      {showGuarantee && (
        <Box className={classes.fieldGroup}>
          <FormGroup aria-label="position" row>
            <FormControlLabel
              value="end"
              control={
                <Switch
                  id="guarantee_days"
                  name="guarantee_days_change_requested"
                  onChange={handleToggleCheck}
                  checked={formValues.guarantee_days_change_requested}
                  color="primary"
                />
              }
              label={strings.feeAgreements.creation.requestGuaranteeChange}
              labelPlacement="end"
              className={classes.tightBottom}
            />
          </FormGroup>
          <AutocompleteSelect
            width="100%"
            name="guarantee_days"
            placeholder={`${strings.feeAgreements.creation.guaranteeDays} *`}
            disableClearable
            selectedValue={
              !isNil(formValues.guarantee_days)
                ? formValues.guarantee_days
                : FeeAgreementDefaultValues.GuaranteeDays
            }
            error={!!errors.guarantee_days}
            errorText={errors.guarantee_days && errors.guarantee_days.message}
            defaultOptions={guaranteeOptions[paymentMode] || []}
            getOptionLabel={option => `${option}`}
            getOptionSelected={(option, value) => option === value}
            onSelect={handleFieldChange}
            disabled={!formValues.guarantee_days_change_requested}
          />
        </Box>
      )}

      <Box className={classes.fieldGroup}>
        <FormGroup aria-label="position" row>
          <FormControlLabel
            value="end"
            control={
              <Switch
                id="verbiage_changes"
                name="verbiage_changes_requested"
                onChange={handleToggleCheck}
                checked={formValues.verbiage_changes_requested}
                color="primary"
              />
            }
            label={strings.feeAgreements.creation.requestVerbiageChange}
            labelPlacement="end"
            className={classes.switchContainer}
          />
        </FormGroup>
        {formValues.verbiage_changes_requested && (
          <TextBox
            name="verbiage_changes"
            label={strings.feeAgreements.creation.verbiageChanges}
            error={!!errors.verbiage_changes}
            errorText={errors.verbiage_changes && errors.verbiage_changes.message}
            multiline
            inputRef={register({
              validate(value) {
                return (
                  !formValues.verbiage_changes_requested ||
                  (formValues.verbiage_changes_requested && !!value) ||
                  strings.feeAgreements.creation.verbiageValidation
                );
              }
            })}
          />
        )}
      </Box>

      <Divider className={classes.divider} />
      {company && (
        <div className={classes.groupWithHint}>
          <TextBox
            outPutValue
            name="company"
            label={strings.shared.fields.company}
            error={!!errors.company}
            errorText={errors.company && errors.company.message}
            value={company?.name}
          />
          <TextBox
            name="company_legal_name"
            label={`${strings.companies.creation.fields.legalName} *`}
            error={!!errors.company_legal_name}
            errorText={errors.company_legal_name?.message}
            inputRef={register(REQUIRED_VALIDATION)}
          />
          <FPHint
            variant="warning"
            size="md"
            description={strings.feeAgreements.creation.legalNameWarning}
            containerClassName={classes.tightMargin}
          />
        </div>
      )}
      <div className={classes.groupWithHint}>
        <AutocompleteSelect
          width="100%"
          name="hiringAuthority"
          displayKey="full_name"
          placeholder={`${strings.shared.fields.hiringAuthority} *`}
          selectedValue={formValues.hiringAuthority || null}
          getOptionSelected={(option, value) => option.work_email === value.work_email}
          renderOption={option => (
            <div>
              <strong>{option.full_name}</strong>
              <Typography variant="body2" component="span" color="textSecondary">
                {` ${option.work_email || ''}`}
              </Typography>
            </div>
          )}
          error={!!errors.hiringAuthority}
          defaultOptions={hiringAuthorities}
          errorText={errors.hiringAuthority && errors.hiringAuthority.message}
          onSelect={handleFieldChange}
          className={classes.tightBottom}
        />
        <FPHint
          variant="warning"
          size="md"
          description={strings.feeAgreements.creation.hiringAuthorityHelper}
          containerClassName={classes.tightMargin}
        />
      </div>
      <div className={classes.groupWithHint}>
        <EmailPicker
          name="cc_emails"
          placeholder={strings.formatString(
            strings.feeAgreements.creation.emailsPlaceholder,
            FeeAgreementMaxCopies
          )}
          selectedValue={formValues.cc_emails}
          error={!!errors.cc_emails}
          errorText={errors.cc_emails && errors.cc_emails.message}
          endpoint={Endpoints.Search}
          typeaheadParams={{
            entityType: EntityType.HiringAuthority,
            inColumns: ['ha.work_email']
          }}
          maxItems={FeeAgreementMaxCopies}
          allowNewItems
          onSelect={handleFieldChange}
          className={classes.tightBottom}
        />
        <FPHint
          variant="warning"
          size="md"
          description={strings.feeAgreements.creation.emailsHelper}
          containerClassName={classes.tightMargin}
        />
      </div>

      <div className={classes.fieldGroup}>
        <AutocompleteSelect
          width="100%"
          name="job_orders"
          placeholder={strings.feeAgreements.creation.relatedJobOrders}
          multiple
          selectedValue={formValues.job_orders || []}
          getOptionSelected={idOptionSelected}
          getOptionLabel={option =>
            `${option.title} / ${option.address?.city?.title}, ${option.address?.city?.state?.slug}`
          }
          groupBy={jo =>
            jo.whiteSheet?.company_fee_agreement_id
              ? strings.feeAgreements.creation.relatedJobOrdersWithFA
              : strings.feeAgreements.creation.relatedJobOrdersWithoutFA
          }
          error={!!errors.job_orders}
          defaultOptions={jobOrders}
          errorText={errors.job_orders && errors.job_orders.message}
          onSelect={handleFieldChange}
          className={classes.tightBottom}
        />
        <FPHint
          variant="warning"
          size="md"
          description={strings.feeAgreements.creation.relatedJobOrdersHelper}
          containerClassName={classes.tightMargin}
        />
      </div>
      <Divider className={classes.divider} />
      <Box className={classes.notesContainer}>
        <Text variant="subtitle1" text={strings.feeAgreements.creation.notesFromRecruiter} />
        <Typography variant="body1" className={classes.noteWarning} color="error">
          {strings.feeAgreements.creation.notesWarning}
        </Typography>
        <TextBox
          name="notes"
          label={strings.feeAgreements.creation.notesFromRecruiter}
          error={!!errors.notes}
          errorText={errors.notes && errors.notes.message}
          multiline
          inputRef={register({
            validate(value) {
              return areNotesValid(value) || strings.feeAgreements.creation.notesValidation;
            }
          })}
        />
        <FPHint
          variant="warning"
          size="md"
          description={strings.feeAgreements.creation.notes}
          containerClassName={classes.tightMargin}
        />
      </Box>
      {requiresLaterTemplateCreation && (
        <>
          <Divider className={classes.divider} />
          <div>
            <FPHint variant="warning" size="md" className={classes.hint} />
            {strings.feeAgreements.creation.verbiageWhenOnbehalf}
          </div>
        </>
      )}
    </>
  );
};

export default ManagedFeeAgreement;
