import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles(theme => ({
  formContainer: {
    padding: theme.spacing(3),
    boxShadow: '1px 3px 7px 0px #0000000f',
    backgroundColor: theme.palette.background.paper,
    marginBottom: theme.spacing(2)
  },
  subtitle: {
    fontWeight: theme.typography.fontWeightBold
  }
}));
