import moment from 'moment';
import { DateFormats } from 'UI/constants/defaults';

export const formatResponse = response => {
  const formattedData = response.data.data.map(item => ({
    ...item,
    phoneNumbers: {
      phone: item?.phone,
      phones: item?.phones
    },
    taskStatus: {
      id: item?.taskStatusId,
      name: item?.taskStatus
    },
    company: {
      id: item?.companyId ?? null,
      name: item?.company ?? null
    },
    contact: {
      entityType: item?.entityType ?? null,
      fullName: item?.contactName ?? null,
      id: item?.contactId ?? item?.entityId ?? null
    },
    date: item?.startDatetime
      ? moment(item?.startDatetime).format(
          item?.startDatetimeIsOnlyDate ? DateFormats.MonthDayYear : DateFormats.MonthDayYearTime
        )
      : null
  }));

  return { ...response, data: { ...response.data, data: formattedData } };
};
