// @flow
import React from 'react';
import Grid from '@material-ui/core/Grid';
import AutocompleteSelect from 'UI/components/molecules/AutocompleteSelect';
import { getGridColumnSize } from 'UI/constants/dimensions';
import { Endpoints } from 'UI/constants/endpoints';
import { idOptionSelected, industrySpecialtyOptionLabel, titleOptionLabel } from 'UI/utils';
import { buildBasePropsForField } from 'UI/utils/forms';

import { IndustryFormFieldsMap } from './fields';
import { useIndustryFields } from './IndustryFields.hooks';

type IndustryFieldsProps = {
  isEditing?: boolean,
  disabled?: boolean,
  onValuesChanged: () => void,
  loading?: boolean
};

const IndustryFields = ({
  isEditing = false,
  disabled = false,
  onValuesChanged,
  loading
}: IndustryFieldsProps) => {
  const { formValues, errors, handleSubSpecialtiesLoaded, handleOnSelect } = useIndustryFields({
    onValuesChanged
  });

  const subspecialtyUrl = formValues[IndustryFormFieldsMap.Specialty.key]
    ? Endpoints.Subspecialties.replace(':id', formValues[IndustryFormFieldsMap.Specialty.key].id)
    : '';

  const functionalTitleUrl = formValues[IndustryFormFieldsMap.Specialty.key]
    ? `${Endpoints.Positions}?specialtyId=${formValues[IndustryFormFieldsMap.Specialty.key].id}`
    : '';

  const { sm, xs, md, lg } = getGridColumnSize(isEditing);

  return (
    <>
      <Grid item xs={xs} sm={sm} md={md} lg={lg}>
        <AutocompleteSelect
          {...buildBasePropsForField(IndustryFormFieldsMap.Specialty.key, errors)}
          selectedValue={formValues[IndustryFormFieldsMap.Specialty.key]}
          placeholder="Industry: Specialty *"
          url={Endpoints.Specialties}
          groupBy={option => option.industry_title}
          getOptionLabel={industrySpecialtyOptionLabel}
          renderOption={titleOptionLabel}
          getOptionSelected={idOptionSelected}
          onSelect={handleOnSelect}
          disabled={disabled}
          fetching={loading}
        />
      </Grid>
      <Grid item xs={xs} sm={sm} md={md} lg={lg}>
        <AutocompleteSelect
          {...buildBasePropsForField(IndustryFormFieldsMap.Subspecialty.key, errors)}
          selectedValue={formValues[IndustryFormFieldsMap.Subspecialty.key]}
          placeholder={`Subspecialty ${
            formValues[IndustryFormFieldsMap.HasSubSpecialties.key] ? '*' : ''
          }`}
          url={subspecialtyUrl}
          onSelect={handleOnSelect}
          onOptionsLoaded={handleSubSpecialtiesLoaded}
          disabled={disabled}
          fetching={loading}
        />
      </Grid>
      <Grid item xs={xs} sm={sm} md={md} lg={lg}>
        <AutocompleteSelect
          {...buildBasePropsForField(IndustryFormFieldsMap.FunctionalTitle.key, errors)}
          selectedValue={formValues[IndustryFormFieldsMap.FunctionalTitle.key]}
          placeholder="Functional Title *"
          url={functionalTitleUrl}
          onSelect={handleOnSelect}
          disabled={disabled}
          fetching={loading}
        />
      </Grid>
    </>
  );
};

export default IndustryFields;
