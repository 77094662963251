export const navBarHeight = 68;
export const searchBarWrapper = 581;
export const sideBarWidth = 240;
export const sideBarWidthCollapsed = 60;
export const sideFiltersWidth = 400;
export const sideFiltersWidthCollapsed = 220;
export const versionBarHeight = 58;
export const toolbarAndContentArea = 174;
export const mainLayoutPadding = 6;
export const topBarIconSize = 26;
export const letterSpacing = 0.8;

export const sidebarItem = {
  height: 63,
  padding: 16,
  paddingSubmenu: 40,
  iconSize: 20
};

export const addMenu = {
  addButton: {
    width: 200,
    widthCollapsed: 40,
    height: 40,
    iconSize: 16
  },

  menuItem: {
    width: 240,
    height: 44
  }
};

export const layout = {
  maxWidth: 1920,
  mainPageContentWidth: {
    maxWidth: 1548
  },
  modalMiddleSize: {
    maxWidth: 1167
  },
  sideBarWidth,
  sideBarWidthCollapsed,
  sideFiltersWidth,
  sideFiltersWidthCollapsed
};

export const input = {
  borderRadius: 8,
  height: 40
};

export const icon = {
  default: 24,
  s: 22,
  xs: 18
};

// New items pages
export const newItemPadding = 2;
export const minWidthTextBox = 192;

// Margin constants m= margin y= vertical x= horizontal
export const mapAutocompletePadding = '2px 0';
export const middlePadding = '12px 0';

export const m24 = 24;
export const mx24 = '0 24px';
export const my24 = '24px 0';

export const m32 = 32;
export const mx32 = '0 32px';
export const my32 = '32px 0';

export const container = { boxShadow: '0px 1px 8px 0px rgba(0, 0, 0, 0.08)' };

export const skeletonRadius = 30;

export const inventorySectionHeader = 110;

// Responsive Values

export const responsiveWidthViewPort = 'max-width: 1366px';
export const responsiveHeightViewPort = 'max-height: 768px';
export const fullWidthViewPort = 'min-width: 1920px';
export const fullHeightViewPort = 'min-height: 1080px';

export const profileLayoutBreakPoint = 'max-width: 1400px';

export const modalWidth = 1417;

export const verticalMenuLayout = {
  maxContent: 'max-content',
  fixed: '100%'
};

const sharedSize = {
  sm: 16,
  md: 24,
  lg: 32
};

/** Design system */
export const iconSizes = {
  streamline: {
    ...sharedSize,
    mini: {
      sm: 12,
      md: 16,
      lg: 24
    },
    bold: {
      ...sharedSize
    },
    regular: {
      ...sharedSize
    }
  },
  custom: {
    sm: 16,
    md: 20,
    lg: 24
  },
  iconButon: {
    sm: 32,
    md: 44
  }
};

export const avatarMeasures = {
  small: 32,
  medium: 40,
  large: 56,
  fontRatio: 3.25
};

export const componentDimensions = {
  drawer: {
    width: 650
  },
  emptyState: {
    width: 350
  },
  fileDraggable: {
    width: 530
  }
};

export const inputContainerSize = 270;
export const AutoWidthGrid = { xs: 'auto', sm: 'auto', md: 'auto' };
export const SharedFullGridLayout = { sm: 12, md: 12, xs: 12 };

export const ExpandedDrawerWidths = {
  largeViewports: 1200,
  smallViewports: '85%'
};

export const gridDimensions = {
  maxContainer: 1080,
  editionDrawer: {
    md: 6,
    sm: 6,
    xs: 6,
    lg: 6
  },
  itemCreation: {
    lg: 3,
    md: 3,
    sm: 6,
    xs: 12
  }
};

export const FORM_SPACING = 4;

export const getGridColumnSize = isEditing =>
  isEditing ? gridDimensions.editionDrawer : gridDimensions.itemCreation;
