import React from 'react';
import strings from 'strings';
import ContentPageLayout from 'UI/components/templates/ContentPageLayout';

export const NewCandidatesFromResume = () => {
  return (
    <ContentPageLayout
      text={strings.candidates.creation.fromResume.title}
      titleLabelProps={{ backNavigation: true }}
    />
  );
};
