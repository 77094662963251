import { getColumnsToHide } from '../utils';

export const statusAll = 'All';

const columnsToHideSetting = `${
  window.GPAC_ENV?.TOOLS_USAGE_REPORT_COLUMNS_HIDE ||
  process.env.REACT_APP_TOOLS_USAGE_REPORT_COLUMNS_HIDE
}`;

export const columnsToHideInUserToolReport = getColumnsToHide(columnsToHideSetting);

export const isColumnToHide = columnLabel => {
  return columnsToHideInUserToolReport.includes(columnLabel);
};
