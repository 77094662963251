import { TABLES_KEYS } from 'features/command-center/constants';
import { ENDPOINTS as ENDPOINTS_DAILY_PLAN } from 'features/daily-plan/constants';
import { EntityRoutes } from 'routes/constants';
import strings from 'strings';
import { DateFormats, FilterType } from 'UI/constants/defaults';
import { Endpoints } from 'UI/constants/endpoints';
import { Groupers, LabelRenderers, OptionRenderers, Selectors } from 'UI/utils/renderers';

const COPIES = strings.dailyPlan;

export const PAGE_KEY = TABLES_KEYS.dailyPlan;

export const FILTERS_DEFINITION = [
  'taskTypes',
  'taskStatuses',
  'callTypes',
  'outcomes',
  'entityTypes',
  'contactStatusIds',
  'companyTypes'
];

export const COLUMNS = [
  {
    name: 'id',
    options: {
      filter: false,
      display: 'excluded'
    }
  },
  {
    name: 'roleId',
    options: {
      filter: false,
      display: 'excluded'
    }
  },
  {
    name: 'taskStatus',
    label: 'Task Status',
    options: {
      filter: true,
      display: true,
      renderer: 'taskStatus'
    }
  },
  {
    name: 'taskType',
    label: 'Task',
    options: {
      filter: true,
      display: true,
      setCellHeaderProps: () => ({
        style: { minWidth: 150 }
      })
    }
  },
  {
    name: 'date',
    label: 'Date & Time',
    options: {
      filter: true,
      display: true
    }
  },
  {
    name: 'contact',
    label: 'Reach Out to',
    options: {
      sort: true,
      display: true,
      renderer: 'contactLink'
    }
  },
  {
    name: 'entity',
    label: 'Contact Type',
    options: {
      filter: true,
      display: true
    }
  },
  {
    name: 'contactStatus',
    label: 'Contact Status',
    options: {
      sort: true,
      display: true
    }
  },
  {
    name: 'candidateType',
    label: 'Candidate Type',
    options: {
      sort: true,
      display: true
    }
  },
  {
    name: 'companyType',
    label: 'Company Type',
    options: {
      sort: true,
      display: true
    }
  },
  {
    name: 'title',
    label: 'Title',
    options: {
      sort: true,
      display: true
    }
  },
  {
    name: 'company',
    label: 'Company',
    options: {
      sort: true,
      display: true,
      renderer: {
        id: 'linkBuilder',
        config: {
          route: EntityRoutes.CompanyProfile,
          idKey: 'id',
          textKey: 'name',
          enableInTabRedirect: true
        }
      }
    }
  },

  {
    name: 'callType',
    label: 'Call Type',
    options: {
      sort: true,
      display: true
    }
  },
  {
    name: 'outcome',
    label: 'Outcome',
    options: {
      sort: true,
      display: true
    }
  },
  {
    name: 'lastCommentBody',
    label: 'Comments',
    options: {
      sort: true,
      display: true
    }
  },
  {
    name: 'createdAt',
    label: 'Created on',
    options: {
      sort: true,
      display: true,
      renderer: {
        id: 'dateBuilder',
        config: { format: DateFormats.MonthDayYearTime }
      }
    }
  },
  {
    name: 'updatedAt',
    label: 'Last Updated on',
    options: {
      sort: true,
      display: true,
      renderer: {
        id: 'dateBuilder',
        config: { format: DateFormats.MonthDayYearTime }
      }
    }
  },
  {
    name: 'lastCommentOn',
    label: 'Last Comment on',
    options: {
      sort: true,
      display: true,
      renderer: {
        id: 'dateBuilder',
        config: { format: DateFormats.MonthDayYearTime }
      }
    }
  }
];

export const EXTRA_FILTERS = [
  {
    name: 'taskTypes',
    paramName: 'taskTypeIds',
    title: COPIES.filters.taskTypes,
    type: FilterType.Autocomplete,
    url: ENDPOINTS_DAILY_PLAN.taskTypes,
    multiple: true
  },
  {
    name: 'taskStatuses',
    paramName: 'taskStatusIds',
    title: COPIES.filters.taskStatus,
    type: FilterType.Autocomplete,
    url: ENDPOINTS_DAILY_PLAN.taskStatuses,
    multiple: true
  },
  {
    name: 'callTypes',
    paramName: 'callTypeIds',
    title: COPIES.filters.callType,
    type: FilterType.Autocomplete,
    url: ENDPOINTS_DAILY_PLAN.callTypes,
    multiple: true
  },
  {
    name: 'outcomes',
    paramName: 'outcomeIds',
    title: COPIES.filters.outcome,
    type: FilterType.Autocomplete,
    url: ENDPOINTS_DAILY_PLAN.outcomes,
    multiple: true
  },
  {
    name: 'entityTypes',
    paramName: 'entityTypeIds',
    title: COPIES.filters.contactType,
    type: FilterType.Autocomplete,
    url: `${Endpoints.EntityTypes}?orderBy=title&direction=asc&relatedEntities=dailyPlanTasks`,
    multiple: true
  },
  {
    name: 'contactStatusIds',
    paramName: 'contactStatusIds',
    title: 'Contact Status',
    multiple: true,
    type: FilterType.Autocomplete,
    groupBy: Groupers.byRole,
    url: Endpoints.NameStatus,
    renderOption: OptionRenderers.title,
    getOptionLabel: LabelRenderers.contactStatus,
    getOptionSelected: Selectors.byId
  }
];

export const ORDER_BY_OPTIONS = {
  column: 'id',
  direction: 'asc'
};
