import React, { useCallback, useMemo, useState } from 'react';
import { Chart } from 'react-google-charts';
import { DRAWER_KEYS, Widget } from 'features/command-center';
import { COMMAND_CENTER_BASE_URL } from 'features/command-center/constants';
import { useDrawerManager } from 'features/command-center/contexts';
import { getInventorySummaryByType } from 'features/command-center/services';
import { calculateChartLabelInterval } from 'features/command-center/utils';
import moment from 'moment';
import strings from 'strings';
import { additionalPeriodRanges } from 'UI/components/templates/SideFiltersLayout/filters';
import { DateFormats } from 'UI/constants/defaults';
import { createCustomStaticRanges, getCurrentWeekRange } from 'UI/utils';

import { DateRange } from '../../shared/InlineFilters/components/DateRange';

import {
  CHART_DATA_CONFIG_BY_ENTITY,
  GROUP_BY,
  METRICS,
  PIL_GROWTH_CHART_DEFAULT_OPTIONS,
  ROUTE_PARAM
} from './InventoryAdded.constants';
import {
  calculateVerticalAxisTicks,
  formatInventoryLineChartResponse,
  getChartColors
} from './InventoryAdded.utils';

const URL_INDEX_ACCORDING_VALUE = 1;

export const InventoryAdded = ({ requestParams = {}, entity, hasDrillDown }) => {
  const [dateRange, setDateRange] = useState(() => getCurrentWeekRange());
  const { openDrawer } = useDrawerManager();

  const queryDateRange = useMemo(
    () => ({
      startDate: moment(dateRange.startDate).format(DateFormats.QueryFormat),
      endDate: moment(dateRange.endDate).format(DateFormats.QueryFormat)
    }),
    [dateRange.startDate, dateRange.endDate]
  );

  const handleDateRangeChange = selectedDateRange => setDateRange(selectedDateRange);

  const title = strings.formatString(strings.commandCenter.activity.inventoryAdded, {
    entity: entity.title
  });

  const promises = useCallback(() => {
    return Promise.all([
      getInventorySummaryByType(
        {
          groupBy: GROUP_BY[entity.id],
          metrics: METRICS[entity.id],
          ...queryDateRange,
          ...requestParams
        },
        ROUTE_PARAM[entity.id]
      )
    ]).then(([data]) => data);
  }, [entity, queryDateRange, requestParams]);

  const cachedPromises = useMemo(() => [promises], [promises]);

  const renderChart = useCallback(
    ([response]) => {
      const data = formatInventoryLineChartResponse(
        response,
        CHART_DATA_CONFIG_BY_ENTITY[entity.id]
      );

      const handlePointClick = (clickedData, row, column) => {
        const url = clickedData[column + URL_INDEX_ACCORDING_VALUE];
        const drawerKey = entity?.title && entity.title.replace(' ', '');

        drawerKey &&
          openDrawer({
            activeDrawer: DRAWER_KEYS[drawerKey].Overall ?? DRAWER_KEYS[drawerKey].Total,
            contentProps: { endpoint: `${COMMAND_CENTER_BASE_URL}${url}` }
          });
      };

      const chartEvents = [
        {
          eventName: 'select',
          callback: ({ chartWrapper }) => {
            const selection = chartWrapper.getChart().getSelection();
            if (selection.length === 0) return;

            const [selectedItem] = selection;
            const { row, column } = selectedItem;

            if (row !== undefined && column !== undefined) {
              const clickedData = data[row + 1]; // +1 beacause the first row is headers
              handlePointClick(clickedData, row, column);
            }
          }
        }
      ];

      const vAxisThicks = calculateVerticalAxisTicks(data);

      const chartOptions = {
        ...PIL_GROWTH_CHART_DEFAULT_OPTIONS,
        lineWidth: 2,
        pointSize: 0,
        vAxis: {
          ...PIL_GROWTH_CHART_DEFAULT_OPTIONS.vAxis,
          ticks: vAxisThicks
        },
        hAxis: {
          showTextEvery: calculateChartLabelInterval(data?.length)
        },
        colors: getChartColors(entity.id)
      };

      return (
        <>
          <DateRange
            DateRangerPickerProps={{
              staticRanges: createCustomStaticRanges(additionalPeriodRanges)
            }}
            defaultRange={dateRange}
            disableTimeRange
            onPeriodChange={handleDateRangeChange}
          />
          <Chart
            chartEvents={hasDrillDown && chartEvents}
            chartType="LineChart"
            data={data}
            height="400px"
            options={chartOptions}
            width="100%"
          />
        </>
      );
    },
    [dateRange, entity.id, entity.title, hasDrillDown, openDrawer]
  );

  return (
    <Widget header={title} promises={cachedPromises}>
      {renderChart}
    </Widget>
  );
};
