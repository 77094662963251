// @flow
import React, { useEffect, useState } from 'react';
import { connect, useDispatch } from 'react-redux';
import { Box, FormControl, FormControlLabel, Tooltip } from '@material-ui/core';
// MATERIAL
// ACTIONS - TYPES
import { confirm as confirmAction, showAlert } from 'actions/app';
import { getOptOuts, removeOptOut } from 'actions/optin';
// SERVICES
import { removeFromOptOut } from 'services/bulkEmail';
import type { Filters } from 'types/app';
import FPActionButton from 'UI/components/atoms/FPActionButton';
import AutocompleteSelect from 'UI/components/molecules/AutocompleteSelect';
import {
  ScrollableDataTable,
  ScrollableDataTableLayout
} from 'UI/components/templates/ScrollableDataTableLayout';
// COMPONENTS
// UTILS
import { Endpoints } from 'UI/constants/endpoints';
import { OptInEmptyState } from 'UI/res';

import DialogBody from './DialogBody';
import { styles } from './style';

// STYLES

// TYPES
type OptInOptOutListProps = {
  isLoading: boolean,
  optins: Array,
  total: number,
  onAddEmailToOptOut: any => void,
  onGetOptOuts: any => void,
  showConfirm: any => void
};

const OptInOptOutList = (props: OptInOptOutListProps) => {
  const { isLoading, optins, total, onAddEmailToOptOut, onGetOptOuts, showConfirm } = props;
  const dispatch = useDispatch();

  const [filters, setFilters] = useState<Filters>({});
  const [uiState, setUiState] = useState({
    userFilter: 0,
    keyword: '',
    orderBy: '',
    direction: '',
    page: 0,
    perPage: 10,
    reasonId: null
  });

  useEffect(() => {
    const { created, reasons } = filters;
    const params = {
      ...uiState,
      page: uiState.page + 1,
      createdBy: created ? created.id : null,
      reasonId: reasons?.id
    };

    onGetOptOuts && onGetOptOuts(params);
  }, [filters, uiState, onGetOptOuts]);

  const handleSearchChange = newKeyword => {
    setUiState(prevState => ({
      ...prevState,
      page: 0,
      keyword: newKeyword
    }));
  };

  const handleFilterChange = (name, value) => {
    setFilters({ ...filters, [name]: value });
    setUiState(prevState => ({
      ...prevState,
      page: 0
    }));
  };

  const handleColumnSortChange = ({ orderBy, direction }) => {
    setUiState(prevState => ({
      ...prevState,
      page: 0,
      orderBy,
      direction
    }));
  };

  const handlePerPageClick = newPerPage => {
    setUiState(prevState => ({
      ...prevState,
      page: 0,
      perPage: newPerPage
    }));
  };

  const handlePageClick = newPage => {
    setUiState(prevState => ({
      ...prevState,
      page: newPage
    }));
  };

  const handleResetFiltersClick = () => setFilters({});

  const handleRemoveFromOptOut = async ({ id, is_unsubscribe, email }) => {
    const response = await removeFromOptOut(id, is_unsubscribe);
    if (response.success) {
      dispatch(removeOptOut({ ...uiState, page: uiState.page + 1 }));
      dispatch(
        showAlert({
          severity: 'success',
          title: 'Email Opted-In Successfully',
          body: `${email} will receive bulks again`
        })
      );
    } else {
      dispatch(showAlert(response.alert));
    }
  };

  const handleOptIn = rowIndex => () => {
    const item = optins[rowIndex];
    showConfirm({
      severity: 'warning',
      title: 'Restore email',
      confirmButtonText: 'Restore',
      cancelButtonText: 'Cancel',
      withButtons: 'YesNo',
      isHighLightActionOnLeft: true,
      changeButtonsOrder: true,
      message: `Are you sure you want this email back in the Opt In Database?`,
      onConfirm: async ok => {
        ok && (await handleRemoveFromOptOut(item));
      },
      body: <DialogBody itemToRestore={item} />
    });
  };

  const columns = [
    {
      name: 'id',
      options: {
        filter: false,
        sort: false,
        print: false,
        display: 'excluded'
      }
    },
    {
      name: 'email',
      label: 'Email',
      options: {
        filter: false,
        sort: true,
        setCellProps: () => {
          return {
            style: { fontWeight: 'bold !important' }
          };
        }
      }
    },
    {
      name: 'reason_for_the_user',
      label: 'Reasons',
      options: {
        filter: true,
        sort: false,
        display: false,
        filterType: 'custom',
        filterOptions: {
          display: () => (
            <FormControl>
              <AutocompleteSelect
                name="reasons"
                placeholder="Reason for Unsubscribing"
                displayKey="description"
                url={`${Endpoints.BulkEmailsOptOutReasons}?all_reasons=true`}
                selectedValue={filters.reasons}
                onSelect={handleFilterChange}
                style={styles.autocompleteFilters}
              />
            </FormControl>
          )
        },
        customBodyRender: value => {
          return (
            <Tooltip title={value} arrow placement="top">
              <span style={styles.title}>{value}</span>
            </Tooltip>
          );
        }
      }
    },
    {
      name: 'created_by_name',
      label: 'Added by',
      options: {
        filter: true,
        sort: true,
        display: false,
        filterType: 'custom',
        filterOptions: {
          display: () => (
            <FormControl>
              <AutocompleteSelect
                name="created"
                placeholder="Added by"
                displayKey="full_name"
                url={`${Endpoints.BulkEmailsOptOuts}/recruiters`}
                selectedValue={filters.created}
                onSelect={handleFilterChange}
                style={styles.autocompleteFilters}
              />
            </FormControl>
          )
        }
      }
    },
    {
      name: 'actions',
      label: 'Actions',
      options: {
        filter: false,
        sort: false,
        customBodyRender: (_, value) => (
          <FormControlLabel
            value={value}
            control={
              <FPActionButton
                text="Opt In"
                variant="outlined"
                style={styles.minActionButton}
                onClick={handleOptIn(value.rowIndex)}
              />
            }
          />
        )
      }
    }
  ];

  return (
    <ScrollableDataTableLayout
      topContent={
        <Box style={styles.actionButtonList} data-testid="opt-in-opt-out-layout">
          <FPActionButton size="small" text="Add Email" onClick={onAddEmailToOptOut} />
        </Box>
      }
      content={
        <ScrollableDataTable
          loading={isLoading}
          data={optins}
          columns={columns}
          count={total}
          page={uiState.page}
          rowsPerPage={uiState.perPage}
          searchText={uiState.keyword}
          sortOrder={{ name: uiState.orderBy, direction: uiState.direction }}
          onResetfiltersClick={handleResetFiltersClick}
          onSearchTextChange={handleSearchChange}
          onColumnSortChange={handleColumnSortChange}
          onPerPageClick={handlePerPageClick}
          onPageClick={handlePageClick}
          selectableRows="none"
          title="No opt-outs"
          subtitle="The opt-outed items will apper here"
          customEmptyState={<OptInEmptyState />}
        />
      }
    />
  );
};

const mapStateToProps = ({ optIn }) => {
  return {
    isLoading: optIn.ui.isLoading,
    optins: optIn.domain.optins,
    total: optIn.domain.total
  };
};

const mapDispatchToProps = dispatch => {
  return {
    onGetOptOuts: params => dispatch(getOptOuts(params)),
    showConfirm: confirmation => dispatch(confirmAction(confirmation))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(OptInOptOutList);
