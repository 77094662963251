export const OPTION_ALL = 'All';
export const PREPEND_OPTIONS_ALL = [{ full_name: OPTION_ALL }];

export const INLINE_FILTERS_KEYS = Object.freeze({
  dominate: {
    hotInventory: 'dominate-hot-inventory',
    interviews: 'dominate-interviews',
    outstandingBillings: 'dominate-outstanding-billings',
    followUpTasks: 'dominate-follow-up-tasks'
  },
  activity: {
    overallPerformance: 'activity-overall-performance',
    overallPerformanceByTeam: 'activity-overall-performance-by-team',
    recruiterGlobal: 'activity-recruiter-global',
    recruiterCandidates: 'activity-recruiter-candidates',
    recruiterCompanies: 'activity-recruiter-companies',
    recruiterHiringAuthorities: 'activity-recruiter-hiring-authorities',
    recruiterProspects: 'activity-recruiter-prospects',
    notRegisteredCalls: 'not-registered-calls'
  },
  dailyPerformance: {
    overallPerformanceReport: 'daily-performance-overall-report'
  }
});

export const TABLES_KEYS = Object.freeze({
  dailyPlan: 'daily-plan',
  drawerCalls: 'drawer-calls',
  drawerCallsRelatedCompanies: 'drawer-calls-related-companies',
  drawerCallsRelatedContacts: 'drawer-calls-related-contacts',
  drawerCandidates: 'drawer-candidates',
  drawerCompanies: 'drawer-companies',
  drawerHiringAuthorities: 'drawer-hiring-authorities',
  drawerJobOrders: 'drawer-job-orders',
  drawerNames: 'drawer-names',
  drawerPlacements: 'drawer-placements',
  drawerSendouts: 'drawer-sendouts',
  drawerSendovers: 'drawer-sendovers',
  drawerSentAgreements: 'drawer-sent-agreements',
  drawerSignedAgreements: 'drawer-signed-agreements',
  feedbackLogSupportActivity: 'feedback-log-support-activity',
  feedbackNotes: 'feedback-notes',
  followUpTasksUnconvertedSendovers: 'dominate-follow-up-tasks-unconverted-sendovers',
  followUpTasksUnsignedAgreements: 'dominate-follow-up-tasks-unsigned-agreements',
  healthDistributionStats: 'health-distribution-stats',
  overallPerformance: 'overall-performance',
  overallPerformanceReport: 'daily-performance-overall-report'
});

export const GLOBAL_SELECTORS_KEYS = Object.freeze({
  dominate: {
    regional: 'dominate-sheet-selector-regional',
    coach: 'dominate-sheet-selector-coach'
  }
});
