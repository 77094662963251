// @flow
import { makeRequest } from 'services/utils';
import strings from 'strings';
import type { Bulk, OperationResult } from 'types/app';
import { Endpoints } from 'UI/constants/endpoints';

const { BulkEmails } = Endpoints;

const { success: successCopies, errors: errorCopies } = strings.bulkEmails;

export const sendBulkEmail = async (bulk: Bulk): Promise<OperationResult> =>
  makeRequest({
    url: BulkEmails,
    data: bulk,
    method: 'post',
    alertConfig: {
      success: bulk.is_draft ? successCopies.sendBulkDraft : successCopies.sendBulkEmail,
      error: bulk.is_draft ? errorCopies.sendBulkDraft : errorCopies.sendBulkEmail
    }
  });
