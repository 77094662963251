import { DEFAULT_COUNTRY_CODE } from 'features/message-center/constants';
import startCase from 'lodash/startCase';
import strings from 'strings';
import { InfoLabelModes } from 'UI/components/molecules/InfoLabel';
import { DateFormats, EMAIL_VALIDATION_STATUSES } from 'UI/constants/defaults';
import { AutoWidthGrid } from 'UI/constants/dimensions';
import { EntityType } from 'UI/constants/entityTypes';
import { FeatureFlags } from 'UI/constants/featureFlags';
import { SvgStar } from 'UI/res';
import { hasFeatureFlag, localTimeFormatter, phoneNumberFormatter } from 'UI/utils';

import { PHONE_TYPE_MAP } from '../PhoneNumbersForm/utils';

import { getEmailValidationIcon } from './commons';

const { primaryText: riskyCopy, secondaryText: emailValidationCopy } =
  strings.inventoryProfiles.common.emailValidationStatus.alert.riskyCopy;

const EMAIL_VALIDATION_STATUS_SUPPORT_URLS = {
  RISKY:
    window.GPAC_ENV?.EMAIL_VALIDATION_RISKY_SUPPORT_URL ||
    process.env.REACT_APP_EMAIL_VALIDATION_RISKY_SUPPORT_URL,
  INVALID:
    window.GPAC_ENV?.EMAIL_VALIDATION_INVALID_SUPPORT_URL ||
    process.env.REACT_APP_EMAIL_VALIDATION_INVALID_SUPPORT_URL,
  OPT_OUT:
    window.GPAC_ENV?.EMAIL_VALIDATION_OPT_OUT_SUPPORT_URL ||
    process.env.REACT_APP_EMAIL_VALIDATION_OPT_OUT_SUPPORT_URL
};

const EMAIL_VALIDATION_COPIES = {
  RISKY:
    window.GPAC_ENV?.EMAIL_VALIDATION_RISKY_CONTEXT_COPY ||
    process.env.REACT_APP_EMAIL_VALIDATION_RISKY_CONTEXT_COPY ||
    riskyCopy,
  SHARED_CONTEXT:
    window.GPAC_ENV?.EMAIL_VALIDATION_SHARED_CONTEXT_COPY ||
    process.env.REACT_APP_EMAIL_VALIDATION_SHARED_CONTEXT_COPY ||
    emailValidationCopy
};

const SEVERITY = {
  Error: 'error',
  Warning: 'warning'
};

export const handleWriteUpActions = async ({ onRefreshData, onWriteUpClose }) => {
  onWriteUpClose();
  await onRefreshData(false);
};

export const extractFullName = (profile, entityType) => {
  if (entityType === EntityType.Company) {
    return profile?.name;
  }

  if (
    entityType !== EntityType.Candidate &&
    entityType !== EntityType.Contact &&
    entityType !== EntityType.HiringAuthority
  ) {
    return null;
  }

  const fullName =
    entityType === EntityType.HiringAuthority
      ? profile.full_name
      : profile.personalInformation?.full_name;

  return fullName;
};

export const validateCountryCodeDisplay = countryCode =>
  countryCode && countryCode !== DEFAULT_COUNTRY_CODE ? countryCode : '';

export const getPhonesLabelsForProfile = (profile, entityType) => {
  if (!profile.phones || !profile.phones.length) return [];

  const labels = profile.phones.map(item => ({
    title: `${item.use} Phone`,
    description: item.phone.extension ? `ext: ${item.phone.extension}` : '',
    secondaryText: `${PHONE_TYPE_MAP[item.type_id].title}`,
    mode: 'ringCentral',
    isDefaultPhone: item.id === profile.phone_id,
    Icon: item.id === profile.phone_id && profile.phones.length > 1 ? SvgStar : null,
    linkProps: {
      url:
        `${validateCountryCodeDisplay(item.phone.country_code)} ${phoneNumberFormatter(
          item.phone.phone
        )}` ?? '',
      id: profile?.id,
      role: entityType,
      customLinkProps: {
        profileName: extractFullName(profile, entityType),
        countryCode: item.phone.country_code,
        phoneNumber: item.phone.phone
      }
    }
  }));

  const defaultPhone = labels.find(item => item.isDefaultPhone);
  const orderedLabels = [defaultPhone, ...labels.filter(item => !item.isDefaultPhone)];

  return orderedLabels;
};

export const getOptOutDate = results => {
  const optOutDate = results?.optOutStatus?.optOut
    ? localTimeFormatter(results?.optOutStatus?.createdAt, DateFormats.SimpleDateTime)
    : '';

  return { optOutDate };
};

export const getEmailValidationAlerts = ({ profile, loading }) => {
  if (!profile || Array.isArray(profile)) return [];

  const firstEmailVerdict = profile?.emailValidation;
  const secondEmailVerdict = profile?.personalEmailValidation;

  const emailAlert = getAlert({
    validationObj: firstEmailVerdict,
    isMainEmail: true,
    loading
  });

  let otherEmailAlert = null;
  if (hasFeatureFlag(FeatureFlags.OtherEmailValidationProfiles)) {
    otherEmailAlert = getAlert({
      validationObj: secondEmailVerdict,
      isMainEmail: false,
      loading
    });
  }

  const optoutAlert = getOptOutAlert({ profile, loading });

  return [optoutAlert, emailAlert, otherEmailAlert].filter(alert => alert);
};

const getOptOutAlert = ({ profile, loading }) => {
  const optOutAlert = profile?.opt_out_reason
    ? {
        key: `optOut ${SEVERITY.Error}`,
        title: 'Email Opt-out',
        loading,
        primaryText: `Reason: ${profile.opt_out_reason}`,
        buttonUrl: EMAIL_VALIDATION_STATUS_SUPPORT_URLS.OPT_OUT,
        severity: SEVERITY.Error
      }
    : null;

  return optOutAlert;
};

const getAlert = ({ validationObj, isMainEmail, loading }) => {
  if (!validationObj) return null;
  const isValid = validationObj.verdict?.name === EMAIL_VALIDATION_STATUSES.VALID;

  const emailTypeString = isMainEmail
    ? strings.shared.fields.email
    : startCase(strings.shared.fields.otherEmail);

  const emailValidationAlertConfig = {
    risky: {
      severity: SEVERITY.Warning,
      supportUrl: EMAIL_VALIDATION_STATUS_SUPPORT_URLS.RISKY,
      primaryText: validationObj.reason || EMAIL_VALIDATION_COPIES.RISKY
    },
    invalid: {
      severity: SEVERITY.Error,
      supportUrl: EMAIL_VALIDATION_STATUS_SUPPORT_URLS.INVALID,
      primaryText: validationObj.reason
    }
  };

  const emailValidationDate = localTimeFormatter(
    validationObj.updated_at,
    DateFormats.SimpleDateTime
  );

  const validationDateCopy = emailValidationDate
    ? `${strings.inventoryProfiles.common.emailValidationStatus.alert.validationDateLabel} ${emailValidationDate}`
    : null;

  const hasVerdict = validationObj?.verdict?.name;

  const emailValidationAlert =
    hasVerdict && !isValid
      ? {
          key: `emailValidation ${
            emailValidationAlertConfig[validationObj?.verdict?.name]?.severity
          }`,
          loading,
          title: `${emailTypeString} is ${validationObj?.verdict?.label}`,
          primaryText: emailValidationAlertConfig[validationObj?.verdict?.name]?.primaryText,
          secondaryText: EMAIL_VALIDATION_COPIES.SHARED_CONTEXT,
          buttonUrl: emailValidationAlertConfig[validationObj?.verdict?.name]?.supportUrl ?? '',
          type: validationObj?.verdict?.name.toUpperCase(),
          severity: emailValidationAlertConfig[validationObj?.verdict?.name]?.severity,
          complementaryCopies: [
            {
              id: 'validationDate',
              copy: validationDateCopy
            }
          ]
        }
      : null;

  return emailValidationAlert;
};

export const buildEmailLabels = ({ profile, entityType }) => {
  if (!profile || Array.isArray(profile)) return [];

  const { primaryEmail, secondaryEmail } = extractEmails({ profile, entityType });

  return [
    {
      title: 'Email',
      mode: InfoLabelModes.EXTERNAL_LINK,
      href: `mailto:${primaryEmail}`,
      linkProps: {
        url: primaryEmail ?? '',
        icon: getEmailValidationIcon({ validationStatus: profile?.emailValidation })
      },
      config: AutoWidthGrid
    },
    {
      title: 'Other Email',
      mode: InfoLabelModes.EXTERNAL_LINK,
      href: `mailto:${secondaryEmail}`,
      linkProps: {
        url: secondaryEmail ?? '',
        icon: getEmailValidationIcon({ validationStatus: profile?.personalEmailValidation })
      },
      config: AutoWidthGrid
    }
  ];
};

export const extractEmails = ({ profile, entityType }) => {
  let primaryEmail = null;
  let secondaryEmail = null;

  if (!profile || !entityType) {
    return {
      primaryEmail,
      secondaryEmail
    };
  }

  if (entityType === EntityType.Candidate || entityType === EntityType.Contact) {
    primaryEmail = profile?.email;
    secondaryEmail = profile?.personalInformation?.contact?.personal_email;
  } else if (entityType === EntityType.HiringAuthority) {
    primaryEmail = profile?.work_email;
    secondaryEmail = profile?.personal_email;
  }

  return {
    primaryEmail,
    secondaryEmail
  };
};

export const handleEntityEditionCompleted =
  ({ handleEditCompleted, enableUrlNavigation, onEditionCompleted }) =>
  () => {
    handleEditCompleted();
    if (!enableUrlNavigation && onEditionCompleted) {
      onEditionCompleted();
    }
  };

export const buildKeywordTagsCard = ({ keywordTags = [], isEnabled, Component }) => {
  return isEnabled && Component
    ? [
        {
          Component,
          componentProps: {
            key: 'keywordTags',
            keywordTags
          }
        }
      ]
    : [];
};
