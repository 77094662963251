import React from 'react';
import NumberFormat from 'react-number-format';
import CircularProgress from '@material-ui/core/CircularProgress';
import useFetchWithStatus from 'hooks/fetchWithStatus';
import { getId } from 'UI/utils';

import { useStyles } from '../styles';

import { isColumnToHide, statusAll } from './UserToolReport.constants';

export const Columns = {
  Team: 'Team',
  Calls: 'Calls',
  InMails: 'InMails',
  Indeed: 'Indeed',
  LivePost: 'LivePost',
  GpacCareer: 'Gpac Career',
  BulksMo: 'Bulks Mo',
  BulksTu: 'Bulks Tu',
  BulksWe: 'Bulks We',
  BulksTh: 'Bulks Th',
  BulksFr: 'Bulks Fr',
  BulksSa: 'Bulks Sa',
  BulksSu: 'Bulks Su',
  BulksTotal: 'Bulks Total'
};

export const SplitCell = ({ column, header }) => {
  const classes = useStyles();
  const labelUp = (
    <div>
      {typeof column.label === 'number' ? (
        <NumberFormat displayType="text" thousandSeparator value={column.label} />
      ) : (
        column.label
      )}
    </div>
  );
  const labelDown = column.labelDown && (
    <div className={header ? classes.headerLabelDown : classes.labelDown}>
      {typeof column.labelDown === 'number' ? (
        <NumberFormat displayType="text" thousandSeparator value={column.labelDown} />
      ) : (
        column.labelDown
      )}
    </div>
  );
  return (
    <>
      {labelUp}
      {labelDown}
    </>
  );
};

export const DataTableRecruiterRender = ({ recruiters, year, extraColumns, personStatus }) => {
  const classes = useStyles();
  return recruiters.map(item => {
    const isYearView = !!year?.date;
    const finalExtraColumns = extraColumns?.extraColumnsInfo
      ? extraColumns.extraColumnsInfo.map(extraColumn => ({
          label: item[extraColumn.columnName],
          ...(extraColumn.labelDown && { labelDown: item[extraColumn.labelDown] }),
          name: item[extraColumn.columnLabel]
        }))
      : [];
    const tableColumns = isYearView
      ? [
          { label: item.recruiter, className: classes.expandibleTeam },
          { label: item.january },
          { label: item.february },
          { label: item.march },
          { label: item.april },
          { label: item.may },
          { label: item.june },
          { label: item.july },
          { label: item.august },
          { label: item.september },
          { label: item.october },
          { label: item.november },
          { label: item.december },
          { label: item.total }
        ]
      : [
          {
            label: item.recruiter,
            className: classes.expandibleTeam,
            labelDown: personStatus?.title === statusAll ? item.user_status : null,
            name: Columns.Team
          },
          { label: item.dials, name: Columns.Calls },
          { label: item.inmails, name: Columns.InMails },
          { label: item.indeed, name: Columns.Indeed },
          { label: item.live_post, name: Columns.LivePost },
          { label: item.gpac_career, name: Columns.GpacCareer },
          { label: item.bulks_mo, labelDown: item.reached_mo, name: Columns.BulksMo },
          { label: item.bulks_tu, labelDown: item.reached_tu, name: Columns.BulksTu },
          { label: item.bulks_we, labelDown: item.reached_we, name: Columns.BulksWe },
          { label: item.bulks_th, labelDown: item.reached_th, name: Columns.BulksTh },
          { label: item.bulks_fr, labelDown: item.reached_fr, name: Columns.BulksFr },
          { label: item.bulks_sa, labelDown: item.reached_sa, name: Columns.BulksSa },
          { label: item.bulks_su, labelDown: item.reached_su, name: Columns.BulksSu },
          { label: item.bulks_total, labelDown: item.reached_total, name: Columns.BulksTotal },
          ...finalExtraColumns
        ];
    return (
      <tr key={getId()} className={classes.expandableTableFontWeight}>
        <td />
        {tableColumns.map(
          column =>
            !isColumnToHide(column.name) && (
              <td key={getId()} className={column.className}>
                <SplitCell column={column} header={false} />
              </td>
            )
        )}
      </tr>
    );
  });
};

const LoadingRender = () => {
  const classes = useStyles();
  return (
    <tr>
      <td colSpan={16} className={classes.expandableLoadingRender}>
        <div className={classes.defaultWrapper}>
          <CircularProgress />
        </div>
      </td>
    </tr>
  );
};

const ExpandableRow = ({ endpoint, coach, year, month, week, extraColumns, personStatus }) => {
  const finalEndpoint = `${endpoint}?year=${year?.date}&coach=${coach}&month=${month?.date}&week=${
    week?.date
  }&extraColumns=${extraColumns.extraColumnsNames ? extraColumns.extraColumnsNames : ''}&status=${
    personStatus.title
  }`;
  const { Status } = useFetchWithStatus(finalEndpoint);
  return (
    <Status
      loading={<LoadingRender />}
      success={recruiters => (
        <DataTableRecruiterRender
          recruiters={recruiters}
          year={year}
          week={week}
          extraColumns={extraColumns}
          personStatus={personStatus}
        />
      )}
    />
  );
};

export default ExpandableRow;
