// @flow

import { Permissions, userHasPermission } from 'services/Authorization';
import { SplitType } from 'UI/constants/entityTypes';
import { FeatureFlags } from 'UI/constants/featureFlags';
import { Roles } from 'UI/constants/roles';
import { PlacementStatus } from 'UI/constants/status';
import { hasFeatureFlag } from 'UI/utils';

export const PlacementActions = {
  Approve: 'approve',
  Suggest: 'suggest',
  Suggesting: 'suggesting',
  ViewInvoices: 'viewInvoices',
  ViewingInvoices: 'viewingInvoices',
  ViewPayments: 'viewPayments',
  ViewingPayments: 'viewingPayments',
  Save: 'save',
  Default: 'default',
  ManageInvoices: 'manageInvoices',
  ManagingInvoices: 'managingInvoices',
  ManagePayments: 'managePayments',
  ManagingPayments: 'managingPayments',
  ViewLogs: 'viewLogs',
  ViewingLogs: 'viewingLogs',
  RequestFallOff: 'requestFallOff',
  RequestingFallOff: 'requestingFallOff',
  MarkFallOff: 'markFallOff',
  RequestRevertFallOff: 'requestRevertFallOff',
  RevertFallOff: 'revertFallOff',
  MakeAdjustment: 'makeAdjustment',
  Adjusting: 'adjusting',
  RequestZip: 'requestZip',
  UploadEstimate: 'uploadEstimate',
  UploadingEstimate: 'uploadingEstimate',
  ApproveEstimate: 'approveEstimate',
  AdjustEstimate: 'adjustEstimate',
  AdjustingEstimate: 'adjustingEstimate',
  ViewEstimate: 'viewEstimate',
  RecreateEstimate: 'recreateEstimate',
  RecreateSalesOrder: 'recreateSalesOrder',
  DeletePlacement: 'deletePlacement'
};

const isUserInSplits = (user: any, splits: any[]) => {
  if (!user || !splits || !splits.length) return false;

  return splits.some(
    ({ recruiter, user: splitUser }) => recruiter?.id === user.id || splitUser?.id === user.id
  );
};

const isUserAssistant = (user, coach) =>
  user && coach?.assistants && coach.assistants.some(assistant => assistant.id === user.id);

const isUserInCompanySide = (user: any, splits: any[]) =>
  splits &&
  splits.some(({ recruiter, type }) => type === SplitType.Company && recruiter.id === user.id);

const ownershipGuard = ({ placement, user }) =>
  placement.created_by === user.id || hasOverridePermission({ user });

const hasOverridePermission = user =>
  userHasPermission(Permissions.Placements.OverrideApproval, user);

export const Guards = {
  ownership: ownershipGuard,
  dealOwnership: ({ placement, user, splits }) =>
    ownershipGuard({ placement, user }) || isUserInSplits(user, splits),
  teamOwnership: ({ placement, user, splits }) =>
    placement?.recruiter?.id === user.id ||
    placement?.recruiter?.teamRelation?.coach?.id === user.id ||
    placement?.recruiter?.teamRelation?.regional?.id === user.id ||
    placement.created_by === user.id ||
    placement.coach?.id === user.id ||
    placement.regional?.id === user.id ||
    isUserInSplits(user, splits) ||
    isUserAssistant(user, placement.coach) ||
    hasOverridePermission(user),
  regionalOwnership: ({ placement, user }) =>
    placement?.recruiter?.teamRelation?.regional?.id === user.id ||
    placement?.regional?.id === user.id ||
    hasOverridePermission(user),
  companySideOwnership: ({ placement, user, splits }) =>
    ownershipGuard({ placement, user }) || isUserInCompanySide(user, splits),
  isVisibleInStack:
    key =>
    ({ uiState: { navStack } }) =>
      navStack.length > 0 && navStack[navStack.length - 1] === key,
  isDeleteEnabled: () => hasFeatureFlag(FeatureFlags.PlacementDeletePermanently)
};

const sharedActionBuilder = key => ({
  id: key,
  guard: Guards.isVisibleInStack(key)
});

const sharedActionsKeys = [
  PlacementActions.ViewingLogs,
  PlacementActions.ManagingPayments,
  PlacementActions.ManagingInvoices,
  PlacementActions.ViewingPayments,
  PlacementActions.ViewingInvoices,
  PlacementActions.Suggesting,
  PlacementActions.RequestingFallOff,
  PlacementActions.Adjusting,
  PlacementActions.UploadingEstimate,
  PlacementActions.AdjustingEstimate
];
const sharedActions = {};

sharedActionsKeys.forEach(key => {
  sharedActions[key] = sharedActionBuilder(key);
});

const defaultAction = {
  primary: { id: PlacementActions.ViewLogs, alternate: [sharedActions.viewingLogs] }
};

const approveEstimateAction = {
  id: PlacementActions.ApproveEstimate,
  ignoreGuardForAlternates: true,
  alternate: [
    sharedActions.adjustingEstimate,
    sharedActions.viewingLogs,
    sharedActions.requestingFallOff
  ]
};

const estimateActions = [
  PlacementActions.ViewEstimate,
  PlacementActions.AdjustEstimate,
  PlacementActions.ViewLogs
];

const deletePlacementAction = {
  id: PlacementActions.DeletePlacement,
  guard: Guards.isDeleteEnabled
};

const forDirectorsWhenInvoicedOrPaid = {
  primary: {
    id: PlacementActions.RequestFallOff,
    alternate: [
      sharedActions.requestingFallOff,
      sharedActions.viewingLogs,
      sharedActions.viewingInvoices,
      sharedActions.viewingPayments
    ],
    fallback: PlacementActions.ViewLogs
  },
  actions: [PlacementActions.ViewInvoices, PlacementActions.ViewPayments, PlacementActions.ViewLogs]
};

const commonActions = {
  forRecruiterWhenValidating: {
    primary: {
      id: PlacementActions.Save,
      ignoreGuardForAlternates: true,
      guard: Guards.dealOwnership,
      alternate: [sharedActions.viewingLogs]
    },
    actions: [PlacementActions.ViewLogs]
  },
  forCoachAndRegionalWhenValidating: {
    primary: {
      id: PlacementActions.Approve,
      guard: Guards.teamOwnership,
      ignoreGuardForAlternates: true,
      alternate: [sharedActions.suggesting, sharedActions.viewingLogs]
    },
    actions: [
      { id: PlacementActions.Suggest, guard: Guards.teamOwnership },
      {
        id: PlacementActions.DeletePlacement,
        guard: [Guards.teamOwnership, Guards.isDeleteEnabled]
      },
      PlacementActions.ViewLogs
    ]
  },
  toViewAllHistory: {
    primary: {
      id: PlacementActions.ViewInvoices,
      alternate: [
        sharedActions.viewingInvoices,
        sharedActions.viewingPayments,
        sharedActions.viewingLogs
      ]
    },
    actions: [PlacementActions.ViewPayments, PlacementActions.ViewLogs]
  },
  forStaffWhenValidating: {
    primary: {
      id: PlacementActions.Approve,
      alternate: [sharedActions.viewingLogs]
    },
    actions: [PlacementActions.ViewLogs]
  },
  forOperationsWhenValidating: {
    primary: {
      id: PlacementActions.Approve,
      alternate: [sharedActions.viewingLogs]
    },
    actions: [deletePlacementAction, PlacementActions.ViewLogs]
  },
  forLeadershipWhenValidating: {
    primary: {
      id: PlacementActions.Approve,
      alternate: [sharedActions.suggesting, sharedActions.viewingLogs]
    },
    actions: [PlacementActions.Suggest, deletePlacementAction, PlacementActions.ViewLogs]
  },
  forDirectorsWhenPendingInvoice: {
    primary: {
      id: PlacementActions.RequestFallOff,
      alternate: [sharedActions.requestingFallOff, sharedActions.viewingLogs],
      fallback: PlacementActions.ViewLogs
    },
    actions: [PlacementActions.ViewLogs]
  },
  forDirectorsWhenInvoiced: forDirectorsWhenInvoicedOrPaid,
  forDirectorsWhenFallOff: {
    primary: {
      id: PlacementActions.RequestRevertFallOff,
      alternate: [sharedActions.viewingLogs],
      fallback: PlacementActions.ViewLogs
    },
    actions: [PlacementActions.ViewLogs]
  },
  forCoachesWhenPendingEstimateApproval: {
    primary: approveEstimateAction,
    guard: Guards.teamOwnership,
    actions: estimateActions
  },
  forRegionalsWhenPendingEstimateApproval: {
    primary: approveEstimateAction,
    guard: Guards.teamOwnership,
    actions: [...estimateActions, PlacementActions.RequestFallOff]
  },
  forStaffWhenPendingEstimate: {
    primary: {
      id: PlacementActions.ApproveEstimate,
      alternate: [sharedActions.adjustingEstimate, sharedActions.viewingLogs]
    },
    actions: [
      PlacementActions.ViewEstimate,
      PlacementActions.AdjustEstimate,
      PlacementActions.ViewLogs
    ]
  },
  allWhenPendingFinanceValidation: {
    primary: {
      id: PlacementActions.ViewEstimate,
      alternate: [sharedActions.viewingLogs]
    },
    actions: [PlacementActions.ViewLogs]
  },
  forCoachAndAssistantOnLeadershipValidation: {
    primary: {
      id: PlacementActions.Save,
      guard: Guards.teamOwnership,
      ignoreGuardForAlternates: true,
      alternate: [sharedActions.viewingLogs]
    },
    actions: [PlacementActions.ViewLogs]
  }
};

const actionsWhenValidating = {
  [Roles.Recruiter]: commonActions.forRecruiterWhenValidating,
  [Roles.Coach]: commonActions.forCoachAndRegionalWhenValidating,
  [Roles.AssistantRegionalDirector]: commonActions.forCoachAndRegionalWhenValidating,
  [Roles.RegionalDirector]: commonActions.forCoachAndRegionalWhenValidating,
  [Roles.Finance]: commonActions.forStaffWhenValidating,
  [Roles.Operations]: commonActions.forOperationsWhenValidating,
  [Roles.ProductionDirector]: commonActions.forLeadershipWhenValidating,
  [Roles.Leadership]: commonActions.forLeadershipWhenValidating
};

export const Rules = {
  [PlacementStatus.PendingValidation]: actionsWhenValidating,
  [PlacementStatus.PendingRegionalValidation]: {
    [Roles.Recruiter]: defaultAction,
    [Roles.Coach]: commonActions.forCoachAndAssistantOnLeadershipValidation,
    [Roles.AssistantRegionalDirector]: commonActions.forCoachAndAssistantOnLeadershipValidation,
    [Roles.RegionalDirector]: commonActions.forCoachAndRegionalWhenValidating,
    [Roles.Finance]: commonActions.forStaffWhenValidating,
    [Roles.Operations]: commonActions.forOperationsWhenValidating,
    [Roles.ProductionDirector]: commonActions.forLeadershipWhenValidating,
    [Roles.Leadership]: commonActions.forLeadershipWhenValidating
  },
  [PlacementStatus.PendingUpdate]: {
    [Roles.Recruiter]: {
      primary: {
        id: PlacementActions.Save,
        guard: Guards.dealOwnership,
        ignoreGuardForAlternates: true,
        alternate: [sharedActions.viewingLogs]
      },
      actions: [PlacementActions.ViewLogs]
    },

    [Roles.Coach]: commonActions.forCoachAndRegionalWhenValidating,
    [Roles.AssistantRegionalDirector]: commonActions.forCoachAndRegionalWhenValidating,
    [Roles.RegionalDirector]: commonActions.forCoachAndRegionalWhenValidating,
    [Roles.Finance]: commonActions.forStaffWhenValidating,
    [Roles.Operations]: commonActions.forOperationsWhenValidating,
    [Roles.ProductionDirector]: commonActions.forLeadershipWhenValidating,
    [Roles.Leadership]: commonActions.forLeadershipWhenValidating
  },
  [PlacementStatus.PendingInvoice]: {
    [Roles.Recruiter]: defaultAction,
    [Roles.Coach]: defaultAction,
    [Roles.AssistantRegionalDirector]: defaultAction,
    [Roles.RegionalDirector]: commonActions.forDirectorsWhenPendingInvoice,
    [Roles.Operations]: defaultAction,
    [Roles.DataCoordinator]: defaultAction,
    [Roles.Finance]: {
      primary: {
        id: PlacementActions.ManageInvoices,
        alternate: [
          sharedActions.managingInvoices,
          sharedActions.adjusting,
          sharedActions.viewingLogs
        ]
      },
      actions: [
        PlacementActions.MakeAdjustment,
        PlacementActions.RecreateEstimate,
        PlacementActions.RecreateSalesOrder,
        PlacementActions.RequestZip,
        PlacementActions.ViewLogs
      ]
    },
    [Roles.ProductionDirector]: commonActions.forDirectorsWhenPendingInvoice,
    [Roles.Leadership]: commonActions.forDirectorsWhenPendingInvoice
  },
  [PlacementStatus.Invoiced]: {
    [Roles.Recruiter]: commonActions.toViewAllHistory,
    [Roles.Coach]: commonActions.toViewAllHistory,
    [Roles.AssistantRegionalDirector]: commonActions.toViewAllHistory,
    [Roles.RegionalDirector]: commonActions.forDirectorsWhenInvoiced,
    [Roles.Operations]: commonActions.toViewAllHistory,
    [Roles.DataCoordinator]: commonActions.toViewAllHistory,
    [Roles.Finance]: {
      primary: {
        id: PlacementActions.ManageInvoices,
        alternate: [
          sharedActions.managingInvoices,
          sharedActions.managingPayments,
          sharedActions.adjusting,
          sharedActions.viewingLogs
        ]
      },
      actions: [
        PlacementActions.ManagePayments,
        PlacementActions.MakeAdjustment,
        PlacementActions.RecreateEstimate,
        PlacementActions.RecreateSalesOrder,
        PlacementActions.RequestZip,
        PlacementActions.ViewLogs
      ]
    },
    [Roles.ProductionDirector]: commonActions.forDirectorsWhenInvoiced,
    [Roles.Leadership]: commonActions.forDirectorsWhenInvoiced
  },
  [PlacementStatus.FallOff]: {
    [Roles.Recruiter]: defaultAction,
    [Roles.Coach]: defaultAction,
    [Roles.AssistantRegionalDirector]: defaultAction,
    [Roles.RegionalDirector]: commonActions.forDirectorsWhenFallOff,
    [Roles.Operations]: defaultAction,
    [Roles.DataCoordinator]: defaultAction,
    [Roles.Finance]: defaultAction,
    [Roles.ProductionDirector]: commonActions.forDirectorsWhenFallOff,
    [Roles.Leadership]: commonActions.forDirectorsWhenFallOff
  },
  [PlacementStatus.PendingFallOff]: {
    [Roles.Recruiter]: defaultAction,
    [Roles.Coach]: defaultAction,
    [Roles.AssistantRegionalDirector]: defaultAction,
    [Roles.RegionalDirector]: defaultAction,
    [Roles.Operations]: defaultAction,
    [Roles.DataCoordinator]: defaultAction,
    [Roles.Finance]: {
      primary: {
        id: PlacementActions.MarkFallOff,
        alternate: [sharedActions.viewingLogs]
      },
      actions: [PlacementActions.ViewLogs]
    },
    [Roles.ProductionDirector]: defaultAction,
    [Roles.Leadership]: defaultAction
  },
  [PlacementStatus.PendingRevertFallOff]: {
    [Roles.Recruiter]: defaultAction,
    [Roles.Coach]: defaultAction,
    [Roles.AssistantRegionalDirector]: defaultAction,
    [Roles.RegionalDirector]: defaultAction,
    [Roles.Operations]: defaultAction,
    [Roles.DataCoordinator]: defaultAction,
    [Roles.Finance]: {
      primary: {
        id: PlacementActions.RevertFallOff,
        alternate: [sharedActions.viewingLogs]
      },
      actions: [PlacementActions.ViewLogs]
    }
  },
  [PlacementStatus.PendingEstimateCreation]: {
    [Roles.Recruiter]: defaultAction,
    [Roles.Coach]: defaultAction,
    [Roles.AssistantRegionalDirector]: defaultAction,
    [Roles.RegionalDirector]: defaultAction,
    [Roles.Operations]: defaultAction,
    [Roles.DataCoordinator]: defaultAction,
    [Roles.Finance]: {
      primary: {
        id: PlacementActions.MakeAdjustment,
        alternate: [sharedActions.adjusting, sharedActions.viewingLogs]
      },
      actions: [PlacementActions.ViewLogs]
    },
    [Roles.ProductionDirector]: defaultAction,
    [Roles.Leadership]: defaultAction
  },
  [PlacementStatus.PendingEstimateApproval]: {
    [Roles.Recruiter]: {
      primary: {
        id: PlacementActions.ApproveEstimate,
        ignoreGuardForAlternates: true,
        alternate: [sharedActions.adjustingEstimate, sharedActions.viewingLogs],
        guard: Guards.companySideOwnership
      },
      guard: Guards.dealOwnership,
      actions: [
        PlacementActions.ViewEstimate,
        PlacementActions.AdjustEstimate,
        PlacementActions.ViewLogs
      ]
    },
    [Roles.Coach]: commonActions.forCoachesWhenPendingEstimateApproval,
    [Roles.AssistantRegionalDirector]: commonActions.forCoachesWhenPendingEstimateApproval,
    [Roles.RegionalDirector]: commonActions.forRegionalsWhenPendingEstimateApproval,
    [Roles.Finance]: commonActions.forStaffWhenPendingEstimate,
    [Roles.Operations]: commonActions.forStaffWhenPendingEstimate,
    [Roles.ProductionDirector]: commonActions.forStaffWhenPendingEstimate,
    [Roles.Leadership]: commonActions.forStaffWhenPendingEstimate
  },
  [PlacementStatus.PendingFinanceValidation]: {
    [Roles.Recruiter]: commonActions.allWhenPendingFinanceValidation,
    [Roles.Coach]: commonActions.allWhenPendingFinanceValidation,
    [Roles.AssistantRegionalDirector]: commonActions.allWhenPendingFinanceValidation,
    [Roles.RegionalDirector]: {
      primary: {
        id: PlacementActions.ViewEstimate,
        alternate: [sharedActions.requestingFallOff, sharedActions.viewingLogs]
      },
      actions: [
        { id: PlacementActions.RequestFallOff, guard: Guards.teamOwnership },
        PlacementActions.ViewLogs
      ]
    },
    [Roles.Finance]: {
      primary: {
        id: PlacementActions.ApproveEstimate,
        alternate: [sharedActions.adjustingEstimate, sharedActions.viewingLogs]
      },
      actions: [
        PlacementActions.ViewEstimate,
        PlacementActions.AdjustEstimate,
        PlacementActions.ViewLogs
      ]
    },
    [Roles.Operations]: commonActions.allWhenPendingFinanceValidation,
    [Roles.DataCoordinator]: commonActions.allWhenPendingFinanceValidation,
    [Roles.ProductionDirector]: commonActions.allWhenPendingFinanceValidation,
    [Roles.Leadership]: commonActions.allWhenPendingFinanceValidation
  },
  [PlacementStatus.FullyPaid]: {
    [Roles.Recruiter]: commonActions.toViewAllHistory,
    [Roles.Coach]: commonActions.toViewAllHistory,
    [Roles.AssistantRegionalDirector]: commonActions.toViewAllHistory,
    [Roles.RegionalDirector]: commonActions.toViewAllHistory,
    [Roles.Operations]: commonActions.toViewAllHistory,
    [Roles.DataCoordinator]: commonActions.toViewAllHistory,
    [Roles.Finance]: {
      primary: {
        id: PlacementActions.ViewInvoices,
        alternate: [
          sharedActions.viewingInvoices,
          sharedActions.viewingPayments,
          sharedActions.viewingLogs
        ]
      },
      actions: [
        PlacementActions.RecreateEstimate,
        PlacementActions.RecreateSalesOrder,
        PlacementActions.ViewPayments,
        PlacementActions.ViewLogs
      ]
    },
    [Roles.ProductionDirector]: commonActions.forDirectorsWhenInvoiced,
    [Roles.Leadership]: forDirectorsWhenInvoicedOrPaid
  }
};
