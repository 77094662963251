import strings from 'strings';
import { Endpoints } from 'UI/constants/endpoints';
import { EntityType } from 'UI/constants/entityTypes';
import { OptionRenderers } from 'UI/utils/renderers';

export const FORM_FIELDS_MAP = {
  OtherTask: {
    key: 'otherTask'
  },
  ReachToEntity: {
    key: 'reachTo'
  },
  ReachToName: {
    key: 'reachToName'
  },
  Comments: {
    key: 'comments'
  }
};

export const ENTITY_OPTIONS_TO_REACH_OUT = [
  {
    label: 'Contacts',
    value: 'contact'
  },
  {
    label: 'Candidate',
    value: 'candidate'
  },
  {
    label: 'Company',
    value: 'company'
  }
];

export const REACH_OUT_AUTOCOMPLETE_PROPS_BY_ENTITY = {
  candidate: {
    placeholder: strings.dailyPlan.drawer.form.placeholders.selectCandidate,
    url: Endpoints.Search,
    typeaheadParams: { entityType: EntityType.Candidate },
    renderOption: OptionRenderers.globalSearchPerson('title')
  },
  contact: {
    placeholder: strings.dailyPlan.drawer.form.placeholders.selectContact,
    url: Endpoints.Search,
    typeaheadParams: { entityType: EntityType.Contact },
    renderOption: OptionRenderers.globalSearchPerson('title')
  },
  company: {
    placeholder: strings.dailyPlan.drawer.form.placeholders.selectCompany,
    url: Endpoints.Search,
    typeaheadParams: { entityType: EntityType.Company },
    displayKey: 'name',
    renderOption: OptionRenderers.globalCompanySearch({ displayKey: 'name' })
  }
};
