// @flow
import { HTTPStatusCodes } from 'constants/httpStatusCodes';
import queryString from 'query-string';
import strings from 'strings';
import type { OperationResult } from 'types/app';
import { SearchProjectPrivacyScopes, SelectionType } from 'UI/constants/defaults';
import { Endpoints } from 'UI/constants/endpoints';
import { getErrorMessage, objectToArray } from 'UI/utils';

import API from '../API';
import { makeRequest } from '../utils';

import {
  buildMasterListPayloadProperties,
  fillIncludedItems,
  getArchiveAlerts,
  getGeneralErrorAlert
} from './utils';

export const SEARCH_PARAMS_KEYS = {
  MetricsParams: 'bulk_metrics_params',
  SearchParams: 'search_params'
};

export const SEARCH_PROJECT_CREATED_FROM_VARIANTS = {
  bulkMetrics: 'bulkMetrics',
  preview: 'searchProjectPreview'
};

const { SearchProjects, SearchProjectsAddPreview, SearchProjectsInventory, SearchProjectDetail } =
  Endpoints;

const { alerts: alertsCopies } = strings.searchProjects;

const getScopeBooleanValue = scope => SearchProjectPrivacyScopes[scope].value;

/**
 * @callback ExcludeMapperCallback
 * @function ExcludeMapper
 * @param {FilteredItemsData[]} data
 * @param {String} type
 * @returns {Array} Contains objects with default properties id and role_id
 */
const defaultExcludeMapper = (data, type) =>
  type === 'name'
    ? data.map(item => ({ id: item.id, role_id: item.type_id }))
    : data.map(item => item.id);

const alternativeExcludeMapper = currentData =>
  currentData.map(item => ({
    id: item.id,
    item_search_project_type: parseInt(item.type_id, 10)
  }));

/**
 * Service that allows to get search project main data (not items list)
 * @param {number} searchProjectId
 * @returns {Promise<OperationResult>}
 */
export const getSearchProjectQuickInfo = async searchProjectId => {
  const { data, ...restResponse } = await makeRequest({
    endpointOptions: {
      path: Endpoints.SearchProjectQuickInfo,
      replaceId: searchProjectId
    },
    method: 'get'
  });

  const {
    archived_at,
    bulkable_items,
    collaborators: collabs,
    created_at,
    daysForDeletion,
    folder,
    geoPolygons,
    id,
    is_archived,
    is_master_list,
    is_mine,
    is_private,
    name,
    role_title,
    seat_count,
    sent_date,
    total_items,
    total_companies
  } = data;

  const mappedData = {
    archivedAt: archived_at,
    bulkableItems: bulkable_items,
    collabs,
    createdAt: created_at,
    daysBeforeDeletion: daysForDeletion,
    folder: { id: folder?.id, name: folder?.name },
    geoPolygon:
      geoPolygons && geoPolygons.length > 0
        ? {
            id: geoPolygons[0].id,
            polygon: geoPolygons[0].polygon
          }
        : null,
    id,
    isMasterList: is_master_list,
    isMine: is_mine,
    isPrivate: is_private,
    name,
    roleTitle: role_title,
    seatCount: seat_count,
    sentDate: sent_date,
    totalItems: total_items,
    totalCompanies: total_companies,
    isArchived: is_archived
  };

  return { ...restResponse, data: mappedData };
};

/**
 * Service that restores an archived search project
 * @param {number} searchProjectId
 * @returns {Promise<OperationResult>}
 */
export const restoreSearchProject = ({ searchProjectId, folderId }) =>
  makeRequest({
    endpointOptions: {
      path: Endpoints.SearchProjectRestore,
      replaceId: searchProjectId
    },
    data: folderId ? { folderId } : null,
    method: 'post',
    alertConfig: {
      success: {
        title: strings.formatString(alertsCopies.success.generalTitle, {
          action: alertsCopies.success.actions.restore,
          entity: alertsCopies.entities.searchProject
        }),
        body: ({ name }) => name
      },
      error: getGeneralErrorAlert({
        action: alertsCopies.error.actions.restore,
        entity: alertsCopies.entities.searchProject
      })
    }
  });

/**
 * @param {Object} formData
 * @param {number} formData.bulkId
 * @param {Object} formData.itemsToAdd
 * @param {string} formData.name
 * @param {Object} formData.queryParams
 * @param {boolean} formData.scope
 * @param {string} formData.searchParamsKey
 * @param {number} [formData.searchProjectId]
 * @param {boolean} formData.shouldMoveCopiedItems
 */
export const createSearchProjectMainFlow = ({
  bulkId,
  canMarkAsMasterList,
  geoPolygon,
  isMasterList,
  itemsToAdd,
  name,
  parentFolder,
  queryParams,
  scope,
  searchParamsKey,
  searchProjectId,
  seatCount,
  shouldMoveCopiedItems
}): Promise<OperationResult> => {
  const createFromAnotherSP = !!searchProjectId;
  let payload = { name, is_private: getScopeBooleanValue(scope) };

  if (itemsToAdd.type === SelectionType.Include) {
    payload = { ...payload, ...fillIncludedItems(itemsToAdd.data) };
  } else if (itemsToAdd.type === SelectionType.Exclude) {
    if (searchParamsKey === SEARCH_PARAMS_KEYS.MetricsParams) payload.email_history_id = bulkId;
    const commonExcludeDataParams = { filteredItems: itemsToAdd, queryParams };
    const excludeDataParams = createFromAnotherSP
      ? {
          dataName: searchParamsKey,
          excludeMapperCallback: alternativeExcludeMapper
        }
      : {};
    payload = {
      ...payload,
      ...fillExcludeData({
        ...commonExcludeDataParams,
        ...excludeDataParams
      })
    };
  }
  if (shouldMoveCopiedItems) payload.removeCopiedItems = shouldMoveCopiedItems;
  if (parentFolder) payload.folderId = parentFolder.id;

  let alertAction = null;
  if (createFromAnotherSP) {
    payload.search_project_id = searchProjectId;
    alertAction = shouldMoveCopiedItems ? 'move' : 'copy';
  } else {
    alertAction = 'create';
  }

  const finalAlertEntity = searchProjectId
    ? alertsCopies.entities.records
    : alertsCopies.entities.searchProject;

  return makeRequest({
    url: SearchProjects,
    method: 'post',
    data: {
      ...payload,
      ...buildMasterListPayloadProperties({
        canMarkAsMasterList,
        geoPolygon,
        isMasterList,
        seatCount
      })
    },
    alertConfig: {
      success: {
        title: strings.formatString(alertsCopies.success.generalTitle, {
          action: alertsCopies.success.actions[alertAction],
          entity: finalAlertEntity
        }),
        body: searchProjectId
          ? strings.formatString(alertsCopies.success.actionToSearchProjectName, {
              action: alertsCopies.success.actions[alertAction],
              searchProjectName: name
            })
          : name
      },
      error: getGeneralErrorAlert({
        action: alertsCopies.error.actions[alertAction],
        entity: finalAlertEntity
      })
    }
  });
};

/**
 * Create search project extracting companies contacts properties
 * @param {Object} SearchProject
 * @param {boolean} SearchProject.addEmployees
 * @param {boolean} SearchProject.addHiringAuthorities
 * @param {string} SearchProject.name
 * @param {string} SearchProject.scope
 * @param {number} SearchProject.searchProjectId
 */
export const createSearchProjectFromCompaniesContacts = ({
  addEmployees,
  addHiringAuthorities,
  name,
  parentFolder,
  scope,
  searchProjectId
}): Promise<OperationResult> => {
  const payload = {
    addEmployees,
    addHiringAuthorities,
    excludeCompanies: true,
    folderId: parentFolder?.id,
    is_private: getScopeBooleanValue(scope),
    name,
    search_params: {
      query: {
        direction: '',
        keyword: '',
        orderBy: '',
        page: '',
        perPage: 25,
        userFilter: '0'
      },
      exclude: []
    },
    search_project_id: searchProjectId
  };

  return makeRequest({
    method: 'post',
    url: SearchProjects,
    data: payload,
    alertConfig: {
      success: {
        title: strings.formatString(alertsCopies.success.generalTitle, {
          action: alertsCopies.success.actions.extract,
          entity: alertsCopies.entities.contacts
        }),
        body: strings.formatString(alertsCopies.success.actionToSearchProjectName, {
          action: alertsCopies.success.actions.extract,
          searchProjectName: name
        })
      },
      error: getGeneralErrorAlert({
        action: alertsCopies.error.actions.extract,
        entity: alertsCopies.entities.contacts
      })
    }
  });
};

export const createSearchProject = (formData): Promise<OperationResult> => {
  if (formData.shouldExtractCompanyContacts) {
    return createSearchProjectFromCompaniesContacts(formData);
  }

  return createSearchProjectMainFlow(formData);
};

/**
 * Update search project properties
 *
 * @param {Object} SearchProject
 * @param {number} SearchProject.searchProjectId
 * @param {string} SearchProject.name
 * @param {boolean} SearchProject.scope
 * @param {Object} [SearchProject.parentFolder]
 */
export const updateSearchProject = async ({
  canMarkAsMasterList,
  geoPolygon,
  geoPolygonId,
  isMasterList,
  name,
  parentFolder,
  scope,
  searchProjectId,
  seatCount
}) => {
  const finalGeoPolygon = geoPolygonId ? { id: geoPolygonId, polygon: geoPolygon } : geoPolygon;

  const { alert, data, success } = await makeRequest({
    method: 'put',
    url: SearchProjectDetail.replace(/:id/, searchProjectId),
    data: {
      name,
      isPrivate: getScopeBooleanValue(scope),
      folderId: parentFolder ? parentFolder.id : null,
      ...buildMasterListPayloadProperties({
        canMarkAsMasterList,
        geoPolygon: finalGeoPolygon,
        isMasterList,
        seatCount
      })
    },
    alertConfig: {
      success: {
        title: strings.formatString(alertsCopies.success.generalTitle, {
          action: alertsCopies.success.actions.edit,
          entity: alertsCopies.entities.searchProject
        }),
        body: name
      },
      error: getGeneralErrorAlert({
        action: alertsCopies.error.actions.edit,
        entity: alertsCopies.entities.searchProject
      })
    }
  });

  if (success) {
    const newData = {
      name: data.name,
      isPrivate: data.is_private
    };

    if (canMarkAsMasterList) {
      newData.isMasterList = data.is_master_list;
      newData.geoPolygon =
        data.geoPolygons.length > 0
          ? {
              id: data.geoPolygons[0].id,
              polygon: data.geoPolygons[0].polygon
            }
          : null;
      newData.seatCount = data.masterList?.seat_count;
    }

    return { alert, data: newData, success };
  }

  return { alert, data, success };
};

/**
 * Deletes a search project
 *
 * @param {number} id
 * @param {string} name
 */
export const archiveSearchProject = (id, isArchiveFeatureActive) =>
  makeRequest({
    method: 'delete',
    url: SearchProjectDetail.replace(/:id/, id),
    alertConfig: getArchiveAlerts({
      entity: alertsCopies.entities.searchProject,
      isArchiveFeatureActive
    })
  });

export const archiveMultipleSearchProjects = ids =>
  makeRequest({
    url: SearchProjects,
    method: 'delete',
    data: {
      searchProjectIds: ids
    },
    alertConfig: getArchiveAlerts({
      entity:
        ids.length > 1 ? alertsCopies.entities.searchProjects : alertsCopies.entities.searchProject,
      isArchiveFeatureActive: true
    })
  });

// TODO: refactor add to sp function and segregate logic as this service does many things
/**
 * Service that helps adding items to a search project
 * @param {Object} Options
 * @param {number} [Options.bulkId]
 * @param {number} [Options.currentSearchProjectId]
 * @param {Object} Options.itemsToAdd
 * @param {boolean} [Options.moveCopiedItems]
 * @param {Object} [Options.queryParams]
 * @param {number} Options.selectedSearchProjectId
 * @returns {Promise}
 */
export const addToSearchProject = ({
  bulkId,
  createdFrom,
  currentSearchProjectId,
  itemsToAdd,
  moveCopiedItems,
  queryParams,
  selectedSearchProjectId
}): Promise<OperationResult> => {
  const basePayload = getSearchProjectAddFlowPayload({
    createdFrom,
    currentSearchProjectId,
    itemsToAdd,
    queryParams
  });
  const payload = moveCopiedItems
    ? { ...basePayload, removeCopiedItems: moveCopiedItems }
    : basePayload;
  if (createdFrom === SEARCH_PROJECT_CREATED_FROM_VARIANTS.bulkMetrics)
    payload.email_history_id = bulkId;

  let alertAction = null;
  if (objectToArray(SEARCH_PROJECT_CREATED_FROM_VARIANTS).includes(createdFrom)) {
    alertAction = moveCopiedItems ? 'move' : 'copy';
  } else {
    alertAction = 'add';
  }

  return makeRequest({
    method: 'put',
    endpointOptions: {
      path: Endpoints.SearchProjectsInventory,
      replaceId: selectedSearchProjectId
    },
    data: payload,
    alertConfig: {
      success: {
        title: strings.formatString(alertsCopies.success.generalTitle, {
          action: alertsCopies.success.actions[alertAction],
          entity: alertsCopies.entities.records
        })
      },
      error: getGeneralErrorAlert({
        action: alertsCopies.success.actions[alertAction],
        entity: alertsCopies.entities.records
      })
    }
  });
};

/**
 * @param {Object} preview
 * @param {number} preview.currentSearchProjectId
 * @param {number} preview.selectedSearchProjectId
 * @param {Object} preview.itemsToAdd
 * @param {Object} preview.queryParams
 * @param {'searchProjectPreview' | 'other' | undefined} [preview.createdFrom]
 * @returns {Promise}
 */
export const getSearchProjectAddItemsPreview = async ({
  selectedSearchProjectId,
  paramsType,
  bulkId,
  ...rest
}): Promise<OperationResult> => {
  const result: OperationResult = { success: false };
  try {
    const payload = getSearchProjectAddFlowPayload(rest);
    if (paramsType === SEARCH_PARAMS_KEYS.MetricsParams) payload.email_history_id = bulkId;
    const response = await API.put(
      SearchProjectsAddPreview.replace(':id', selectedSearchProjectId),
      payload
    );
    if (response.status === 200) {
      result.data = { ...response.data };
      result.success = true;
    } else {
      result.alert = {
        severity: 'error',
        title: 'Items add preview',
        body: 'Unable to get row items preview to display repeated and new items'
      };
    }
  } catch (error) {
    result.alert = {
      severity: 'error',
      title: 'Job order detail',
      body: getErrorMessage(error)
    };
  }
  return result;
};

const getSearchProjectAddFlowPayload = ({
  createdFrom,
  currentSearchProjectId,
  itemsToAdd,
  queryParams
}) => {
  const optionalParams = {};
  if (createdFrom === SEARCH_PROJECT_CREATED_FROM_VARIANTS.preview) {
    optionalParams.dataName = SEARCH_PARAMS_KEYS.SearchParams;
    optionalParams.excludeMapperCallback = alternativeExcludeMapper;
  }
  if (createdFrom === SEARCH_PROJECT_CREATED_FROM_VARIANTS.bulkMetrics) {
    optionalParams.dataName = SEARCH_PARAMS_KEYS.MetricsParams;
  }
  const requestData = getRequestData({
    itemsToAdd,
    queryParams,
    ...optionalParams
  });
  requestData.search_project_id = currentSearchProjectId;
  return requestData;
};

/**
 * @param {Object} request
 * @param {Object} itemsToAdd
 * @param {Object} queryParams
 * @param {function} [excludeMapperCallback]
 * @param {string} [dataName]
 * @returns {Object}
 */
const getRequestData = ({ itemsToAdd, queryParams, ...rest }) => {
  const filledData = {
    include: () => fillIncludedItems(itemsToAdd.data),
    exclude: () =>
      fillExcludeData({
        filteredItems: itemsToAdd,
        queryParams,
        ...rest
      })
  };

  return (filledData[itemsToAdd.type] && filledData[itemsToAdd.type]()) || {};
};

/**
 * @param {Number} searchProjectId
 * @param {FilteredItems} itemsToRemove
 * @param {Object} queryParams
 * @param {string} paramsType
 * @returns {OperationResult}
 */
export const removeItemsFromSearchProject = async ({
  searchProjectId,
  itemsToRemove,
  queryParams,
  paramsType,
  bulkId
}): Promise<OperationResult> => {
  const result: OperationResult = { success: false };
  try {
    const data = {};
    if (itemsToRemove.type === SelectionType.Include) {
      data.entities = { ...fillIncludedItems(itemsToRemove.data) };
      const { job_orders: _, ...entities } = data.entities;
      data.requestData = { ...entities };
    } else if (itemsToRemove.type === SelectionType.Exclude) {
      data.requestData = {
        ...fillExcludeData({
          filteredItems: itemsToRemove,
          queryParams,
          dataName: paramsType,
          excludeMapperCallback: alternativeExcludeMapper
        })
      };
    }
    const dataToSend =
      paramsType === SEARCH_PARAMS_KEYS.MetricsParams
        ? { data: { ...data.requestData, email_history_id: bulkId } }
        : {
            data: data.requestData
          };
    const response = await API.delete(
      SearchProjectsInventory.replace(/:id/, searchProjectId),
      dataToSend
    );
    result.success = response.status === HTTPStatusCodes.Ok;
  } catch (error) {
    result.alert = {
      severity: 'error',
      title: 'Error while deleting search project items',
      body: getErrorMessage(error)
    };
  }
  return result;
};

/**
 * @typedef FilteredItems
 * @property {FilteredItemsData[]} data
 * @property {String} type
 * @property {Number} count
 */

/**
 * @param {Object} excludeParams
 * @param {FilteredItems} excludeParams.filteredItems - Object containing items to exclude, type and count
 * @param {Object} excludeParams.queryParams - Filters used on list
 * @param {String} [excludeParams.dataName] - In case you don't want the default data name
 * @param {ExcludeMapperCallback} [excludeParams.excludeMapperCallback] - Callback to set the excluded items
 * based on data an type
 *
 * @returns {Object} Contains params or query info and excluded items, in case user
 * used filters to remove some items
 */
const fillExcludeData = ({ filteredItems, queryParams, dataName, excludeMapperCallback }) => {
  const { data, count } = filteredItems;
  const { type, params } = {
    ...queryParams,
    params: { ...queryString.parse(queryParams.params), page: '1' }
  };
  const exclude = excludeMapperCallback
    ? excludeMapperCallback(data, type)
    : defaultExcludeMapper(data, type);
  return {
    [dataName || `${type}_query`]: {
      query: { ...params, perPage: count },
      exclude
    }
  };
};
