// @flow
import { HTTPStatusCodes } from 'constants/httpStatusCodes';
import qs from 'query-string';
import type { OperationResult } from 'types/app';
import { getErrorMessage, getId } from 'UI/utils';

import API from './API';

const BASE_ALERT = {
  isNotification: false,
  isOpen: false,
  onClick: undefined,
  onDismissClick: undefined
};

export const hasSuccessHTTPStatusCode = statusCode =>
  Number.parseInt(statusCode, 10) >= HTTPStatusCodes.Ok &&
  Number.parseInt(statusCode, 10) <= HTTPStatusCodes.MiscellaneousPersistentWarning;

export const sendRequest = async (
  url: string,
  method: string,
  data: any = null,
  alertBody: string,
  alertTitle: string
): Promise<OperationResult> => {
  const result: OperationResult = { success: false };
  try {
    const response = await API({ method, url, data });
    if (hasSuccessHTTPStatusCode(response?.status)) {
      result.success = true;
      result.data = response.data;
      result.alert = {
        severity: 'success',
        title: alertTitle || 'Awesome',
        body: alertBody,
        key: url,
        ...BASE_ALERT
      };
    }
  } catch (error) {
    result.alert = {
      severity: 'error',
      title: 'Something happened',
      body: getErrorMessage(error),
      autoHideDuration: 5000,
      key: url,
      ...BASE_ALERT
    };
  }
  return result;
};

const successStatuses = [HTTPStatusCodes.Ok, HTTPStatusCodes.Created, HTTPStatusCodes.NoContent];

/**
 * @typedef {Object} AlertConfig
 * @property {Object} [success]
 * @property {string} [success.title]
 * @property {string|function} [success.body]
 * @property {Object} [error]
 * @property {string} [error.title]
 */

/**
 * @typedef {Object} EndpointOptions
 * @property {string} path
 * @property {number} replaceId
 * @property {Object} [queryParams] Object key would be the name of the query param and object value remains as it value
 */

/**
 * @typedef {Object} RequestConfig
 * @property {string} url
 * @property {'get' | 'post' | 'put' | 'delete' | 'patch'} method
 * @property {Object} [data]
 * @property {AlertConfig} [alertConfig]
 * @property {number} [apiVersion]
 * @property {EndpointOptions} [endpointOptions = {}]
 */

/**
 * @param {RequestConfig} RequestConfig
 * @returns {Promise}
 */

export const makeRequest = async ({
  alertConfig,
  apiVersion,
  data,
  endpointOptions = {},
  method,
  url
}): Promise<OperationResult> => {
  const result: OperationResult = { success: false };
  const { success: successAlert, error: errorAlert } = alertConfig || {};
  try {
    let finalEndpoint = url;
    if (endpointOptions.path) {
      const finalUrl = endpointOptions.replaceId
        ? endpointOptions.path.replace(/:id/, endpointOptions.replaceId)
        : endpointOptions.path;
      finalEndpoint = qs.stringifyUrl(
        {
          url: finalUrl,
          query: endpointOptions.queryParams || {}
        },
        { arrayFormat: 'comma' }
      );
    }
    const response = await API({ method, url: finalEndpoint, data, apiVersion: apiVersion || 1 });
    if (successStatuses.includes(response?.status)) {
      result.success = true;
      result.data = response.data;
      result.alert = {
        severity: 'success',
        title: successAlert?.title || 'Awesome',
        body:
          typeof successAlert?.body === 'function'
            ? successAlert?.body(result.data)
            : successAlert?.body,
        key: getId(),
        ...BASE_ALERT
      };
    }
  } catch (error) {
    result.alert = {
      severity: 'error',
      title: errorAlert?.title || 'Something happened',
      body: errorAlert?.body || getErrorMessage(error),
      autoHideDuration: 10000,
      key: getId(),
      ...BASE_ALERT
    };
    if (error?.response?.data) result.error = error.response.data;
  }
  return result;
};
