import React, { useEffect } from 'react';
import { useFormContext } from 'react-hook-form';
import Grid from '@material-ui/core/Grid';
import moment from 'moment';
import strings from 'strings';
import CustomDatePicker from 'UI/components/atoms/CustomDatePicker';

import { DEFAULT_TIME_PICKER_PROPS, FORM_FIELDS_MAP } from './DateTimePickers.constants';

const STRINGS = strings.dailyPlan.drawer.form;

const validateField = ({ value, getValues, fieldsKeySufix }) => {
  const validationMessage = 'This field is required';
  if (!fieldsKeySufix) {
    return value || validationMessage;
  }
  const formValues = getValues();
  const isChecked = formValues[`isSelected-${fieldsKeySufix}`];
  return !isChecked || (isChecked && value) || validationMessage;
};

export const DateTimePickers = ({ areEnabled = true, fieldsKeySufix = null }) => {
  const { errors, register, unregister, watch, setValue, getValues } = useFormContext();

  const formValues = watch();

  const dateFieldKey = fieldsKeySufix
    ? `${FORM_FIELDS_MAP.Date.key}-${fieldsKeySufix}`
    : FORM_FIELDS_MAP.Date.key;
  const timeFieldKey = fieldsKeySufix
    ? `${FORM_FIELDS_MAP.Time.key}-${fieldsKeySufix}`
    : FORM_FIELDS_MAP.Time.key;

  const handleDateChange = (name, value) => {
    setValue(name, value ? moment(value) : null, { shouldValidate: true });
  };

  useEffect(() => {
    register(
      { name: dateFieldKey },
      { validate: value => validateField({ value, getValues, fieldsKeySufix }) }
    );
    register(
      { name: timeFieldKey },
      { validate: value => validateField({ value, getValues, fieldsKeySufix }) }
    );

    return () => {
      unregister(dateFieldKey);
      unregister(timeFieldKey);
    };
  }, [register, unregister, dateFieldKey, timeFieldKey, getValues, fieldsKeySufix]);

  return (
    <>
      <Grid item xs={6}>
        <CustomDatePicker
          disablePast
          isClearable
          error={!!errors[dateFieldKey]}
          helperText={errors[dateFieldKey]?.message}
          label={STRINGS.placeholders.setDate}
          name={dateFieldKey}
          value={formValues[dateFieldKey] || null}
          onDateChange={handleDateChange}
          placeholder={STRINGS.placeholders.setDate}
          disabled={!areEnabled}
        />
      </Grid>
      <Grid item xs={6}>
        <CustomDatePicker
          disablePast
          isClearable
          error={!!errors[timeFieldKey]}
          helperText={errors[timeFieldKey]?.message}
          label={STRINGS.placeholders.setTime}
          name={timeFieldKey}
          value={formValues[timeFieldKey] || null}
          onDateChange={handleDateChange}
          variant="keyboardTime"
          withTime
          disabled={!areEnabled}
          {...DEFAULT_TIME_PICKER_PROPS}
        />
      </Grid>
    </>
  );
};
