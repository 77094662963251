export const FORM_FIELDS_MAP = {
  Date: {
    key: 'date'
  },
  Time: {
    key: 'time'
  },
  Interview: {
    key: 'interview'
  },
  Comments: {
    key: 'comments'
  },
  IsCandidateSelected: {
    key: 'isSelected-candidate'
  },
  IsHiringAuthSelected: {
    key: 'isSelected-hiringAuthority'
  },
  AtLeastOneContact: {
    key: 'atLeastOneContact'
  }
};
